import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './views/main/main.component';
import {HeaderComponent} from './views/header/header.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {SidebarComponent} from './views/sidebar/sidebar.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FaqComponent} from './views/help/faq/faq.component';
import {LogoutComponent} from './views/logout/logout.component';
import {LogService} from './service/log.service';
import {AppDataService} from './service/app_data.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination';
import {LoginComponent} from './views/login/login.component';
import {UserComponent} from './views/user/user.component';
import {HomeComponent} from './views/home/home.component';
import {NgxMaskModule} from 'ngx-mask';
import {ReportComponent} from './views/report/report.component';
import {ReportUserComponent} from './views/report/report-user/report-user.component';
import {ReportAccessComponent} from './views/report/report-access/report-access.component';
import {ResidentsComponent} from './views/residents/residents.component';
import {ActivitiesComponent} from './views/activities/activities.component';
import {UnitiesComponent} from './views/unities/unities.component';
import {LogsComponent} from './views/logs/logs.component';
import {ResidentsDetailComponent} from './views/residents/residents-detail/residents-detail.component';
import {HeaderHttpInterceptor} from './base/header-interceptor';
import {ResidentComponent} from './views/residents/residents-detail/resident/resident.component';
import {NursingComponent} from './views/residents/residents-detail/nursing/nursing.component';
import {NutritionComponent} from './views/residents/residents-detail/nutrition/nutrition.component';
import {PhysiotherapyComponent} from './views/residents/residents-detail/physiotherapy/physiotherapy.component';
import {PsychologyComponent} from './views/residents/residents-detail/psychology/psychology.component';
import {MedicineComponent} from './views/residents/residents-detail/medicine/medicine.component';
import {ResponsibleComponent} from './views/residents/residents-detail/responsible/responsible.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {VitalComponent} from './views/residents/residents-detail/vital/vital.component';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {RecoveryComponent} from './views/recovery/recovery.component';
import {DietComponent} from './views/residents/residents-detail/diet/diet.component';
import {ResidentNursingComponent} from './views/residents/residents-detail/resident/resident-nursing/resident-nursing.component';
import {ResidentPhysiotherapyComponent} from './views/residents/residents-detail/resident/resident-physiotherapy/resident-physiotherapy.component';
import {ResidentPsychologyComponent} from './views/residents/residents-detail/resident/resident-psychology/resident-psychology.component';
import {ResidentMedicineComponent} from './views/residents/residents-detail/resident/resident-medicine/resident-medicine.component';
import {ResidentNutritionComponent} from './views/residents/residents-detail/resident/resident-nutrition/resident-nutrition.component';
import { SpeechTherapyComponent } from './views/residents/residents-detail/speech-therapy/speech-therapy.component';
import { DoctorEvaluationComponent } from './views/residents/residents-detail/doctor-evaluation/doctor-evaluation.component';
import { DentistComponent } from './views/residents/residents-detail/dentist/dentist.component';
import { EvolutionComponent } from './views/residents/residents-detail/evolution/evolution.component';
import { MedicineTableComponent } from './views/residents/residents-detail/medicine-table/medicine-table.component';

// import { RecoveryComponent } from './views/recovery/recovery.component';

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        HeaderComponent,
        SidebarComponent,
        FaqComponent,
        LogoutComponent,
        LoginComponent,
        UserComponent,
        HomeComponent,
        ReportComponent,
        ReportUserComponent,
        ReportAccessComponent,
        ResidentsComponent,
        ActivitiesComponent,
        UnitiesComponent,
        LogsComponent,
        ResidentsDetailComponent,
        ResidentComponent,
        NursingComponent,
        NutritionComponent,
        PhysiotherapyComponent,
        PsychologyComponent,
        MedicineComponent,
        ResponsibleComponent,
        VitalComponent,
        RecoveryComponent,
        DietComponent,
        ResidentNursingComponent,
        ResidentPhysiotherapyComponent,
        ResidentPsychologyComponent,
        ResidentMedicineComponent,
        ResidentNutritionComponent,
        SpeechTherapyComponent,
        DoctorEvaluationComponent,
        DentistComponent,
        EvolutionComponent,
        MedicineTableComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        AngularSvgIconModule.forRoot(),
        SlickCarouselModule,
        NgxMaskModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgMultiSelectDropDownModule.forRoot(),
        NgxPaginationModule,
        CurrencyMaskModule
    ],
    providers: [
        LogService,
        AppDataService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderHttpInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
