<div class="bg-gray pt-3">
    <nav class="mb-4 px-3">
        <div class="nav nav-tabs" id="nav-tab-nursing" role="tablist">
            <a class="nav-item nav-link active" id="nav-home-nursing-tab" data-toggle="tab" href="#nav-home-nursing"
               role="tab"
               aria-controls="nav-home" aria-selected="true">Enfermagem</a>
            <a class="nav-item nav-link" id="nav-profile-nursing-tab" data-toggle="tab" href="#nav-profile-nursing"
               role="tab" aria-controls="nav-profile-nursing" aria-selected="false">Alergia</a>
            <a class="nav-item nav-link" id="nav-contact-nursing-tab" data-toggle="tab" href="#nav-contact-nursing"
               role="tab" aria-controls="nav-contact-nursing" aria-selected="false">Integridade Cutânea</a>
        </div>
    </nav>
</div>

<div class="card-body" id="nursingDisabled">
    <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-home-nursing" role="tabpanel" aria-labelledby="nav-home-tab">
            <div id="accordionH">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="input-material">
                        <input id="sickness" class="form-control" type="text" maxlength="10000"
                               autocomplete="off"
                               [(ngModel)]="model.baseDisease" required/>
                        <label for="sickness">Doença de Base</label>
                    </div>
                </div>

                <div class="card shadow-none my-3">
                    <div class="card-header" id="headingTwo1B">
                        <h5 class="mb-0">
                            <button class="btn btn-block btn-link text-primary font-weight-bold text-left"
                                    data-toggle="collapse" data-target="#collapseTwo1B" aria-expanded="true"
                                    aria-controls="collapseTwo1B">
                                Outras Patologias
                            </button>
                        </h5>
                    </div>
                    <div id="collapseTwo1B" class="collapse " aria-labelledby="headingTwo1B"
                         data-parent="#accordionH">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Doença Mental</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="mental" name="mental"
                                                       class="custom-control-input"
                                                       [checked]="model.mentalDisease === 'yes'"
                                                       (change)="model.mentalDisease = 'yes'">
                                                <label class="custom-control-label" for="mental">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="nomental" name="nomental"
                                                       class="custom-control-input"
                                                       [checked]="model.mentalDisease === 'no'"
                                                       (change)="model.mentalDisease = 'no'">
                                                <label class="custom-control-label"
                                                       for="nomental">Não</label>
                                            </div>
                                            <div class="input-material bg-gray mb-0">
                                                <input id="mentaltype" class="bg-gray form-control"
                                                       type="text" maxlength="10000" autocomplete="off"
                                                       required name="mentaltype"
                                                       [(ngModel)]="model.mentalDiseaseType"/>
                                                <label for="mentaltype">Tipo</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Função Cognitiva Aparentemente
                                                    Preservada</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="cognitive" name="cognitive"
                                                       [checked]="model.cognitiveFunction === 'yes'"
                                                       (change)="model.cognitiveFunction = 'yes'"
                                                       class="custom-control-input">
                                                <label class="custom-control-label" for="cognitive">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="noCognitive" name="noCognitive"
                                                       [checked]="model.cognitiveFunction === 'no'"
                                                       (change)="model.cognitiveFunction = 'no'"
                                                       class="custom-control-input">
                                                <label class="custom-control-label"
                                                       for="noCognitive">Não</label>
                                            </div>

                                            <div class="input-material bg-gray mb-0">
                                                <input id="cognitivetype" class="bg-gray form-control"
                                                       type="text" maxlength="10000" autocomplete="off"
                                                       required name="mentaltype"
                                                       [(ngModel)]="model.cognitiveFunctionType"/>
                                                <label for="mentaltype">Tipo</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Hipertensão Arterial</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="hiper" name="hiper"
                                                       class="custom-control-input"
                                                       [checked]="model.arterialHypertension === 'yes'"
                                                       (change)="model.arterialHypertension = 'yes'">
                                                <label class="custom-control-label" for="hiper">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="noHiper" name="noHiper"
                                                       [checked]="model.arterialHypertension === 'no'"
                                                       (change)="model.arterialHypertension = 'no'"
                                                       class="custom-control-input">
                                                <label class="custom-control-label" for="noHiper">Não</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Diabetes</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="diabetes" name="diabetes"
                                                       class="custom-control-input"
                                                       [checked]="model.diabetes === 'yes'"
                                                       (change)="model.diabetes = 'yes'">
                                                <label class="custom-control-label" for="diabetes">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="nodiabetes" name="nodiabetes"
                                                       class="custom-control-input"
                                                       [checked]="model.diabetes === 'no'"
                                                       (change)="model.diabetes = 'no'">
                                                <label class="custom-control-label" for="nodiabetes">Não</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Cardiopatia</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="cardio" name="cardio"
                                                       class="custom-control-input"
                                                       [checked]="model.heartDisease === 'yes'"
                                                       (change)="model.heartDisease = 'yes'">
                                                <label class="custom-control-label" for="cardio">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="noCardio" name="noCardio"
                                                       [checked]="model.heartDisease === 'no'"
                                                       (change)="model.heartDisease = 'no'"
                                                       class="custom-control-input">
                                                <label class="custom-control-label" for="noCardio">Não</label>
                                            </div>
                                            <div class="input-material bg-gray mb-0">
                                                <input id="cardiopatyType" class="bg-gray form-control"
                                                       type="text" maxlength="10000" autocomplete="off"
                                                       required name="cardiopatyType"
                                                       [(ngModel)]="model.heartDiseaseType"/>
                                                <label for="cardiopatyType">Tipo</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">DST</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="dst" name="dst"
                                                       class="custom-control-input"
                                                       [checked]="model.sexuallyTransmittedDisease === 'yes'"
                                                       (change)="model.sexuallyTransmittedDisease = 'yes'">
                                                <label class="custom-control-label" for="dst">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="nodst" name="nodst"
                                                       class="custom-control-input"
                                                       [checked]="model.sexuallyTransmittedDisease === 'no'"
                                                       (change)="model.sexuallyTransmittedDisease = 'no'">
                                                <label class="custom-control-label" for="nodst">Não</label>
                                            </div>
                                            <div class="input-material bg-gray mb-0">
                                                <input id="dstType" class="bg-gray form-control" type="text"
                                                       maxlength="10000" autocomplete="off" required
                                                       name="dstType"
                                                       [(ngModel)]="model.sexuallyTransmittedDiseaseType"/>
                                                <label for="dstType">Qual?</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Faz uso de Insulina</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="insulin" name="insulin"
                                                       class="custom-control-input"
                                                       [checked]="model.insulin === 'yes'"
                                                       (change)="model.insulin = 'yes'">
                                                <label class="custom-control-label" for="insulin">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="noinsulin" name="noinsulin"
                                                       class="custom-control-input"
                                                       [checked]="model.insulin === 'no'"
                                                       (change)="model.insulin = 'no'">
                                                <label class="custom-control-label" for="noinsulin">Não</label>
                                            </div>
                                            <div class="input-material bg-gray mb-0">
                                                <input id="insulinType" class="bg-gray form-control" type="text"
                                                       maxlength="10000" autocomplete="off" required
                                                       name="insulinType"
                                                       [(ngModel)]="model.insulinType"/>
                                                <label for="insulinType">Tipo:</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Doenças Renais:</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="renais" name="renais"
                                                       class="custom-control-input"
                                                       [checked]="model.kidneyDisease === 'yes'"
                                                       (change)="model.kidneyDisease = 'yes'">
                                                <label class="custom-control-label" for="renais">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="norenais" name="norenais"
                                                       class="custom-control-input"
                                                       [checked]="model.kidneyDisease === 'no'"
                                                       (change)="model.kidneyDisease = 'no'">
                                                <label class="custom-control-label" for="norenais">Não</label>
                                            </div>
                                            <div class="input-material bg-gray mb-0">
                                                <input id="renalType" class="bg-gray form-control" type="text"
                                                       maxlength="10000" [(ngModel)]="model.othersDiseases"
                                                       autocomplete="off" required name="renalType"/>
                                                <label for="renalType">Qual:</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Artrite</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="artrite" name="artrite"
                                                       class="custom-control-input"
                                                       [checked]="model.arthritis === 'yes'"
                                                       (change)="model.arthritis = 'yes'">
                                                <label class="custom-control-label" for="artrite">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="noartrite" name="noartrite"
                                                       class="custom-control-input"
                                                       [checked]="model.arthritis === 'no'"
                                                       (change)="model.arthritis = 'no'">
                                                <label class="custom-control-label" for="noartrite">Não</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Artrose</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="artrose" name="artrose"
                                                       class="custom-control-input"
                                                       [checked]="model.arthrosis === 'yes'"
                                                       (change)="model.arthrosis = 'yes'">
                                                <label class="custom-control-label" for="artrose">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="noartrose" name="noartrose"
                                                       class="custom-control-input"
                                                       [checked]="model.arthrosis === 'no'"
                                                       (change)="model.arthrosis = 'no'">
                                                <label class="custom-control-label" for="noartrose">Não</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Osteoporose</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="osteoporose" name="osteoporose"
                                                       class="custom-control-input"
                                                       [checked]="model.osteoporosis === 'yes'"
                                                       (change)="model.osteoporosis = 'yes'">
                                                <label class="custom-control-label"
                                                       for="osteoporose">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="noosteoporose" name="noosteoporose"
                                                       class="custom-control-input"
                                                       [checked]="model.osteoporosis === 'no'"
                                                       (change)="model.osteoporosis = 'no'">
                                                <label class="custom-control-label"
                                                       for="noosteoporose">Não</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Ulceras estomacais</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="ulceras" name="ulceras"
                                                       class="custom-control-input"
                                                       [checked]="model.stomachUlcers === 'yes'"
                                                       (change)="model.stomachUlcers = 'yes'">
                                                <label class="custom-control-label" for="ulceras">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="noulceras" name="noulceras"
                                                       class="custom-control-input"
                                                       [checked]="model.stomachUlcers === 'no'"
                                                       (change)="model.stomachUlcers = 'no'">
                                                <label class="custom-control-label" for="noulceras">Não</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-12 col-lg-12">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Possui Histórico Familiar do
                                                    diagnóstico?</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="history" name="history"
                                                       class="custom-control-input"
                                                       [checked]="model.familyDiseaseHistoric === 'yes'"
                                                       (change)="model.familyDiseaseHistoric = 'yes'">
                                                <label class="custom-control-label" for="history">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="nohistory" name="nohistory"
                                                       class="custom-control-input"
                                                       [checked]="model.familyDiseaseHistoric === 'no'"
                                                       (change)="model.familyDiseaseHistoric = 'no'">
                                                <label class="custom-control-label" for="nohistory">Não</label>
                                            </div>
                                            <div class="input-material bg-gray mb-0">
                                                <input id="familiarParental" class="bg-gray form-control"
                                                       type="text"
                                                       [(ngModel)]="model.familyDiseaseKinship"
                                                       maxlength="10000" autocomplete="off" required
                                                       name="familiarParental"/>
                                                <label for="familiarParental">Qual o parentesco?</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Déficit Auditivo</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="defAuditivo" name="defAuditivo"
                                                       class="custom-control-input"
                                                       [checked]="model.hearingDeficit === 'yes'"
                                                       (change)="model.hearingDeficit = 'yes'">
                                                <label class="custom-control-label"
                                                       for="defAuditivo">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="noDefauditivo" name="noDefauditivo"
                                                       class="custom-control-input"
                                                       [checked]="model.hearingDeficit === 'no'"
                                                       (change)="model.hearingDeficit = 'no'">
                                                <label class="custom-control-label"
                                                       for="noDefauditivo">Não</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Possui Aparelho Auditivo</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="auditivo" name="auditivo"
                                                       class="custom-control-input"
                                                       [checked]="model.hearingAid === 'yes'"
                                                       (change)="model.hearingAid = 'yes'">
                                                <label class="custom-control-label" for="auditivo">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="noauditivo" name="noauditivo"
                                                       class="custom-control-input"
                                                       [checked]="model.hearingAid === 'no'"
                                                       (change)="model.hearingAid = 'no'">
                                                <label class="custom-control-label" for="noauditivo">Não</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Déficit Visual</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="defVisual" name="defVisual"
                                                       class="custom-control-input"
                                                       [checked]="model.visualDeficit === 'yes'"
                                                       (change)="model.visualDeficit = 'yes'">
                                                <label class="custom-control-label" for="defVisual">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="noDefVisual" name="noDefVisual"
                                                       class="custom-control-input"
                                                       [checked]="model.visualDeficit === 'no'"
                                                       (change)="model.visualDeficit = 'no'">
                                                <label class="custom-control-label"
                                                       for="noDefVisual">Não</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Possui óculos?</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="oculos" name="oculos"
                                                       class="custom-control-input"
                                                       [checked]="model.visualAid === 'yes'"
                                                       (change)="model.visualAid = 'yes'">
                                                <label class="custom-control-label" for="oculos">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="nooculos" name="nooculos"
                                                       class="custom-control-input"
                                                       [checked]="model.visualAid === 'no'"
                                                       (change)="model.visualAid = 'no'">
                                                <label class="custom-control-label" for="nooculos">Não</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Protese dentaria?</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="dentaria" name="dentaria"
                                                       class="custom-control-input"
                                                       [checked]="model.dentalProsthesis === 'yes'"
                                                       (change)="model.dentalProsthesis = 'yes'">
                                                <label class="custom-control-label" for="dentaria">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="nodentaria" name="nodentaria"
                                                       class="custom-control-input"
                                                       [checked]="model.dentalProsthesis === 'no'"
                                                       (change)="model.dentalProsthesis = 'no'">
                                                <label class="custom-control-label" for="nodentaria">Não</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="superior" name="superior"
                                                       class="custom-control-input"
                                                       [checked]="model.dentalProsthesisType === 'higher'"
                                                       (change)="model.dentalProsthesisType = 'higher'">
                                                <label class="custom-control-label"
                                                       for="superior">Superior</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="inferior" name="inferior"
                                                       class="custom-control-input"
                                                       [checked]="model.dentalProsthesisType === 'inferior'"
                                                       (change)="model.dentalProsthesisType = 'inferior'">
                                                <label class="custom-control-label"
                                                       for="inferior">Inferior</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="proteseTotal" name="proteseTotal"
                                                       class="custom-control-input"
                                                       [checked]="model.dentalProsthesisType === 'all'"
                                                       (change)="model.dentalProsthesisType = 'all'">
                                                <label class="custom-control-label"
                                                       for="proteseTotal">Total</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Faz uso de sonda para se
                                                    Alimentar?</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="alimento" name="alimento"
                                                       class="custom-control-input"
                                                       [checked]="model.foodProbe === 'yes'"
                                                       (change)="model.foodProbe = 'yes'">
                                                <label class="custom-control-label" for="alimento">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="noalimento" name="noalimento"
                                                       class="custom-control-input"
                                                       [checked]="model.foodProbe === 'no'"
                                                       (change)="model.foodProbe = 'no'">
                                                <label class="custom-control-label" for="noalimento">Não</label>
                                            </div>
                                            <div class="input-material bg-gray mb-0">
                                                <input id="sondaALimentarType" class="bg-gray form-control"
                                                       type="text" maxlength="10000" autocomplete="off"
                                                       required name="sondaALimentarType"
                                                       [(ngModel)]="model.foodProbeType"/>
                                                <label for="sondaALimentarType">Tipo:</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12">
                                                <p class="text-gray-primary mb-0">A quanto Tempo?</p>
                                            </div>
                                            <div class="input-material bg-gray mb-0">
                                                <input id="sondaALimentarTime" class="bg-gray form-control"
                                                       type="text" maxlength="10000" autocomplete="off"
                                                       [(ngModel)]="model.foodProbeTime"
                                                       required name="sondaALimentarTime"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Faz uso de sonda para
                                                    Eliminações?</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="elimination" name="elimination"
                                                       class="custom-control-input"
                                                       [checked]="model.eliminationProbe === 'yes'"
                                                       (change)="model.eliminationProbe = 'yes'">
                                                <label class="custom-control-label"
                                                       for="elimination">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="noelimination" name="noelimination"
                                                       class="custom-control-input"
                                                       [checked]="model.eliminationProbe === 'no'"
                                                       (change)="model.eliminationProbe = 'no'">
                                                <label class="custom-control-label"
                                                       for="noelimination">Não</label>
                                            </div>

                                            <div class="input-material bg-gray mb-0">
                                                <input id="sondaEliminationType" class="bg-gray form-control"
                                                       type="text"
                                                       maxlength="10000" autocomplete="off" required
                                                       name="sondaEliminationType"
                                                       [(ngModel)]="model.eliminationProbeType"/>
                                                <label for="sondaEliminationType">Tipo:</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12">
                                                <p class="text-gray-primary mb-0">A quanto Tempo?</p>
                                            </div>
                                            <div class="input-material bg-gray mb-0">
                                                <input id="sondaEliminationTime" class="bg-gray form-control"
                                                       type="text"
                                                       maxlength="10000" autocomplete="off" required
                                                       [(ngModel)]="model.eliminationProbeTime"
                                                       name="sondaEliminationTime"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Faz uso de Fraldas?</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="fraldas" name="fraldas"
                                                       class="custom-control-input"
                                                       [checked]="model.diaper === 'yes'"
                                                       (change)="model.diaper = 'yes'">
                                                <label class="custom-control-label" for="fraldas">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="nofraldas" name="nofraldas"
                                                       class="custom-control-input"
                                                       [checked]="model.diaper === 'no'"
                                                       (change)="model.diaper = 'no'">
                                                <label class="custom-control-label" for="nofraldas">Não</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class=" col-12">
                                        <p class="text-gray-primary">Periodo:</p>
                                    </div>
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="fraldasdiurnas" name="fraldasdiurnas"
                                                       class="custom-control-input"
                                                       [checked]="model.diaperTime === 'daytime'"
                                                       (change)="model.diaperTime = 'daytime'">
                                                <label class="custom-control-label"
                                                       for="fraldasdiurnas">Diurna</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="fraldasnoturnas" name="fraldasnoturnas"
                                                       class="custom-control-input"
                                                       [checked]="model.diaperTime === 'night'"
                                                       (change)="model.diaperTime = 'night'">
                                                <label class="custom-control-label"
                                                       for="fraldasnoturnas">Noturna</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="fraldasconstantes"
                                                       name="fraldasconstantes"
                                                       class="custom-control-input"
                                                       [checked]="model.diaperTime === 'constant'"
                                                       (change)="model.diaperTime = 'constant'">
                                                <label class="custom-control-label"
                                                       for="fraldasconstantes">Constante</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Faz uso de Absorventes?</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="absorventes" name="absorventes"
                                                       class="custom-control-input"
                                                       [checked]="model.tampons === 'yes'"
                                                       (change)="model.tampons = 'yes'">
                                                <label class="custom-control-label"
                                                       for="absorventes">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="noabsorventes" name="noabsorventes"
                                                       class="custom-control-input"
                                                       [checked]="model.tampons === 'no'"
                                                       (change)="model.tampons = 'no'">
                                                <label class="custom-control-label"
                                                       for="noabsorventes">Não</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <p class="text-gray-primary">Periodo:</p>
                                        </div>
                                        <div class="col-12">
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="absorventediurnas"
                                                       name="absorventediurnas"
                                                       class="custom-control-input"
                                                       [checked]="model.tamponsTime === 'day'"
                                                       (change)="model.tamponsTime = 'day'">
                                                <label class="custom-control-label"
                                                       for="absorventediurnas">Diurna</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="absorventenoturna"
                                                       name="absorventenoturna"
                                                       class="custom-control-input"
                                                       [checked]="model.tamponsTime === 'night'"
                                                       (change)="model.tamponsTime = 'night'">
                                                <label class="custom-control-label"
                                                       for="absorventenoturna">Noturna</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="absorventeconstante"
                                                       name="absorventeconstante"
                                                       class="custom-control-input"
                                                       [checked]="model.tamponsTime === 'constant'"
                                                       (change)="model.tamponsTime = 'constant'">
                                                <label class="custom-control-label"
                                                       for="absorventeconstante">Constante</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Hábito Urinário:</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="continente" name="continente"
                                                       class="custom-control-input"
                                                       [checked]="model.urinaryHabit === 'continent'"
                                                       (change)="model.urinaryHabit = 'continent'">
                                                <label class="custom-control-label"
                                                       for="continente">Continente</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="incontinente" name="incontinente"
                                                       class="custom-control-input"
                                                       [checked]="model.urinaryHabit === 'incontinent'"
                                                       (change)="model.urinaryHabit = 'incontinent'">
                                                <label class="custom-control-label"
                                                       for="incontinente">Incontinente</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="dificuldade" name="dificuldade"
                                                       class="custom-control-input"
                                                       [checked]="model.urinaryHabit === 'difficulty'"
                                                       (change)="model.urinaryHabit = 'difficulty'">
                                                <label class="custom-control-label" for="dificuldade">Dificuldade
                                                    para Urinar</label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Hábito Intestinal:</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="intestinalNormal"
                                                       name="intestinalNormal"
                                                       class="custom-control-input"
                                                       [checked]="model.bowelHabit === 'normal'"
                                                       (change)="model.bowelHabit = 'normal'">
                                                <label class="custom-control-label"
                                                       for="intestinalNormal">Normal</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="intestinalIncontinente"
                                                       name="intestinalIncontinente"
                                                       class="custom-control-input"
                                                       [checked]="model.bowelHabit === 'incontinent'"
                                                       (change)="model.bowelHabit = 'incontinent'">
                                                <label class="custom-control-label"
                                                       for="intestinalIncontinente">Incontinente</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="constipacao" name="constipacao"
                                                       class="custom-control-input"
                                                       [checked]="model.bowelHabit === 'constipation'"
                                                       (change)="model.bowelHabit = 'constipation'">
                                                <label class="custom-control-label"
                                                       for="constipacao">Constipação</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="diarreia" name="diarreia"
                                                       class="custom-control-input"
                                                       [checked]="model.bowelHabit === 'diarrhea'"
                                                       (change)="model.bowelHabit = 'diarrhea'">
                                                <label class="custom-control-label"
                                                       for="diarreia">Diarreia</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 my-3 col-md-12 col-lg-12">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="col-12 px-0 pb-3">
                                                <p class="text-gray-primary mb-0">Tipo de Sono:</p>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="sleepNormal" name="sleepNormal"
                                                       class="custom-control-input"
                                                       [checked]="model.sleep === 'normal'"
                                                       (change)="model.sleep = 'normal'">
                                                <label class="custom-control-label"
                                                       for="sleepNormal">Normal</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="insonia" name="insonia"
                                                       class="custom-control-input"
                                                       [checked]="model.sleep === 'insomnia'"
                                                       (change)="model.sleep = 'insomnia'">
                                                <label class="custom-control-label"
                                                       for="insonia">Insônia</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="interrompido" name="interrompido"
                                                       class="custom-control-input"
                                                       [checked]="model.sleep === 'interrupted'"
                                                       (change)="model.sleep = 'interrupted'">
                                                <label class="custom-control-label" for="interrompido">Sono
                                                    Interrompido</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="hipersonia" name="hipersonia"
                                                       class="custom-control-input"
                                                       [checked]="model.sleep === 'hypersomnia'"
                                                       (change)="model.sleep = 'hypersomnia'">
                                                <label class="custom-control-label"
                                                       for="hipersonia">Hiperssonia</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                                        <div class="col-12">
                                            <p class="text-gray-primary mb-0">Tipo de Cirurgia</p>
                                        </div>
                                        <div class="col-12 mt-2" *ngFor="let t of previousSurgeries">
                                            <div class="d-flex justify-content-between">
                                                <p>
                                                    {{t}}
                                                </p>
                                                <div>
                                                    <a href="javascript:void(0)" class="btn btn-danger btn-custom mb-1"
                                                       *ngIf="!isNullOrUndefined(t)"
                                                       (click)="removeItemOnce(t)">
                                                        x
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex col-12">
                                            <div class="input-material bg-gray mb-0 ">
                                                <input id="surgeryType" class="form-control bg-gray" type="text"
                                                       maxlength="10000"
                                                       autocomplete="off" [(ngModel)]="surgeries" required/>
                                                <label for="surgeryType"></label>
                                            </div>
                                            <div>
                                                <button [disabled]="!surgeries" href="javascript:void(0)"
                                                        class="btn btn-success btn-custom" (click)="add()">
                                                    +
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                                        <div class="col-12">
                                            <p class="text-gray-primary mb-0">Em que ano ?</p>
                                        </div>
                                        <div class="col-12 mt-2" *ngFor="let t of previousSurgeriesTime">
                                            <div class="d-flex justify-content-between">
                                                <p>
                                                    {{t}}
                                                </p>
                                                <div>
                                                    <a href="javascript:void(0)" class="btn btn-danger btn-custom mb-1"
                                                       *ngIf="!isNullOrUndefined(t)"
                                                       (click)="removeTime(t)">
                                                        x
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex col-12">
                                            <div class="input-material bg-gray mb-0 ">
                                                <input id="ortopedicaTime" class="form-control bg-gray" type="text"
                                                       maxlength="10000"
                                                       autocomplete="off" [(ngModel)]="times" required mask="0000"/>
                                                <label for="ortopedicaTime"></label>
                                            </div>
                                            <div>
                                                <button [disabled]="!times" href="javascript:void(0)"
                                                        class="btn btn-success btn-custom" (click)="addTime()">
                                                    +
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="card shadow-none my-3">
                    <div class="card-header" id="headingThree1B">
                        <h5 class="mb-0">
                            <button class="btn btn-block btn-link text-primary font-weight-bold text-left"
                                    data-toggle="collapse" data-target="#collapseThree1B" aria-expanded="true"
                                    aria-controls="collapseThree1B">
                                Exames
                            </button>
                        </h5>
                    </div>
                    <div id="collapseThree1B" class="collapse" aria-labelledby="headingThree1B"
                         data-parent="#accordionH">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                                        <div class="col-12 pb-3">
                                            <p class="text-gray-primary mb-0">Exames Laboratoriais:</p>
                                        </div>
                                        <div class="col-12 d-flex align-items-center">
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="labExamYes" name="labExamYes"
                                                       class="custom-control-input"
                                                       [checked]="model.labExam === 'yes'"
                                                       (change)="model.labExam = 'yes'">
                                                <label class="custom-control-label" for="labExamYes">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="labExamNo" name="labExamNo"
                                                       class="custom-control-input"
                                                       [checked]="model.labExam === 'no'"
                                                       (change)="model.labExam = 'no'">
                                                <label class="custom-control-label" for="labExamNo">Não</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                                        <div class="col-12 pb-3">
                                            <p class="text-gray-primary mb-0">Exames de Imagem:</p>
                                        </div>
                                        <div class="col-12 d-flex align-items-center">
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="labExamImgYes" name="labExamImgYes"
                                                       class="custom-control-input"
                                                       [checked]="model.imageExam === 'yes'"
                                                       (change)="model.imageExam = 'yes'">
                                                <label class="custom-control-label"
                                                       for="labExamImgYes">Sim</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="labExamImgNo" name="labExamImgNo"
                                                       class="custom-control-input"
                                                       [checked]="model.imageExam === 'no'"
                                                       (change)="model.imageExam = 'no'">
                                                <label class="custom-control-label"
                                                       for="labExamImgNo">Não</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-12 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="input-material bg-gray mb-0">
                                                <input id="hospitalVisitFrequency" class="bg-gray form-control"
                                                       type="text" maxlength="10000" autocomplete="off"
                                                       [(ngModel)]="model.hospitalVisitFrequency"
                                                       required name="hospitalVisitFrequency"/>
                                                <label for="hospitalVisitFrequency">Com que frequência vai ao
                                                    hospital? </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-12 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="input-material bg-gray mb-0">
                                                <input id="trustedDoctor" class="bg-gray form-control"
                                                       type="text" maxlength="10000" autocomplete="off"
                                                       [(ngModel)]="model.trustedDoctor"
                                                       required name="trustedDoctor"/>
                                                <label for="trustedDoctor">Com que médico se trata? </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-12 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="input-material bg-gray mb-0">
                                                <input id="medicalInsurance" class="bg-gray form-control"
                                                       type="text" maxlength="10000" autocomplete="off"
                                                       [(ngModel)]="model.medicalInsurance"
                                                       required name="medicalInsurance"/>
                                                <label for="medicalInsurance">Possui Convênio Médico? </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 my-3 col-md-12 col-lg-6">
                                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                        <div class="col-12">
                                            <div class="input-material bg-gray mb-0">
                                                <input id="coveredHospitals" class="bg-gray form-control"
                                                       type="text" maxlength="10000" autocomplete="off"
                                                       [(ngModel)]="model.coveredHospitals"
                                                       required name="coveredHospitals"/>
                                                <label for="coveredHospitals">Qual hospital de
                                                    atendimento? </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xs-5 ml-auto mt-5" *ngIf="!isCaregiver">
                    <a href="javascript:void(0)" class="btn btn-success btn-ico ml-2 btn-block" (click)="actionSave()">
                        <svg-icon class="svg-white" src="assets/svg/check-circle.svg"></svg-icon>
                        Salvar
                    </a>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="nav-profile-nursing" role="tabpanel" aria-labelledby="nav-profile-tab">
            <div class="row mx-0 align-items-center">
                <div class="col-sm-12 col-lg-12">
                    <div class="rounded py-3 row mx-0 mb-3 align-items-center">
                        <div class="col-12">
                            <p class="text-gray-primary">Possui alergia?</p>
                        </div>
                        <div class="col-12 d-flex align-items-center">
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="allergyCheck" name="allergy" class="custom-control-input"
                                       [checked]="allergyCheck === true " (change)="allergyCheck = true ">
                                <label class="custom-control-label" for="allergyCheck">Sim</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="noallergy" name="noallergy" class="custom-control-input"
                                       [checked]="allergyCheck === false " (change)="allergyCheck = false">
                                <label class="custom-control-label" for="noallergy">Não</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                        <div class="col-12">
                            <div class="input-material bg-gray mb-0">
                                <input id="drugAllergy" class="bg-gray form-control" type="text"
                                       maxlength="10000" autocomplete="off" name="drugAllergy"
                                       required
                                       [(ngModel)]="drug.allergy"/>
                                <label for="drugAllergy">Alergia a Medicamentos</label>
                            </div>
                            <div class="input-material bg-gray mb-0 mt-1">
                                <input id="drugAllergyDescription" class="bg-gray form-control" type="text"
                                       maxlength="10000" autocomplete="off" name="drugAllergy"
                                       required
                                       [(ngModel)]="drug.description"/>
                                <label for="drugAllergy">Descrição</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                        <div class="col-12">
                            <div class="input-material bg-gray mb-0">
                                <input id="perfumeAllergy" class="bg-gray form-control" type="text"
                                       maxlength="10000" autocomplete="off" name="perfumeAllergy"
                                       required
                                       [(ngModel)]="perfume.allergy"/>
                                <label for="perfumeAllergy">Alergia a Perfumes</label>
                            </div>
                            <div class="input-material bg-gray mb-0 mt-1">
                                <input id="perfumeDescription" class="bg-gray form-control" type="text"
                                       maxlength="10000" autocomplete="off" name="perfumeAllergy"
                                       required
                                       [(ngModel)]="perfume.description"/>
                                <label for="perfumeDescription">Descrição</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                        <div class="col-12">
                            <div class="input-material bg-gray mb-0">
                                <input id="foodAllergy" class="bg-gray form-control" type="text"
                                       maxlength="10000" autocomplete="off" name="foodAllergy"
                                       required
                                       [(ngModel)]="food.allergy"/>
                                <label for="foodAllergy">Alergia a Alimentos</label>
                            </div>
                            <div class="input-material bg-gray mb-0">
                                <input id="foodAllergyDescription" class="bg-gray form-control" type="text"
                                       maxlength="10000" autocomplete="off" name="foodAllergy"
                                       required
                                       [(ngModel)]="food.description"/>
                                <label for="foodAllergyDescription">Descrição</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                        <div class="col-12">
                            <div class="input-material bg-gray mb-0">
                                <input id="othersAllergy" class="bg-gray form-control" type="text"
                                       maxlength="10000" autocomplete="off" name="othersAllergy"
                                       required
                                       [(ngModel)]="other.allergy"/>
                                <label for="othersAllergy">Outros</label>
                            </div>
                            <div class="input-material bg-gray mb-0 mt-1">
                                <input id="othersAllergyDescrição" class="bg-gray form-control" type="text"
                                       maxlength="10000" autocomplete="off" name="othersAllergy"
                                       required
                                       [(ngModel)]="other.description"/>
                                <label for="othersAllergy">Descrição</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-5 ml-auto mt-5" *ngIf="!isCaregiver">
                <a href="javascript:void(0)" class="btn btn-success btn-ico ml-2 btn-block"
                   (click)="actionSaveAllergy()">
                    <svg-icon class="svg-white" src="assets/svg/check-circle.svg"></svg-icon>
                    Salvar
                </a>
            </div>
        </div>
        <div class="tab-pane fade" id="nav-contact-nursing" role="tabpanel" aria-labelledby="nav-contact-tab">
            <div class="mb-4 text-right" *ngIf="!isCaregiver">
                <a href="javascript:void(0)" class="btn btn-success btn-ico ml-2 btn-sm" (click)="handlerButtonSkin()">
                    <svg-icon class="svg-white" src="assets/svg/plus-circle.svg"></svg-icon>
                    Cadastrar Integridade
                </a>
            </div>

            <div class="row mx-0">
                <div class="table-responsive">
                    <table class="table ">
                        <thead>
                        <tr>
                            <th class="align-middle" scope="col">Lesão Apresentada</th>
                            <th class="align-middle" scope="col">Lesão Apresentada Local</th>
                            <th class="align-middle" scope="col">Duração da Lesão</th>
                            <th class="align-middle" scope="col">Tratamento anterior</th>
                            <th class="align-middle" scope="col">Tratamento anterior qual?</th>
                            <th class="align-middle text-center" scope="col">Opções</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngIf="isNullOrUndefined(modelSkins)">
                            <td colspan="6">
                                <p class="pt-5 pb-5 text-center">
                                    Nenhum registro encontrado.
                                </p>
                            </td>
                        </tr>
                        <tr *ngFor="let r of modelSkins">
                            <td>
                                <span *ngIf="r.presentedInjuryType == 'allergy'">Alergia</span>
                                <span *ngIf="r.presentedInjuryType == 'pressureUlcer'">Úlcera de Pressão</span>
                                <span *ngIf="r.presentedInjuryType == 'ringworm'">Micose</span>
                            </td>
                            <td>{{r.place}}</td>
                            <td>{{handlerDuration(r.presentedInjuryTime)}}</td>
                            <td>{{r.previousTreatment === 'yes' ? 'Sim' : 'Não'}}</td>
                            <td>{{r.previousTreatmentType}}</td>
                            <td class="text-center">
                                <a href="javascript:void('detail')" class="btn"
                                   (click)="handlerDetailSkin(r)">
                                    <svg-icon class="ico-edit" src="assets/svg/edit.svg"></svg-icon>
                                </a>
                                <a href="javascript:void('delete')" class="btn ml-3"
                                   (click)="deleteSkin(r.id)">
                                    <svg-icon class="ico-close" src="assets/svg/close.svg"></svg-icon>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="registerSkinIntegrity" tabindex="-1" role="dialog" aria-labelledby="registerSkinIntegrity"
     aria-hidden="true">
    <div class="modal-dialog modal-75 modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    <span>Integridade Cutânea</span>
                </h5>
                <button type="button" class="close close-primary pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">x</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row mx-0 align-items-center">
                    <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="col-12">
                                <div class="col-12 px-0 pb-3">
                                    <p class="text-gray-primary mb-0">Lesão Apresentada</p>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="allergy" name="allergy"
                                           class="custom-control-input"
                                           [checked]="modelSkinIntegrity.presentedInjuryType === 'allergy'"
                                           (change)="modelSkinIntegrity.presentedInjuryType = 'allergy'">
                                    <label class="custom-control-label" for="allergy">Alergia</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="pressureUlcer" name="pressureUlcer"
                                           class="custom-control-input"
                                           [checked]="modelSkinIntegrity.presentedInjuryType === 'pressureUlcer'"
                                           (change)="modelSkinIntegrity.presentedInjuryType = 'pressureUlcer'">
                                    <label class="custom-control-label"
                                           for="pressureUlcer">Úlcera de Pressão</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="ringworm" name="ringworm"
                                           class="custom-control-input"
                                           [checked]="modelSkinIntegrity.presentedInjuryType === 'ringworm'"
                                           (change)="modelSkinIntegrity.presentedInjuryType = 'ringworm'">
                                    <label class="custom-control-label" for="ringworm">Micose</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="other" name="other"
                                           class="custom-control-input"
                                           [checked]="modelSkinIntegrity.presentedInjuryType === 'other'"
                                           (change)="modelSkinIntegrity.presentedInjuryType = 'other'">
                                    <label class="custom-control-label" for="other">Outro</label>
                                </div>

                                <div class="input-material bg-gray mb-0"
                                     *ngIf="modelSkinIntegrity.presentedInjuryType === 'other'">
                                    <input id="otherDetail" class="bg-gray form-control" type="text"
                                           maxlength="10000" autocomplete="off" name="otherDetail"
                                           required
                                           [(ngModel)]="modelSkinIntegrity.presentedInjuryTypeOthers"/>
                                    <label for="otherDetail">Outro, Qual ?</label>
                                </div>
                                <div class="input-material bg-gray mb-0">
                                    <input id="injuryPlace" class="bg-gray form-control" type="text"
                                           maxlength="10000" autocomplete="off" name="injuryPlace"
                                           required
                                           [(ngModel)]="modelSkinIntegrity.place"/>
                                    <label for="injuryPlace">Local</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="col-12">
                                <div class="col-12 px-0 pb-3">
                                    <p class="text-gray-primary mb-0">Duração da Lesão</p>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="zeroTwo" name="zeroTwo"
                                           class="custom-control-input"
                                           [checked]="modelSkinIntegrity.presentedInjuryTime === 'zeroTwo'"
                                           (change)="modelSkinIntegrity.presentedInjuryTime = 'zeroTwo'">
                                    <label class="custom-control-label" for="zeroTwo">0 à 2
                                        meses</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="twoFour" name="twoFour"
                                           class="custom-control-input"
                                           [checked]="modelSkinIntegrity.presentedInjuryTime === 'twoFour'"
                                           (change)="modelSkinIntegrity.presentedInjuryTime = 'twoFour'">
                                    <label class="custom-control-label" for="twoFour">2 à 4
                                        meses</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="fourSix" name="fourSix"
                                           class="custom-control-input"
                                           [checked]="modelSkinIntegrity.presentedInjuryTime === 'fourSix'"
                                           (change)="modelSkinIntegrity.presentedInjuryTime = 'fourSix'">
                                    <label class="custom-control-label" for="fourSix">4 à 6
                                        meses</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="sixEight" name="sixEight"
                                           class="custom-control-input"
                                           [checked]="modelSkinIntegrity.presentedInjuryTime === 'sixEight'"
                                           (change)="modelSkinIntegrity.presentedInjuryTime = 'sixEight'">
                                    <label class="custom-control-label" for="sixEight">6 à 8
                                        meses</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="moreEight" name="moreEight"
                                           class="custom-control-input"
                                           [checked]="modelSkinIntegrity.presentedInjuryTime === 'moreEight'"
                                           (change)="modelSkinIntegrity.presentedInjuryTime = 'moreEight'">
                                    <label class="custom-control-label" for="moreEight">mais de 8
                                        meses</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="col-12">
                                <div class="col-12 px-0 pb-3">
                                    <p class="text-gray-primary mb-0">Tratamentos anteriores</p>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="yesPrevious" name="yesPrevious"
                                           class="custom-control-input"
                                           [checked]="modelSkinIntegrity.previousTreatment === 'yes'"
                                           (change)="modelSkinIntegrity.previousTreatment = 'yes'">
                                    <label class="custom-control-label" for="yesPrevious">Sim</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="noPrevious" name="noPrevious"
                                           class="custom-control-input"
                                           [checked]="modelSkinIntegrity.previousTreatment === 'no'"
                                           (change)="modelSkinIntegrity.previousTreatment = 'no'">
                                    <label class="custom-control-label" for="noPrevious">Não</label>
                                </div>

                                <div class="input-material bg-gray mb-0">
                                    <input id="injuryType" class="bg-gray form-control"
                                           type="text" maxlength="10000" autocomplete="off"
                                           required name="injuryType"
                                           [(ngModel)]="modelSkinIntegrity.previousTreatmentType"/>
                                    <label for="injuryType">Qual?</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="col-12 mb-3">
                                <div class="input-material bg-gray mb-0">
                                    <input id="antiseptics" class="bg-gray form-control" type="text"
                                           maxlength="10000" autocomplete="off" required
                                           name="antiseptics"
                                           [(ngModel)]="modelSkinIntegrity.antiseptic"/>
                                    <label for="antiseptics">Antissépticos</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="input-material bg-gray mb-0">
                                    <input id="ointments" class="bg-gray form-control" type="text"
                                           maxlength="10000" autocomplete="off" required name="ointments"
                                           [(ngModel)]="modelSkinIntegrity.pomade"/>
                                    <label for="ointments">Pomadas</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 my-3 col-md-12 col-lg-12">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="col-12 col-md-6">
                                <div class="input-material bg-gray mb-0">
                                    <input id="alternatives" class="bg-gray form-control" type="text"
                                           maxlength="10000" autocomplete="off" required
                                           name="alternatives"
                                           [(ngModel)]="modelSkinIntegrity.alternative"/>
                                    <label for="alternatives">Alternativos</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="input-material bg-gray mb-0">
                                    <input id="othersNu" class="bg-gray form-control" type="text"
                                           maxlength="10000" autocomplete="off" required name="othersNu"
                                           [(ngModel)]="modelSkinIntegrity.others"/>
                                    <label for="othersNu">Outros</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-8 mx-auto mt-4 px-0" *ngIf="!isCaregiver">
                    <a href="javascript:void(0)" class="btn btn-success btn-ico btn-block" (click)="actionSaveSkins()">
                        <svg-icon class="svg-white" src="assets/svg/check-circle.svg"></svg-icon>
                        Salvar
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
