<div class="card-body">
    <div class="mb-4 text-right" *ngIf="!isCaregiver">
        <a href="javascript:void(0)" class="btn btn-success btn-ico ml-2 btn-sm" (click)="handlerButton()">
            <svg-icon class="svg-white" src="assets/svg/plus-circle.svg"></svg-icon>
            Cadastrar Medicamento
        </a>
    </div>

    <div class="row mx-0">
        <div class="table-responsive">
            <table class="table ">
                <thead>
                <tr>
                    <th class="align-middle" scope="col">Medicação</th>
                    <th class="align-middle" scope="col">Tipo</th>
                    <th class="align-middle" scope="col">Quantidade</th>
                    <th class="align-middle text-center" scope="col">Período</th>
                    <th class="align-middle text-center" scope="col">Horário</th>
                    <th class="align-middle text-center" scope="col">Momento do Dia</th>
                    <th class="align-middle text-center" scope="col">Macerar</th>
                    <th class="align-middle text-center" scope="col">Início/fim do tratamento</th>
                    <th class="align-middle text-center" scope="col">Opções</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="isNullOrUndefined(modelMedicine)">
                    <td colspan="12">
                        <p class="pt-5 pb-5 text-center">
                            Nenhum registro encontrado.
                        </p>
                    </td>
                </tr>
                <tr *ngFor="let r of modelMedicine">
                    <td>{{r.name}}</td>
                    <td>{{useTypeTraduction(r.type)}}</td>
                    <td>{{r.amount}}</td>
                    <td class="text-center">{{r.period}}</td>
                    <td class="text-center">{{r.timeOfTheDay | mask: '00:00'}}</td>
                    <td class="text-center">{{timeofTheDayTraduction(r.medicineTime)}}</td>
                    <td class="text-center">{{r.macerate ? 'Sim' : 'Não'}}</td>
                    <td class="text-center">{{r.treatmentStart | date: 'dd/MM/yyyy'}}
                        - {{r.treatmentFinish | date: 'dd/MM/yyyy'}}</td>
                    <td class="text-center">
                        <a href="javascript:void('detail')" class="btn mx-1"
                           (click)="handlerDetail(r)">
                            <svg-icon class="ico-edit" src="assets/svg/eye.svg"></svg-icon>
                        </a>
                        <a href="javascript:void('delete')" class="btn mx-1"
                           (click)="delete(r.id)" *ngIf="!isCaregiver">
                            <svg-icon class="ico-close" src="assets/svg/close.svg"></svg-icon>
                        </a>
                        <a href="javascript:void('duplicate')" class="btn mx-1"
                           (click)="handlerDuplicate(r)" *ngIf="!isCaregiver">
                            <svg-icon class="ico-edit" src="assets/svg/duplicate.svg"></svg-icon>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="modal fade" id="registerMedicineR" tabindex="-1" role="dialog" aria-labelledby="registerMedicineR"
     aria-hidden="true">
    <div class="modal-dialog modal-75 modal-dialog-centered" id="medicineDisabled" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    <span>Medicamento</span>
                </h5>
                <button type="button" class="close close-primary pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">x</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row mx-0 align-items-center">
                    <div class="col-sm-12 col-md-12 col-lg-6 d-flex">
                        <div class="col-sm-12 col-md-12 col-lg-12 input-material">
                            <input id="medicationR" class="form-control" type="text" maxlength="10000" autocomplete="off"
                                   required [(ngModel)]="model.name"/>
                            <label for="medicationR">Medicamento</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                        <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                            <div class="col-12 d-flex align-items-center">
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="tablet" name="tablet" class="custom-control-input"
                                           [checked]="model.type === 'tablet'" (change)="model.type = 'tablet'">
                                    <label class="custom-control-label" for="tablet">Comprimido</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="liquid" name="liquid" class="custom-control-input"
                                           [checked]="model.type === 'liquid'" (change)="model.type = 'liquid'">
                                    <label class="custom-control-label" for="liquid">Líquido</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="dust" name="dust" class="custom-control-input"
                                           [checked]="model.type === 'dust'" (change)="model.type = 'dust'">
                                    <label class="custom-control-label" for="dust">Pó</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-3 d-flex">
                        <div class="col-sm-12 col-md-12 col-lg-12 input-material">
                            <input id="posologiaR" class="form-control" type="text" maxlength="10000" autocomplete="off"
                                   required [(ngModel)]="model.amount"/>
                            <label for="posologiaR">Posologia</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 mt-2">
                        <div class="input-material">
                            <select id="period" class="form-control" name="state"
                                    [(ngModel)]="model.period"
                                    (ngModelChange)="model.period">
                                <option [ngValue]="undefined">Selecione</option>
                                <option *ngFor="let s of periodResidentTeste" [ngValue]="s.id">{{s.name}}</option>
                            </select>
                            <label for="period">Periodo</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-3 d-flex">
                        <div class="input-material">
                            <select id="timeOftheDay" class="form-control" name="state"
                                    [(ngModel)]="model.medicineTime"
                                    (ngModelChange)="model.medicineTime">
                                <option [ngValue]="undefined">Selecione</option>
                                <option *ngFor="let s of listTimeofTheDay" [ngValue]="s.id">{{s.name}}</option>
                            </select>
                            <label for="timeOftheDay">Momento do dia</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3">
                        <div class="col-sm-6 col-md-6 col-lg-12 input-material">
                            <input id="horario" class="form-control" type="text" maxlength="10000" autocomplete="off"
                                   required [(ngModel)]="model.timeOfTheDay" mask="00:00"/>
                            <label for="horario">Horário</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                            <div class="col-12">
                                <p class="text-gray-primary ">Possui Receita</p>
                            </div>
                            <div class="col-9 d-flex align-items-center">
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="prescription" name="prescription"
                                           class="custom-control-input"
                                           [checked]="model.prescription === 'yes'"
                                           (change)="model.prescription = 'yes'">
                                    <label class="custom-control-label" for="prescription">Sim</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="noPrescription" name="noPrescription"
                                           class="custom-control-input"
                                           [checked]="model.prescription === 'no'" (change)="model.prescription = 'no'">
                                    <label class="custom-control-label" for="noPrescription">Não</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                            <div class="col-12">
                                <p class="text-gray-primary">Receita Controlada</p>
                            </div>
                            <div class="col-6 d-flex align-items-center">
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="controledPrescription" name="controledPrescription"
                                           class="custom-control-input"
                                           [checked]="model.controlledPrescription === 'yes'"
                                           (change)="model.controlledPrescription = 'yes'">
                                    <label class="custom-control-label" for="controledPrescription">Sim</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="noControledPrescription" name="noControledPrescription"
                                           class="custom-control-input"
                                           [checked]="model.controlledPrescription === 'no'"
                                           (change)="model.controlledPrescription = 'no'">
                                    <label class="custom-control-label"
                                           for="noControledPrescription">Não</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                            <div class="col-12">
                                <p class="text-gray-primary ">Macerar</p>
                            </div>
                            <div class="col-9 d-flex align-items-center">
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="macerate2" name="macerate2"
                                           class="custom-control-input"
                                           [checked]="model.macerate"
                                           (change)="model.macerate = true">
                                    <label class="custom-control-label" for="macerate2">Sim</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="noMacerate2" name="noMacerate2"
                                           class="custom-control-input"
                                           [checked]="!model.macerate" (change)="model.macerate = false">
                                    <label class="custom-control-label" for="noMacerate2">Não</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                        <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                            <div class="col-sm-5 col-md-5 col-lg-12 row mx-0  align-items-center">
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="temporaryUse" name="temporaryUse"
                                           class="custom-control-input"
                                           [checked]="model.continuousUse === 'temporary'"
                                           (change)="handlerContinuous('temporary')">
                                    <label class="custom-control-label" for="temporaryUse">Uso
                                        Temporario</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="continuousUse" name="continuousUse"
                                           class="custom-control-input"
                                           [checked]="model.continuousUse === 'continuous'"
                                           (change)="handlerContinuous('continuous')">
                                    <label class="custom-control-label" for="continuousUse">Uso Contínuo</label>
                                </div>
                            </div>
                            <div class="col-sm-7 col-md-7 col-lg-12">
                                <div class=" input-material bg-gray">
                                    <input id="dateR" class="form-control bg-gray js-daterangepicker" [disabled]="model.continuousUse !== 'temporary'" type="text" maxlength="10000"
                                           autocomplete="off" required/>
                                    <label class="disabled" for="dateR">Início/Fim:</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                        <div class="bg-gray rounded py-4 row mx-0 py-2 mb-3 align-items-center">
                            <div class="col-12">
                                <p class="text-gray-primary">Farmácia de Alto Custo</p>
                            </div>
                            <div class="col-12 d-flex align-items-center">
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="highCost" name="highCost" class="custom-control-input"
                                           [checked]="model.highPriceMedicine === 'yes'"
                                           (change)="model.highPriceMedicine = 'yes'">
                                    <label class="custom-control-label" for="highCost">Sim</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="noHighCost" name="noHighCost" class="custom-control-input"
                                           [checked]="model.highPriceMedicine === 'no'"
                                           (change)="model.highPriceMedicine = 'no'">
                                    <label class="custom-control-label" for="noHighCost">Não</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="input-material">
                            <input id="observationM1" class="form-control" type="text" maxlength="10000"
                                   autocomplete="off"
                                   required [(ngModel)]="model.observation"/>
                            <label for="observationM1">Observação</label>
                        </div>
                    </div>
                </div>

                <div class="col-8 mx-auto mt-4 px-0" *ngIf="!isCaregiver">
                    <a href="javascript:void(0)" class="btn btn-success btn-ico btn-block" (click)="actionSave()">
                        <svg-icon class="svg-white" src="assets/svg/check-circle.svg"></svg-icon>
                        Salvar
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
