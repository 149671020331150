import {Component, Input, OnInit} from '@angular/core';
import {
    CreateResidentMedicineTableItem, Days,
    Item,
    ResidentMedicineTable
} from '../../../../model/resident/resident-medicine-table';
import {Router} from '@angular/router';
import {UserService} from '../../../../service/user.service';
import {MedicineService} from '../../../../service/resident/medicine.service';
import {BaseComponent} from '../../../../base/base.component';
import * as moment from 'moment';
import {now} from "moment";
import {User} from "../../../../model/user/user";

@Component({
    selector: 'app-medicine-table',
    templateUrl: './medicine-table.component.html',
    styleUrls: ['./medicine-table.component.scss']
})
export class MedicineTableComponent extends BaseComponent implements OnInit {
    residentId: string;
    month = moment().month() + 1;
    year = new Date().getFullYear();
    model: ResidentMedicineTable = new ResidentMedicineTable();
    modelList: ResidentMedicineTable [] = [];
    modelItem: CreateResidentMedicineTableItem = new CreateResidentMedicineTableItem();
    currentUser: User = new User();

    constructor(public router: Router,
                public userService: UserService,
                public medicineService: MedicineService) {
        super(router);
    }

    @Input() set resident(val: any) {
        this.residentId = val;
    }

    ngOnInit(): void {
        this.userService.getMe().subscribe({
            next: data => {
                this.currentUser = data as User;
            }, error: err => super.onError(err)
        });
        if (!this.isNullOrUndefined(this.residentId)) {
            this.actionMedicineList();
        }
    }

    actionMedicineList() {
        this.medicineService.getList(this.residentId, this.month, this.year).subscribe({
            next: data => {
                this.modelList = data as ResidentMedicineTable[];
            }, error: err => super.onError(err)
        });
    }

    getExcel() {
        const token = localStorage.getItem('token') as string;

        let url = `${this.getBaseURL()}/resident/search/medicine/excel?`;

        if (!super.isNullOrUndefined(this.residentId)) {
            url += ('&id=' + this.residentId);
        }
        if (!super.isNullOrUndefined(this.year)) {
            url += ('&year=' + this.year);
        }
        if (!super.isNullOrUndefined(this.month)) {
            url += ('&month=' + this.month);
        }
        if (!super.isNullOrUndefined(token)) {
            url += ('&token=' + token);
        }
        window.open(encodeURI(url));
    }


    timeofTheDayTraduction(val) {
        switch (val) {
            case 'fasting' : {
                return 'Jejum';
            }
            case 'after_breakfast' : {
                return 'Após o café';
            }
            case 'before_lunch' : {
                return 'Antes do almoço';
            }
            case 'lunch' : {
                return 'No Almoço';
            }
            case 'after_lunch' : {
                return 'Após o almoço';
            }
            case 'before_dinner' : {
                return 'Antes do Jantar';
            }
            case 'after_dinner' : {
                return 'Após o Jantar';
            }
            case 'after_supper' : {
                return 'Após a Ceia';
            }
            default: {
                return '-';
            }
        }
    }

    CreateMedicineTableItem(id: string, v: Days, event: any) {
        super.confirmMessage('Atenção', 'Deseja realmente ministrar este medicamento?', () => {
                this.modelItem.medicineId = id;
                this.modelItem.residentId = this.residentId;
                this.modelItem.date = new Date();
                this.modelItem.date.setMonth(this.month - 1);
                this.modelItem.date.setFullYear(this.year);
                this.modelItem.date.setDate(v.day);
                this.medicineService.createTableItem(this.modelItem).subscribe(
                    {
                        next: data => {
                            v.status = true;
                            v.initials = this.currentUser.initials;
                            super.showMessage('Sucesso', 'Medicamento ministrado!', 'success');
                        }, error: err => super.onError(err)
                    }
                );
            },
            () => {
            });
    }
}
