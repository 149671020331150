import {User} from '../user/user';

export class FilterEvolution {
    name: string;
    profileId: string;
    start: Date;
    end: Date;
    userId: string;

    constructor() {
    }
}
