<header class="header d-flex justify-content-between align-items-center">
  <span class="d-flex d-lg-none d-flex justify-content-center align-items-center" (click)="actionNavToggle()">
    <div class="hamburger d-flex d-lg-none">
      <span class="bar"></span>
    </div>
  </span>
    <img src="assets/images/logo-white.png" class="d-block logo" height="45" alt="Logo"/>
    <div class="d-none d-lg-flex">
        <a href="#" class="d-none d-lg-flex align-items-center header__user">
            <span>Olá, <strong>{{modelUser.name}}</strong></span>
            <!--<img src="https://via.placeholder.com/30" />-->
        </a>
        <div class="header__btn">
            <a href="javascript:void('forgotPassword');" class="d-none header__btn__content"
               (click)="handlerForgotPassword()">
                <span>Alterar Senha</span>
            </a>
        </div>
        <div class="header__btn pr-0">
            <a href="javascript:void('sair');" [routerLink]="['/login']" class="d-none d-lg-flex header__user">
                <strong style="padding: 3px 15px 3px 3px;">Sair</strong>
            </a>
        </div>
    </div>

    <div class="modal fade" id="forgotModal" tabindex="-1" role="dialog" aria-labelledby="forgotModal"
         aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content p-0">
                <div class="modal-header bg-gray">
                    <h5 class="modal-title text-primary">
                        <span>Alteração de Senha</span>
                    </h5>
                    <button type="button" class="close close-primary pt-0" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">x</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mr-2">
                            <div class="input-material">
                                <input id="actual" name="actual" class="form-control" type="password"
                                       [(ngModel)]="modelForgot.actualPassword" required/>
                                <a href="javascript:void(0)" class="btn p-0 icon" (click)="handlerViewPassword('actual')">
                                    <svg-icon
                                            [src]="!handlerViewPasswordIcon('actual') ? 'assets/svg/password-open.svg' : 'assets/svg/password-closed.svg'"></svg-icon>
                                </a>
                                <label for="actual">Senha atual</label>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-material">
                                <input id="new" name="new" class="form-control" type="password"
                                       [(ngModel)]="modelForgot.newPassword" required/>
                                <a href="javascript:void(0)" class="btn p-0 icon" (click)="handlerViewPassword('new')">
                                    <svg-icon
                                            [src]="!handlerViewPasswordIcon('new') ? 'assets/svg/password-open.svg' : 'assets/svg/password-closed.svg'"></svg-icon>
                                </a>
                                <label for="new">Nova Senha</label>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-material">
                                <input id="newPass" name="newPass" class="form-control" type="password"
                                       [(ngModel)]="modelForgot.confirmNewPassword" required/>
                                <a href="javascript:void(0)" class="btn p-0 icon"
                                   (click)="handlerViewPassword('newPass')">
                                    <svg-icon
                                            [src]="!handlerViewPasswordIcon('newPass') ? 'assets/svg/password-open.svg' : 'assets/svg/password-closed.svg'"></svg-icon>
                                </a>
                                <label for="newPass">Confirme sua nova senha </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <button type="button" class="btn btn-block btn-primary"
                                (click)="actionForgotPassword()">Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</header>
