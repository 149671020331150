export class ResidentPhysiotherapyEvaluation {
    id: string;
    anamnesis: string;
    bloodPressure: string;
    heartRate: number;
    oxygenSaturation: number;
    heartRateFc: string;
    evaluation: string;
    evaluationTime: Date;
    orientation: string;
    physiotherapy: string;
    medicalOpinion: string;
    residentId: string;
    physicalEvaluation: string;
    functionalEvaluation: string;
    observation: string;
    createdAt: Date;
    updatedAt: Date;
    createdByNavigation: CreatedByNavigation;

    constructor() {
    }
}

export class CreatedByNavigation {
    id: string;
    name: string;
    email: string;
    photo: string;
    professionalCouncilCode: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    profile: Profile;
    userUnities: UserUnity[];
}

export class Profile {
    id: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;
}

export class UserUnity {
    id: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
}
