import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';

declare var $: any;

@Component({
    selector: 'app-residents-detail',
    templateUrl: './residents-detail.component.html',
    styleUrls: ['./residents-detail.component.scss']
})
export class ResidentsDetailComponent extends BaseComponent implements OnInit {

    residentId: string;

    constructor(public router: Router,
                public activatedRoute: ActivatedRoute) {
        super(router);
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.residentId = data.id;
                } else {

                }
            }
        });
    }
}
