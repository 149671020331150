
    export class CreateSpeech {
        residentId: string;
        anamnesis: string;
        evaluation: string;
        riskLevel: string;
        evaluationTime: Date;
    }


