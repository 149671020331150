<section class="container">

    <div class="d-flex align-items-center justify-content-between mx-3 mt-4">
        <h1 class="title">Atividades</h1>
        <a href="javascript:void (0)" (click)="handlerNewActivities()" class="btn btn-success btn-ico ml-2 btn-sm">
            <svg-icon class="svg-white mr-2" src="assets/svg/plus-circle.svg"></svg-icon>
            Cadastrar Atividade
        </a>
    </div>

    <div class="mt-3">

        <div class="d-sm-flex align-items-center justify-content-between mt-4 mx-4">
            <strong class="subTitle"></strong>
        </div>

        <div class="card mt-3 bg-white mx-2">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12 col-lg-4 col-xxl-2">
                        <div class="input-material">
                            <input id="activityPeriod" class="form-control js-daterangepicker" type="text"
                                   maxlength="10000" autocomplete="off" required/>
                            <label for="activityPeriod">Periodo</label>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-4 col-xxl-2">
                        <div class="input-material">
                            <input id="activity" class="form-control" type="text" maxlength="10000" autocomplete="off"
                                   [(ngModel)]="modelFilter.activityName" required/>
                            <label for="activity">Nome da atividade</label>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6 col-xxl-3">
                        <div class="form-group">
                            <label></label>
                            <ng-multiselect-dropdown
                                    [placeholder]="'Residentes'"
                                    [data]="listResident"
                                    [(ngModel)]="modelFilter.residents"
                                    [settings]="dropdownSettings">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6 col-xxl-3">
                        <div class="form-group">
                            <label></label>
                            <ng-multiselect-dropdown
                                    [placeholder]="'Unidade'"
                                    [data]="listUnity"
                                    [(ngModel)]="modelFilter.unities"
                                    [settings]="dropdownSettings">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-4 col-xxl-2">
                        <div class="input-material">
                            <select id="period" class="form-control" name="period" [(ngModel)]="modelFilter.period">
                                <option [ngValue]="undefined">Selecione</option>
                                <option [ngValue]="'unique'">Não se repete</option>
                                <option [ngValue]="'everyday'">Todos os dias</option>
                                <option [ngValue]="'week'">Seg-Sex</option>
                                <option [ngValue]="'weekends'">Final de Semana</option>
                            </select>
                            <label for="period">Periodicidade</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-info mx-2 mb-3 mb-xs-0"
                           (click)="getExcel()">
                            Exportar Excel
                        </a>
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0"
                           (click)="actionSearch()">
                            Buscar
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mt-3 mx-2 bg-white p-3">
            <div class="table-responsive">
                <table class="table ">
                    <thead>
                    <tr>
                        <!--<th class="align-middle text-center" scope="col">Periodicidade</th>-->
                        <th class="align-middle w-20" scope="col">Data de Atividade</th>
                        <th class="align-middle text-center" scope="col">Horário</th>
                        <th class="align-middle text-center" scope="col">Atividade</th>
                        <th class="align-middle text-center" scope="col">Residentes</th>
                        <th class="align-middle text-center w-15" scope="col">Opções</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="isNullOrUndefined(modelResult.results)">
                        <td colspan="5">
                            <p class="pt-5 pb-5 text-center">
                                Nenhum registro encontrado.
                            </p>
                        </td>
                    </tr>
                    <tr *ngFor="let r of modelResult.results |
                                paginate: {
                                 itemsPerPage: modelResult.pageSize,
                                 currentPage: modelResult.currentPage,
                                 totalItems: modelResult.totalCount}">
                        <!--<td class="align-middle text-center">
                            <p class="unique" *ngIf="r.period === 'unique'">Não se repete</p>
                            <p class="everyday" *ngIf="r.period === 'everyday'">Todos os dias</p>
                            <p class="week" *ngIf="r.period === 'week'">Seg-Sex</p>
                            <p class="weekends" *ngIf="r.period === 'weekends'">Final de Semana</p>
                        </td>-->
                        <td>{{r.dateStart | date: 'dd/MM/yyyy'}}</td>
                        <td class="text-center">{{r.dateStart | date: 'HH:mm'}}</td>
                        <td class="text-center">{{r.name}}</td>
                        <td class="text-center">
                            {{r.residents?.length}}
                            <svg-icon src="assets/svg/profile.svg"></svg-icon>
                        </td>
                        <td class="text-center">
                            <a href="javascript:void('detail')" (click)="handlerModalActivities(r)" class="mr-2">
                                <svg-icon class="ico-edit" src="assets/svg/edit.svg"></svg-icon>
                            </a>
                            <a href="javascript:void('detail')" (click)="delete(r.id)">
                                <svg-icon class="ico-close" src="assets/svg/close.svg"></svg-icon>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-center">
                <pagination-controls
                        [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"
                        [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                        [screenReaderPageLabel]="labels.screenReaderPageLabel"
                        [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                        (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
        </div>

    </div>
</section>

<div class="modal fade" id="activitiesNewModal" tabindex="-1" role="dialog" aria-labelledby="activitiesModal"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    <span *ngIf="isNullOrUndefined(model.id)">Cadastrar Nova Agenda</span>
                    <span *ngIf="!isNullOrUndefined(model.id)">Editar Agenda</span>
                </h5>
                <button type="button" class="close close-primary pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">x</span>
                </button>
            </div>
            <div class="modal-body p-5">
                <form autocomplete="off">
                    <div class="row">
                        <div class="col-sm-10 col-lg-10 mx-auto">
                            <div class="input-material">
                                <input id="activityNewName" name="activityNewName" class="form-control" type="text"
                                       [(ngModel)]="model.name" required/>
                                <label for="activityNewName">Nome da atividade</label>
                            </div>
                        </div>
                        <div class="col-sm-10 col-md-5 col-lg-5 ml-auto">
                            <div class="input-material">
                                <select id="type" class="form-control" name="type" [(ngModel)]="model.activityType"
                                        [disabled]="!isNullOrUndefined(model.id)" required>
                                    <option [ngValue]="undefined">Selecione</option>
                                    <option [ngValue]="'feed'">Alimentação</option>
                                    <option [ngValue]="'vitalsigns'">Sinais Vitais</option>
                                    <option [ngValue]="'medicine'">Medicamento</option>
                                    <option [ngValue]="'other'">Outros</option>
                                </select>
                                <label for="type" [ngClass]="{'disabled': !isNullOrUndefined(model.id)}">Tipo de
                                    atividade</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-5 col-lg-5 mr-auto">
                            <div class="input-material">
                                <select id="unityNewName" class="form-control" name="state" [(ngModel)]="model.unity.id"
                                        (ngModelChange)="actionResidentUnity()"
                                        [disabled]="!isNullOrUndefined(model.id)" required>
                                    <option [ngValue]="undefined">Selecione</option>
                                    <option *ngFor="let u of listUnity" [ngValue]="u.id">{{u.name}}</option>
                                </select>
                                <label for="unityNewName"
                                       [ngClass]="{'disabled': !isNullOrUndefined(model.id)}">Unidade</label>
                            </div>
                        </div>
                        <div class="col-10 mx-auto" *ngIf="isNullOrUndefined(model.id)">
                            <div class="card_period">
                                <div class="card_period-content p-3" [ngClass]="handlerSelected()">
                                    <div class="col-12">
                                        <p class="text-white">Periodicidade</p>
                                    </div>
                                    <div class="col-12 row mx-0 justify-content-center align-items-center">
                                        <div class="row">
                                            <div class="col-12 col-md-6">
                                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                                    <input type="radio" id="unique" name="unique"
                                                           class="custom-control-input"
                                                           [checked]="selectedDay === 'unique'"
                                                           (change)="selectedDay = 'unique'"
                                                    />
                                                    <label class="custom-control-label" for="unique">
                                                        Não se repete</label>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                                    <input type="radio" id="week" name="week"
                                                           class="custom-control-input"
                                                           [checked]="selectedDay === 'week'"
                                                           (change)="selectedDay = 'week'"/>
                                                    <label class="custom-control-label" for="week">
                                                        De Segunda a Sexta</label>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                                    <input type="radio" id="everyday" name="everyday"
                                                           class="custom-control-input"
                                                           [checked]="selectedDay === 'everyday'"
                                                           (change)="selectedDay = 'everyday'"/>
                                                    <label class="custom-control-label" for="everyday">
                                                        Todos os dias</label>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                                    <input type="radio" id="weekends" name="weekends"
                                                           class="custom-control-input"
                                                           [checked]="selectedDay === 'weekends'"
                                                           (change)="selectedDay = 'weekends'"/>
                                                    <label class="custom-control-label" for="weekends">
                                                        Final de Semana</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row align-items-center mx-0">
                                <div class="col-1 px-0 d-none d-md-block">
                                    <svg-icon src="assets/svg/calendar.svg"></svg-icon>
                                </div>
                                <div class="col-md-5 pl-md-0 col-lg-4 m-0">
                                    <div class="input-material">
                                        <input id="activityPeriodStart" class="form-control js-daterangepickerStart"
                                               type="text" maxlength="10000" autocomplete="off" required/>
                                        <label for="activityPeriodStart">Início</label>
                                    </div>
                                </div>
                                <div class="col-md-5 col-lg-2 m-0">
                                    <div class="input-material">
                                        <input id="activityTimeStartnew" name="activityTimeStartnew" mask="Hh:m0"
                                               minlength="3" maxlength="5" class="form-control" type="text"
                                               [(ngModel)]="hourStart"
                                               [clearIfNotMatch]="true"
                                               (blur)="onClearModel($event)"
                                               [dropSpecialCharacters]="false" required/>
                                        <label for="activityTimeStartNew">Horário</label>
                                    </div>
                                </div>
                                <b [ngClass]="{'d-none' : selectedDay === 'unique'}" class="text-primary">Até</b>
                                <div class="col-md-4 col-lg-4 m-0" [ngClass]="{'d-none' : selectedDay === 'unique'}">
                                    <div class="input-material">
                                        <input id="activityPeriodEnd" class="form-control js-daterangepickerEnd"
                                               (change)="setDatePickerModal()"
                                               type="text" maxlength="10000" autocomplete="off" required/>
                                        <label for="activityPeriodEnd">Fim</label>
                                    </div>
                                </div>
                                <!--<div class="col-sm-4 col-lg-2 m-0">
                                    <div class="input-material">
                                        <input id="activityTimeStartEnd" mask="Hh:m0" name="activityTimeStartEnd"
                                               class="form-control" type="text" [(ngModel)]="hourEnd"
                                               [dropSpecialCharacters]="false" required/>
                                        <label for="activityTimeStartEnd">Horário</label>
                                    </div>
                                </div>-->
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row mx-0">
                                <div class="col-1 px-0 d-none d-md-block">
                                    <svg-icon src="assets/svg/user-primary.svg"></svg-icon>
                                </div>
                                <div class="pl-md-0 col-lg-8">
                                    <div class="form-group">
                                        <label></label>
                                        <ng-multiselect-dropdown
                                                name="residents"
                                                [placeholder]="'Residentes'"
                                                [data]="listResidentUnity"
                                                [(ngModel)]="model.residents"
                                                [settings]="dropdownSettings"
                                                (ngModelChange)="onSelectAll($event)">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <button type="button" class="btn btn-block btn-primary"
                                            (click)="model.residents = []">Limpar
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 my-2">
                            <div class="resident_thumb_list justify-content-center">
                                <div *ngFor="let r of model.residents; let i = index" class="resident_thumb_list_item">
                                    <img class="resident_photo" src="{{r.picture}}" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <div class="row mx-0">
                                <div class="col-1 px-0 d-none d-md-block">
                                    <svg-icon src="assets/svg/statistics.svg"></svg-icon>
                                </div>
                                <div class="col-sm-11 col-lg-11 pl-md-0">
                                    <div class="input-material no-border bg-gray rounded">
                                        <textarea class="form-control bg-gray" id="activityObervationNew"
                                                  [disabled]="!isNullOrUndefined(model.id)" rows="4"
                                                  name="activityObervationNew" [(ngModel)]="model.description">
                                        </textarea>
                                        <label for="activityObervationNew">Descrição</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <div class="row col-12 d-flex justify-content-center">
                    <div class="col-md-4 my-2 col-12 my-2">
                        <button type="button" class="btn btn-block btn-outline-danger" (click)="delete(model.id)">
                            Excluir
                        </button>
                    </div>
                    <div class="col-md-4 col-12 my-2">
                        <button type="button" class="btn btn-block btn-primary" (click)="actionSave()">Salvar</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
