import {Objectives} from './objectives';

export class ResidentNutrition {
    id: string;
    weight: number;
    height: number;
    collectionMethod: string;
    bmi: number;
    cb: string;
    cp: string;
    aj: string;
    dcse: string;
    hipCircumference: string;
    abdominalCircumference: string;
    anamnesis: string;
    energyNeed: number;
    nutritionalStatus: string;
    objectives: Objectives[];
    calKg: number;
    observation: string;
    evaluationTime: Date;
    createdAt: Date;
    updatedAt: Date;
    createdBy: CreatedBy;

    constructor() {
        this.objectives = [];
    }
}


export class CreatedBy {
    id: string;
    name: string;
    email: string;
    photo: string;
    professionalCouncilCode: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    profile: {
        id: string;
        name: string;
        createdAt: Date;
        updatedAt: Date;
    };
}

