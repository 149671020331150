import { Component, OnInit } from '@angular/core';
import {User} from '../../model/user/user';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {UserService} from '../../service/user.service';
import {Token} from '../../model/user/token';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {

  section = 0;
  model: User = new User();


  constructor(public router: Router,
              public  userService: UserService) {
    super(router);
  }

  ngOnInit(): void {
    setTimeout(() => {
      $('#loaderBox').fadeOut();
    }, 1000);
  }

  nextSection(n: any) {
    this.section = n;
  }

  onForgot() {
    if (!super.isEMailValid(this.model.email)) {
      return;
    }

    this.userService.forgotPassword(this.model).subscribe({
      next: data => {
        // @ts-ignore
        super.showMessage('Sucesso', data.message, 'success');
        this.nextSection(0);
      }, error: err => super.onError(err)
    });
  }

  onLogin() {
    this.model.platform = 'web';
    const arrValidateFields = [
      {value: this.model.email, text: 'Email*,<br>'},
      {value: this.model.password, text: 'Senha*.<br>'},
    ];

    const stringError = this.validateField(arrValidateFields);

    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
      return;
    }

    if (!super.isEMailValid(this.model.email)) {
      return;
    }

    this.userService.login(this.model).subscribe({
      next: data => this.saveLocalUser(data)
      , error: err => super.onError(err)
    });
  }

  saveLocalUser(data) {
    const token = data as Token;
    this.setToken(token.authorizationToken);
    this.userService.updateUser(token.user);
    this.router.navigate(['/']).then();
  }

}
