import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {User} from '../../model/user/user';
import {Unity} from '../../model/unity/unity';
import {UnityService} from '../../service/unity.service';
import {FilterUnity} from '../../model/unity/filter-unity';
import {ResultUnity} from '../../model/unity/result-unity';

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-unities',
    templateUrl: './unities.component.html',
    styleUrls: ['./unities.component.scss']
})
export class UnitiesComponent extends BaseComponent implements OnInit, OnDestroy {

    modelFilter: FilterUnity = new FilterUnity();
    modelResult: ResultUnity = new ResultUnity();
    model: Unity = new Unity();
    listUnity: Unity[] = [];
    currentPage = 1;

    constructor(public route: Router,
                public unityService: UnityService) {
        super(route);
    }

    ngOnInit(): void {
        this.setDatePicker();
        this.cleanFilterStart();
        this.actionSearch();
    }

    ngOnDestroy(): void {
        const a = $('#unityModal');
        if (a[0]) {
            a.remove();
        }
    }

    cleanFilterStart() {
        this.modelFilter.start = null;
        this.modelFilter.end = null;
    }

    setDatePicker() {
        const data = moment().subtract(0, 'days');
        this.modelFilter.start = data;
        this.modelFilter.end = moment();
        $('.js-daterangepicker').daterangepicker({
            showDropdowns: false,
            startDate: data,
            locale: super.objLocaleDateRangePicker()
        }, (start, end, label) => {
            this.modelFilter.start = start.utcOffset(0, true).format();
            this.modelFilter.end = end.utcOffset(0, true).format();
        });
    }

    actionSearch() {
        this.unityService.search(this.modelFilter, this.currentPage).subscribe({
            next: data => {
                this.modelResult = data as ResultUnity;
            }, error: err => super.onError(err)
        });
    }

    handlerDetail(model) {
        this.model = Object.assign({}, model);
        this.showModal('unityModal');
    }

    create() {
        this.unityService.create(this.model).subscribe({
            next: data => {
                this.model = new Unity();
                this.actionSearch();
                $('#unityModal').modal('hide');
                super.showMessage('Sucesso', 'Unidade cadastrada com sucesso!', 'success');
            }, error: err => super.onError(err)
        });
    }

    update() {
        this.unityService.update(this.model).subscribe({
            next: data => {
                this.model = new User();
                this.actionSearch();
                $('#unityModal').modal('hide');
                super.showMessage('Sucesso', 'Usuário alterado com sucesso!', 'success');
            }, error: err => super.onError(err)
        });
    }

    delete(id) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar esta unidade?', () => {
            this.unityService.delete(id).subscribe({
                next: data => {
                    this.actionSearch();
                    super.showMessage('Sucesso', 'Unidade deletada com sucesso!', 'success');
                }, error: err => super.onError(err)
            });
        });
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.name, text: 'Nome da Unidade*,<br>'},

        ];


        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }


        if (this.isNullOrUndefined(this.model.id)) {
            this.create();
        } else {
            this.update();
        }
    }

    pageChanged(event) {
        this.currentPage = event;
        this.actionSearch();
    }

    getExcel() {
        const token = localStorage.getItem('token') as string;
        const start = moment(this.modelFilter.start).format('YYYY-MM-DD');
        const end = moment(this.modelFilter.end).format('YYYY-MM-DD');

        let url = `${this.getBaseURL()}/unity/search/excel?token=${token}`;

        if (!super.isNullOrUndefined(this.modelFilter.name)) {
            url += ('&name=' + this.modelFilter.name);
        }
        if (!super.isNullOrUndefined(start)) {
            url += ('&start=' + start);
        }
        if (!super.isNullOrUndefined(end)) {
            url += ('&end=' + end);
        }
        window.open(encodeURI(url));
    }

    handlerRegister() {
        this.model = new Unity();
        this.showModal('unityModal');
    }
}
