import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ServiceBase} from '../base/service.base';

@Injectable({
    providedIn: 'root'
})
export class EvolutionService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }
    search(residentId, page, filter) {
        const url = `${this.BASE_URL}/resident/${residentId}/search-evolution/${page}`;
        return this.http.post(url, filter);
    }

    create(model) {
        const url = `${this.BASE_URL}/resident/create-evolution`;
        return this.http.post(url, model);
    }

    list() {
        const url = `${this.BASE_URL}/resident/list-evolution`;
        return this.http.get(url);
    }

    update(model) {
        const url = `${this.BASE_URL}/resident/update-evolution`;
        return this.http.put(url, model);
    }

    delete(id) {
        const url = `${this.BASE_URL}/resident/delete-evolution/${id}`;
        return this.http.delete(url);
    }
}
