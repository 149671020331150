export class Dentist {
    id: string;
    residentId: string;
    procedure: string;
    anamnesis: string;
    evaluationTime: Date;
    updatedAt: Date;
    createdAt: Date;
    createdBy: CreatedByNavigation;

    constructor() {
    }
}
export class CreatedByNavigation {
    id: string;
    name: string;
    email: string;
    photo: string;
    professionalCouncilCode: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    profile: Profile;
    userUnities: UserUnity[];
}

export class Profile {
    id: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;
}

export class UserUnity {
    id: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
}

