<div class="mb-4 text-right" *ngIf="!isCaregiver">
    <a href="javascript:void(0)" class="btn btn-success btn-ico ml-2 btn-sm" (click)="handlerButton()">
        <svg-icon class="svg-white" src="assets/svg/plus-circle.svg"></svg-icon>
        Cadastrar Dieta
    </a>
</div>

<div class="row mx-0">
    <div class="table-responsive">
        <table class="table ">
            <thead>
            <tr>
                <th class="align-middle" scope="col">Refeição</th>
                <th class="align-middle" scope="col">Item</th>
                <th class="align-middle text-center" scope="col">Quantidade</th>
                <th class="align-middle text-center" scope="col">Data de Registro</th>
                <th class="align-middle text-center" scope="col">Opções</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="isNullOrUndefined(modelDiets)">
                <td colspan="6">
                    <p class="pt-5 pb-5 text-center">
                        Nenhum registro encontrado.
                    </p>
                </td>
            </tr>
            <tr *ngFor="let r of modelDiets" [ngClass]="{'bluer' : !this.isNullOrUndefined(r.updatedAt)}">
                <td>{{r.meal}}</td>
                <td>{{r.item}}</td>
                <td class="text-center">{{r.amount}}</td>
                <td class="text-center">{{r.createdAt | date:'dd/MM/yyyy'}}</td>
                <td class="text-center">
                    <a href="javascript:void('detail')" class="btn"
                       (click)="handlerDetail(r)">
                        <svg-icon class="ico-edit" src="assets/svg/eye.svg"></svg-icon>
                    </a>

                    <a href="javascript:void('delete')" class="btn"
                       (click)="delete(r.id)" *ngIf="!isCaregiver">
                        <svg-icon class="ico-close" src="assets/svg/close.svg"></svg-icon>
                    </a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="modal fade" id="registerDiet" tabindex="-1" role="dialog" aria-labelledby="registerDiet"
     aria-hidden="true">
    <div class="modal-dialog modal-75 modal-dialog-centered" id="dietDisabled" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    <span>Dieta</span>
                </h5>
                <button type="button" class="close close-primary pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">x</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row mx-0 align-items-center">
                    <div class="col-sm-12 my-3">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="input-material bg-gray mb-0 mx-3">
                                <textarea id="meal" class="bg-gray form-control" type="text"
                                       maxlength="10000" autocomplete="off" name="meal" required
                                       [(ngModel)]="model.meal">
                                </textarea>
                                <label for="meal">Refeição</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="input-material bg-gray mb-0 mx-3">
                                <textarea id="item" class="bg-gray form-control" type="text"
                                       maxlength="10000" autocomplete="off" name="item" required
                                       [(ngModel)]="model.item">
                                </textarea>
                                <label for="item">Item</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="input-material bg-gray mb-0 mx-3">
                                <textarea id="amount" class="bg-gray form-control" type="number"
                                       maxlength="10000" autocomplete="off" name="amount" required
                                       [(ngModel)]="model.amount">
                                </textarea>
                                <label for="amount">Quantidade</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="bg-gray rounded p-3 row mx-0 align-items-center">
                            <div class="input-material bg-gray">
                                <textarea id="observationD" class="form-control bg-gray" type="text" maxlength="10000"
                                       autocomplete="off"
                                       required [(ngModel)]="model.observation">
                                </textarea>
                                <label for="observationD">Observação</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-8 mx-auto mt-4 px-0" *ngIf="!isCaregiver">
                        <a href="javascript:void(0)" class="btn btn-success btn-ico btn-block" (click)="actionSave()">
                            <svg-icon class="svg-white" src="assets/svg/check-circle.svg"></svg-icon>
                            Salvar
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
