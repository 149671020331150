<div class="mb-4 text-right" *ngIf="!isCaregiver">
    <a href="javascript:void(0)" class="btn btn-success btn-ico ml-2 btn-sm" (click)="handlerRegister()">
        <svg-icon class="svg-white" src="assets/svg/plus-circle.svg"></svg-icon>
        Cadastrar Psicologia
    </a>
</div>

<div class="table-responsive">
    <table class="table ">
        <thead>
        <tr>
            <th class="align-middle text-center" scope="col">Data da Atividade</th>
            <th class="align-middle" scope="col">Atividade</th>
            <th class="align-middle" scope="col">Iniciativa</th>
            <th class="align-middle" scope="col">Participante</th>
            <th class="align-middle" scope="col">Modalidade</th>
            <th class="align-middle" scope="col">Responsável</th>
            <th class="align-middle" scope="col">Código de Conselho</th>
            <th class="align-middle" scope="col">Data de Registro</th>
            <th class="align-middle text-center" scope="col">Opções</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="isNullOrUndefined(modelList)">
            <td colspan="8">
                <p class="pt-5 pb-5 text-center">
                    Nenhum registro encontrado.
                </p>
            </td>
        </tr>
        <tr *ngFor="let r of modelList" [ngClass]="{'bluer' : !this.isNullOrUndefined(r.updatedAt)}">
            <td class="text-center">{{r.evaluationTime | date: 'dd/MM/yyyy'}}</td>
            <td>{{handlerTranslatorActivity(r.activity)}}</td>
            <td>{{handlerTranslatorInitiative(r.initiative)}}</td>
            <td>{{handlerTranslatorParticipant(r.participant)}}</td>
            <td>{{handlerTranslatorModality(r.modality)}}</td>
            <td>{{r.createdByNavigation.name}}</td>
            <td>{{r.createdByNavigation.professionalCouncilCode}}</td>
            <td>{{r.createdAt | date: 'dd/MM/yyyy'}}</td>
            <!--<td>{{handlerTranslatorParticipation(r.participation)}}</td>-->
            <td class="text-center">
                <a href="javascript:void('detail')" class="btn" (click)="handlerDetail(r)">
                    <svg-icon class="ico-edit" src="assets/svg/eye.svg"></svg-icon>
                </a>

<!--                <a href="javascript:void('delete')" class="btn" (click)="delete(r.id)" *ngIf="!isCaregiver">-->
<!--                    <svg-icon class="ico-close" src="assets/svg/close.svg"></svg-icon>-->
<!--                </a>-->
            </td>
        </tr>
        </tbody>
    </table>
</div>

<div class="modal fade" id="psychologyModal" tabindex="-1" role="dialog" aria-labelledby="psychologyModal"
     aria-hidden="true">
    <div class="modal-dialog modal-75 modal-dialog-centered" role="document" id="phychologyDetailDisabled">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    <span>Psicologia</span>
                </h5>
                <button type="button" class="close close-primary pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">x</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6">
                        <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                            <div class="col-12">
                                <p class="text-gray-primary">Atividade</p>
                            </div>
                            <div class="col-12 row mx-0 align-items-center">
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="residentWelcome" name="residentWelcome"
                                           class="custom-control-input"
                                           [checked]="model.activity === 'residentWelcome'"
                                           (change)="model.activity = 'residentWelcome'">
                                    <label class="custom-control-label" for="residentWelcome">Acolhimento
                                        Residente</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="familyWelcome" name="familyWelcome"
                                           class="custom-control-input"
                                           [checked]="model.activity === 'familyWelcome'"
                                           (change)="model.activity = 'familyWelcome'">
                                    <label class="custom-control-label" for="familyWelcome">Acolhimento Familiar</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="cognitiveStimulation" name="cognitiveStimulation"
                                           class="custom-control-input"
                                           [checked]="model.activity === 'cognitiveStimulation'"
                                           (change)="model.activity = 'cognitiveStimulation'">
                                    <label class="custom-control-label"
                                           for="cognitiveStimulation">Estimulação Cognitiva</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="initialEnterview" name="initialEnterview"
                                           class="custom-control-input"
                                           [checked]="model.activity === 'initialEnterview'"
                                           (change)="model.activity = 'initialEnterview'">
                                    <label class="custom-control-label"
                                           for="initialEnterview">Entrevista Inicial</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="event" name="event"
                                           class="custom-control-input"
                                           [checked]="model.activity === 'event'"
                                           (change)="model.activity = 'event'">
                                    <label class="custom-control-label"
                                           for="event">Evento</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                        <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                            <div class="col-12">
                                <p class="text-gray-primary">Iniciativa</p>
                            </div>
                            <div class="col-12 row mx-0 align-items-center">
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="resident" name="resident" class="custom-control-input"
                                           [checked]="model.initiative === 'resident'"
                                           (change)="model.initiative = 'resident'">
                                    <label class="custom-control-label" for="resident">Residente</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="family" name="family" class="custom-control-input"
                                           [checked]="model.initiative === 'family'"
                                           (change)="model.initiative = 'family'">
                                    <label class="custom-control-label" for="family">Familiar</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="institution" name="institution" class="custom-control-input"
                                           [checked]="model.initiative === 'institution'"
                                           (change)="model.initiative = 'institution'">
                                    <label class="custom-control-label" for="institution">Instituição</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                            <div class="col-12">
                                <p class="text-gray-primary">Participante</p>
                            </div>
                            <div class="col-12 row mx-0 align-items-center">
                                <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                                    <input type="checkbox" id="residentParticipant" name="residentParticipant"
                                           class="custom-control-input"
                                           [checked]="handlerParticipantCheck('resident')"
                                           (change)="handlerParticipant('resident')">
                                    <label class="custom-control-label" for="residentParticipant">Residente</label>
                                </div>
                                <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                                    <input type="checkbox" id="familyParticipant" name="familyParticipant"
                                           class="custom-control-input"
                                           [checked]="handlerParticipantCheck('family')"
                                           (change)="handlerParticipant('family')">
                                    <label class="custom-control-label" for="familyParticipant">Familiar</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 my-3 col-md-6 col-lg-4">
                        <div class="bg-gray rounded py-3 row mx-0 mb-2 align-items-center">
                            <div class="bg-gray input-material mx-3">
                                <input id="evaluationDate" class="form-control bg-gray js-daterangepickerEvaluation"
                                       type="text" maxlength="10000" autocomplete="off" required/>
                                <label for="evaluationDate">Data da Atividade</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                            <div class="col-12">
                                <p class="text-gray-primary">Modalidade</p>
                            </div>
                            <div class="col-12 row mx-0 align-items-center">
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="individual" name="individual"
                                           class="custom-control-input" [checked]="model.modality === 'individual'"
                                           (change)="model.modality = 'individual'">
                                    <label class="custom-control-label" for="individual">Individual</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="group" name="group"
                                           class="custom-control-input" [checked]="model.modality === 'group'"
                                           (change)="model.modality = 'group'">
                                    <label class="custom-control-label" for="group">Grupo</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center"
                             *ngIf="model.activity === 'cognitiveStimulation'">
                            <div class="col-12">
                                <p class="text-gray-primary">Participação</p>
                            </div>
                            <div class="col-12 row mx-0 align-items-center">
<!--                                <div class="col-12 row">-->
<!--                                    <div class="custom-control   custom-checkbox custom-control-inline mb-0">-->
<!--                                        <input type="checkbox" id="participation1" name="participation1"-->
<!--                                               class="custom-control-input"-->
<!--                                               [checked]="handlerParticipationCheck('participation1')"-->
<!--                                               (change)="handlerParticipation('participation1')">-->
<!--                                        <label class="custom-control-label" for="participation1">Não Presente</label>-->
<!--                                    </div>-->
<!--                                    <div class="custom-control custom-checkbox custom-control-inline mb-0">-->
<!--                                        <input type="checkbox" id="participation0" name="participation0"-->
<!--                                               class="custom-control-input"-->
<!--                                               [checked]="handlerParticipationCheck('participation0')"-->
<!--                                               (change)="handlerParticipation('participation0')">-->
<!--                                        <label class="custom-control-label" for="participation0">Presente</label>-->
<!--                                    </div>-->
<!--                                </div>-->
                                <div class="col-12 row">
                                    <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                                        <input type="checkbox" id="participation3" name="participation3"
                                               class="custom-control-input"
                                               [checked]="handlerParticipationCheck('participation3')"
                                               (change)="handlerParticipation('participation3')">
                                        <label class="custom-control-label" for="participation3">Presente</label>
                                    </div>
                                    <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                                        <input type="checkbox" id="participation2" name="participation2"
                                               class="custom-control-input"
                                               [checked]="handlerParticipationCheck('participation2')"
                                               (change)="handlerParticipation('participation2')">
                                        <label class="custom-control-label" for="participation2">Participou</label>
                                    </div>
                                    <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                                        <input type="checkbox" id="participation8" name="participation8"
                                               class="custom-control-input"
                                               [checked]="handlerParticipationCheck('participation8')"
                                               (change)="handlerParticipation('participation8')">
                                        <label class="custom-control-label" for="participation8">Participou Ativamente</label>
                                    </div>
                                </div>
                                <div class="col-12 row">
                                    <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                                        <input type="checkbox" id="participation4" name="participation4"
                                               class="custom-control-input"
                                               [checked]="handlerParticipationCheck('participation4')"
                                               (change)="handlerParticipation('participation4')">
                                        <label class="custom-control-label" for="participation4">
                                            Expressou-se verbalmente</label>
                                    </div>
                                    <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                                        <input type="checkbox" id="participation5" name="participation5"
                                               class="custom-control-input"
                                               [checked]="handlerParticipationCheck('participation5')"
                                               (change)="handlerParticipation('participation5')">
                                        <label class="custom-control-label" for="participation5">
                                            Expressou-se verbalmente em relação à atividade</label>
                                    </div>
                                </div>
                                <div class="col-12 row">
                                    <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                                        <input type="checkbox" id="participation6" name="participation6"
                                               class="custom-control-input"
                                               [checked]="handlerParticipationCheck('participation6')"
                                               (change)="handlerParticipation('participation6')">
                                        <label class="custom-control-label" for="participation6">
                                            Expressou-se através de gestos</label>
                                    </div>
                                    <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                                        <input type="checkbox" id="participation7" name="participation7"
                                               class="custom-control-input"
                                               [checked]="handlerParticipationCheck('participation7')"
                                               (change)="handlerParticipation('participation7')">
                                        <label class="custom-control-label" for="participation7">
                                            Expressou-se através de gestos em relação à atividade</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                            <div class="col-12 row mx-0 align-items-center">
                                <div class="input-material bg-gray">
                                    <textarea class="form-control bg-gray" id="description" rows="4"
                                              [(ngModel)]="model.description" required></textarea>
                                    <label for="description">Descrição</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="bg-gray rounded p-3 row mx-0 align-items-center">
                            <div class="input-material bg-gray">
                                <textarea id="observationPs" class="form-control bg-gray" type="text" maxlength="10000"
                                       autocomplete="off" required [(ngModel)]="model.observation">
                                </textarea>
                                <label for="observationPs">Observação</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-8 mx-auto mt-4 px-0" *ngIf="!isCaregiver">
                    <a href="javascript:void(0)" class="btn btn-success btn-ico btn-block" (click)="actionSave()">
                        <svg-icon class="svg-white" src="assets/svg/check-circle.svg"></svg-icon>
                        Salvar
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

