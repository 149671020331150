import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../../../service/user.service';
import {BaseComponent} from '../../../../base/base.component';
import {Evolution} from '../../../../model/evolution/evolution';
import {User} from '../../../../model/user/user';
import {EvolutionService} from '../../../../service/evolution.service';
import {FilterEvolution} from '../../../../model/evolution/filterEvolution';
import {ResultEvolution} from '../../../../model/evolution/result-evolution';
import {Doctor} from '../../../../model/doctor';
import {Unity} from '../../../../model/unity/unity';
import {Profile} from '../../../../model/profile';
import {ProfileService} from '../../../../service/profile.service';
// @ts-ignore
import moment from 'moment';

declare var $: any;

@Component({
    selector: 'app-evolution',
    templateUrl: './evolution.component.html',
    styleUrls: ['./evolution.component.scss']
})
export class EvolutionComponent extends BaseComponent implements OnInit {

    @Input() set resident(val: any) {
        this.residentId = val;
    }

    constructor(public router: Router,
                public userService: UserService,
                public profileService: ProfileService,
                public evolutionService: EvolutionService,
    ) {
        super(router);
    }

    residentId: string;
    modelUser: User = new User();
    listUsers: User[] = [];
    model: Evolution = new Evolution();
    modelFilter: FilterEvolution = new FilterEvolution();
    modelEvolutions: Evolution[] = [];
    listProfile: Profile[] = [];
    currentPage = 1;
    modelResult: ResultEvolution = new ResultEvolution();
    hour: string;

    ngOnInit(): void {
        if (!this.isNullOrUndefined(this.residentId)) {
            this.getUsers();
            this.actionProfile();
            this.setDatePicker();
            this.setDatePickerModal();
            this.actionSearch();
        }
    }

    actionSearch() {
        this.evolutionService.search(this.residentId, this.currentPage, this.modelFilter).subscribe({
            next: data => {
                this.modelResult = data as ResultEvolution;
            }, error: err => super.onError(err)
        });
    }

    handlerSelectedUser(user: User) {
        this.modelFilter.userId = user.id;
    }


    pageChanged(event) {
        this.currentPage = event;
        this.actionSearch();
    }

    getUsers() {
        this.userService.listUser().subscribe({
            next: data => {

                this.listUsers = data as User[];
            }, error: err => super.onError(err)
        });
    }

    actionCreate() {
        this.evolutionService.create(this.model).subscribe({
            next: (data: Evolution) => {
                $('#registerEvolution').modal('hide');
                super.showMessage('Sucesso', 'Evolução cadastrada com sucesso!', 'success');
                this.actionSearch();
            }, error: err => super.onError(err)
        });
    }

    setDatePicker() {
        const data = moment().subtract(30, 'days');
        this.modelFilter.start = data;
        this.modelFilter.end = moment().add(30, 'days');
        $('.js-daterangepickerFilter').daterangepicker({
            showDropdowns: false,
            startDate: data,
            locale: super.objLocaleDateRangePicker()
        }, (start, end, label) => {
            this.modelFilter.start = start.utcOffset(0, true).format();
            this.modelFilter.end = end.utcOffset(0, true).format();
        });
    }

    actionProfile() {
        this.profileService.get().subscribe({
            next: data => {
                this.listProfile = data as Profile[];
                this.listProfile.forEach(e => {
                    e.name = this.handlerProfileArrayTraduction(e.id);
                });
            }, error: err => super.onError(err)
        });
    }


    setDatePickerModal() {
        this.setDate('js-daterangepickerEvolution', (start, end) => {
            this.model.evaluationTime = end.utcOffset(0, true).format();
        }, this.model.evaluationTime, true, null, 'DD/MM/YYYY - HH:mm');
    }

    handlerDetail(model) {
        this.model = Object.assign({}, model);
        this.model.evaluationTime = moment(this.model.evaluationTime).utcOffset(0, true).format();
        $('#registerEvolution').modal('show');
        this.setDatePickerModal();
    }

    delete(id) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar esta Evolução?', () => {
            this.evolutionService.delete(id).subscribe({
                next: data => {
                    super.showMessage('Sucesso', 'Evolução deletada com sucesso!', 'success');
                    this.actionSearch();
                }, error: err => super.onError(err)
            });
        });
    }

    handlerButton() {
        this.hour = null;
        this.model = new Evolution();
        this.model.evaluationTime = moment().utcOffset(0, true);
        $('#registerEvolution').modal('show');
        this.setDatePickerModal();
        console.log(moment());
    }

    actionSave() {

        this.model.residentId = this.residentId;

        const arrValidateFields = [
            {value: this.model.evaluationTime, text: 'Data da Ocorrência, '},
            {value: this.model.description, text: 'Descrição, '},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        if (this.isNullOrUndefined(this.model.id)) {
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    actionUpdate() {
        this.evolutionService.update(this.model).subscribe({
            next: (data: Evolution) => {
                this.model = new Evolution();
                $('#registerEvolution').modal('hide');
                super.showMessage('Sucesso', 'Evolução atualizada com sucesso!', 'success');
                this.actionSearch();
            }, error: err => super.onError(err)
        });
    }

    handlerProfileArrayTraduction(val) {
        switch (val) {
            case '2d46a64c-39ce-4a8a-9f4b-2ed58e2f4e84' : {
                return 'Dentista';
            }
            case '6c58a7e1-f79a-4089-a84d-00f658be82fa' : {
                return 'Fisioterapêuta';
            }
            case 'a4e4f59a-5f21-47e1-9e95-2e6a74239595' : {
                return 'Psicólogo';
            }
            case '87115c40-8ac3-45c0-a70b-4f8b7ebba7bb' : {
                return 'Administrador';
            }
            case 'a5cf1872-07ed-413e-aa7c-fc9c6c05aa15' : {
                return 'Enfermeiro';
            }
            case 'be55443d-79f0-4e97-83e1-9f2e00415d0b' : {
                return 'Nutricionista';
            }
            case 'f2c34c68-b1de-4b4d-81ca-7bda38ff5696' : {
                return 'Médico';
            }
            case 'b975c9f9-eeab-4baa-a72e-551121e6b816' : {
                return 'Fonoaudiólogo';
            }
            case '916c5ade-d985-406f-ae3a-8a90f7519e84' : {
                return 'Assistente de Farmácia';
            }
            case 'a5027f28-9ace-4c9c-b05d-8a5835c99e5f' : {
                return 'Cuidador';
            }
            case 'cf17d929-9315-4fdf-90ad-4decd3045b64' : {
                return 'Responsável de Unidade';
            }
            case 'f2b171de-7633-4374-9bdc-3cc1b1b2c31e' : {
                return 'Equipe Técnica';
            }
            default :
                return 'Psicólogo';
        }
    }
}
