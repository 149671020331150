<div class="mb-4 text-right" *ngIf="!isCaregiver">
    <a href="javascript:void(0)" class="btn btn-success btn-ico ml-2 btn-sm" (click)="handlerRegister()">
        <svg-icon class="svg-white" src="assets/svg/plus-circle.svg"></svg-icon>
        Cadastrar Fisioterapia
    </a>
</div>

<div class="table-responsive">
    <table class="table">
        <thead>
        <tr>
            <th class="align-middle" scope="col">Data da Ocorrência</th>
            <th class="align-middle" scope="col">Anamnese</th>
            <th class="align-middle" scope="col">Evolução</th>
            <th class="align-middle" scope="col">Responsável</th>
            <th class="align-middle" scope="col">Código de Conselho</th>
            <th class="align-middle" scope="col">Data de Registro</th>
            <th class="align-middle text-center" scope="col">Opções</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="isNullOrUndefined(modelList)">
            <td colspan="6">
                <p class="pt-5 pb-5 text-center">
                    Nenhum registro encontrado.
                </p>
            </td>
        </tr>
        <tr *ngFor="let r of modelList" [ngClass]="{'bluer' : !this.isNullOrUndefined(r.updatedAt)}">
            <td>{{r.evaluationTime | date: 'dd/MM/YYYY'}}</td>
            <td>{{r.anamnesis}}</td>
            <td>{{r.medicalOpinion}}</td>
            <td>{{r.createdByNavigation.name}}</td>
            <td>{{r.createdByNavigation.professionalCouncilCode}}</td>
            <td>{{r.createdAt | date:'dd/MM/yyyy'}}</td>
            <td class="text-center">
                <a href="javascript:void('detail')" class="btn" (click)="handlerDetail(r)">
                    <svg-icon class="ico-edit" src="assets/svg/eye.svg"></svg-icon>
                </a>

<!--                <a href="javascript:void('delete')" class="btn" (click)="delete(r.id)" *ngIf="!isCaregiver">-->
<!--                    <svg-icon class="ico-close" src="assets/svg/close.svg"></svg-icon>-->
<!--                </a>-->
            </td>
        </tr>
        </tbody>
    </table>
</div>

<div class="modal fade" id="physiotherapyModal" tabindex="-1" role="dialog" aria-labelledby="physiotherapyModal"
     aria-hidden="true">
    <div class="modal-dialog modal-75 modal-dialog-centered" id="physiotherapyDetailDisabled" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <div class="col-12">
                    <div class="row nav-header">
                        <h5 class="modal-title text-primary">
                            <span>Físioterapia</span>
                        </h5>
                        <button type="button" class="close close-primary pt-0" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">x</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row align-items-center">
                    <div class="col-sm-12 col-lg-12">
                        <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                            <div class="col-12">
                                <div class="col-12">
                                    <p class="text-gray-primary mb-0">Anamnese</p>
                                </div>
                                <div class="input-material bg-gray mb-0 ">
                                    <textarea id="anamnese" class="bg-gray form-control" type="text"
                                           autocomplete="off" [(ngModel)]="model.anamnesis" required>
                                    </textarea>
                                    <label for="anamnese"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-lg-12">
                        <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                            <div class="col-12">
                                <div class="col-12">
                                    <p class="text-gray-primary mb-0">Avaliação Física</p>
                                </div>
                                <div class="input-material bg-gray mb-0">
                                    <textarea id="FisicalEvaluation" class="bg-gray form-control" type="text"
                                           maxlength="10000"
                                           autocomplete="off" [(ngModel)]="model.physicalEvaluation" required>
                                    </textarea>
                                    <label for="FisicalEvaluation"></label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 my-3 col-md-6 col-lg-4">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="col-4 px-2 text-center text-primary">
                                P.A:
                            </div>
                            <div class="col-4 px-0">
                                <div class="input-material bg-gray mb-0">
                                    <input id="bloodPressure" class="bg-gray form-control p-0" type="text"
                                           [disabled]="!isNullOrUndefined(model.id)"
                                           maxlength="10000" autocomplete="off" name="bloodPressure" required
                                           [(ngModel)]="model.bloodPressure" mask="000/000"/>
                                    <label for="bloodPressure"></label>
                                </div>
                            </div>
                            <div class="col-4 px-2 text-center text-primary">
                                mmhg
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 my-3 col-md-6 col-lg-4">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="col-4 px-2 text-center text-primary">
                                Pulso:
                            </div>
                            <div class="col-4 px-0">
                                <div class="input-material bg-gray mb-0">
                                    <input id="pulse" class="bg-gray form-control p-0" type="number"
                                           [disabled]="!isNullOrUndefined(model.id)"
                                           maxlength="10000" autocomplete="off" name="pulse" required
                                           [(ngModel)]="model.heartRate"/>
                                    <label for="pulse"></label>
                                </div>
                            </div>
                            <div class="col-4 px-2 text-center text-primary">
                                bpm
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 my-3 col-md-6 col-lg-4">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="col-5 px-2 text-center text-primary">
                                Frequência Respiratória (FR)
                            </div>
                            <div class="col-4 px-0">
                                <div class="input-material bg-gray mb-0">
                                    <input id="temperature" class="bg-gray form-control p-0" type="number"
                                           [disabled]="!isNullOrUndefined(model.id)"
                                           maxlength="10000" autocomplete="off" name="temperature" required
                                           [(ngModel)]="model.heartRateFc"/>
                                    <label for="temperature"></label>
                                </div>
                            </div>
                            <div class="col-3 px-2 text-center text-primary">
                                ipm
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="col-5 px-2 text-center text-primary">
                                Saturação:
                            </div>
                            <div class="col-4 px-0">
                                <div class="input-material bg-gray mb-0">
                                    <input id="respiration" class="bg-gray form-control p-0" type="number"
                                           [disabled]="!isNullOrUndefined(model.id)"
                                           maxlength="10000" autocomplete="off" name="respiration" required
                                           [(ngModel)]="model.oxygenSaturation"/>
                                    <label for="respiration"></label>
                                </div>
                            </div>
                            <div class="col-3 px-2 text-center text-primary">
                                mrpm
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="bg-gray input-material mb-0 mx-3">
                                <input id="evaluationDate"
                                       class="form-control bg-gray js-daterangepickerEvaluation"
                                       type="text" maxlength="10000" autocomplete="off" required/>
                                <label for="evaluationDate">Data da Ocorrência</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 my-3 col-md-6 col-lg-12">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="col-12">
                                <p class="text-gray-primary mb-0">Avaliação Funcional</p>
                            </div>
                            <div class="col-12">
                                <div class="input-material bg-gray mb-0">
                                    <textarea id="evaluation" class="bg-gray form-control" type="text"
                                           [disabled]="!isNullOrUndefined(model.id)"
                                           maxlength="10000" autocomplete="off" name="evaluation" required
                                           [(ngModel)]="model.functionalEvaluation">
                                    </textarea>
                                    <label for="evaluation"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 my-3 col-md-6 col-lg-12">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="col-12">
                                <p class="text-gray-primary mb-0">Orientação aos cuidadores</p>
                            </div>
                            <div class="col-12">
                                <div class="input-material bg-gray mb-0">
                                    <textarea id="orientation" class="bg-gray form-control" type="text"
                                           [disabled]="!isNullOrUndefined(model.id)"
                                           maxlength="10000" autocomplete="off" name="orientation" required
                                           [(ngModel)]="model.orientation">
                                    </textarea>
                                    <label for="orientation"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-lg-12">
                        <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                            <div class="col-12">
                                <div class="col-12">
                                    <p class="text-gray-primary mb-0">Fisioterapia</p>
                                </div>
                                <div class="input-material bg-gray mb-0">
                                    <textarea class="form-control bg-gray mt-0" id="physiotherapy" rows="2"
                                              autocomplete="off" [(ngModel)]="model.physiotherapy" required>
                                    </textarea>
                                    <label for="physiotherapy"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-lg-12">
                        <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                            <div class="col-12">
                                <div class="col-12">
                                    <p class="text-gray-primary mb-0">Objetivos</p>
                                </div>
                                <div class="input-material bg-gray mb-0">
                                    <textarea class="form-control bg-gray mt-0" id="objectives" rows="2"
                                              autocomplete="off" [(ngModel)]="model.observation" required>

                                    </textarea>
                                    <label for="physiotherapy"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-lg-12">
                        <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                            <div class="col-12">
                                <div class="col-12">
                                    <p class="text-gray-primary mb-0">Parecer Médico e Nutricional</p>
                                </div>
                                <div class="input-material bg-gray mb-0">
                                    <textarea class="form-control bg-gray mt-0" id="medicalOpinion" rows="2"
                                              autocomplete="off" [(ngModel)]="model.medicalOpinion" required></textarea>
                                    <label for="medicalOpinion"></label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-7 mx-auto mt-4 text-right px-0" *ngIf="!isCaregiver">
                    <a href="javascript:void(0)" class="btn btn-success btn-ico btn-block"
                       (click)="actionSave()">
                        <svg-icon class="svg-white" src="assets/svg/check-circle.svg"></svg-icon>
                        Salvar
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
