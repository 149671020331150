import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ServiceBase} from '../base/service.base';

@Injectable({
    providedIn: 'root'
})
export class DentistService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    get(id) {
        const url = `${this.BASE_URL}/resident/get-dental-health/${id}`;
        return this.http.get(url);
    }

    list(id) {
        const url = `${this.BASE_URL}/resident/${id}/list-dental-health`;
        return this.http.get(url);
    }

    create(model) {
        const url = `${this.BASE_URL}/resident/create-dental-health`;
        return this.http.post(url, model);
    }

    update(residentId, model) {
        const url = `${this.BASE_URL}/resident/update-dental-health`;
        return this.http.put(url, model);
    }

    delete(residentId, id) {
        const url = `${this.BASE_URL}/resident/${id}/delete-dental-health`;
        return this.http.delete(url);
    }

}
