import {State} from './state';

export class City {
  id: string;
  name: string;
  state: State;

  constructor() {
    this.state = new State();
  }
}

