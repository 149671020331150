<div class="container">

    <!-- Outer Row -->
    <section *ngIf="section == 0">
        <div class="row justify-content-center">

            <div class="col-xl-10 col-lg-12 col-md-9 col-11 mt-5">

                <div class="card o-hidden border-0 shadow-lg my-5 mt-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row m-0">
                            <div class="col-lg-6 d-flex align-items-center justify-content-center mt-5 mt-lg-0"> <!--d-flex align-items-center justify-content-center mt-5 mt-lg-0-->
                                <div class="d-flex align-items-center justify-content-center">
                                    <img src="assets/images/logo.png"  alt="login"/>
                                </div>
                            </div>
                            <div class="col-lg-6 d-grid align-content-center">
                                <div class="px-3 px-md-5 py-5">
                                    <div class="text-center">
                                        <h1 class="h4 text-gray-text mb-4">LOGIN</h1>
                                    </div>
                                    <form class="user" autocomplete="off" (keyup.enter)="onLogin()">
                                        <div class="input-material mb-3">
                                            <input type="text" class="form-control" id="emailHelp" name="emailHelp" [(ngModel)]="model.email" required>
                                            <label for="emailHelp">Email</label>
                                        </div>
                                        <div class="input-material">
                                            <input type="password" class="form-control" id="password" name="password" [(ngModel)]="model.password" required>
                                            <a href="javascript:void(0)" class="btn p-0 icon" (click)="handlerViewPassword()">
                                                <svg-icon [src]="!handlerViewPasswordIcon() ? 'assets/svg/password-open.svg' : 'assets/svg/password-closed.svg'"></svg-icon>
                                            </a>
                                            <label for="password">Senha</label>
                                        </div>
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox" *ngIf="false">
                                                <input type="checkbox" class="custom-control-input" id="customCheck">
                                                <label class="custom-control-label" for="customCheck">Lembrar-Me</label>
                                            </div>
                                        </div>
                                        <a href="javascript:void('');" (click)="onLogin()"
                                           class="btn btn-primary btn-user btn-block">
                                            Entrar
                                        </a>
                                    </form>
                                    <hr>
                                    <div class="text-center">
                                        <a href="javascript:void('');" class="text-primary" (click)="nextSection(1)">
                                            Esqueceu a senha?
                                        </a>
                                    </div>
                                    <!--<div class="text-center">-->
                                    <!--<a class="small" (click)="nextSection(2)">Inscrever-se</a>-->
                                    <!--</div>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </section>

    <section *ngIf="section == 1">
        <div class="row justify-content-center">

            <div class="col-xl-10 col-lg-12 col-md-9 mt-5">

                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row m-0">
                            <div class="col-lg-6 d-flex align-items-center justify-content-center mt-5 mt-lg-0">
                                <div class="d-flex align-items-center justify-content-center">
                                    <img src="assets/images/logo.png" height="200" alt="login"/>
                                </div>
                            </div>
                            <div class="col-lg-6 d-grid align-content-center">
                                <div class="p-5">
                                    <div class="text-center">
                                        <h1 class="h4 text-gray-text mb-3">Esqueceu sua senha?</h1>
                                        <p class="mb-4 text-gray-text">Entendemos, as coisas acontecem. Basta digitar
                                            seu
                                            endereço de e-mail abaixo e
                                            enviaremos um link para redefinir sua senha!</p>
                                    </div>
                                    <form class="user" (keyup.enter)="onForgot()">

                                        <div class="form-group mb-5">
                                            <input type="email" class="form-control form-control-user" id="email"
                                                   [(ngModel)]="model.email"
                                                   name="email" placeholder="Email" maxlength="100">
                                        </div>
                                        <a href="javascript:void('');" (click)="onForgot()"
                                           class="btn btn-primary btn-user btn-block">
                                            Redefinir senha
                                        </a>
                                    </form>
                                    <hr>
                                    <!--<div class="text-center">-->
                                    <!--<a class="small" (click)="nextSection(2)">Inscrever-se!</a>-->
                                    <!--</div>-->
                                    <div class="text-center">
                                        <a href="javascript:void('');" class="text-primary" (click)="nextSection(0)">Entrar</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </section>

    <section *ngIf="section == 2">
        <div class="card o-hidden border-0 shadow-lg my-5">
            <div class="card-body p-0">
                <!-- Nested Row within Card Body -->
                <div class="row">
                    <div class="col-lg-5 d-none d-lg-block bg-register-image"></div>
                    <div class="col-lg-7">
                        <div class="p-5">
                            <div class="text-center">
                                <h1 class="h4 text-gray-900 mb-4">Crie a sua conta!</h1>
                            </div>
                            <form class="user">
                                <div class="form-group row">
                                    <div class="col-sm-12 mb-3 mb-sm-0">
                                        <input type="text" class="form-control form-control-user" id="name"
                                               placeholder="Nome Completo">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control form-control-user" id="registerEmail"
                                           placeholder="Email">

                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6 mb-3 mb-sm-0">
                                        <input type="password" class="form-control form-control-user"
                                               id="registerPassword"
                                               placeholder="Senha">
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="password" class="form-control form-control-user"
                                               id="registerRepeatPassword"
                                               placeholder="Confirmar Senha">
                                    </div>
                                </div>
                                <a href="login.html" class="btn btn-primary btn-user btn-block">
                                    Inscrever-se
                                </a>
                            </form>
                            <hr>
                            <div class="text-center">
                                <a class="small" (click)="nextSection(1)">Esqueceu a senha?</a>
                            </div>
                            <div class="text-center">
                                <a class="small" (click)="nextSection(0)">Entrar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>

