import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../model/user/user';

@Injectable({
    providedIn: 'root'
})
export class UserService extends ServiceBase {

    private userDataSource = new BehaviorSubject<User>(new User());
    user = this.userDataSource.asObservable();

    constructor(http: HttpClient) {
        super(http);
    }

    getAuthenticate(model) {
        const url = `${this.BASE_URL}/user/authenticate`;
        return this.http.post(url, model);
    }

    getMe() {
        const url = `${this.BASE_URL}/user/me`;
        return this.http.get(url);
    }

    update(model) {
        const url = `${this.BASE_URL}/user/update`;
        return this.http.put(url, model);
    }

    updateUser(user: User) {
        this.userDataSource.next(user);
    }

    updatePassword(model) {
        const url = `${this.BASE_URL}/user/updatePassword`;
        return this.http.put(url, model);
    }

    register(model) {
        const url = `${this.BASE_URL}/user/register`;
        return this.http.post(url, model);
    }

    listUser() {
        const url = `${this.BASE_URL}/user`;
        return this.http.get(url);
    }

    search(filter, page) {
        const url = `${this.BASE_URL}/user/search-user/${page}`;
        return this.http.post(url, filter);
    }

    forgotPassword(model) {
        const url = `${this.BASE_URL}/user/forgot-password`;
        return this.http.post(url, model);
    }

    refreshToken(model) {
        const url = `${this.BASE_URL}/user/refresh-token`;
        return this.http.post(url, model);
    }

    login(model) {
        const url = `${this.BASE_URL}/user/authenticate`;
        return this.http.post(url, model);
    }

    delete(id) {
        const url = `${this.BASE_URL}/user/${id}`;
        return this.http.delete(url);
    }

    excel() {
        const url = `${this.BASE_URL}/user/search/excel`;
        return this.http.get(url);
    }

    validate(id) {
        const url = `${this.BASE_URL}/user/validate-reset-token/${id}`;
        return this.http.get(url);
    }

    reset(data) {
        const url = `${this.BASE_URL}/user/reset-password`;
        return this.http.post(url, data);
    }

    upload(file, id) {
        const url = `${this.BASE_URL}/upload/user-profile/${id}`;
        return new Observable((observer) => {
            // tslint:disable-next-line:one-variable-per-declaration
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();

            if (file != null) {
                formData.append('file', file, file.name);
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.upload.onprogress = (event) => {
                const progress = Math.round(event.loaded / event.total * 100);
            };

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.getToken());
            xhr.send(formData);
        });
    }
}
