export class ResidentResponsible {
    id: string;
    name: string;
    email: string;
    cpf: string;
    birthday: string;
    degreeOfKinship: string;
    address: string;
    phoneNumber: string;
    city: string;
    isCurator: boolean;
    createdAt: Date;
    updatedAt: Date;

    constructor() {
    }
}
