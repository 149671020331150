import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {UserService} from '../../service/user.service';
import {User} from '../../model/user/user';
import {ForgotPassword} from '../../model/user/forgot-password';

declare var $: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit, OnDestroy {

    modelUser: User = new User();
    modelForgot: ForgotPassword = new ForgotPassword();

    constructor(public router: Router,
                public userService: UserService) {
        super(router);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.modelUser = data as User;
            }
        });
    }

    ngOnDestroy(): void {
        const a = $('#forgotModal');
        if (a[0]) {
            a.remove();
        }
    }

    actionNavToggle(): void {
        $('body').toggleClass('nav-open');
        $('.hamburger').toggleClass('open');
    }

    actionForgotPassword() {
        const arrValidateFields = [
            {value: this.modelForgot.actualPassword, text: 'Senha Atual, '},
            {value: this.modelForgot.newPassword, text: `Nova Senha, <br>`},
            {value: this.modelForgot.confirmNewPassword, text: `Confirme sua nova senha.`},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }


        if (this.modelForgot.newPassword.length < 6) {
            super.showMessage('Atenção', 'Sua senha deve conter no mínimo 6 caracteres.', 'warning');
            return;
        }

        if (typeof this.modelForgot.newPassword === 'undefined' || this.modelForgot.newPassword.length === 0
            || this.modelForgot.newPassword !== this.modelForgot.confirmNewPassword) {
            super.showMessage('Atenção', 'Senhas não conferem!', 'warning');
            return;
        }

        this.userService.updatePassword(this.modelForgot).subscribe({
            next: data => {
                $('#forgotModal').modal('hide');
                super.showMessage('Sucesso', 'Senha alterada com sucesso!', 'success');
                this.modelForgot = new ForgotPassword();
            }
        });
    }

    handlerForgotPassword() {
        $('#forgotModal').modal('show');
        this.modelForgot = new ForgotPassword();
    }
}
