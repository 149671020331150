export class ResidentHealths {
    id: string;
    residentId: string;
    baseDisease: string;
    mentalDisease: string;
    mentalDiseaseType: string;
    cognitiveFunction: string;
    cognitiveFunctionType: string;
    arterialHypertension: string;
    heartDisease: string;
    heartDiseaseType: string;
    sexuallyTransmittedDisease: string;
    sexuallyTransmittedDiseaseType: string;
    diabetes: string;
    insulin: string;
    insulinType: string;
    arthritis: string;
    arthrosis: string;
    osteoporosis: string;
    stomachUlcers: string;
    kidneyDisease: string;
    othersDiseases: string;
    familyDiseaseHistoric: string;
    familyDiseaseKinship: string;
    hearingDeficit: string;
    hearingAid: string;
    visualAid: string;
    visualDeficit: string;
    dentalProsthesis: string;
    dentalProsthesisType: string;
    foodProbe: string;
    foodProbeType: string;
    foodProbeTime: string;
    eliminationProbe: string;
    eliminationProbeType: string;
    eliminationProbeTime: string;
    diaper: string;
    diaperTime: string;
    tampons: string;
    tamponsTime: string;
    urinaryHabit: string;
    bowelHabit: string;
    sleep: string;
    previousSurgeries: string[];
    previousSurgeriesTime: string[];
    labExam: string;
    imageExam: string;
    hospitalVisitFrequency: string;
    trustedDoctor: string;
    medicalInsurance: string;
    coveredHospitals: string;
    createdAt: Date;
    updatedAt: Date;

    constructor() {
    }
}
