import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {ResidentService} from '../../service/resident/resident.service';
import {ResultResident} from '../../model/resident/result-resident';
import {FilterResident} from '../../model/resident/filter-resident';
import {FilterUnity} from '../../model/unity/filter-unity';
import {Unity} from '../../model/unity/unity';
import {Profile} from '../../model/profile';
import {UnityService} from '../../service/unity.service';
import {Resident} from '../../model/resident/resident';
import {ResidentResponsible} from '../../model/resident/resident-responsible';
import {ResponsibleService} from '../../service/resident/responsible.service';
import {UserService} from '../../service/user.service';
import {User} from '../../model/user/user';

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-residents',
    templateUrl: './residents.component.html',
    styleUrls: ['./residents.component.scss']
})
export class ResidentsComponent extends BaseComponent implements OnInit, OnDestroy {

    modelFilter: FilterResident = new FilterResident();
    modelResult: ResultResident = new ResultResident();
    listUnity: Unity[] = [];

    model: Resident = new Resident();
    listResponsible: ResidentResponsible[] = [];
    modelResponsible: ResidentResponsible = new ResidentResponsible();
    modelUser: User = new User();
    currentPage = 1;
    isAdmin = true;
    caregiverOrUnit = false;

    constructor(public router: Router,
                public residentService: ResidentService,
                public unityService: UnityService,
                public responsibleService: ResponsibleService,
                public userService: UserService) {
        super(router);
    }

    ngOnInit(): void {
        this.actionUnity();
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.modelUser = data as User;
                    // ID = ADMIN
                    if (this.modelUser.profile?.id !== '87115c40-8ac3-45c0-a70b-4f8b7ebba7bb') {
                        this.isAdmin = false;
                    }
                    // ID = CAREGIVER AND UNIT DIRECTOR
                    if (this.modelUser.profile?.id === 'a5027f28-9ace-4c9c-b05d-8a5835c99e5f' || this.modelUser.profile?.id === 'cf17d929-9315-4fdf-90ad-4decd3045b64') {
                        this.modelFilter.unity = this.modelUser.userUnities;
                        this.caregiverOrUnit = true;
                        this.actionSearch();
                    }
                }
            }
        });
        moment.locale('pt-br');
        this.setDatePicker();
        this.cleanFilterStart();
        this.actionSearch();
    }

    ngOnDestroy(): void {
        const a = $('#userModal');
        if (a[0]) {
            a.remove();
        }
    }

    actionUnity() {
        this.unityService.get().subscribe({
            next: data => {
                this.listUnity = data as Unity[];
            }, error: err => super.onError(err)
        });
    }

    setDatePicker() {
        const data = moment().subtract(30, 'days');
        this.modelFilter.start = data;
        this.modelFilter.end = moment();

        $('.js-daterangepicker').daterangepicker({
            showDropdowns: false,
            startDate: data,
            locale: super.objLocaleDateRangePicker()
        }, (start, end, label) => {
            this.modelFilter.start = start.utcOffset(0, true).format();
            this.modelFilter.end = end.utcOffset(0, true).format();
        });
    }

    setDatePickerModal() {
        const startAdmission = this.isNullOrUndefined(this.model.admissionDate) ? moment() : moment(this.model.admissionDate);
        $('.js-admissionDate').daterangepicker({
            singleDatePicker: true,
            autoUpdateInput: false,
            showDropdowns: true,
            startDate: startAdmission,
            locale: super.objLocaleDateRangePicker()
        }, (start) => {
            this.model.admissionDate = start.utcOffset(0, true).format();
            $('.js-admissionDate').val(moment(this.model.admissionDate).add(1, 'day').format('DD/MM/YYYY'));
        });
        const startExit = this.isNullOrUndefined(this.model.exitDate) ? moment() : moment(this.model.exitDate);
        $('.js-exitDate').daterangepicker({
            singleDatePicker: true,
            autoUpdateInput: false,
            showDropdowns: true,
            startDate: startExit,
            locale: super.objLocaleDateRangePicker()
        }, (start) => {
            this.model.exitDate = start.utcOffset(0, true).format();
            $('.js-exitDate').val(moment(this.model.exitDate).add(1, 'day').format('DD/MM/YYYY'));
        });
    }

    actionSearch() {
        this.residentService.search(this.modelFilter, this.currentPage).subscribe({
            next: data => {
                this.modelResult = data as ResultResident;
            }, error: err => super.onError(err)
        });
    }

    handlerAdmin(model) {
        this.model = Object.assign({}, model);
        this.model.birthday = moment(this.model.birthday).format('DD/MM/YYYY');
        this.setDatePickerModal();
        if (!this.isNullOrUndefined(this.model.admissionDate)) {
            $('.js-admissionDate').val(moment(this.model.admissionDate).format('DD/MM/YYYY'));
        }
        if (!this.isNullOrUndefined(this.model.exitDate)) {
            $('.js-exitDate').val(moment(this.model.exitDate).format('DD/MM/YYYY'));
        }
        this.actionListResponsible();
        this.showModal('userModal');
    }

    pageChanged(event) {
        this.currentPage = event;
        this.actionSearch();
    }

    getExcel() {
        const token = localStorage.getItem('token') as string;
        const start = !super.isNullOrUndefined(this.modelFilter.start) ?
            moment(this.modelFilter.start).format('YYYY-MM-DD') : null;
        const end = !super.isNullOrUndefined(this.modelFilter.end) ?
            moment(this.modelFilter.end).format('YYYY-MM-DD') : null;

        let url = `${this.getBaseURL()}/resident/search/excel?token=${token}`;

        if (!super.isNullOrUndefined(this.modelFilter.keyword)) {
            url += ('&name=' + this.modelFilter.keyword);
        }

        if (!super.isNullOrUndefined(this.modelFilter.unity)) {
            url += ('&email=' + this.modelFilter.unity.map(m => m.id).join('|'));
        }
        if (!super.isNullOrUndefined(start)) {
            url += ('&start=' + start);
        }
        if (!super.isNullOrUndefined(end)) {
            url += ('&end=' + end);
        }
        window.open(encodeURI(url));
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.name, text: 'Nome, <br>'},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }


        if (this.isNullOrUndefined($('.js-admissionDate').val())) {
            this.model.admissionDate = undefined;
        }
        if (this.isNullOrUndefined($('.js-exitDate').val())) {
            this.model.exitDate = undefined;
        }

        this.model.birthday = moment(this.model.birthday).utcOffset(0, true).format();

        this.residentService.update(this.model).subscribe({
            next: (data: Resident) => {
                this.model = new Resident();
                super.showMessage('Sucesso', 'Residente atualizado com sucesso!', 'success');
                this.actionSearch();
                this.closeModal('userModal');
            }, error: err => super.onError(err)
        });
    }

    delete(id) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar este residente?', () => {
            this.residentService.delete(id).subscribe({
                next: data => {
                    this.actionSearch();
                    super.showMessage('Sucesso', 'Residente deletado com sucesso!', 'success');
                }, error: err => super.onError(err)
            });
        });
    }

    cleanFilterStart() {
        this.modelFilter.start = null;
        this.modelFilter.end = null;
    }

    actionListResponsible() {
        this.responsibleService.get(this.model.id).subscribe({
            next: data => {
                this.listResponsible = data as ResidentResponsible[];
                this.modelResponsible = this.listResponsible[0];
            }, error: err => super.onError(err)
        });
    }

    handlerDetailResponsible(model) {
        if (this.isNullOrUndefined(this.modelResponsible.id)) {
            this.modelResponsible = Object.assign({}, model);
            $('#' + this.modelResponsible.id).collapse('show');
        } else {
            $('#' + this.modelResponsible.id).collapse('hide');
            this.modelResponsible = new ResidentResponsible();
        }
    }

    actionResponsible() {
        const arrValidateFields = [
            {value: this.modelResponsible.name, text: 'Nome, '},
            {value: this.modelResponsible.birthday, text: 'Data de Nascimento, <br>'},
            {value: this.modelResponsible.degreeOfKinship, text: 'Grau de Parentesco,'},
            {value: this.modelResponsible.address, text: 'Endereço Atual, '},
            {value: this.modelResponsible.city, text: 'Cidade, <br>'},
            {value: this.modelResponsible.phoneNumber, text: 'Telefone, '},
            {value: this.modelResponsible.email, text: 'E-mail. '},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        if (!super.isEMailValid(this.modelResponsible.email)) {
            return;
        }

        this.responsibleService.update(this.model.id, this.modelResponsible).subscribe({
            next: (data: ResidentResponsible) => {
                $('#' + this.modelResponsible.id).collapse('hide');
                this.modelResponsible = new ResidentResponsible();
                super.showMessage('Sucesso', 'Reponsável atualizado com sucesso!', 'success');
                this.actionListResponsible();
            }, error: err => super.onError(err)
        });
    }

    handlerJudicial(val) {
        if (val !== 'yes') {
            this.model.judicialInterdiction = 'yes';
        } else {
            this.model.judicialInterdiction = 'no';
        }
    }
}
