import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../../base/base.component';
import {ResidentHealths} from '../../../../../model/resident/resident-healths';
import {ResidentSkinIntegrity} from '../../../../../model/resident/resident-skin-integrity';
import {ResidentAllergies} from '../../../../../model/resident/resident-allergies';
import {Router} from '@angular/router';
import {NursingService} from '../../../../../service/resident/nursing.service';
import {SkinIntegrityService} from '../../../../../service/resident/skinIntegrity.service';
import {AllergyService} from '../../../../../service/resident/allergy.service';
import {User} from '../../../../../model/user/user';
import {UserService} from '../../../../../service/user.service';

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-resident-nursing',
    templateUrl: './resident-nursing.component.html',
    styleUrls: ['./resident-nursing.component.scss']
})
export class ResidentNursingComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    model: ResidentHealths = new ResidentHealths();
    residentId: string;
    surgeries: string;
    drug = {allergy: '', description: ''};
    perfume = {allergy: '', description: ''};
    food = {allergy: '', description: ''};
    other = {allergy: '', description: ''};
    times: string;
    allergyCheck = false;
    previousSurgeries = [];
    previousSurgeriesTime = [];
    modelSkinIntegrity: ResidentSkinIntegrity = new ResidentSkinIntegrity();
    modelSkins: ResidentSkinIntegrity[] = [];
    modelUser: User = new User();
    isCaregiver = false;
    modelAllergy: ResidentAllergies = new ResidentAllergies();
    modelAllergies: ResidentAllergies[] = [];

    @Input() set resident(val: any) {
        this.residentId = val;
    }

    constructor(public router: Router,
                public nursingService: NursingService,
                public skinService: SkinIntegrityService,
                public userService: UserService,
                public allergyService: AllergyService) {
        super(router);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.modelUser = data as User;
                    // ID = ADMIN + NURSE
                    if (this.modelUser.profile?.id !== '87115c40-8ac3-45c0-a70b-4f8b7ebba7bb' && this.modelUser.profile?.id !== 'a5cf1872-07ed-413e-aa7c-fc9c6c05aa15' && this.modelUser.profile?.id !== 'cf17d929-9315-4fdf-90ad-4decd3045b64') {
                        $('#nursingDisabled input').prop('disabled', true);
                        $('#nursingDisabled select').prop('disabled', true);
                        $('#nursingDisabled label').addClass('disabled');
                        this.isCaregiver = true;
                    }
                }
            }
        });
        if (!this.isNullOrUndefined(this.residentId)) {
            this.actionNursing();
            this.actionAllergies();
            this.actionSkinIntegrity();
        }
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        const a = $('#nursingModal');
        if (a[0]) {
            a.remove();
        }
        const i = $('#registerSkinIntegrity');
        if (i[0]) {
            i.remove();
        }
    }

    actionNursing() {
        this.nursingService.get(this.residentId).subscribe({
            next: (data: ResidentHealths) => {
                if (!this.isNullOrUndefined(data)) {
                    this.model = data as ResidentHealths;
                    if (this.isNullOrUndefined(this.previousSurgeries)) {
                        this.previousSurgeries = this.model.previousSurgeries;
                    }
                    if (this.isNullOrUndefined(this.previousSurgeriesTime)) {
                        this.previousSurgeriesTime = this.model.previousSurgeriesTime;
                    }
                }
            }, error: err => super.onError(err)
        });
    }

    delete(id) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar esta enfermagem?', () => {
            this.nursingService.delete(this.residentId, id).subscribe({
                next: data => {
                    super.showMessage('Sucesso', 'Enfermagem deletada com sucesso!', 'success');
                    this.actionNursing();
                }, error: err => super.onError(err)
            });
        });
    }

    actionSave() {
        this.model.previousSurgeries = this.previousSurgeries;
        this.model.previousSurgeriesTime = this.previousSurgeriesTime;
        const arrValidateFields = [
            {value: this.model.baseDisease, text: 'Doença Base, '},
            {value: this.model.mentalDisease, text: `Doença Mental, <br>`},
            {value: this.model.arterialHypertension, text: 'Hipertensão,'},
            {value: this.model.diabetes, text: `Diabetes.`},
        ];

        const stringError = this.validateField(arrValidateFields);
        if (!this.isNullOrUndefined(this.previousSurgeries)) {
            this.model.previousSurgeries = this.previousSurgeries;
        }
        if (!this.isNullOrUndefined(this.previousSurgeriesTime)) {
            this.model.previousSurgeriesTime = this.previousSurgeriesTime;
        }
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        if (this.isNullOrUndefined(this.model.id)) {
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    actionCreate() {
        this.nursingService.create(this.residentId, this.model).subscribe({
            next: (data: ResidentHealths) => {
                this.model = data as ResidentHealths;
                this.actionNursing();
                $('#registerNursing').modal('hide');
                super.showMessage('Sucesso', 'Enfermagem cadastrado com sucesso!', 'success');
            }, error: err => super.onError(err)
        });
    }

    actionUpdate() {
        this.nursingService.update(this.residentId, this.model).subscribe({
            next: (data: ResidentHealths) => {
                this.model = data as ResidentHealths;
                this.actionNursing();
                $('#registerNursing').modal('hide');
                super.showMessage('Sucesso', 'Enfermagem atualizado com sucesso!', 'success');
            }, error: err => super.onError(err)
        });
    }

    // SkinIntegrity
    handlerDetailSkin(model) {
        this.modelSkinIntegrity = Object.assign({}, model);
        $('#registerSkinIntegrity').modal('show');
    }

    deleteSkin(id) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar esta integridade?', () => {
            this.skinService.delete(this.residentId, id).subscribe({
                next: data => {
                    super.showMessage('Sucesso', 'Integridade deletada com sucesso!', 'success');
                }, error: err => super.onError(err)
            });
        });
    }

    handlerButtonSkin() {
        this.modelSkinIntegrity = new ResidentSkinIntegrity();
        $('#registerSkinIntegrity').modal('show');
    }

    actionSkinIntegrity() {
        this.skinService.get(this.residentId).subscribe({
            next: data => {
                this.modelSkins = data as ResidentSkinIntegrity[];
            }, error: err => super.onError(err)
        });
    }

    actionSaveSkins() {
        const arrValidateFields = [
            {value: this.modelSkinIntegrity.presentedInjuryType, text: 'Lesão Apresentada, '},
            {value: this.modelSkinIntegrity.place, text: 'Lesão Apresentada local <br> '},
            {value: this.modelSkinIntegrity.presentedInjuryTime, text: 'Duração da Lesão,'},
            {value: this.modelSkinIntegrity.previousTreatment, text: 'Tratamentos anteriores, <br>'},
        ];
        if (this.modelSkinIntegrity.previousTreatment === 'yes') {
            arrValidateFields.push({value: this.modelSkinIntegrity.previousTreatmentType, text: 'Tratamento anterior qual?'});
        }

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        if (this.isNullOrUndefined(this.modelSkinIntegrity.id)) {
            this.actionCreateSkins();
        } else {
            this.actionUpdateSkins();
        }
    }

    actionCreateSkins() {
        this.skinService.create(this.residentId, this.modelSkinIntegrity).subscribe({
            next: (data: ResidentSkinIntegrity) => {
                this.modelSkinIntegrity = new ResidentSkinIntegrity();
                $('#registerSkinIntegrity').modal('hide');
                super.showMessage('Sucesso', 'Integridade cadastrada com sucesso!', 'success');
                this.actionSkinIntegrity();
            }, error: err => super.onError(err)
        });
    }

    actionUpdateSkins() {
        this.skinService.update(this.residentId, this.modelSkinIntegrity).subscribe({
            next: (data: ResidentSkinIntegrity) => {
                this.modelSkinIntegrity = new ResidentSkinIntegrity();
                $('#registerSkinIntegrity').modal('hide');
                super.showMessage('Sucesso', 'Integridade atualizada com sucesso!', 'success');
                this.actionSkinIntegrity();
            }, error: err => super.onError(err)
        });
    }

    handlerDuration(value) {
        switch (value) {
            case 'zeroTwo':
                return '0 à 2 meses';
            case 'twoFour':
                return '2 à 4 meses';
            case 'fourSix':
                return '4 à 6 meses';
            case 'sixEight':
                return '6 à 8 meses';
            case 'moreEight':
                return 'mais de 8 meses';
        }
    }


    // Allergy
    handlerDetailAllergy(model) {
        this.modelAllergy = Object.assign({}, model);
        $('#registerAllergy').collapse('show');
    }

    deleteAllergy(id) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar esta alergia?', () => {
            this.allergyService.delete(this.residentId, id).subscribe({
                next: data => {
                    super.showMessage('Sucesso', 'Alergia deletada com sucesso!', 'success');
                }, error: err => super.onError(err)
            });
        });
    }

    handlerButtonAllergy() {
        this.modelAllergy = new ResidentAllergies();
    }

    actionAllergies() {
        this.allergyService.get(this.residentId).subscribe({
            next: (data: ResidentAllergies) => {
                if (!this.isNullOrUndefined(data)) {
                    this.modelAllergy = data as ResidentAllergies;
                }
                this.allergyCheck = this.allergyCheckBool(data);
            }, error: err => super.onError(err)
        });
    }

    actionSaveAllergy() {
        // if (this.isNullOrUndefined(this.modelAllergy.drugAllergy) && this.isNullOrUndefined(this.modelAllergy.foodAllergy)
        //     && this.isNullOrUndefined(this.modelAllergy.perfumeAllergy) && this.isNullOrUndefined(this.modelAllergy.othersAllergy)) {
        //     super.showMessage('Atenção', 'Preencha pelo menos um campo para prosseguir!', 'warning');
        //     return;
        // }
        if (!this.isNullOrUndefined(this.drug)) {
            this.modelAllergy.drugAllergy = this.drug.allergy + '' + this.drug.description;
        }
        if (!this.isNullOrUndefined(this.perfume)) {
            this.modelAllergy.perfumeAllergy = this.perfume.allergy + '' + this.perfume.description;
        }
        if (!this.isNullOrUndefined(this.food)) {
            this.modelAllergy.foodAllergy = this.food.allergy + '' + this.food.description;
        }
        if (!this.isNullOrUndefined(this.other)) {
            this.modelAllergy.othersAllergy = this.other.allergy + '' + this.other.description;
        }
        if (this.isNullOrUndefined(this.modelAllergy.id)) {
            this.actionCreateAllergy();
        } else {
            this.actionUpdateAllergy();
        }
    }

    actionCreateAllergy() {
        this.allergyService.create(this.residentId, this.modelAllergy).subscribe({
            next: (data: ResidentAllergies) => {
                this.modelAllergy = new ResidentAllergies();
                $('#registerAllergy').collapse('hide');
                super.showMessage('Sucesso', 'Alergia cadastrada com sucesso!', 'success');
                this.actionAllergies();
            }, error: err => super.onError(err)
        });
    }

    actionUpdateAllergy() {
        this.allergyService.update(this.residentId, this.modelAllergy).subscribe({
            next: (data: ResidentAllergies) => {
                this.modelAllergy = new ResidentAllergies();
                $('#registerAllergy').collapse('hide');
                super.showMessage('Sucesso', 'Alergia atualizada com sucesso!', 'success');
                this.actionAllergies();
            }, error: err => super.onError(err)
        });
    }

    // ADICIONAR
    add() {
        this.previousSurgeries.push(this.surgeries);
        console.log(this.previousSurgeries);
        this.surgeries = null;
    }

    addTime() {
        this.previousSurgeriesTime.push(this.times);
        console.log(this.previousSurgeriesTime);
        this.times = null;
    }

    // REMOVER
    removeItemOnce(x) {
        const index = this.previousSurgeries.indexOf(x);
        if (index > -1) {
            this.previousSurgeries.splice(index, 1);
        }
        console.log(this.previousSurgeries);
    }

    removeTime(x) {
        const index = this.previousSurgeriesTime.indexOf(x);
        if (index > -1) {
            this.previousSurgeriesTime.splice(index, 1);
        }
        console.log(this.previousSurgeriesTime);
    }

    allergyCheckBool(x: ResidentAllergies) {
        if (!this.isNullOrUndefined(x?.drugAllergy)) {
            return true;
        }
        if (!this.isNullOrUndefined(x?.perfumeAllergy)) {
            return true;
        }
        if (!this.isNullOrUndefined(x?.foodAllergy)) {
            return true;
        }
        if (!this.isNullOrUndefined(x?.othersAllergy)) {
            return true;
        }
        return false;
    }

    AllergyDesc() {

    }

}
