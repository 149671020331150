import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../base/base.component';
import {Router} from '@angular/router';
import {UserService} from '../../../../service/user.service';
import {Resident} from '../../../../model/resident/resident';
import {SpeechTherapy} from '../../../../model/speech-therapy/speechTherapy';
import {Dentist} from '../../../../model/dentist';
import {DentistService} from '../../../../service/dentist.service';
import {Doctor} from '../../../../model/doctor';
import {User} from '../../../../model/user/user';

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-dentist',
    templateUrl: './dentist.component.html',
    styleUrls: ['./dentist.component.scss']
})
export class DentistComponent extends BaseComponent implements OnInit {

    @Input() set resident(val: any) {
        this.residentId = val;
    }

    constructor(public router: Router,
                public dentistService: DentistService,
                public userService: UserService,
    ) {
        super(router);
    }

    residentId: string;
    modelResident: Resident = new Resident();
    model: Dentist = new Dentist();
    modelDentists: Dentist[] = [];
    isCaregiver = false;
    modelUser: User;

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.modelUser = data as User;
                    // ID = DENTIST
                    if (this.modelUser.profile?.id !== '2d46a64c-39ce-4a8a-9f4b-2ed58e2f4e84') {
                        $('#dentistDisabled input').prop('disabled', true);
                        $('#dentistDisabled select').prop('disabled', true);
                        $('#dentistDisabled textarea').prop('disabled', true);
                        $('#dentistDisabled label').addClass('disabled');
                        this.isCaregiver = true;
                    }
                }
            }
        });
        if (!this.isNullOrUndefined(this.residentId)) {
            this.actionDentist();
        }
    }

    setDatePickerModal() {
        $('.js-daterangepickerEvaluation').daterangepicker({
            showDropdowns: false,
            singleDatePicker: true,
            autoUpdateInput: false,
            locale: super.objLocaleDateRangePicker()
        }, (start, end, label) => {
            this.model.evaluationTime = start.utcOffset(0, true).format();
            $('.js-daterangepickerEvaluation').val(moment(this.model.evaluationTime).add(1, 'day').format('DD/MM/YYYY'));
        });
    }

    handlerButton() {
        this.model = new Dentist();
        $('#registerDentist').modal('show');
        this.model.evaluationTime = moment().utcOffset(0, true).format();
        $('.js-daterangepickerEvaluation').val(moment(this.model.evaluationTime).format('DD/MM/YYYY'));
        this.setDatePickerModal();
    }

    actionSave() {
        this.model.residentId = this.residentId;
        const arrValidateFields = [
            {value: this.model.procedure, text: 'Anamnese, '},
            {value: this.model.evaluationTime, text: 'Data da Ocorrência, '},
            {value: this.model.anamnesis, text: 'Observação, '},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        if (this.isNullOrUndefined(this.model.id)) {
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    actionCreate() {
        this.dentistService.create(this.model).subscribe({
            next: (data: Dentist) => {
                this.model = new Dentist();
                $('#registerDentist').modal('hide');
                super.showMessage('Sucesso', 'Avaliação Odontológica cadastrada com sucesso!', 'success');
                this.actionDentist();
            }, error: err => super.onError(err)
        });
    }

    actionUpdate() {
        this.dentistService.update(this.residentId, this.model).subscribe({
            next: (data: Dentist) => {
                this.model = new Dentist();
                $('#registerDentist').modal('hide');
                super.showMessage('Sucesso', 'Avaliação Odontológica atualizada com sucesso!', 'success');
                this.actionDentist();
            }, error: err => super.onError(err)
        });
    }

    actionDentist() {
        this.dentistService.list(this.residentId).subscribe({
            next: data => {
                this.modelDentists = data as Dentist[];
            }, error: err => super.onError(err)
        });
    }

    handlerDetail(model) {
        this.model = Object.assign({}, model);
        $('#registerDentist').modal('show');
    }

    delete(id) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar esta Avaliação?', () => {
            this.dentistService.delete(this.residentId, id).subscribe({
                next: data => {
                    super.showMessage('Sucesso', 'Avaliação Médica deletada com sucesso!', 'success');
                    this.actionDentist();
                }, error: err => super.onError(err)
            });
        });
    }
}
