<section class="container">

    <div class="d-flex align-items-center justify-content-between mx-3 mt-4">
        <h1 class="title">Usuários</h1>
        <a href="javascript:void (0)" (click)="handlerRegister()" class="btn btn-success btn-ico ml-2 btn-sm">
            <svg-icon class="svg-white mr-2" src="assets/svg/plus-circle.svg"></svg-icon>
            Cadastrar Usuário
        </a>
    </div>

    <div class="mt-3">

        <div class="d-sm-flex align-items-center justify-content-between mt-4 mx-4">
            <strong class="subTitle"></strong>
        </div>

        <div class="card mt-3 bg-white mx-2">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12 col-lg-6 col-xxl-3">
                        <div class="input-material">
                            <input id="date" class="form-control js-daterangepicker" type="text" maxlength="10000"
                                   autocomplete="off" required/>
                            <label for="date">Periodo</label>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6 col-xxl-3">
                        <div class="input-material">
                            <input id="name" class="form-control" type="text" maxlength="10000" autocomplete="off"
                                   [(ngModel)]="modelFilter.name" required/>
                            <label for="name">Nome</label>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6 col-xxl-3">
                        <div class="form-group">
                            <label></label>
                            <ng-multiselect-dropdown
                                    [placeholder]="'Perfil'"
                                    [data]="listProfile"
                                    [(ngModel)]="modelFilter.profile"
                                    [settings]="dropdownSettings">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6 col-xxl-3">
                        <div class="input-material">
                            <input id="email" class="form-control" type="text" maxlength="10000" autocomplete="off"
                                   [(ngModel)]="modelFilter.email" required/>
                            <label for="email">Email</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <a href="javascript:void('');" (click)="getExcel()"
                           class="btn btn-ico btn-sm btn-info mx-2 mb-3 mb-xs-0">
                            Exportar Excel
                        </a>
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0"
                           (click)="actionSearch()">
                            Buscar
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mt-3 mx-2 bg-white p-3">
            <div class="table-responsive">
                <table class="table ">
                    <thead>
                    <tr>
                        <th class="align-middle w-15" scope="col"></th>
                        <th class="align-middle w-15" scope="col">Data de Criação</th>
                        <th class="align-middle" scope="col">Nome</th>
                        <th class="align-middle" scope="col">Email</th>
                        <th class="align-middle text-center" scope="col">Perfil</th>
                        <th class="align-middle text-center w-15" scope="col">Opções</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="isNullOrUndefined(modelResult.results)">
                        <td colspan="7">
                            <p class="pt-5 pb-5 text-center">
                                Nenhum registro encontrado.
                            </p>
                        </td>
                    </tr>
                    <tr *ngFor="let r of modelResult.results |
                                paginate: {
                                 itemsPerPage: modelResult.pageSize,
                                 currentPage: modelResult.currentPage,
                                 totalItems: modelResult.totalCount}; let i = index">
                        <td class="text-center">
                            <img [src]="isNullOrUndefined(r.photo) ? 'https://via.placeholder.com/120' : r.photo"
                                 class="photo-user" width="70" height="70" alt="...">
                        </td>
                        <td>{{r.createdAt | date: 'dd/MM/yyyy'}}</td>
                        <td>{{r.name}}</td>
                        <td>{{r.email}}</td>
                        <td class="text-center">{{handlerProfileTraduction(r.profile.id)}}</td>
                        <td class="text-center">
                            <a href="javascript:void('detail')" (click)="handlerDetail(r)" class="btn">
                                <svg-icon class="ico-edit" src="assets/svg/edit.svg"></svg-icon>
                            </a>
                            <a href="javascript:void('delete')" class="btn" (click)="delete(r.id)">
                                <svg-icon class="ico-close" src="assets/svg/close.svg"></svg-icon>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-center">
                <pagination-controls
                        [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"
                        [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                        [screenReaderPageLabel]="labels.screenReaderPageLabel"
                        [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                        (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
        </div>

    </div>
</section>

<!-- Modal -->
<div class="modal fade" id="userModal" tabindex="-1" role="dialog" aria-labelledby="userModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    <span *ngIf="isNullOrUndefined(model.id)">Cadastro de Usuário</span>
                    <span *ngIf="!isNullOrUndefined(model.id)">Detalhe do Usuário</span>
                </h5>
                <button type="button" class="close close-primary pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">x</span>
                </button>
            </div>
            <div class="modal-body">
                <form autocomplete="off">
                    <div class="col-12 mb-3 text-center">
                        <img [src]="isNullOrUndefined(thumpPath) ? 'https://via.placeholder.com/120' : thumpPath"
                             class="photo-user-edit" alt="...">
                    </div>
                    <form>
                        <div class="form-group mt-4">
                            <label for="FormControlFile3" class="btn btn-block btn-outline-primary w-5">
                                <span>Alterar Imagem</span>
                            </label>
                            <input type="file" class="form-control-file d-none" id="FormControlFile3"
                                   onclick="this.value = null" (change)="handlerUpload($event)">
                        </div>
                    </form>
                    <div class="col-sm-12">
                        <div class="input-material">
                            <input id="modalName" name="modalName" class="form-control" type="text"
                                   [(ngModel)]="model.name" required/>
                            <label for="modalName">Nome</label>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="input-material">
                            <input id="modalEmail" name="modalEmail" class="form-control" type="text"
                                   [(ngModel)]="model.email" required/>
                            <label for="modalEmail">Email</label>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="input-material">
                            <select id="modalProfile" name="modalProfile" [(ngModel)]="model.profileId"
                                    (ngModelChange)="handlerProfile(model.profileId)">
                                <option [ngValue]="undefined">Selecione</option>
                                <option *ngFor="let p of listProfile" [ngValue]="p.id">{{handlerProfileTraduction(p.id)}}</option>
                            </select>
                            <label for="modalProfile">Perfil</label>
                        </div>
                    </div>
                    <!--ID'S ADMIN ; CAREGIVER; UNIT DIRECTOR; TECHNICAL TEAM-->
                    <div class="col-sm-12">
                        <div class="input-material" *ngIf="model.profile.id === '2d46a64c-39ce-4a8a-9f4b-2ed58e2f4e84' || model.profile.id === '6c58a7e1-f79a-4089-a84d-00f658be82fa' || model.profile.id === 'a4e4f59a-5f21-47e1-9e95-2e6a74239595' || model.profile.id === 'a5cf1872-07ed-413e-aa7c-fc9c6c05aa15' || model.profile.id === 'be55443d-79f0-4e97-83e1-9f2e00415d0b' || model.profile.id === 'f2c34c68-b1de-4b4d-81ca-7bda38ff5696' || model.profile.id === 'b975c9f9-eeab-4baa-a72e-551121e6b816' ">
                            <input id="modalPCouncilCode" name="modalPCouncilCode" class="form-control" type="text"
                                   [(ngModel)]="model.professionalCouncilCode" required/>
                            <label for="modalPCouncilCode">{{handlerProfileCouncilCode()}}</label>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label></label>
                            <ng-multiselect-dropdown
                                    name="unity"
                                    [placeholder]="'Unidade'"
                                    [data]="listUnity"
                                    [(ngModel)]="model.userUnities"
                                    [settings]="dropdownSettings">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="input-material">
                            <input id="modalInitials" name="modalInitials" class="form-control" type="text"
                                   [(ngModel)]="model.initials" required/>
                            <label for="modalInitials">Iniciais</label>
                        </div>
                    </div>
                    <div class="col-sm-12" *ngIf="isNullOrUndefined(model.id)">
                        <div class="input-material">
                            <input id="modalPassword" name="modalPassword" class="form-control" type="password"
                                   [(ngModel)]="model.password"
                                   required/>
                            <a href="javascript:void(0)" class="btn p-0 icon"
                               (click)="handlerViewPassword('modalPassword')">
                                <svg-icon
                                        [src]="!handlerViewPasswordIcon('modalPassword') ? 'assets/svg/password-open.svg' : 'assets/svg/password-closed.svg'"></svg-icon>
                            </a>
                            <label for="modalPassword">Senha</label>
                        </div>
                    </div>
                    <div class="col-sm-12" *ngIf="isNullOrUndefined(model.id)">
                        <div class="input-material">
                            <input id="modalConfirmPassword" name="modalConfirmPassword" class="form-control"
                                   type="password"
                                   [(ngModel)]="confirmPassword" required/>
                            <a href="javascript:void(0)" class="btn p-0 icon"
                               (click)="handlerViewPassword('modalConfirmPassword')">
                                <svg-icon
                                        [src]="!handlerViewPasswordIcon('modalConfirmPassword') ? 'assets/svg/password-open.svg' : 'assets/svg/password-closed.svg'"></svg-icon>
                            </a>
                            <label for="modalConfirmPassword">Confirmar Senha</label>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-block btn-primary" (click)="actionSave()">Salvar</button>
            </div>
        </div>
    </div>
</div>
