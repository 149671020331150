<div class="mb-4 text-right">
    <a href="javascript:void(0)" class="btn btn-success btn-ico ml-2 btn-sm" *ngIf="!isCaregiver"
       (click)="handlerButton()">
        <svg-icon class="svg-white" src="assets/svg/plus-circle.svg"></svg-icon>
        Cadastrar Coleta
    </a>
</div>

<div class="row mx-0">
    <div class="table-responsive">
        <table class="table">
            <thead>
            <tr>
                <th class="align-middle text-center" scope="col">Data da Ocorrência</th>
                <th class="align-middle text-center" scope="col">P.A</th>
                <th class="align-middle text-center" scope="col">Pulso</th>
                <th class="align-middle text-center" scope="col">Temperatura</th>
                <th class="align-middle text-center" scope="col">Saturação</th>
                <th class="align-middle text-center" scope="col">Horario de aferição </th>
                <th class="align-middle text-center" scope="col">Data de Registro</th>
                <th class="align-middle text-center" scope="col">Opções</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="isNullOrUndefined(modelVitalSigns)">
                <td colspan="7">
                    <p class="pt-5 pb-5 text-center">
                        Nenhum registro encontrado.
                    </p>
                </td>
            </tr>
            <tr *ngFor="let r of modelVitalSigns" [ngClass]="{'bluer' : !this.isNullOrUndefined(r.updatedAt)}">
                <td class="text-center">{{r.evaluationTime | date : 'dd/MM/yyyy'}}</td>
                <td class="text-center">{{r.bloodPressure | mask : '000/00'}}</td>
                <td class="text-center">{{r.pulse}} bpm</td>
                <td class="text-center">{{r.temperature | mask : '00,0'}}ºC</td>
                <td class="text-center">{{r.respiration}}%</td>
                <td class="text-center">{{r.evaluationTime | date:'hh:mm'}}</td>
                <td class="text-center">{{r.createdAt | date:'dd/MM/yyyy'}}</td>
                <td class="text-center">
                    <a href="javascript:void('detail')" class="btn"
                       (click)="handlerDetail(r)">
                        <svg-icon class="ico-edit" src="assets/svg/eye.svg"></svg-icon>
                    </a>

                    <a href="javascript:void('delete')" *ngIf="!isCaregiver" class="btn"
                       (click)="delete(r.id)">
                        <svg-icon class="ico-close" src="assets/svg/close.svg"></svg-icon>
                    </a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="modal fade" id="registerVital" tabindex="-1" role="dialog" aria-labelledby="registerVital"
     aria-hidden="true">
    <div class="modal-dialog modal-75 modal-dialog-centered" id="vitalDetailDisabled" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    <span>Coleta</span>
                </h5>
                <button type="button" class="close close-primary pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">x</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row mx-0 align-items-center">
                    <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="col-3 px-2 text-center text-primary">
                                P.A:
                            </div>
                            <div class="col-6 px-0">
                                <div class="input-material bg-gray mb-0">
                                    <input id="bloodPressureVital" class="bg-gray form-control p-0" type="text"
                                           [disabled]="isCaregiver"
                                           maxlength="10000" autocomplete="off" name="bloodPressureVital" required
                                           [(ngModel)]="model.bloodPressure" mask="000/000"/>
                                    <label for="bloodPressureVital"></label>
                                </div>
                            </div>
                            <div class="col-3 px-2 text-center text-primary">
                                mmhg
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="col-3 px-2 text-center text-primary">
                                Pulso:
                            </div>
                            <div class="col-6 px-0">
                                <div class="input-material bg-gray mb-0">
                                    <input id="pulseVital" class="bg-gray form-control p-0" type="text"
                                           [disabled]="isCaregiver"
                                           maxlength="10000" autocomplete="off" name="pulseVital" required
                                           [(ngModel)]="model.pulse"/>
                                    <label for="pulseVital"></label>
                                </div>
                            </div>
                            <div class="col-3 px-2 text-center text-primary">
                                bpm
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="col-3 px-2 text-center text-primary">
                                Temperatura:
                            </div>
                            <div class="col-6 px-0">
                                <div class="input-material bg-gray mb-0">
                                    <input id="temperatureVital" class="bg-gray form-control p-0" type="text"
                                           [disabled]="isCaregiver"
                                           maxlength="10000" autocomplete="off" name="temperatureVital" required
                                           [(ngModel)]="model.temperature" mask="00,0"/>
                                    <label for="temperatureVital"></label>
                                </div>
                            </div>
                            <div class="col-3 px-2 text-center text-primary">
                                ºC
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 my-3 col-md-6 col-lg-6">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="col-3 px-2 text-center text-primary">
                                Saturação:
                            </div>
                            <div class="col-6 px-0">
                                <div class="input-material bg-gray mb-0">
                                    <input id="respirationVital" class="bg-gray form-control p-0" type="text"
                                           [disabled]="isCaregiver"
                                           maxlength="10000" autocomplete="off" name="respirationVital" required
                                           [(ngModel)]="model.respiration"/>
                                    <label for="respirationVital"></label>
                                </div>
                            </div>
                            <div class="col-3 px-2 text-center text-primary">
                                mrpm
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 my-3 col-md-6 col-lg-4">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="input-material bg-gray mb-0 mx-3">
                                <select [(ngModel)]="model.finger" id="finger" name="finger" class="bg-gray"
                                        [disabled]="isCaregiver" required>
                                    <option *ngFor="let f of [1,2,3,4,5,6,7,8,9,10]; let i = index"
                                            [ngValue]="(i + 1)">{{'Dedo - ' + (i + 1)}}</option>
                                </select>
                                <label for="finger"
                                       [ngClass]="{'disabled': !isNullOrUndefined(model.id)}">Dedo</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 my-3 col-md-6 col-lg-4">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="bg-gray input-material mb-0 mx-3">
                                <input id="evaluationDate" class="form-control bg-gray js-daterangepickerEvaluation"
                                       type="text" maxlength="10000" autocomplete="off" required/>
                                <label for="evaluationDate">Data da Ocorrência</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 my-3 col-md-6 col-lg-4">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="input-material bg-gray mb-0 mx-3">
                                <input id="bloodGlucose" class="bg-gray form-control" type="number"
                                       [disabled]="isCaregiver"
                                       maxlength="10000" autocomplete="off" name="bloodGlucose" required
                                       [(ngModel)]="model.bloodGlucose"/>
                                <label for="bloodGlucose" [ngClass]="{'disabled': !isNullOrUndefined(model.id)}">
                                    Glicose no Sangue</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="bg-gray rounded p-3 row mx-0 align-items-center">
                            <div class="input-material bg-gray">
                                <textarea id="observationV" class="form-control bg-gray" type="text" maxlength="10000"
                                          autocomplete="off"
                                          required [(ngModel)]="model.observation">
                                </textarea>
                                <label for="observationV">Observação</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-8 mx-auto mt-4 px-0">
                    <a href="javascript:void(0)" class="btn btn-success btn-ico btn-block" *ngIf="!isCaregiver"
                       (click)="actionSave()">
                        <svg-icon class="svg-white" src="assets/svg/check-circle.svg"></svg-icon>
                        Salvar
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
