import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../base/base.component';
import {Router} from '@angular/router';
import {ResidentService} from '../../../../service/resident/resident.service';
import {Resident} from '../../../../model/resident/resident';
import {StateService} from '../../../../service/state.service';
import {CityService} from '../../../../service/city.service';
import {State} from '../../../../model/state';
import {City} from '../../../../model/city';
import {Unity} from '../../../../model/unity/unity';
import {UnityService} from '../../../../service/unity.service';
import {ResidentPsychology} from '../../../../model/resident/resident-psychology';
import {User} from '../../../../model/user/user';
import {UserService} from '../../../../service/user.service';

declare var moment: any;
declare var $: any;

@Component({
    selector: 'app-resident',
    templateUrl: './resident.component.html',
    styleUrls: ['./resident.component.scss']
})
export class ResidentComponent extends BaseComponent implements OnInit, AfterViewInit {

    residentId: string;

    @Input() set resident(val: any) {
        this.residentId = val;
    }

    thumpPath: string | ArrayBuffer;
    selectedType: string;
    file: File = null;
    dateOfBirth: string;
    model: Resident = new Resident();
    listStateBorn: State[] = [];
    listCityBorn: City[] = [];
    listState: State[] = [];
    listCity: City[] = [];
    favorite = {one: '', two: '', three: ''};
    listUnity: Unity[] = [];
    modelUser: User = new User();
    isCaregiverOrTech = false;

    constructor(public router: Router,
                public residentService: ResidentService,
                public stateService: StateService,
                public unityService: UnityService,
                public userService: UserService,
                public cityService: CityService) {
        super(router);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.modelUser = data as User;
                    // ID = ADMIN + NURSER + UNIT DIRECTOR
                    if (this.modelUser.profile?.id !== '87115c40-8ac3-45c0-a70b-4f8b7ebba7bb' && this.modelUser.profile?.id !== 'a5cf1872-07ed-413e-aa7c-fc9c6c05aa15' && this.modelUser.profile?.id !== 'cf17d929-9315-4fdf-90ad-4decd3045b64') {
                        $('#profileDisabled input').prop('disabled', true);
                        $('#profileDisabled select').prop('disabled', true);
                        $('#profileDisabled label').addClass('disabled');
                        this.isCaregiverOrTech = true;
                    }
                }
            }
        });
        this.actionUnity();
        this.actionStateBorn();
        this.actionState();
        if (!this.isNullOrUndefined(this.residentId)) {
            this.actionResident();
        } else {
            this.setDatePickerModal();
        }
    }

    ngAfterViewInit(): void {
    }

    actionUnity() {
        this.unityService.get().subscribe({
            next: data => {
                this.listUnity = data as Unity[];
            }, error: err => super.onError(err)
        });
    }

    actionResident() {
        this.residentService.getId(this.residentId).subscribe({
            next: data => {
                this.model = data as Resident;
                if (!this.isNullOrUndefined(this.model.picture)) {
                    this.thumpPath = this.model.picture;
                }
                this.dateOfBirth = moment(this.model.birthday).format('DD/MM/yyyy');
                this.setDatePickerModal();

                if (!this.isNullOrUndefined(this.model.admissionDate)) {
                    $('.js-admissionDate').val(moment(this.model.admissionDate).format('DD/MM/YYYY'));
                }
                if (!this.isNullOrUndefined(this.model.exitDate)) {
                    $('.js-exitDate').val(moment(this.model.exitDate).format('DD/MM/YYYY'));
                }
                if (!this.isNullOrUndefined(this.model.city.state.id)) {
                    this.actionCity();
                }
                if (!this.isNullOrUndefined(this.model.bornCity.state.id)) {
                    this.actionCityBorn();
                }

                if (!this.isNullOrUndefined(this.model.favoriteFood)) {
                    this.model.favoriteFood.split('|').forEach((x, index) => {
                        if (index === 0) {
                            this.favorite.one = x;
                        } else if (index === 1) {
                            this.favorite.two = x;
                        } else if (index === 2) {
                            this.favorite.three = x;
                        }
                    });
                }
            }, error: err => super.onError(err)
        });
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.name, text: 'Nome, '},
            {value: this.dateOfBirth, text: `Data de Nascimento, <br>`},
            {value: this.model.unity.id, text: 'Unidade, '},
            {value: this.model.status, text: 'Status, <br>'},
            {value: this.model.bornCity.state.id, text: 'Estado Onde Nasceu, '},
            {value: this.model.bornCity.id, text: `Cidade Onde Nasceu, <br>`},
            {value: this.model.city.state.id, text: 'Estado, '},
            {value: this.model.city.id, text: `Cidade.`},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        if (this.isNullOrUndefined($('.js-admissionDate').val())) {
            this.model.admissionDate = undefined;
        }
        if (this.isNullOrUndefined($('.js-exitDate').val())) {
            this.model.exitDate = undefined;
        }

        if (!super.dateOfBirthDateValidate(this.dateOfBirth)) {
            return;
        }

        this.model.birthday = moment(this.dateOfBirth, 'DD-MM-YYYY').utcOffset(0, true).format();

        if (!this.isNullOrUndefined(this.favorite)) {
            this.model.favoriteFood = this.favorite.one + '|' + this.favorite.two + '|' + this.favorite.three;
        }

        if (this.isNullOrUndefined(this.model.id)) {
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    actionCreate() {
        this.residentService.create(this.model).subscribe({
            next: (data: Resident) => {
                if (this.isNullOrUndefined(this.file)) {
                    this.model = new Resident();
                    super.showMessage('Sucesso', 'Residente cadastrado com sucesso!', 'success');
                    this.router.navigate(['/resident-detail/' + data.id]);
                } else {
                    this.actionUpload(data.id, 'create');
                }
            }, error: err => super.onError(err)
        });
    }

    actionUpdate() {
        this.residentService.update(this.model).subscribe({
            next: (data: Resident) => {
                this.model = data as Resident;
                if (this.isNullOrUndefined(this.file)) {
                    this.model = data as Resident;
                    super.showMessage('Sucesso', 'Residente atualizado com sucesso!', 'success');
                } else {
                    this.actionUpload(data.id, 'update');
                }
            }, error: err => super.onError(err)
        });
    }

    actionUpload(id, type) {
        this.residentService.upload(this.file, id).subscribe({
            next: (data: Resident) => {
                if (type === 'create') {
                    this.model = new Resident();
                    super.showMessage('Sucesso', 'Residente cadastrado com sucesso!', 'success');
                    this.router.navigate(['/resident-detail/' + id]);
                } else {
                    super.showMessage('Sucesso', 'Residente atualizado com sucesso!', 'success');
                    this.actionResident();
                }
            }, error: err => super.onError(err)
        });
    }

    setDatePickerModal() {
        const startAdmission = this.isNullOrUndefined(this.model.admissionDate) ? moment() : moment(this.model.admissionDate);
        $('.js-admissionDate').daterangepicker({
            singleDatePicker: true,
            autoUpdateInput: false,
            showDropdowns: true,
            startDate: startAdmission,
            locale: super.objLocaleDateRangePicker()
        }, (start) => {
            this.model.admissionDate = start.utcOffset(0, true).format();
            $('.js-admissionDate').val(moment(this.model.admissionDate).add(1, 'day').format('DD/MM/YYYY'));
        });
        const startExit = this.isNullOrUndefined(this.model.exitDate) ? moment() : moment(this.model.exitDate);
        $('.js-exitDate').daterangepicker({
            singleDatePicker: true,
            autoUpdateInput: false,
            showDropdowns: true,
            startDate: startExit,
            locale: super.objLocaleDateRangePicker()
        }, (start) => {
            this.model.exitDate = start.utcOffset(0, true).format();
            $('.js-exitDate').val(moment(this.model.exitDate).add(1, 'day').format('DD/MM/YYYY'));
        });
    }

    // DADOS BASICOS INITIAL

    actionStateBorn() {
        this.stateService.get().subscribe({
            next: data => {
                this.listStateBorn = data as State[];
            }, error: err => super.onError(err)
        });
    }

    actionCityBorn() {
        this.cityService.get(this.model.bornCity.state.id).subscribe({
            next: data => {
                this.listCityBorn = data as City[];
            }, error: err => super.onError(err)
        });
    }

    actionState() {
        this.stateService.get().subscribe({
            next: data => {
                this.listState = data as State[];
            }, error: err => super.onError(err)
        });
    }

    actionCity() {
        this.cityService.get(this.model.city.state.id).subscribe({
            next: data => {
                this.listCity = data as City[];
            }, error: err => super.onError(err)
        });
    }

    // Gender
    handlerGender(value) {
        this.model.gender = value;
    }

    handlerGenderCheck(value) {
        return value === this.model.gender;
    }

    // MaritalStatus
    handlerMaritalStatus(value) {
        this.model.maritalStatus = value;
    }

    handlerMaritalStatusCheck(value) {
        if (value === 'others' && this.model.maritalStatus !== 'married'
            && this.model.maritalStatus !== 'single' && this.model.maritalStatus !== 'widow') {
            return this.model.maritalStatus;
        } else if (value === 'married' || value === 'single' || value === 'widow') {
            return value === this.model.maritalStatus;
        }
    }

    // SkinTone
    handlerSkinTone(value) {
        this.model.skinTone = value;
    }

    handlerSkinToneCheck(value) {
        return value === this.model.skinTone;
    }

    // Zone
    handlerZone(value) {
        this.model.zone = value;
    }

    handlerZoneCheck(value) {
        return value === this.model.zone;
    }

    // AgitatedPlace
    handlerAgitatedPlace(value) {
        this.model.agitatedPlace = value;
    }

    handlerAgitatedPlaceCheck(value) {
        return value === this.model.agitatedPlace;
    }

    handlerSelectedStateBorn() {
        this.actionCityBorn();
    }

    handlerSelectedCityBorn(city: City) {
        this.model.bornCity = this.listCityBorn.filter(x => x.id === city.id)[0];
    }

    handlerSelectedState() {
        this.actionCity();
    }

    handlerSelectedCity(city: City) {
        this.model.city = this.listCity.filter(x => x.id === city.id)[0];
    }

    // GRAU DE ESCOLARIDADE INITIAL

    // EducationalLevel
    handlerEducationalLevel(value) {
        this.model.educationDegree = value;
    }

    handlerEducationalLevelCheck(value) {
        if (value === 'others' && this.model.educationDegree !== '1c'
            && this.model.educationDegree !== '2c' && this.model.educationDegree !== '1i' && this.model.educationDegree !== '2i'
            && this.model.educationDegree !== 'an' && this.model.educationDegree !== 'sc' && this.model.educationDegree !== 'si') {
            return this.model.educationDegree;
        } else if (value === '1c' || value === '2c' || value === '1i' || value === '2i' || value === 'an' || value === 'sc' || value === 'si') {
            return value === this.model.educationDegree;
        }
    }


    // SOCIAL HISTORY INITIAL

    // TYPE OF RESIDENCE
    handlerTypeOfResidence(value) {
        this.model.typeOfResidence = value;
    }

    handlerTypeOfResidenceCheck(value) {
        return value === this.model.typeOfResidence;
    }

    // LIVE ALONE
    handlerLiveAlone(value) {
        this.model.liveAlone = value;
    }

    handlerLiveAloneCheck(value) {
        return value === this.model.liveAlone;
    }

    // LIVES WITH RELATIVES
    handlerLivesRelatives(value) {
        this.model.livesWithRelatives = value;
    }

    handlerLivesRelativesCheck(value) {
        return value === this.model.livesWithRelatives;
    }

    // LIVES WITH CAREGIVERS
    handlerLivesCaregivers(value) {
        this.model.livesWithCaregiver = value;
    }

    handlerLivesCaregiversCheck(value) {
        return value === this.model.livesWithCaregiver;
    }

    // PETS
    handlerPets(value) {
        this.model.pets = value;
    }

    handlerPetsCheck(value) {
        return value === this.model.pets;
    }

    handlerUpload(event) {
        const files = event.target.files;
        if (files.length > 0) {
            if (files[0].size > 20000000) {
                super.showMessage('Atenção', 'O arquivo não deve ultrapassar o tamanho de 20MB', 'info');
                return;
            }

            if (files[0].type.indexOf('jpeg') === -1 && files[0].type.indexOf('png') === -1) {
                super.showMessage('Atenção', 'Só será permitido arquivos com extensões .jpeg ou .png', 'info');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (file) => {
            this.thumpPath = file.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);

        this.selectedType = files[0].type.split('/')[1];
        this.file = files[0];
    }

    handlerSelectedUnity(unity: Unity) {
        this.model.unity = this.listUnity.filter(x => x.id === unity.id)[0];
    }

    handlerJudicial(val) {
        console.log(val);
        if (val !== 'yes') {
            this.model.judicialInterdiction = 'yes';
        } else {
            this.model.judicialInterdiction = 'no';
        }
    }
}
