import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../base/base.component';
import {Router} from '@angular/router';
import {UserService} from '../../../../service/user.service';
import {Resident} from '../../../../model/resident/resident';
import {SpeechTherapy} from '../../../../model/speech-therapy/speechTherapy';
import {SpeechTherapyService} from '../../../../service/speechTherapy.service';
import {Dentist} from '../../../../model/dentist';
import {ResidentService} from '../../../../service/resident/resident.service';
import {User} from '../../../../model/user/user';
import {ListFois} from '../../../../model/speech-therapy/listFois';
import {CreateSpeech} from '../../../../model/speech-therapy/createSpeech';

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-speech-therapy',
    templateUrl: './speech-therapy.component.html',
    styleUrls: ['./speech-therapy.component.scss']
})
export class SpeechTherapyComponent extends BaseComponent implements OnInit {

    @Input() set resident(val: any) {
        this.residentId = val;
    }

    constructor(public router: Router,
                public speechTherapyService: SpeechTherapyService,
                public residentService: ResidentService,
                public userService: UserService,
    ) {
        super(router);
    }

    modelCreate: CreateSpeech = new CreateSpeech();
    model: SpeechTherapy = new SpeechTherapy();
    modelSpeechs: SpeechTherapy[] = [];
    modelFois: ListFois[] = [];
    residentId: string;
    modelUser: User = new User();
    riskLevel: string;
    notAllowed = false;
    modelResident: Resident = new Resident();

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.modelUser = data as User;
                    // ID = fono
                    if (this.modelUser.profile?.id !== 'b975c9f9-eeab-4baa-a72e-551121e6b816') {
                        $('#speechDisabled input').prop('disabled', true);
                        $('#speechDisabled select').prop('disabled', true);
                        $('#speechDisabled textarea').prop('disabled', true);
                        $('#speechDisabled label').addClass('disabled');
                        this.notAllowed = true;
                    }
                }
            }
        });
        if (!this.isNullOrUndefined(this.residentId)) {
            this.actionSpeech();
            this.actionFois();
        }
    }

    setDatePickerModal() {
        $('.js-daterangepickerEvaluation').daterangepicker({
            showDropdowns: false,
            singleDatePicker: true,
            autoUpdateInput: false,
            locale: super.objLocaleDateRangePicker()
        }, (start, end, label) => {
            this.model.evaluationTime = start.utcOffset(0, true).format();
            $('.js-daterangepickerEvaluation').val(moment(this.model.evaluationTime).add(1, 'day').format('DD/MM/YYYY'));
        });
    }

    handlerButton() {
        this.model = new SpeechTherapy();
        this.modelFois = [];
        this.actionFois();
        $('#registerSpeech').modal('show');
        this.model.evaluationTime = moment().utcOffset(0, true).format();
        $('.js-daterangepickerEvaluation').val(moment(this.model.evaluationTime).format('DD/MM/YYYY'));
        this.setDatePickerModal();
    }

    actionSave() {
        console.log(this.riskLevel);
        this.model.residentId = this.residentId;
        const arrValidateFields = [
            {value: this.model.anamnesis, text: 'Anamnese, '},
            {value: this.model.evaluation, text: 'Avaliação, '},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }
        this.modelCreate.residentId = this.residentId;
        this.modelCreate.anamnesis = this.model.evaluation;
        this.modelCreate.evaluation = this.model.evaluation;
        this.modelCreate.evaluationTime = this.model.evaluationTime;
        this.modelCreate.riskLevel = this.riskLevel;
        // this.changeResidentRisk();
        if (this.isNullOrUndefined(this.model.id)) {
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    actionSpeech() {
        this.speechTherapyService.list(this.residentId).subscribe({
            next: data => {
                this.modelSpeechs = data as SpeechTherapy[];
            }, error: err => super.onError(err)
        });
    }

    actionFois() {
        this.speechTherapyService.listFois().subscribe({
            next: data => {
                this.modelFois = data as ListFois[];
            }, error: err => super.onError(err)
        });
    }

    actionCreate() {
        this.speechTherapyService.create(this.modelCreate).subscribe({
            next: (data: CreateSpeech) => {
                this.modelCreate = new CreateSpeech();
                $('#registerSpeech').modal('hide');
                super.showMessage('Sucesso', 'Avaliação Fonoaudiólógica cadastrada com sucesso!', 'success');
                this.actionSpeech();
            }, error: err => super.onError(err)
        });
    }

    actionUpdate() {
        this.speechTherapyService.update(this.residentId, this.model).subscribe({
            next: (data: SpeechTherapy) => {
                this.model = new SpeechTherapy();
                $('#registerSpeech').modal('hide');
                super.showMessage('Sucesso', 'Avaliação Fonoaudiólógica atualizada com sucesso!', 'success');
                this.actionSpeech();
            }, error: err => super.onError(err)
        });
    }

    handlerDetail(model) {
        this.model = Object.assign({}, model);
        $('#registerSpeech').modal('show');
    }

    delete(id) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar esta Avaliação?', () => {
            this.speechTherapyService.delete(this.residentId, id).subscribe({
                next: data => {
                    super.showMessage('Sucesso', 'Avaliação Fonoaudiólógica deletada com sucesso!', 'success');
                    this.actionSpeech();
                }, error: err => super.onError(err)
            });
        });
    }

    // handlerRiskTraduction(val) {
    //     switch (val) {
    //         case 'high' : {
    //             return 'Alto';
    //         }
    //         case 'medium' : {
    //             return 'Médio';
    //         }
    //         case 'low' : {
    //             return 'Baixo';
    //         }
    //         default: {
    //             return '-';
    //         }
    //     }
    // }

    changeResidentRisk() {
        this.residentService.updateRisk(this.residentId, this.modelResident.riskLevel).subscribe({
            next: data => {
                console.log(data);
            }, error: err => super.onError(err)
        });
    }
}
