import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../../base/base.component';
import {Resident} from '../../../../../model/resident/resident';
import {Unity} from '../../../../../model/unity/unity';
import {Router} from '@angular/router';
import {ResidentService} from '../../../../../service/resident/resident.service';
import {UnityService} from '../../../../../service/unity.service';
import {UserService} from '../../../../../service/user.service';
import {User} from '../../../../../model/user/user';

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-resident-nutrition',
    templateUrl: './resident-nutrition.component.html',
    styleUrls: ['./resident-nutrition.component.scss']
})
export class ResidentNutritionComponent extends BaseComponent implements OnInit, AfterViewInit {

    residentId: string;

    @Input() set resident(val: any) {
        this.residentId = val;
    }

    model: Resident = new Resident();
    favorite = {one: '', two: '', three: ''};
    listUnity: Unity[] = [];
    modelUser: User = new User();
    isCaregiver = false;

    constructor(public router: Router,
                public residentService: ResidentService,
                public userService: UserService,
                public unityService: UnityService) {
        super(router);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.modelUser = data as User;
                    // ID = ADMIN + NUTRITIONIST
                    if (this.modelUser.profile?.id !== '87115c40-8ac3-45c0-a70b-4f8b7ebba7bb' && this.modelUser.profile?.id !== 'be55443d-79f0-4e97-83e1-9f2e00415d0b' && this.modelUser.profile?.id !== 'a5cf1872-07ed-413e-aa7c-fc9c6c05aa15' && this.modelUser.profile?.id !== 'cf17d929-9315-4fdf-90ad-4decd3045b64') {
                        $('#nutritionDisabled input').prop('disabled', true);
                        $('#nutritionDisabled select').prop('disabled', true);
                        $('#nutritionDisabled label').addClass('disabled');
                        this.isCaregiver = true;
                    }
                }
            }
        });
        this.actionUnity();
        if (!this.isNullOrUndefined(this.residentId)) {
            this.actionResident();
        }
    }

    ngAfterViewInit(): void {
    }

    actionUnity() {
        this.unityService.get().subscribe({
            next: data => {
                this.listUnity = data as Unity[];
            }, error: err => super.onError(err)
        });
    }

    actionResident() {
        this.residentService.getId(this.residentId).subscribe({
            next: data => {
                this.model = data as Resident;

                if (!this.isNullOrUndefined(this.model.favoriteFood)) {
                    this.model.favoriteFood.split('|').forEach((x, index) => {
                        if (index === 0) {
                            this.favorite.one = x;
                        } else if (index === 1) {
                            this.favorite.two = x;
                        } else if (index === 2) {
                            this.favorite.three = x;
                        }
                    });
                }
            }, error: err => super.onError(err)
        });
    }

    actionSave() {
        /*const arrValidateFields = [
            {value: this.model.name, text: 'Nome, '},
            {value: this.model.unity.id, text: 'Unidade, '},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }*/

        if (!this.isNullOrUndefined(this.favorite)) {
            this.model.favoriteFood = this.favorite.one + '|' + this.favorite.two + '|' + this.favorite.three;
        }

        if (this.isNullOrUndefined(this.model.id)) {
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    actionCreate() {
        this.residentService.create(this.model).subscribe({
            next: (data: Resident) => {
                this.model = new Resident();
                super.showMessage('Sucesso', 'Residente cadastrado com sucesso!', 'success');
                this.router.navigate(['/resident-detail/' + data.id]);
            }, error: err => super.onError(err)
        });
    }

    actionUpdate() {
        this.residentService.update(this.model).subscribe({
            next: (data: Resident) => {
                this.model = data as Resident;
                super.showMessage('Sucesso', 'Residente atualizado com sucesso!', 'success');
            }, error: err => super.onError(err)
        });
    }

    handlerRestaurantType(val) {
        if (this.isNullOrUndefined(this.model.restaurantType)) {
            this.model.restaurantType = val;
            return;
        }
        const filter = this.model.restaurantType.split('|').filter(x => {
            if (val !== 'others') {
                return x === val;
            } else {
                if (x !== 'kilo' && x !== 'selfService' && x !== 'marmitex' && x !== 'home' && x !== 'fast') {
                    return x;
                }
            }
        });
        if (this.isNullOrUndefined(filter) && !this.isNullOrUndefined(val)) {
            this.model.restaurantType = this.model.restaurantType + '|' + val;
        } else {
            const restaurantType = this.model.restaurantType;
            this.model.restaurantType = null;
            restaurantType.split('|').forEach(x => {
                if (!this.isNullOrUndefined(val)) {
                    if (x !== val) {
                        this.model.restaurantType = !this.isNullOrUndefined(this.model.restaurantType) ?
                            this.model.restaurantType + '|' + x : x;
                    }
                } else {
                    if (x === 'kilo' || x === 'selfService' || x === 'marmitex' || x === 'home' || x === 'fast') {
                        this.model.restaurantType = !this.isNullOrUndefined(this.model.restaurantType) ?
                            this.model.restaurantType + '|' + x : x;
                    }
                }
            });
        }
    }

    handlerRestaurantTypeCheck(val) {
        if (!this.isNullOrUndefined(this.model.restaurantType)) {
            if (val !== 'others') {
                return !this.isNullOrUndefined(this.model.restaurantType.split('|').filter(x => x === val));
            } else {
                const filter = this.model.restaurantType.split('|').filter(x => {
                    if (x !== 'kilo' && x !== 'selfService' && x !== 'marmitex' && x !== 'home' && x !== 'fast') {
                        return x;
                    }
                });
                return filter[0];
            }
        }
    }

    handlerHabitualDrink(val) {
        if (this.isNullOrUndefined(this.model.habitualDrink)) {
            this.model.habitualDrink = val;
            return;
        }
        const filter = this.model.habitualDrink.split('|').filter(x => x === val);
        if (this.isNullOrUndefined(filter)) {
            this.model.habitualDrink = this.model.habitualDrink + '|' + val;
        } else {
            const habitualDrink = this.model.habitualDrink;
            this.model.habitualDrink = null;
            habitualDrink.split('|').forEach(x => {
                if (x !== val) {
                    this.model.habitualDrink = !this.isNullOrUndefined(this.model.habitualDrink) ?
                        this.model.habitualDrink + '|' + x : x;
                }
            });
        }
    }

    handlerHabitualDrinkCheck(val) {
        if (!this.isNullOrUndefined(this.model.habitualDrink)) {
            return !this.isNullOrUndefined(this.model.habitualDrink.split('|').filter(x => x === val)[0]);
        }
    }

    handlerSugarCheck(val) {
        if (!this.isNullOrUndefined(this.model.sugarType)) {
            return !this.isNullOrUndefined(this.model.sugarType.split('|').filter(x => x === val)[0]);
        }
    }

    handlerSugar(val) {
        if (this.isNullOrUndefined(this.model.sugarType)) {
            this.model.sugarType = val;
            return;
        }
        const filter = this.model.sugarType.split('|').filter(x => x === val);
        if (this.isNullOrUndefined(filter)) {
            this.model.sugarType = this.model.sugarType + '|' + val;
        } else {
            const sugarType = this.model.sugarType;
            this.model.sugarType = null;
            sugarType.split('|').forEach(x => {
                if (x !== val) {
                    this.model.sugarType = !this.isNullOrUndefined(this.model.sugarType) ?
                        this.model.sugarType + '|' + x : x;
                }
            });
        }
    }
    handlerMeatMethodCheck(val) {
        if (!this.isNullOrUndefined(this.model.meatMethod)) {
            return !this.isNullOrUndefined(this.model.meatMethod.split('|').filter(x => x === val)[0]);
        }
    }

    handlerMeatMethod(val) {
        if (this.isNullOrUndefined(this.model.meatMethod)) {
            this.model.meatMethod = val;
            return;
        }
        const filter = this.model.meatMethod.split('|').filter(x => x === val);
        if (this.isNullOrUndefined(filter)) {
            this.model.meatMethod = this.model.meatMethod + '|' + val;
        } else {
            const meatMethod = this.model.meatMethod;
            this.model.meatMethod = null;
            meatMethod.split('|').forEach(x => {
                if (x !== val) {
                    this.model.meatMethod = !this.isNullOrUndefined(this.model.meatMethod) ?
                        this.model.meatMethod + '|' + x : x;
                }
            });
        }
    }
}
