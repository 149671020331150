export class ResidentPsychologyEvaluation {
    id: string;
    residentId: string;
    activity: string;
    initiative: string;
    participant: string;
    modality: string;
    description: string;
    participation: string;
    observation: string;
    evaluationTime: Date;
    createdAt: Date;
    updatedAt: Date;
    createdByNavigation: CreatedByNavigation;

    constructor() {
    }
}

export class CreatedByNavigation {
    id: string;
    name: string;
    email: string;
    photo: string;
    professionalCouncilCode: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    profile: Profile;
    userUnities: UserUnity[];
}

export class Profile {
    id: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;
}

export class UserUnity {
    id: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
}

