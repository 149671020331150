import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {User} from '../model/user/user';

@Injectable({
    providedIn: 'root'
})
export class StateService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    get() {
        const url = `${this.BASE_URL}/state`;
        return this.http.get(url);
    }

    create(model) {
        const url = `${this.BASE_URL}/state`;
        return this.http.post(url, model);
    }

    update(model) {
        const url = `${this.BASE_URL}/state`;
        return this.http.put(url, model);
    }

    delete(id) {
        const url = `${this.BASE_URL}/state/${id}`;
        return this.http.delete(url);
    }

}
