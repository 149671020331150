<div class="mb-4 text-right" *ngIf="!isCaregiver">
    <a href="javascript:void(0)" class="btn btn-success btn-ico ml-2 btn-sm" (click)="handlerRegister()">
        <svg-icon class="svg-white" src="assets/svg/plus-circle.svg"></svg-icon>
        Cadastrar Evolução
    </a>
</div>

<div class="row mx-0">
    <div class="table-responsive">
        <table class="table ">
            <thead>
            <tr>
                <th class="align-middle text-center" scope="col">Data da Ocorrência</th>
                <th class="align-middle text-center" scope="col">Pressão Sanguínea</th>
                <th class="align-middle text-center" scope="col">Glicose</th>
                <th class="align-middle text-center" scope="col">Temperatura</th>
                <th class="align-middle text-left" scope="col">Resumo</th>
                <th class="align-middle text-left" scope="col">Responsável</th>
                <th class="align-middle text-left" scope="col">Código de Conselho</th>
                <th class="align-middle text-left" scope="col">Data de Registro</th>
                <th class="align-middle text-center" scope="col">Opções</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="isNullOrUndefined(modelList)">
                <td colspan="8">
                    <p class="pt-5 pb-5 text-center">
                        Nenhum registro encontrado.
                    </p>
                </td>
            </tr>
            <tr *ngFor="let r of modelList" [ngClass]="{'bluer' : !this.isNullOrUndefined(r.updatedAt)}" >
                <td class="text-center">{{r.evaluationTime | date : 'dd/MM/yyyy'}}</td>
                <td class="text-center">{{r.bloodPressure | mask : '000/000'}}</td>
                <td class="text-center">{{r.bloodGlucose}}</td>
                <td class="text-center">{{r.temperature}}ºC</td>
                <td class="text-left">{{r.resume}}</td>
                <td class="text-left">{{r.createdByNavigation.name}}</td>
                <td class="text-left">{{r.createdByNavigation.professionalCouncilCode}}</td>
                <td class="text-left">{{r.createdAt | date: 'dd/MM/yyyy'}}</td>
<!--                <td class="text-left">{{!this.isNullOrUndefined(r.updatedAt) ? 'Sim' : 'Não'}}</td>-->
                <td class="text-center">
                    <a href="javascript:void('detail')" class="btn"
                       (click)="handlerDetail(r)">
                        <svg-icon class="ico-edit" src="assets/svg/eye.svg"></svg-icon>
                    </a>
<!--                    <a href="javascript:void('Delete')" class="btn mx-1"-->
<!--                       (click)="delete(r.id)" *ngIf="!isCaregiver">-->
<!--                        <svg-icon class="ico-close" src="assets/svg/close.svg"></svg-icon>-->
<!--                    </a>-->
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>


<div class="modal fade" id="registerNursingEvolution" tabindex="-1" role="dialog"
     aria-labelledby="registerNursingEvolution"
     aria-hidden="true">
    <div class="modal-dialog modal-75 modal-dialog-centered"  id="nursingDetailDisabled" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    <span>Evolução</span>
                </h5>
                <button type="button" class="close close-primary pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">x</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row mx-0 align-items-center">
                    <div class="col-sm-12 my-3 col-lg-4">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="col text-primary">
                                Glicose
                            </div>
                            <div class="col-12">
                                <div class="input-material bg-gray mb-0">
                                    <input id="glucose" class="bg-gray form-control p-0" type="number"
                                           [disabled]="isCaregiver"
                                           maxlength="10000" autocomplete="off" name="glucose" required
                                           [(ngModel)]="model.bloodGlucose"/>
                                    <label for="glucose"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 my-3 col-lg-4">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="col-5 text-primary">
                                Temperatura
                            </div>
                            <div class="col-5">
                                <div class="input-material bg-gray mb-0">
                                    <input id="temperatureNursing" class="bg-gray form-control p-0" type="number"
                                           [disabled]="isCaregiver"
                                           maxlength="10000" autocomplete="off" name="temperatureNursing" required
                                           [(ngModel)]="model.temperature"/>
                                    <label for="temperatureNursing"></label>
                                </div>
                            </div>
                            <div class="col-2 text-primary">
                                ºC
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 my-3 col-lg-4">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="col-5 text-primary">
                                Pressão Sanguínea
                            </div>
                            <div class="col-4 px-0">
                                <div class="input-material bg-gray mb-0">
                                    <input id="bloodPressureNursing" class="bg-gray form-control p-0" type="text"
                                           [disabled]="isCaregiver"
                                           maxlength="10000" autocomplete="off" name="bloodPressureNursing" required
                                           [(ngModel)]="model.bloodPressure" mask="000/000"/>
                                    <label for="bloodPressureNursing"></label>
                                </div>
                            </div>
                            <div class="col-3 text-center text-primary">
                                mmhg
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 my-3 col-lg-12">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="col-12 text-primary">
                                Resumo
                            </div>
                            <div class="col-12">
                                <div class="input-material bg-gray mb-0">
                                    <textarea id="resume" class="bg-gray form-control" type="text"
                                           [disabled]="isCaregiver"
                                           maxlength="10000" autocomplete="off" name="resume" required
                                           [(ngModel)]="model.resume">
                                    </textarea>
                                    <label for="resume"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="bg-gray rounded p-3 row mx-0 align-items-center">
                            <div class="input-material bg-gray">
                                <textarea id="observationN" class="form-control bg-gray" type="text" maxlength="10000"
                                       [disabled]="isCaregiver"
                                       autocomplete="off"
                                       required [(ngModel)]="model.observation">
                                </textarea>
                                <label for="observationN">Observação</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 my-3 col-md-6 col-lg-12">
                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                        <div class="bg-gray input-material mb-0 mx-3">
                            <input id="evaluationDate" class="form-control bg-gray js-daterangepickerEvaluation"
                                   [disabled]="isCaregiver"
                                   type="text" maxlength="10000" autocomplete="off" required/>
                            <label for="evaluationDate">Data da Ocorrência</label>
                        </div>
                    </div>
                </div>
                <div class="col-8 mx-auto mt-4 px-0" *ngIf="!isCaregiver">
                    <a href="javascript:void(0)" class="btn btn-success btn-ico btn-block" (click)="actionSave()">
                        <svg-icon class="svg-white" src="assets/svg/check-circle.svg"></svg-icon>
                        Salvar
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
