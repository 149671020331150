<div class="single__page">
  <h1 class="mb-5">
    <img src="assets/images/logo.png" class="logo" alt="Liquigás" />
  </h1>
  <p>
    Você não está logado.
  </p>
  <p>
    <a class="btn btn-secondary" href="javascript:void('Login');" [routerLink]="['/authenticate']">Clique aqui para <strong>ENTRAR!</strong></a>
  </p>
</div>
