export class ResidentSkinIntegrity {
    id: string;
    place: string;
    presentedInjuryType: string;
    presentedInjuryTypeOthers: string;
    presentedInjuryTime: string;
    previousTreatment: string;
    previousTreatmentType: string;
    antiseptic: string;
    pomade: string;
    alternative: string;
    others: string;

    constructor() {
    }
}
