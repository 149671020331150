import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {User} from '../model/user/user';

@Injectable({
    providedIn: 'root'
})
export class UnityService extends ServiceBase {

    private userDataSource = new BehaviorSubject<User>(new User());
    user = this.userDataSource.asObservable();

    constructor(http: HttpClient) {
        super(http);
    }

    get() {
        const url = `${this.BASE_URL}/unity`;
        return this.http.get(url);
    }

    search(filter, page) {
        const url = `${this.BASE_URL}/unity/search/${page}`;
        return this.http.post(url, filter);
    }

    create(model) {
        const url = `${this.BASE_URL}/unity`;
        return this.http.post(url, model);
    }

    update(model) {
        const url = `${this.BASE_URL}/unity`;
        return this.http.put(url, model);
    }

    delete(id) {
        const url = `${this.BASE_URL}/unity/${id}`;
        return this.http.delete(url);
    }

}
