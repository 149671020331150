<div class="mb-4 text-right" *ngIf="!isCaregiver">
    <a href="javascript:void(0)" class="btn btn-success btn-ico ml-2 btn-sm" (click)="handlerButton()">
        <svg-icon class="svg-white" src="assets/svg/plus-circle.svg"></svg-icon>
        Cadastrar Medicamento
    </a>
</div>

<div class="row mx-0">
    <div class="table-responsive">
        <table class="table ">
            <thead>
            <tr>
                <th class="align-middle" scope="col">Medicação</th>
                <th class="align-middle" scope="col">Posologia</th>
                <th class="align-middle text-center" scope="col">Período</th>
                <th class="align-middle text-center" scope="col">Momento</th>
                <th class="align-middle text-center" scope="col">Horário</th>
                <th class="align-middle text-center" scope="col">Macerar</th>
                <th class="align-middle text-center" scope="col">Local</th>
                <th class="align-middle text-center" scope="col">Via ADM</th>
                <th class="align-middle text-center" scope="col">Responsável</th>
                <th class="align-middle text-center" scope="col">Código de Conselho</th>
                <th class="align-middle text-center" scope="col">Data de Registro</th>
                <th class="align-middle text-center" scope="col">Início/Fim Tratamento</th>
                <th class="align-middle text-center" scope="col">Opções</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="isNullOrUndefined(modelMedicine)">
                <td colspan="11">
                    <p class="pt-5 pb-5 text-center">
                        Nenhum registro encontrado.
                    </p>
                </td>
            </tr>
            <tr *ngFor="let r of modelMedicine"
                [ngClass]="{'bluer' : r.period === 'Manhã' || r.period === 'Tarde' ,'red' : r.period === 'Noite'}">
                <td>{{r.name}}</td>
                <td>{{r.amount}}</td>
                <td class="text-center">{{r.period}}</td>
                <td class="text-center">{{timeofTheDayTraduction(r.medicineTime)}}</td>
                <td class="text-center">{{r.timeOfTheDay | mask: '00:00'}}</td>
                <td class="text-center">{{r.macerate ? 'Sim' : 'Não'}}</td>
                <td class="text-center">{{r.place}}</td>
                <td class="text-center">{{r.administration}}</td>
                <td class="text-center">{{r.createdBy?.name}}</td>
                <td class="text-center">{{r.createdBy?.professionalCouncilCode}}</td>
                <td class="text-center">{{r.createdAt | date: 'dd/MM/yyyy'}}</td>
                <td class="text-center">{{r.treatmentStart | date: 'dd/MM/yyyy'}}
                    {{r.treatmentStart != null ? "-" : ""}} {{r.treatmentFinish | date: 'dd/MM/yyyy'}}</td>
                <td class="text-center">
                    <a href="javascript:void('detail')" class="btn mx-1"
                       (click)="handlerDetail(r)">
                        <svg-icon class="ico-edit" src="assets/svg/eye.svg"></svg-icon>
                    </a>
                    <!--                    <a href="javascript:void('delete')" class="btn mx-1"-->
                    <!--                       (click)="delete(r.id)" *ngIf="!isCaregiver">-->
                    <!--                        <svg-icon class="ico-close" src="assets/svg/close.svg"></svg-icon>-->
                    <!--                    </a>-->
                    <a href="javascript:void('duplicate')" class="btn mx-1"
                       (click)="handlerDuplicate(r)" *ngIf="!isCaregiver">
                        <svg-icon class="ico-edit" src="assets/svg/duplicate.svg"></svg-icon>
                    </a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="modal fade" id="registerMedicine" tabindex="-1" role="dialog" aria-labelledby="registerMedicine"
     aria-hidden="true">
    <div class="modal-dialog modal-75 modal-dialog-centered" id="medicineDetailDisabled" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    <span>Medicamento</span>
                </h5>
                <button type="button" class="close close-primary pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">x</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row mx-0 align-items-center">
                    <div class="col-sm-12 col-md-12 col-lg-6 d-flex">
                        <div class="input-material">
                            <input id="medication" class="form-control" type="text" maxlength="10000" autocomplete="off"
                                   required [(ngModel)]="model.name"/>
                            <label for="medication">Medicamento</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 d-flex">
                        <div class="input-material">
                            <input id="posologia" class="form-control" type="text" maxlength="10000" autocomplete="off"
                                   required [(ngModel)]="model.amount"/>
                            <label for="posologia">Posologia</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 mt-2">
                        <div class="input-material">
                            <select id="period" class="form-control" name="state"
                                    [(ngModel)]="model.period"
                                    (ngModelChange)="model.period">
                                <option [ngValue]="undefined">Selecione</option>
                                <option *ngFor="let s of periodResidentTeste" [ngValue]="s.id">{{s.name}}</option>
                            </select>
                            <label for="period">Periodo</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-3">
                        <div class="input-material">
                            <select id="timeOftheDay" class="form-control" name="state"
                                    [(ngModel)]="model.medicineTime"
                                    (ngModelChange)="model.medicineTime">
                                <option [ngValue]="undefined">Selecione</option>
                                <option *ngFor="let s of listTimeofTheDay" [ngValue]="s.id">{{s.name}}</option>
                            </select>
                            <label for="timeOftheDay">Momento do dia</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-3">
                        <div class="input-material">
                            <input id="horary" class="form-control" type="text" maxlength="10000" autocomplete="off"
                                   required [(ngModel)]="model.timeOfTheDay" mask="00:00"/>
                            <label for="horary">Horário</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-3">
                        <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                            <div class="col-12">
                                <p class="text-gray-primary ">Macerar</p>
                            </div>
                            <div class="col-9 d-flex align-items-center">
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="macerate" name="macerate"
                                           class="custom-control-input"
                                           [checked]="model.macerate === true"
                                           (change)="model.macerate = true">
                                    <label class="custom-control-label" for="macerate">Sim</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="noMacerate" name="noMacerate"
                                           class="custom-control-input"
                                           [checked]="model.macerate === false"
                                           (change)="model.macerate = false">
                                    <label class="custom-control-label" for="noMacerate">Não</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 col-lg-3">
                        <div class="input-material">
                            <input id="start" name="date-start" class="form-control" type="text" maxlength="10000"
                                   autocomplete="off" required/>
                            <label for="start">Início</label>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 col-lg-3 mb-3">
                        <div class="input-material m-0">
                            <input id="date" class="form-control js-daterangepicker" type="text" maxlength="10000"
                                   autocomplete="off" required/>
                            <label for="date">Periodo</label>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 col-lg-3">
                        <div class="input-material">
                            <input id="place" class="form-control" type="text" maxlength="10000" autocomplete="off"
                                   required [(ngModel)]="model.place"/>
                            <label for="place">Local</label>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-3 col-lg-3">
                        <div class="input-material">
                            <input id="administration" class="form-control" type="text" maxlength="10000"
                                   autocomplete="off"
                                   required [(ngModel)]="model.administration"/>
                            <label for="administration">Via ADM</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="input-material">
                            <textarea id="observationM" class="form-control" type="text" maxlength="10000"
                                      autocomplete="off"
                                      required [(ngModel)]="model.observation">
                            </textarea>
                            <label for="observationM">Observação</label>
                        </div>
                    </div>
                </div>

                <div class="col-8 mx-auto mt-4 px-0" *ngIf="!isCaregiver">
                    <a href="javascript:void(0)" class="btn btn-success btn-ico btn-block" (click)="actionSave()">
                        <svg-icon class="svg-white" src="assets/svg/check-circle.svg"></svg-icon>
                        Salvar
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
