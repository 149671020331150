<div class="row justify-content-between">
    <div class="row col-6 m-2">
        <div class="col-sm-12 col-lg-3">
            <div class="input-material">
                <select id="year" name="year" [(ngModel)]="year" (ngModelChange)="actionMedicineList()">
                    <option value="2022">2022</option>
                </select>
                <label for="year">Ano</label>
            </div>
        </div>
        <div class="col-sm-12 col-lg-3">
            <div class="input-material">
                <select id="month" name="month" [(ngModel)]="month" (ngModelChange)="actionMedicineList()">
                    <option value="1">Janeiro</option>
                    <option value="2">Fevereiro</option>
                    <option value="3">Março</option>
                    <option value="4">Abril</option>
                    <option value="5">Maio</option>
                    <option value="6">Junho</option>
                    <option value="7">Julho</option>
                    <option value="8">Agosto</option>
                    <option value="9">Setembro</option>
                    <option value="10">Outubro</option>
                    <option value="11">Novembro</option>
                    <option value="12">Dezembro</option>
                </select>
                <label for="month">Mês</label>
            </div>
        </div>
    </div>
    <div class="m-4">
        <a href="javascript:void(0)" class="btn btn-info btn-ico ml-2 btn-sm" (click)="getExcel()">
            Exportar Agenda
        </a>
    </div>
</div>


<div class="row mx-0">
    <div class="table-responsive">
        <!--            <div class="inner">-->
        <table class="table">
            <thead>
            <tr>
                <th class="sticky-col first-col align-middle text-center" scope="col">Medicação</th>
                <th class="align-middle" scope="col">Dosagem</th>
                <th class="align-middle text-center" scope="col">Horário</th>
                <th class="align-middle text-center" scope="col">Local</th>
                <th class="align-middle text-center" scope="col">Via ADM</th>
                <th class="align-middle text-center" scope="col">Macerar</th>
                <th class="align-middle text-center" scope="col">1</th>
                <th class="align-middle text-center" scope="col">2</th>
                <th class="align-middle text-center" scope="col">3</th>
                <th class="align-middle text-center" scope="col">4</th>
                <th class="align-middle text-center" scope="col">5</th>
                <th class="align-middle text-center" scope="col">6</th>
                <th class="align-middle text-center" scope="col">7</th>
                <th class="align-middle text-center" scope="col">8</th>
                <th class="align-middle text-center" scope="col">9</th>
                <th class="align-middle text-center" scope="col">10</th>
                <th class="align-middle text-center" scope="col">11</th>
                <th class="align-middle text-center" scope="col">12</th>
                <th class="align-middle text-center" scope="col">13</th>
                <th class="align-middle text-center" scope="col">14</th>
                <th class="align-middle text-center" scope="col">15</th>
                <th class="align-middle text-center" scope="col">16</th>
                <th class="align-middle text-center" scope="col">17</th>
                <th class="align-middle text-center" scope="col">18</th>
                <th class="align-middle text-center" scope="col">19</th>
                <th class="align-middle text-center" scope="col">20</th>
                <th class="align-middle text-center" scope="col">21</th>
                <th class="align-middle text-center" scope="col">22</th>
                <th class="align-middle text-center" scope="col">23</th>
                <th class="align-middle text-center" scope="col">24</th>
                <th class="align-middle text-center" scope="col">25</th>
                <th class="align-middle text-center" scope="col">26</th>
                <th class="align-middle text-center" scope="col">27</th>
                <th class="align-middle text-center" scope="col">28</th>
                <th class="align-middle text-center" scope="col">29</th>
                <th class="align-middle text-center" scope="col">30</th>
                <th class="align-middle text-center" scope="col">31</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="isNullOrUndefined(modelList)">
                <td></td>
                <td colspan="37" class="text-center">
                    <p>Não há medicamentos</p>
                </td>
            </tr>
            <ng-container *ngFor="let item of modelList">
                <tr *ngFor="let r of item.items"
                    [ngClass]="{'blued' : r.period === 'Manhã' || r.period === 'Tarde' ,'reded' : r.period === 'Noite'}">
                    <td class="text-center sticky-col first-col"
                        [ngClass]="{'blued' : r.period === 'Diurno', 'reded' : r.period === 'Noturno'}">{{r.name}}</td>
                    <td class="text-center">{{r.amount}}</td>
                    <td class="text-center"
                        *ngIf="!isNullOrUndefined(r.timeOfTheDay)">{{r.timeOfTheDay | mask:'hh:mm'}}</td>
                    <td class="text-center"
                        *ngIf="isNullOrUndefined(r.timeOfTheDay)">{{timeofTheDayTraduction(r.medicineTime)}}</td>
                    <td class="text-center">{{r.place}}</td>
                    <td class="text-center">{{r.administration}}</td>
                    <td class="text-center">{{r.macerate ? 'Sim' : 'Não'}}</td>
                    <td *ngFor="let v of r.days">
                        <div class="text-center" *ngIf="v.status">
                            <img src="assets/images/check-box-checked.png" height="15" width="15" alt="checked">
                        </div>
                        <div class="text-center" *ngIf="!v.status">
                            <input class="custom-checkbox-medicine"
                                   type="image"
                                   src="assets/images/check-box-outline.png"
                                   height="15"
                                   width="15"
                                   [disabled]="v.status"
                                   [readOnly]="v.status"
                                   [ngModel]="v.status" id="index1"
                                   (click)="CreateMedicineTableItem(r.id,v,$event)"
                                   aria-label="...">
                        </div>
                        <div class="text-center">{{v.initials}}</div>
                    </td>
                </tr>
                <tr *ngIf="item.period === 'Tarde'">
                    <th colspan="38" rowspan="1" class="white">
                        <p></p>
                    </th>
                </tr>
            </ng-container>
            </tbody>
        </table>
        <div class="row ml-3">
            <div id="circulo-manha" class="ml-2"></div>
            <div class="ml-2 mr-2 mb-2 mt-1">Diurno</div>
            <div id="circulo-noite" class="ml-2"></div>
            <div class="ml-2 mr-2 mb-2 mt-1">Noturno</div>
        </div>
    </div>

</div>
