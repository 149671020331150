import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {LogoutComponent} from './views/logout/logout.component';
import {LoginComponent} from './views/login/login.component';
import {UserComponent} from './views/user/user.component';
import {HomeComponent} from './views/home/home.component';
import {ReportAccessComponent} from './views/report/report-access/report-access.component';
import {ReportUserComponent} from './views/report/report-user/report-user.component';
import {ResidentsComponent} from './views/residents/residents.component';
import {ActivitiesComponent} from './views/activities/activities.component';
import {UnitiesComponent} from './views/unities/unities.component';
import {LogsComponent} from './views/logs/logs.component';
import {ResidentsDetailComponent} from './views/residents/residents-detail/residents-detail.component';
import {RecoveryComponent} from './views/recovery/recovery.component';

const routes: Routes = [
    {path: 'login', component: LoginComponent},
    {path: 'logout', component: LogoutComponent},
    {path: 'recovery/:id', component: RecoveryComponent},
    {
        path: '', component: MainComponent,
        children: [
            // {path: '', component: HomeComponent},
            {path: '', component: ResidentsComponent},
            {path: 'usuarios', component: UserComponent},
            {path: 'residents', component: ResidentsComponent},
            {path: 'resident-detail/:id', component: ResidentsDetailComponent},
            {path: 'resident-register', component: ResidentsDetailComponent},
            {path: 'atividades', component: ActivitiesComponent},
            {path: 'unidades', component: UnitiesComponent},
            {path: 'logs', component: LogsComponent},
            {path: 'acessos', component: ReportAccessComponent},
            {path: 'usuarios', component: ReportUserComponent},
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollOffset: [0, 0], scrollPositionRestoration: 'top'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
