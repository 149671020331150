import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../base/base.component';
import {Router} from '@angular/router';
import {VitalService} from '../../../../service/resident/vital.service';
import {ResidentVital} from '../../../../model/resident/resident-vital';
import {User} from '../../../../model/user/user';
import {UserService} from '../../../../service/user.service';

declare var moment: any;
declare var $: any;

@Component({
    selector: 'app-vital',
    templateUrl: './vital.component.html',
    styleUrls: ['./vital.component.scss']
})
export class VitalComponent extends BaseComponent implements OnInit, OnDestroy {

    residentId: string;
    model: ResidentVital = new ResidentVital();
    modelUser: User = new User();
    modelVitalSigns: ResidentVital[] = [];
    isCaregiver = false;

    @Input() set resident(val: any) {
        this.residentId = val;
    }

    constructor(public router: Router,
                public vitalService: VitalService,
                public userService: UserService) {
        super(router);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.modelUser = data as User;
                    // ID = DOCTOR
                    if (this.modelUser.profile?.id !== 'f2c34c68-b1de-4b4d-81ca-7bda38ff5696') {
                        $('#vitalDetailDisabled input').prop('disabled', true);
                        $('#vitalDetailDisabled select').prop('disabled', true);
                        $('#vitalDetailDisabled textarea').prop('disabled', true);
                        $('#vitalDetailDisabled label').addClass('disabled');
                        this.isCaregiver = true;
                    }
                }
            }
        });
        if (!this.isNullOrUndefined(this.residentId)) {
            this.actionVital();
        }
    }

    ngOnDestroy(): void {
        const a = $('#registerVital');
        if (a[0]) {
            a.remove();
        }
    }

    actionVital() {
        this.vitalService.get(this.residentId).subscribe({
            next: data => {
                this.modelVitalSigns = data as ResidentVital[];
            }, error: err => super.onError(err)
        });
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.bloodPressure, text: 'P.A, '},
            {value: this.model.pulse, text: 'Pulso, <br>'},
            {value: this.model.temperature, text: 'Temperatura, '},
            {value: this.model.respiration, text: 'Respiração.'},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        if (this.isNullOrUndefined(this.model.id)) {
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    actionCreate() {
        this.vitalService.create(this.residentId, this.model).subscribe({
            next: (data: ResidentVital) => {
                this.model = new ResidentVital();
                $('#registerVital').modal('hide');
                super.showMessage('Sucesso', 'Sinal vital cadastrado com sucesso!', 'success');
                this.actionVital();
            }, error: err => super.onError(err)
        });
    }

    actionUpdate() {
        this.vitalService.update(this.residentId, this.model).subscribe({
            next: (data: ResidentVital) => {
                this.model = new ResidentVital();
                $('#registerVital').modal('hide');
                super.showMessage('Sucesso', 'Sinal vital atualizado com sucesso!', 'success');
                this.actionVital();
            }, error: err => super.onError(err)
        });
    }

    handlerDetail(model) {
        this.model = Object.assign({}, model);
        $('#registerVital').modal('show');
    }

    delete(id) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar este sinal vital?', () => {
            this.vitalService.delete(this.residentId, id).subscribe({
                next: data => {
                    super.showMessage('Sucesso', 'Sinal vital deletado com sucesso!', 'success');
                    this.actionVital();
                }, error: err => super.onError(err)
            });
        });
    }

    setDatePickerModal() {
        $('.js-daterangepickerEvaluation').daterangepicker({
            showDropdowns: false,
            singleDatePicker: true,
            autoUpdateInput: false,
            locale: super.objLocaleDateRangePicker()
        }, (start, end, label) => {
            this.model.evaluationTime = start.utcOffset(0, true).format();
            $('.js-daterangepickerEvaluation').val(moment(this.model.evaluationTime).add(1, 'day').format('DD/MM/YYYY'));
        });
    }

    handlerButton() {
        this.model = new ResidentVital();
        $('#registerVital').modal('show');
        this.model.evaluationTime = moment().utcOffset(0, true).format();
        $('.js-daterangepickerEvaluation').val(moment(this.model.evaluationTime).format('DD/MM/YYYY'));
        this.setDatePickerModal();
    }

}
