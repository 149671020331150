// @ts-ignore
import {Profile, UserUnity} from '../resident/resident-psychology-evaluation';

export class SpeechTherapy {
    id: string;
    residentId: string;
    anamnesis: string;
    evaluation: string;
    riskLevel: RiskLevel;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    evaluationTime: Date;
    createdBy: CreatedBy;
}

export class RiskLevel {
    id: string;
    fois: string;
    level: number;
    riskLevel: number;
}


export class CreatedBy {
    id: string;
    name: string;
    email: string;
    photo: string;
    professionalCouncilCode: string;
    initials: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    profile: Profile;
    userUnities: UserUnity[];
}
