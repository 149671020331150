<div class="card-body" id="nutritionDisabled">
    <form class="row">
        <div class="col-12 row">
            <div class="row mx-0 col-12 align-items-center">
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="input-material">
                        <input id="pesoCad" class="form-control" name="pesoCad" type="text" maxlength="10000"
                               autocomplete="off" [(ngModel)]="model.weight" required
                               (ngModelChange)="model.weight"/>
                        <label for="pesoCad">Peso (Kg)</label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="input-material">
                        <input id="alturaCad" name="alturaCad" class="form-control" type="text" maxlength="10000"
                               autocomplete="off" [(ngModel)]="model.height" required
                               (ngModelChange)="model.height"/>
                        <label for="alturaCad">Altura (M)</label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 mb-md-3 mb-lg-0 mb-sm-3 col-lg-4">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="aferidoCad" name="aferido" class="custom-control-input"
                               [checked]="model.collectionMethod === 'measured'"
                               (change)="model.collectionMethod = 'measured'">
                        <label class="custom-control-label" for="aferidoCad">Aferido</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="estimadoCad" name="estimadoCad" class="custom-control-input"
                               [checked]="model.collectionMethod === 'estimated'"
                               (change)="model.collectionMethod = 'estimated'">
                        <label class="custom-control-label" for="estimadoCad">Estimado</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-lg-12 mb-3">
            <div class="bg-gray rounded py-3 row mx-0 mb-0 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Quais os tipos de alimentos preferidos?</p>
                </div>
                <div class="col-4">
                    <div class="input-material bg-gray mb-0">
                        <input id="1fav" class="bg-gray form-control" type="text"
                               maxlength="10000" autocomplete="off" required
                               name="1fav" [(ngModel)]="favorite.one"/>
                        <label for="1fav">1 - </label>
                    </div>
                </div>
                <div class="col-4">
                    <div class="input-material bg-gray mb-0">
                        <input id="2fav" class="bg-gray form-control" type="text"
                               maxlength="10000" autocomplete="off" required
                               name="2fav" [(ngModel)]="favorite.two"/>
                        <label for="2fav">2 - </label>
                    </div>
                </div>
                <div class="col-4">
                    <div class="input-material bg-gray mb-0">
                        <input id="3fav" class="bg-gray form-control" type="text"
                               maxlength="10000" autocomplete="off" required
                               name="3fav" [(ngModel)]="favorite.three"/>
                        <label for="3fav">3 - </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary mb-0 text-align-center">Costuma beber: </p>
                </div>
                <div class="col-12 ml-3 row mx-0 px-1 align-items-center">
                    <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                        <input type="checkbox" id="water" name="water"
                               class="custom-control-input" [checked]="handlerHabitualDrinkCheck('water')"
                               (change)="handlerHabitualDrink('water')">
                        <label class="custom-control-label" for="water">Água</label>
                    </div>
                    <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                        <input type="checkbox" id="soda" name="soda"
                               class="custom-control-input" [checked]="handlerHabitualDrinkCheck('soda')"
                               (change)="handlerHabitualDrink('soda')">
                        <label class="custom-control-label" for="soda">Refrigerante</label>
                    </div>
                    <div class="custom-control custom-checkbox custom-control-inline mb-0">
                        <input type="checkbox" id="dietSoda" name="dietSoda"
                               class="custom-control-input" [checked]="handlerHabitualDrinkCheck('dietSoda')"
                               (change)="handlerHabitualDrink('dietSoda')">
                        <label class="custom-control-label" for="dietSoda">Refrigerante Diet</label>
                    </div>
                    <div class="custom-control custom-checkbox custom-control-inline mb-0">
                        <input type="checkbox" id="natJuice" name="natJuice"
                               class="custom-control-input" [checked]="handlerHabitualDrinkCheck('natJuice')"
                               (change)="handlerHabitualDrink('natJuice')">
                        <label class="custom-control-label" for="natJuice">Suco natural</label>
                    </div>
                    <div class="custom-control custom-checkbox custom-control-inline mb-0">
                        <input type="checkbox" id="artJuice" name="artJuice"
                               class="custom-control-input" [checked]="handlerHabitualDrinkCheck('artJuice')"
                               (change)="handlerHabitualDrink('artJuice')">
                        <label class="custom-control-label" for="artJuice">Suco artificial</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="bg-gray rounded p-3 row mx-0 mb-3 align-items-center">
                <div class="input-material bg-gray mb-0">
                    <input id="alcoholperDay" class="bg-gray form-control" type="text" maxlength="10000"
                           autocomplete="off" required name="alcoholperDay" [(ngModel)]="model.alcoholDay"/>
                    <label for="alcoholperDay">Quantidade por dia</label>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Uso de adoçante:</p>
                </div>
                <div class="col-12 mx-0 row align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="addSweatener" name="addSweatener" class="custom-control-input"
                               [checked]="model.sweetener === 'yes'" (change)="model.sweetener = 'yes'">
                        <label class="custom-control-label" for="addSweatener">Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="addSweetenerNo" name="addSweetenerNo"
                               class="custom-control-input"
                               [checked]="model.sweetener === 'no'" (change)="model.sweetener = 'no'">
                        <label class="custom-control-label" for="addSweetenerNo">Não</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Tipo de adoçante</p>
                </div>
                <div class="col-12 mx-0 row align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="natural" name="natural" class="custom-control-input"
                               [checked]="model.sweetenerType === 'natural'" (change)="model.sweetenerType = 'natural'">
                        <label class="custom-control-label" for="natural">natural</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="art" name="art" class="custom-control-input"
                               [checked]="model.sweetenerType === 'artificial'"
                               (change)="model.sweetenerType = 'artificial'">
                        <label class="custom-control-label" for="art">artificial</label>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Ao comer carne de boi ou porco tira a gordura?</p>
                </div>
                <div class="col-12 row mx-0 align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="removeFat" name="removeFat"
                               class="custom-control-input" [checked]="model.removeFat === 'yes'"
                               (change)="model.removeFat = 'yes'">
                        <label class="custom-control-label" for="removeFat">Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="notremoveFat" name="notremoveFat"
                               class="custom-control-input" [checked]="model.removeFat === 'no'"
                               (change)="model.removeFat = 'no'">
                        <label class="custom-control-label" for="notremoveFat">Não</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Ao comer carne de frango ou peixe tira a pele?</p>
                </div>
                <div class="col-12 row mx-0">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="removeSkin" name="removeSkin"
                               class="custom-control-input"
                               [checked]="model.removeSkin === 'yes'"
                               (change)="model.removeSkin = 'yes'">
                        <label class="custom-control-label" for="removeSkin">Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="notremoveSkin" name="notremoveSkin"
                               class="custom-control-input"
                               [checked]="model.removeSkin === 'no'"
                               (change)="model.removeSkin = 'no'">
                        <label class="custom-control-label" for="notremoveSkin">Não</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
            <div class="col-12">
                <p class="text-gray-primary">Acrescenta sal na comida depois de pronta?</p>
            </div>
            <div class="col-12 mx-0 row align-items-center">
                <div class="custom-control custom-radio custom-control-inline mb-0">
                    <input type="radio" id="addSalt" name="addSalt" class="custom-control-input"
                           [checked]="model.salt === 'yes'"
                           (change)="model.salt = 'yes'">
                    <label class="custom-control-label" for="addSalt">Sim</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline mb-0">
                    <input type="radio" id="addSaltNo" name="addSaltNo" class="custom-control-input"
                           [checked]="model.salt === 'no'"
                           (change)="model.salt = 'no'">
                    <label class="custom-control-label" for="addSaltNo">Não</label>
                </div>
            </div>
        </div>
    </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Uso de açucar:</p>
                </div>
                <div class="col-12 row mx-0 align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="addSugar" name="addSugar" class="custom-control-input"
                               [checked]="model.sugar === 'yes'"
                               (change)="model.sugar = 'yes'">
                        <label class="custom-control-label" for="addSugar">Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="addSugarNo" name="addSugarNo" class="custom-control-input"
                               [checked]="model.sugar === 'no'"
                               (change)="model.sugar = 'no'">
                        <label class="custom-control-label" for="addSugarNo">Não</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 mb-sm-3">
            <div class="bg-gray rounded py-3 row mx-0 pl-2 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Qual o tipo de açúcar? </p>
                </div>
                <div class="col-12 mx-0 row align-items-center">
                    <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                        <input type="checkbox" id="sugarRefined" name="sugarRefined"
                               class="custom-control-input"
                               [checked]="handlerSugarCheck('Refinado')"
                               (change)="handlerSugar('Refinado')">
                        <label class="custom-control-label" for="sugarRefined">Refinado</label>
                    </div>
                    <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                        <input type="checkbox" id="mascavo" name="mascavo" class="custom-control-input"
                               [checked]="handlerSugarCheck('Mascavo')"
                               (change)="handlerSugar('Mascavo')">
                        <label class="custom-control-label" for="mascavo">Mascavo</label>
                    </div>
                    <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                        <input type="checkbox" id="cristal" name="cristal" class="custom-control-input"
                               [checked]="handlerSugarCheck('Cristal')"
                               (change)="handlerSugar('Cristal')">
                        <label class="custom-control-label" for="cristal">Cristal</label>
                    </div>
                    <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                        <input type="checkbox" id="mel" name="mel" class="custom-control-input"
                               [checked]="handlerSugarCheck('Mel')"
                               (change)="handlerSugar('Mel')">
                        <label class="custom-control-label" for="mel">Mel</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="bg-gray rounded py-3 mt-sm-3 mt-md-0 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Acrescenta pimenta na comida depois de pronta?</p>
                </div>
                <div class="col-12 row mx-0 align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="pepperyes" name="pepperyes" class="custom-control-input"
                               [checked]="model.chili === 'yes'"
                               (change)="model.chili = 'yes'">
                        <label class="custom-control-label" for="pepperyes">Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="notPepper" name="notPepper" class="custom-control-input"
                               [checked]="model.chili === 'no'"
                               (change)="model.chili = 'no'">
                        <label class="custom-control-label" for="notPepper">Não</label>
                    </div>
                    <div class="input-material col-lg-12 text-left bg-gray mb-2">
                        <input id="chiliType" class="bg-gray form-control" type="text"
                               maxlength="10000" autocomplete="off" required
                               name="chiliType" [(ngModel)]="model.chiliType"/>
                        <label for="chiliType">Tipo de pimenta</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary mb-0">Bebida Alcoólica? </p>
                </div>
                <div class="col-9 d-flex align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="alcool" name="alcool" class="custom-control-input"
                               [checked]="model.alcohol === 'yes'" (change)="model.alcohol = 'yes'">
                        <label class="custom-control-label" for="alcool">Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="notAlcool" name="notAlcool" class="custom-control-input"
                               [checked]="model.alcohol === 'no'" (change)="model.alcohol = 'no'">
                        <label class="custom-control-label" for="notAlcool">Não</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4">
            <div class="bg-gray rounded p-3 row mx-0 mb-3 align-items-center">
                <div class="input-material bg-gray mb-0">
                    <input id="alcoholType" class="bg-gray form-control" type="text" maxlength="10000"
                           autocomplete="off" required name="alcoholType" [(ngModel)]="model.alcoholType"/>
                    <label for="alcoholType">Tipo de Álcool</label>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Fica chateado quando criticam sua forma de beber ?</p>
                </div>
                <div class="row">
                    <div class="col-12 ml-3 d-flex align-items-center">
                        <div class="custom-control custom-radio custom-control-inline mb-0">
                            <input type="radio" id="alcoholUpsetyes" name="alcoholUpsetyes"
                                   class="custom-control-input" [checked]="model.alcoholUpset === 'yes'"
                                   (change)="model.alcoholUpset = 'yes'">
                            <label class="custom-control-label" for="alcoholUpsetyes">Sim</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline mb-0">
                            <input type="radio" id="alcoholUpsetno" name="alcoholUpsetno"
                                   class="custom-control-input" [checked]="model.alcoholUpset === 'no'"
                                   (change)="model.alcoholUpset = 'no'">
                            <label class="custom-control-label" for="alcoholUpsetno">Não</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-12">
            <div class="bg-gray rounded p-3 row mx-0 mb-3 align-items-center">
                <div class="input-material bg-gray mb-0">
                    <input id="alcoholSince" class="form-control bg-gray" type="text" maxlength="10000"
                           autocomplete="off" [(ngModel)]="model.alcoholSince" required/>
                    <label for="alcoholSince">Bebe desde?</label>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="bg-gray rounded py-3 mb-3 ml-0 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Possui alguma aversão alimentar ?</p>
                </div>
                <div class="row">
                    <div class="col-12 ml-3 align-items-center">
                        <div class="custom-control custom-radio custom-control-inline mb-0">
                            <input type="radio" id="foodAversionYes" name="foodAversionYes"
                                   class="custom-control-input"
                                   [checked]="model.foodAversion === 'Sim'"
                                   (change)="model.foodAversion = 'Sim'">
                            <label class="custom-control-label" for="foodAversionYes">Sim</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline mb-0">
                            <input type="radio" id="foodAversionNo" name="foodAversionNo"
                                   class="custom-control-input"
                                   [checked]="model.foodAversion === 'Não'"
                                   (change)="model.foodAversion = 'Não'">
                            <label class="custom-control-label" for="foodAversionNo">Não</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-8 pb-md-4 col-lg-8">
            <div class="bg-gray rounded py-3 pb-4 row mx-0 mb-0 align-items-center">
                <div class="col-12">
                    <div class="input-material bg-gray mb-0">
                        <input id="aversionName" class="bg-gray form-control" type="text"
                               maxlength="10000" autocomplete="off" required
                               name="aversionName" [(ngModel)]="model.foodAversionType"/>
                        <label for="aversionName">Tipo:</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="bg-gray rounded py-3 mb-3 mt-sm-3 mt-md-0 ml-0 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Já fez ou faz uso de suplemento ?</p>
                </div>
                <div class="row">
                    <div class="col-12 ml-3 align-items-center">
                        <div class="custom-control custom-radio custom-control-inline mb-0">
                            <input type="radio" id="suplement" name="suplement" class="custom-control-input"
                                   [checked]="model.supplement === 'Faz'"
                                   (change)="model.supplement = 'Faz'">
                            <label class="custom-control-label" for="suplement">Faz</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline mb-0">
                            <input type="radio" id="nosuplement" name="nosuplement"
                                   class="custom-control-input"
                                   [checked]="model.supplement === 'Fez'"
                                   (change)="model.supplement = 'Fez'">
                            <label class="custom-control-label" for="nosuplement">Fez</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline mb-0">
                            <input type="radio" id="nosuplementno" name="nosuplementno"
                                   class="custom-control-input"
                                   [checked]="model.supplement === 'no'"
                                   (change)="model.supplement = 'no'">
                            <label class="custom-control-label" for="nosuplementno">Não</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-8 col-lg-8">
            <div class="bg-gray rounded py-3 pb-4 row mx-0 mb-0 align-items-center">
                <div class="col-12">
                    <div class="input-material bg-gray mb-0">
                        <input id="suplementName" class="bg-gray form-control" type="text"
                               maxlength="10000" autocomplete="off" required
                               name="suplementName"
                               [(ngModel)]="model.supplementName"/>
                        <label for="suplementName">Nome do Suplemento</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="bg-gray rounded py-4 mt-sm-3 mt-md-0 mb-3 ml-0 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Faz uso de Restaurante ?</p>
                </div>
                <div class="row">
                    <div class="col-12 ml-3 align-items-center">
                        <div class="custom-control custom-radio custom-control-inline mb-0">
                            <input type="radio" id="restaurant" name="restaurant"
                                   class="custom-control-input"
                                   [checked]="model.restaurant === 'Sim'"
                                   (change)="model.restaurant = 'Sim'">
                            <label class="custom-control-label" for="restaurant">Sim</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline mb-0">
                            <input type="radio" id="norestaurant" name="norestaurant"
                                   class="custom-control-input"
                                   [checked]="model.restaurant === 'Não'"
                                   (change)="model.restaurant = 'Não'">
                            <label class="custom-control-label" for="norestaurant">Não</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-8 col-lg-8 mb-4">
            <div class="bg-gray rounded p-3 row mx-0 mb-0 align-items-center">
                <div class="custom-control custom-checkbox custom-control-inline mb-0">
                    <input type="checkbox" id="kilo" name="kilo" class="custom-control-input"
                           [checked]="handlerRestaurantTypeCheck('kilo')"
                           (change)="handlerRestaurantType('kilo')">
                    <label class="custom-control-label" for="kilo">Por Kilo</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline mb-0">
                    <input type="checkbox" id="selfService" name="selfService" class="custom-control-input"
                           [checked]="handlerRestaurantTypeCheck('selfService')"
                           (change)="handlerRestaurantType('selfService')">
                    <label class="custom-control-label" for="selfService">Self Service</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline mb-0">
                    <input type="checkbox" id="marmitex" name="marmitex" class="custom-control-input"
                           [checked]="handlerRestaurantTypeCheck ('marmitex')"
                           (change)="handlerRestaurantType('marmitex')">
                    <label class="custom-control-label" for="marmitex">Marmitex</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline mb-0">
                    <input type="checkbox" id="home" name="home" class="custom-control-input"
                           [checked]="handlerRestaurantTypeCheck('home')"
                           (change)="handlerRestaurantType('home')">
                    <label class="custom-control-label" for="home">Traz de casa</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline mb-0">
                    <input type="checkbox" id="fast" name="fast" class="custom-control-input"
                           [checked]="handlerRestaurantTypeCheck ('fast')"
                           (change)="handlerRestaurantType('fast')">
                    <label class="custom-control-label" for="fast">Lanche Rápido</label>
                </div>
                <div class="input-material bg-gray mb-0">
                    <input id="othersRestaurant" class="bg-gray form-control" type="text"
                           maxlength="10000" autocomplete="off" required name="othersRestaurant"
                           [ngModel]="handlerRestaurantTypeCheck('others')"
                           (focusout)="handlerRestaurantType($event.target.value)"/>
                    <label for="othersRestaurant">Outros</label>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="bg-gray rounded py-3 mb-3 ml-0 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Anemia ?</p>
                </div>
                <div class="row">
                    <div class="col-12 ml-3 align-items-center">
                        <div class="custom-control custom-radio custom-control-inline mb-0">
                            <input type="radio" id="anemia" name="anemia" class="custom-control-input"
                                   [checked]="model.anemia === 'Sim'"
                                   (change)="model.anemia = 'Sim'">
                            <label class="custom-control-label" for="anemia">Sim</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline mb-0">
                            <input type="radio" id="noanemia" name="noanemia"
                                   class="custom-control-input"
                                   [checked]="model.anemia === 'Não'"
                                   (change)="model.anemia = 'Não'">
                            <label class="custom-control-label" for="noanemia">Não</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-8 col-lg-8">
            <div class="bg-gray rounded py-2 mb-sm-3 row mx-0 align-items-center">
                <div class="col-12">
                    <div class="input-material bg-gray mt-1">
                        <input id="anemiaSince" class="bg-gray form-control" type="text"
                               maxlength="10000" autocomplete="off" required
                               name="anemiaSince" [(ngModel)]="model.anemiaSince"/>
                        <label for="anemiaSince">Há quanto tempo ?</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-4 pb-md-2 pb-lg-0 col-lg-4">
            <div class="bg-gray rounded py-3 mb-3 ml-0 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Sofre de Dislipdemia (Colesterol Alto)?</p>
                </div>
                <div class="row">
                    <div class="col-12 ml-3 align-items-center">
                        <div class="custom-control custom-radio custom-control-inline mb-0">
                            <input type="radio" id="dislipidemia" name="dislipidemia"
                                   class="custom-control-input"
                                   [checked]="model.dyslipidemia === 'Sim'"
                                   (change)="model.dyslipidemia = 'Sim'">
                            <label class="custom-control-label" for="dislipidemia">Sim</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline mb-0">
                            <input type="radio" id="nodislipidemia" name="nodislipidemia"
                                   class="custom-control-input"
                                   [checked]="model.dyslipidemia === 'Não'"
                                   (change)="model.dyslipidemia = 'Não'">
                            <label class="custom-control-label" for="nodislipidemia">Não</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-8 col-lg-8">
            <div class="bg-gray rounded pb-md-4 pb-lg-1 py-2 row mx-0 align-items-center">
                <div class="col-12">
                    <div class="input-material bg-gray mt-1">
                        <input id="dislipidemiaSince" class="bg-gray form-control" type="text"
                               maxlength="10000" autocomplete="off" required
                               name="dislipidemiaSince" [(ngModel)]="model.dyslipidemiaSince"/>
                        <label for="dislipidemiaSince">Há quanto tempo?</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mt-sm-3 mt-md-0 mb-0 align-items-center">
                <div class="col-12">
                    <div class="input-material bg-gray mb-0">
                        <input id="mealsDay" class="bg-gray form-control" type="text"
                               maxlength="10000" autocomplete="off" required
                               name="mealsDay" [(ngModel)]="model.numberMealsADay"/>
                        <label for="mealsDay">Número total de refeições por dia:</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 sm-mt-3 col-lg-6">
            <div class="bg-gray rounded py-3 mt-md-3 mt-sm-3 mt-lg-0 row mx-0 mb-0 align-items-center">
                <div class="col-12">
                    <div class="input-material bg-gray mb-0">
                        <input id="meatWeekly" class="bg-gray form-control" type="text"
                               maxlength="10000" autocomplete="off" required
                               name="meatWeekly" [(ngModel)]="model.meatWeekly"/>
                        <label for="meatWeekly">Quantas vezes come carne por semana?</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 mt-3">
            <div class="bg-gray rounded py-3 row mx-0 mb-0 align-items-center">
                <div class="col-12">
                    <div class="input-material bg-gray mb-0">
                        <input id="outsideMeals" class="bg-gray form-control" type="text"
                               maxlength="10000" autocomplete="off" required
                               name="outsideMeals" [(ngModel)]="model.outsideHomeWeeklyMeals"/>
                        <label for="outsideMeals">Refeições semanais fora de casa:</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 mt-3">
            <div class="bg-gray rounded py-3 row mx-0 mb-0 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Costuma comer carne na forma mais ?</p>
                </div>
                <div class="col-2">
                    <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                        <input type="checkbox" id="roasted" name="roasted" class="custom-control-input"
                               [checked]="handlerMeatMethodCheck('roasted')"
                               (change)="handlerMeatMethod('roasted')">
                        <label class="custom-control-label" for="roasted">Assada</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                        <input type="checkbox" id="cooked" name="cooked" class="custom-control-input"
                               [checked]="handlerMeatMethodCheck('cooked')"
                               (change)="handlerMeatMethod('cooked')">
                        <label class="custom-control-label" for="cooked">Cozida</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                        <input type="checkbox" id="fried" name="fried" class="custom-control-input"
                               [checked]="handlerMeatMethodCheck('fried')"
                               (change)="handlerMeatMethod('fried')">
                        <label class="custom-control-label" for="fried">Frita</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                        <input type="checkbox" id="grilled" name="grilled" class="custom-control-input"
                               [checked]="handlerMeatMethodCheck('grilled')"
                               (change)="handlerMeatMethod('grilled')">
                        <label class="custom-control-label" for="grilled">Grelhada</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                        <input type="checkbox" id="raw" name="raw" class="custom-control-input"
                               [checked]="handlerMeatMethodCheck('raw')"
                               (change)="handlerMeatMethod('raw')">
                        <label class="custom-control-label" for="raw">Crua</label>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="col-xs-5 ml-auto mt-5" *ngIf="!isCaregiver">
        <a href="javascript:void(0)" class="btn btn-success btn-ico ml-2 btn-block" (click)="actionSave()">
            <svg-icon class="svg-white" src="assets/svg/check-circle.svg"></svg-icon>
            Salvar
        </a>
    </div>
</div>
