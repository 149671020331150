import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../base/base.component';
import {Router} from '@angular/router';
import {NursingService} from '../../../../service/resident/nursing.service';
import {ResidentHealths} from '../../../../model/resident/resident-healths';
import {SkinIntegrityService} from '../../../../service/resident/skinIntegrity.service';
import {ResidentAllergies} from '../../../../model/resident/resident-allergies';
import {AllergyService} from '../../../../service/resident/allergy.service';
import {ResidentSkinIntegrity} from '../../../../model/resident/resident-skin-integrity';
import {ResidentHealthEvolution} from '../../../../model/resident/resident-health-evolution';
import {ResidentPhysiotherapyEvaluation} from '../../../../model/resident/resident-physiotherapy-evaluation';
import {User} from '../../../../model/user/user';
import {UserService} from '../../../../service/user.service';

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-nursing',
    templateUrl: './nursing.component.html',
    styleUrls: ['./nursing.component.scss']
})
export class NursingComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    residentId: string;
    model: ResidentHealthEvolution = new ResidentHealthEvolution();
    modelList: ResidentHealthEvolution[] = [];
    modelUser: User = new User();
    isCaregiver = false;

    @Input() set resident(val: any) {
        this.residentId = val;
    }

    constructor(public router: Router,
                public userService: UserService,
                public nursingService: NursingService) {
        super(router);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.modelUser = data as User;
                    // ID = NURSE
                    if (this.modelUser.profile?.id !== 'a5cf1872-07ed-413e-aa7c-fc9c6c05aa15') {
                        $('#nursingDetailDisabled input').prop('disabled', true);
                        $('#nursingDetailDisabled select').prop('disabled', true);
                        $('#nursingDetailDisabled textarea').prop('disabled', true);
                        $('#nursingDetailDisabled label').addClass('disabled');
                        this.isCaregiver = true;
                    }
                }
            }
        });
        if (!this.isNullOrUndefined(this.residentId)) {
            this.actionDetail();
        }
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        const a = $('#registerNursingEvolution');
        if (a[0]) {
            a.remove();
        }
    }

    actionDetail() {
        this.nursingService.getEvaluation(this.residentId).subscribe({
            next: (data: ResidentHealthEvolution[]) => {
                this.modelList = data as ResidentHealthEvolution[];
            }, error: err => super.onError(err)
        });
    }

    actionSave() {
        this.model.residentId = this.residentId;
        const arrValidateFields = [
            {value: this.model.bloodGlucose, text: `Glicose, `},
            {value: this.model.temperature, text: `Temperatura, <br>`},
            {value: this.model.bloodPressure, text: `Pressão Sanguínea, `},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        if (this.isNullOrUndefined(this.model.id)) {
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    actionCreate() {
        this.nursingService.createEvaluation(this.residentId, this.model).subscribe({
            next: (data: ResidentHealthEvolution) => {
                this.model = new ResidentHealthEvolution();
                $('#registerNursingEvolution').modal('hide');
                super.showMessage('Sucesso', 'Evolução cadastrada com sucesso!', 'success');
                this.actionDetail();
            }, error: err => super.onError(err)
        });
    }

    actionUpdate() {
        this.nursingService.updateEvaluation(this.residentId, this.model).subscribe({
            next: (data: ResidentHealthEvolution) => {
                this.model = new ResidentHealthEvolution();
                $('#registerNursingEvolution').modal('hide');
                super.showMessage('Sucesso', 'Evolução atualizada com sucesso!', 'success');
                this.actionDetail();
            }, error: err => super.onError(err)
        });
    }

    handlerDetail(model) {
        this.model = Object.assign({}, model);
        $('#registerNursingEvolution').modal('show');
    }

    delete(id) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar este item?', () => {
            this.nursingService.deleteEvaluation(id).subscribe({
                next: data => {
                    this.actionDetail();
                    super.showMessage('Sucesso', 'Item deletado com sucesso!', 'success');
                }, error: err => super.onError(err)
            });
        });
    }

    setDatePickerModal() {
        $('.js-daterangepickerEvaluation').daterangepicker({
            showDropdowns: false,
            singleDatePicker: true,
            autoUpdateInput: false,
            locale: super.objLocaleDateRangePicker()
        }, (start, end, label) => {
            this.model.evaluationTime = start.utcOffset(0, true).format();
            $('.js-daterangepickerEvaluation').val(moment(this.model.evaluationTime).add(1, 'day').format('DD/MM/YYYY'));
        });
    }

    handlerRegister() {
        this.model = new ResidentHealthEvolution();
        $('#registerNursingEvolution').modal('show');
        this.model.evaluationTime = moment().utcOffset(0, true).format();
        $('.js-daterangepickerEvaluation').val(moment(this.model.evaluationTime).format('DD/MM/YYYY'));
        this.setDatePickerModal();
    }
}
