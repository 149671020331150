export class ResidentAllergies {
    id: string;
    drugAllergy: string;
    perfumeAllergy: string;
    foodAllergy: string;
    othersAllergy: string;

    constructor() {
    }
}
