<section class="container">

    <div class="row align-items-center justify-content-between mx-3 mt-4">
        <h1 class="title">Residentes</h1>
        <a href="javascript:void(0)" class="btn btn-success btn-ico ml-sm-2 btn-sm"
           [routerLink]="['/resident-register']" *ngIf="isAdmin">
            <svg-icon class="svg-white mr-2" src="assets/svg/plus-circle.svg"></svg-icon>
            Cadastrar Residente
        </a>
    </div>

    <div class="mt-3">

        <div class="d-sm-flex align-items-center justify-content-between mt-4 mx-4">
            <strong class="subTitle"></strong>
        </div>

        <div class="card mt-3 bg-white mx-2">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-4 col-lg-3">
                        <div class="input-material m-0">
                            <input id="date" class="form-control js-daterangepicker" type="text" maxlength="10000"
                                   autocomplete="off" required/>
                            <label for="date">Periodo</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-3">
                        <div class="form-group">
                            <label></label>
                            <ng-multiselect-dropdown
                                    [disabled]="caregiverOrUnit"
                                    [placeholder]="'Unidade'"
                                    [data]="listUnity"
                                    [(ngModel)]="modelFilter.unity"
                                    [settings]="dropdownSettings">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-3">
                        <div class="input-material m-0">
                            <input id="name" class="form-control" type="text" maxlength="10000" autocomplete="off"
                                   [(ngModel)]="modelFilter.keyword" required/>
                            <label for="name">Nome</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-3">
                        <div class="input-material">
                            <select id="period" class="form-control" name="period" [(ngModel)]="modelFilter.status">
                                <option [ngValue]="undefined">Selecione</option>
                                <option [ngValue]="'active'">Ativo</option>
                                <option [ngValue]="'inactive'">Inativo</option>
                                <option [ngValue]="'termination'">Rescisão</option>
                                <option [ngValue]="'death'">Falecido</option>
                            </select>
                            <label for="period">Status</label>
                        </div>
                    </div>

                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-info mx-2 mb-3 mb-xs-0"
                           (click)="getExcel()">
                            Exportar Excel
                        </a>
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0"
                           (click)="actionSearch()">
                            Buscar
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mt-3 mx-2 bg-white p-3">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                    <tr>
                        <th class="align-middle" scope="col"></th>
                        <th class="align-middle" scope="col">Nome</th>
                        <th class="align-middle text-center" scope="col">Data Nascimento</th>
                        <th class="align-middle" scope="col">Unidade</th>
                        <th class="align-middle" scope="col">Responsável</th>
                        <th class="align-middle text-center" scope="col">Contato</th>
                        <th class="align-middle text-center" scope="col">Data de Entrada</th>
                        <th class="align-middle text-center" scope="col">Opções</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="isNullOrUndefined(modelResult.results)">
                        <td colspan="8">
                            <p class="pt-5 pb-5 text-center">
                                Nenhum registro encontrado.
                            </p>
                        </td>
                    </tr>
                    <tr *ngFor="let r of modelResult.results |
                                paginate: {
                                 itemsPerPage: modelResult.pageSize,
                                 currentPage: modelResult.currentPage,
                                 totalItems: modelResult.totalCount}">
                        <td class="text-center">
                            <img [src]="isNullOrUndefined(r.picture) ? 'https://via.placeholder.com/120' : r.picture"
                                 class="photo-resident" width="70" height="70" alt="...">
                        </td>
                        <td>{{r.name}}</td>
                        <td class="text-center">{{r.birthday | date: 'dd/MM/yyyy'}}</td>
                        <td>{{r.unity.name}}</td>
                        <td class="text-center" *ngIf="isNullOrUndefined(r.residentResponsibles[0]?.name)" colspan="2">
                            Nenhum Responsável cadastrado!
                        </td>
                        <ng-container *ngIf="!isNullOrUndefined(r.residentResponsibles[0]?.name)">
                            <td>{{r.residentResponsibles[0]?.name}}</td>
                            <td class="text-center">{{r.residentResponsibles[0]?.phoneNumber | mask: '(00) 0 0000-0000||(00) 0000-0000'}}</td>
                        </ng-container>
                        <td class="text-center">{{r.admissionDate | date: 'dd/MM/yyyy'}}</td>
                        <td class="text-center">
                            <a href="javascript:void('modal')" (click)="handlerAdmin(r)" class="btn p-1"
                               *ngIf="isAdmin">
                                <svg-icon src="assets/svg/dollar-sign.svg"></svg-icon>
                            </a>
                            <a href="javascript:void('detail')" class="btn p-1"
                               [routerLink]="'/resident-detail/' + r.id">
                                <svg-icon class="ico-edit" src="assets/svg/edit.svg"></svg-icon>
                            </a>

                            <a href="javascript:void('delete')" class="btn p-1" (click)="delete(r.id)"
                               *ngIf="isAdmin">
                                <svg-icon class="ico-close" src="assets/svg/close.svg"></svg-icon>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-center">
                <pagination-controls
                        [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"
                        [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                        [screenReaderPageLabel]="labels.screenReaderPageLabel"
                        [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                        (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
        </div>

    </div>

    <div class="modal fade" id="userModal" tabindex="-1" role="dialog" aria-labelledby="userModal" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content p-0">
                <div class="modal-header">
                    <h5 class="modal-title text-primary">
                        <span>Dados Administrativos</span>
                    </h5>
                    <button type="button" class="close close-primary pt-0" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">x</span>
                    </button>
                </div>
                <div class="modal-body">
                    <ul class="nav nav-pills justify-content-center mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item col-6 px-0 text-center">
                            <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home"
                               role="tab" aria-controls="pills-home" aria-selected="true">Residente</a>
                        </li>
                        <li class="nav-item col-6 px-0 text-center">
                            <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile"
                               role="tab" aria-controls="pills-profile" aria-selected="false">Responsável</a>
                        </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                             aria-labelledby="pills-home-tab">
                            <form class="row mx-0 align-items-center" autocomplete="off">
                                <div class="col-sm-4 mb-3 text-center">
                                    <img [src]="isNullOrUndefined(model.picture) ? 'https://via.placeholder.com/120' : model.picture"
                                         class="rounded-circle object-fit-cover" width="120" height="120" alt="...">
                                </div>
                                <div class="col-sm-8 mb-3">
                                    <div class="input-material">
                                        <input id="modalName" name="modalName" class="form-control" type="text"
                                               [(ngModel)]="model.name" required/>
                                        <label for="modalName">Nome</label>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="input-material">
                                        <select id="unity" name="unity" [(ngModel)]="model.unity.id">
                                            <option [ngValue]="undefined">Selecione</option>
                                            <option *ngFor="let u of listUnity" [ngValue]="u.id">{{u.name}}</option>
                                        </select>
                                        <label for="unity">Unidade</label>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="input-material">
                                        <select id="status" name="status" [(ngModel)]="model.status">
                                            <option [ngValue]="undefined">Selecione</option>
                                            <option *ngFor="let s of listStatusResident"
                                                    [ngValue]="s.id">{{s.name}}</option>
                                        </select>
                                        <label for="status">Status</label>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="input-material">
                                        <input id="modalBirthday" name="modalBirthday" class="form-control" type="text"
                                               mask="00/00/0000" [dropSpecialCharacters]="false"
                                               [disabled]="true"
                                               [(ngModel)]="model.birthday" required/>
                                        <label class="disabled" for="modalBirthday">Data de Nascimento</label>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="input-material">
                                        <input id="ageAdmin" name="ageAdmin" class="form-control" type="text"
                                               [ngModel]="handlerAge(model.birthday)" disabled required/>
                                        <label class="disabled" for="ageAdmin">Idade</label>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="input-material">
                                        <input id="cpfAdmin" name="cpfAdmin" class="form-control"
                                               type="text" mask="000.000.000-00" [dropSpecialCharacters]="false"
                                               [(ngModel)]="model.cpf" required/>
                                        <label for="cpfAdmin">CPF</label>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="input-material">
                                        <input id="rgAdmin" name="rgAdmin" class="form-control"
                                               type="text" mask="00.000.000-0" [dropSpecialCharacters]="false"
                                               [(ngModel)]="model.rg" required/>
                                        <label for="rgAdmin">RG</label>
                                    </div>
                                </div>
                                <div class="col-12 my-3 bg-gray rounded p-3">
                                    <h5 class="text-primary mb-3">Datas</h5>
                                    <div class="row  ">
                                        <div class="col-sm-6">
                                            <div class="input-material bg-gray">
                                                <input id="internationDate" name="internationDate" type="text"
                                                       class="form-control bg-gray js-admissionDate"
                                                       mask="00/00/0000" [dropSpecialCharacters]="false" required/>
                                                <label for="internationDate">Internação</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="input-material bg-gray">
                                                <input id="outDate" type="text" name="outDate"
                                                       class="form-control bg-gray js-exitDate"
                                                       mask="00/00/0000" [dropSpecialCharacters]="false" required/>
                                                <label for="outDate">Saída</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row col-lg-8">
                                    <div class="col-sm-4 px-2">
                                        <div class="input-material">
                                            <input id="roomVacancy" name="roomVacancy" class="form-control"
                                                   type="text" [(ngModel)]="model.room" required/>
                                            <label for="roomVacancy">Quarto</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 px-2">
                                        <div class="input-material">
                                            <input id="responsable" name="responsable" class="form-control"
                                                   type="text" [(ngModel)]="model.bed" required/>
                                            <label for="responsable">Cama</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 px-2">
                                        <div class="input-material">
                                            <input id="monthlyPayment" name="monthlyPayment" class="form-control"
                                                   type="text" [(ngModel)]="model.monthlyPayment" currencyMask
                                                   [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left'}"
                                                   required/>
                                            <label for="monthlyPayment">Mensalidade</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="customSwitch1"
                                               [checked]="model.judicialInterdiction === 'yes'"
                                               (change)="handlerJudicial(model.judicialInterdiction)">
                                        <label class="custom-control-label font-weight-bold" for="customSwitch1">Interdição
                                            Judicial</label>
                                    </div>
                                </div>
                            </form>
                            <div class="col-12 mt-3">
                                <button type="button" class="btn btn-block btn-primary" (click)="actionSave()">Salvar
                                </button>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-profile" role="tabpanel"
                             aria-labelledby="pills-profile-tab">
                            <div id="accordion">
                                <div class="card shadow-none my-3" *ngFor="let r of listResponsible; let i = index">
                                    <div class="card-header" id="headingTwo">
                                        <h5 class="mb-0">
                                            <button class="btn btn-block btn-link text-primary font-weight-bold text-left"
                                                    data-toggle="collapse" data-target="#{{r.id}}"
                                                    (click)="handlerDetailResponsible(r)"
                                                    aria-expanded="true" aria-controls="collapseOne">

                                                <div class="row mx-0 justify-content-between">
                                                    <span>{{r.name}}</span>
                                                    <span *ngIf="r.isCurator">Curador</span>
                                                </div>
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="{{r.id}}" [ngClass]="{'show': i === 0}" class="collapse"
                                         aria-labelledby="headingTwo"
                                         data-parent="#accordion">
                                        <div class="card-body">
                                            <form class="row mx-0 align-items-center" autocomplete="off">
                                                <div class="col-sm-9 mb-3">
                                                    <div class="input-material">
                                                        <input type="text" id="responsibleName" name="responsibleName"
                                                               class="form-control" [(ngModel)]="modelResponsible.name"
                                                               required/>
                                                        <label for="responsibleName">Nome</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-3 mb-3">
                                                    <div class="custom-control custom-switch">
                                                        <input type="checkbox" class="custom-control-input"
                                                               id="customSwitch2" name="checkbox"
                                                               [disabled]="model.judicialInterdiction !== 'yes'"
                                                               [(ngModel)]="modelResponsible.isCurator">
                                                        <label class="custom-control-label font-weight-bold"
                                                               for="customSwitch2">Curador</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="input-material">
                                                        <input type="text" id="responsibleCPF" name="responsibleCPF"
                                                               class="form-control" [(ngModel)]="modelResponsible.cpf"
                                                               mask="000.000.000-00" [dropSpecialCharacters]="false"
                                                               required/>
                                                        <label for="responsibleCPF">CPF</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="input-material">
                                                        <input id="responsiblePhone" name="responsiblePhone"
                                                               class="form-control"
                                                               [(ngModel)]="modelResponsible.phoneNumber"
                                                               type="text" mask="(00) 0000-0000||(00) 0 0000-0000"
                                                               [dropSpecialCharacters]="false" required/>
                                                        <label for="responsiblePhone">Telefone</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="input-material">
                                                        <input id="responsibleEmail" name="responsibleEmail"
                                                               class="form-control"
                                                               [(ngModel)]="modelResponsible.email"
                                                               type="text" required/>
                                                        <label for="responsibleEmail">E-mail</label>
                                                    </div>
                                                </div>
                                                <!--<div class="col-12 my-3 bg-gray rounded px-3 py-2">
                                                    <div class="input-material bg-gray">
                                                        <input id="responsibleObs" name="responsibleObs"
                                                               class="form-control bg-gray" required/>
                                                        <label for="responsibleObs">Observações</label>
                                                    </div>
                                                </div>-->
                                            </form>
                                            <div class="col-12 mt-3">
                                                <button type="button" class="btn btn-block btn-primary"
                                                        (click)="actionResponsible()">Salvar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
