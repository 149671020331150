import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../base/base.component';
import {Router} from '@angular/router';
import {ResponsibleService} from '../../../../service/resident/responsible.service';
import {ResidentResponsible} from '../../../../model/resident/resident-responsible';
import {Resident} from '../../../../model/resident/resident';
import {User} from '../../../../model/user/user';
import {UserService} from '../../../../service/user.service';

declare var moment: any;
declare var $: any;

@Component({
    selector: 'app-responsible',
    templateUrl: './responsible.component.html',
    styleUrls: ['./responsible.component.scss']
})
export class ResponsibleComponent extends BaseComponent implements OnInit, OnDestroy {

    residentId: string;

    @Input() set resident(val: any) {
        this.residentId = val;
    }

    listResponsible: ResidentResponsible[] = [];
    model: ResidentResponsible = new ResidentResponsible();
    isCaregiverOrTech = false;
    modelUser: User = new User();

    constructor(public router: Router,
                public userService: UserService,
                public responsibleService: ResponsibleService) {
        super(router);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.modelUser = data as User;
                    // ID = ADMIN + NURSE
                    if (this.modelUser.profile?.id !== '87115c40-8ac3-45c0-a70b-4f8b7ebba7bb' && this.modelUser.profile?.id !== 'a5cf1872-07ed-413e-aa7c-fc9c6c05aa15' && this.modelUser.profile?.id !== 'cf17d929-9315-4fdf-90ad-4decd3045b64') {
                        $('#responsibleDisabled input').prop('disabled', true);
                        $('#responsibleDisabled select').prop('disabled', true);
                        $('#responsibleDisabled label').addClass('disabled');
                        this.isCaregiverOrTech = true;
                    }
                }
            }
        });
        if (!this.isNullOrUndefined(this.residentId)) {
            this.actionList();
        }
    }

    ngOnDestroy(): void {
        const a = $('#responsibleModal');
        if (a[0]) {
            a.remove();
        }
    }

    kinshipTraduction(val) {
        switch (val) {
            case 'son' : {
                return 'Filho';
            }
            case 'spouse' : {
                return 'Cônjuge';
            }
            case 'brother' : {
                return 'Irmão';
            }
            case 'cousins' : {
                return 'Primos';
            }
            default: {
                return 'Outros';
            }
        }
    }

    actionList() {
        this.responsibleService.get(this.residentId).subscribe({
            next: data => {
                this.listResponsible = data as ResidentResponsible[];
            }, error: err => super.onError(err)
        });
    }

    handlerResponsible(value) {
        this.model.degreeOfKinship = value;
    }

    handlerResponsibleCheck(value) {
        if (value === 'others' && this.model.degreeOfKinship !== 'son' && this.model.degreeOfKinship !== 'spouse'
            && this.model.degreeOfKinship !== 'brother' && this.model.degreeOfKinship !== 'cousins') {
            return this.model.degreeOfKinship;
        } else if (value === 'son' || value === 'spouse' || value === 'brother' || value === 'cousins') {
            return value === this.model.degreeOfKinship;
        }
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.name, text: 'Nome, '},
            {value: this.model.birthday, text: 'Data de Nascimento, <br>'},
            {value: this.model.degreeOfKinship, text: 'Grau de Parentesco,'},
            {value: this.model.address, text: 'Endereço Atual, '},
            {value: this.model.city, text: 'Cidade, <br>'},
            {value: this.model.phoneNumber, text: 'Telefone, '},
            {value: this.model.email, text: 'E-mail. '},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        if (!super.isEMailValid(this.model.email)) {
            return;
        }

        this.model.birthday = moment(this.model.birthday, 'DD-MM-YYYY').utcOffset(0, true).format();

        if (this.isNullOrUndefined(this.model.id)) {
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    actionCreate() {
        this.responsibleService.create(this.residentId, this.model).subscribe({
            next: (data: Resident) => {
                $('#responsibleModal').modal('hide');
                this.model = new ResidentResponsible();
                super.showMessage('Sucesso', 'Responsável cadastrado com sucesso!', 'success');
                this.actionList();
            }, error: err => super.onError(err)
        });
    }

    actionUpdate() {
        this.responsibleService.update(this.residentId, this.model).subscribe({
            next: (data: Resident) => {
                $('#responsibleModal').modal('hide');
                this.model = new ResidentResponsible();
                super.showMessage('Sucesso', 'Responsável atualizado com sucesso!', 'success');
                this.actionList();
            }, error: err => super.onError(err)
        });
    }

    handlerRegister() {
        this.model = new ResidentResponsible();
        $('#responsibleModal').modal('show');
    }

    handlerDetail(model) {
        this.model = Object.assign({}, model);
        this.model.birthday = moment(this.model.birthday).format('DD/MM/yyyy');
        $('#responsibleModal').modal('show');
    }

    delete(id) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar este reponsável?', () => {
            this.responsibleService.delete(this.residentId, id).subscribe({
                next: data => {
                    this.actionList();
                    super.showMessage('Sucesso', 'Reponsável deletado com sucesso!', 'success');
                }, error: err => super.onError(err)
            });
        });
    }

    handlerCurator(val) {
        if (val !== 'yes') {
            this.model.isCurator = true;
        } else {
            this.model.isCurator = false;
        }
    }

    handlerButton() {
        this.model = new ResidentResponsible();
    }
}
