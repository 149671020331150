<section class="container">

    <div class="d-flex align-items-center mx-3 mt-4">
        <h1 class="title">Gestão de Logs</h1>
    </div>

    <div class="mt-3">

        <div class="d-sm-flex align-items-center justify-content-between mt-4 mx-4">
            <strong class="subTitle"></strong>
        </div>

        <div class="card mt-3 bg-white mx-2">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <input id="name" class="form-control" type="text" maxlength="10000" autocomplete="off" required />
                            <label for="name">Nome</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <select id="profile" class="form-control" name="type">
                                <option selected [ngValue]="''">Todos</option>
                            </select>
                            <label for="profile" class="mb-0 mr-3">Ativo</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-info mx-2 mb-3 mb-xs-0">
                            <svg-icon class="svg-white" src="assets/svg/excel.svg"></svg-icon>
                            Exportar Excel
                        </a>
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0"
                        >
                            <svg-icon class="svg-white" src="assets/svg/search.svg"></svg-icon>
                            Buscar
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mt-3 mx-2 bg-white p-3">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                    <tr>
                        <th class="align-middle" scope="col">Nome</th>
                        <th class="align-middle" scope="col">Endereço</th>
                        <th class="align-middle text-center" scope="col">Data de Criação</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let r of [1,2,3,4]; let i = index">
                        <td>Unidade Jucá</td>
                        <td>Av. Juca Peçanha, 553 - Vila Santista, Atibaia - SP, 12940-000</td>
                        <td class="text-center">21/06/2021</td>
                    </tr>
                    <!--<tr *ngIf="isNullOrUndefined(modelResult.results)">
                      <td colspan="4">
                        <p class="pt-5 pb-5 text-center">
                          Nenhum registro encontrado.
                        </p>
                      </td>
                    </tr>
                    <tr *ngFor="let r of modelResult.results |
                                paginate: {
                                 itemsPerPage: modelResult.pageSize,
                                 currentPage: modelResult.currentPage,
                                 totalItems: modelResult.totalCount}">
                      <td>{{r.}}</td>
                      <td>{{r.}}</td>
                      <td>{{r.}}</td>
                      <td>{{r.}}</td>
                      <td>{{r.}}</td>
                      <td>{{r.}}</td>
                      <td class="text-center d-grid">
                        <a href="javascript:void('DETAIL');" (click)="handlerDetail(r)"
                           class="btn btn-sm btn-info mb-2">
                          <svg-icon class="svg-secondary" src="assets/svg/edit.svg"></svg-icon>
                        </a>
                      </td>
                    </tr>-->
                    </tbody>
                </table>
            </div>
            <div class="text-center">
                PAGINAÇÃO
                <!--<pagination-controls
                        [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"
                        [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                        [screenReaderPageLabel]="labels.screenReaderPageLabel"
                        [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                        (pageChange)="pageChanged($event)"></pagination-controls>-->
            </div>
        </div>

    </div>
</section>
