<section class="container">

    <div class="d-flex align-items-center justify-content-between mx-3 mt-4">
        <h1 class="title">Unidades</h1>
        <a href="javascript:void (0)" class="btn btn-success btn-ico ml-2 btn-sm" (click)="handlerRegister()">
            <svg-icon class="svg-white mr-2" src="assets/svg/plus-circle.svg"></svg-icon>
            Cadastrar Unidade
        </a>
    </div>

    <div class="mt-3">
        <div class="d-sm-flex align-items-center justify-content-between mt-4 mx-4">
            <strong class="subTitle"></strong>
        </div>

        <div class="card mt-3 bg-white mx-2">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12 col-lg-6 col-xxl-3">
                        <div class="input-material">
                            <input id="date" class="form-control js-daterangepicker" type="text" maxlength="10000"
                                   autocomplete="off" required/>
                            <label for="date">Periodo</label>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6 col-xxl-3">
                        <div class="input-material">
                            <input id="name" class="form-control" type="text" maxlength="10000"
                                   [(ngModel)]="modelFilter.name" autocomplete="off" required/>
                            <label for="name">Nome</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-info mx-2 mb-3 mb-xs-0"
                           (click)="getExcel()">
                            Exportar Excel
                        </a>
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0"
                           (click)="actionSearch()">
                            Buscar
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mt-3 mx-2 bg-white p-3">
            <div class="table-responsive">
                <table class="table ">
                    <thead>
                    <tr>
                        <th class="align-middle w-15" scope="col">Data de Criação</th>
                        <th class="align-middle" scope="col">Nome</th>
                        <th class="align-middle text-center w-15" scope="col">Opções</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="isNullOrUndefined(modelResult.results)">
                        <td colspan="4">
                            <p class="pt-5 pb-5 text-center">
                                Nenhum registro encontrado.
                            </p>
                        </td>
                    </tr>
                    <tr *ngFor="let r of modelResult.results |
                                paginate: {
                                 itemsPerPage: modelResult.pageSize,
                                 currentPage: modelResult.currentPage,
                                 totalItems: modelResult.totalCount}; let i = index">
                        <td>{{r.createdAt | date: 'dd/MM/yyyy'}}</td>
                        <td>{{r.name}}</td>
                        <td class="text-center ">
                            <a href="javascript:void('DETAIL');" (click)="handlerDetail(r)"
                               class="btn">
                                <svg-icon class="ico-edit" src="assets/svg/edit.svg"></svg-icon>
                            </a>
                            <a href="javascript:void('DETAIL');" (click)="delete(r.id)"
                               class="btn">
                                <svg-icon class="ico-close" src="assets/svg/close.svg"></svg-icon>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-center">
                <pagination-controls
                        [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"
                        [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                        [screenReaderPageLabel]="labels.screenReaderPageLabel"
                        [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                        (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
        </div>

    </div>
</section>

<!-- Modal -->
<div class="modal fade" id="unityModal" tabindex="-1" role="dialog" aria-labelledby="unityModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    <span *ngIf="isNullOrUndefined(model.id)">Cadastro da Unidade</span>
                    <span *ngIf="!isNullOrUndefined(model.id)">Detalhe da Unidade</span>
                </h5>
                <button type="button" class="close close-primary pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">x</span>
                </button>
            </div>
            <div class="modal-body">
                <form autocomplete="off">
                    <div class="col-sm-12">
                        <div class="input-material">
                            <input id="modalName" name="modalName" class="form-control" type="text"
                                   [(ngModel)]="model.name" required/>
                            <label for="modalName">Nome</label>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="actionSave()" class="btn btn-block btn-primary">Salvar</button>
            </div>
        </div>
    </div>
</div>
