import {User} from '../user/user';
import {Unity} from '../unity/unity';

export class FilterUnity {
    id: string;
    name: string;
    start: Date;
    end: Date;

    constructor() {
    }
}
