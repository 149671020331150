export class ResidentMedicine {
    id: string;
    name: string;
    medicineTime: string;
    amount: string;
    type: string;
    prescription: string;
    macerate: boolean;
    place: string;
    mode: string;
    administration: string;
    period: string;
    controlledPrescription: string;
    treatmentStart: Date;
    treatmentFinish: Date;
    highPriceMedicine: string;
    continuousUse: string;
    observation: string;
    timeOfTheDay: string;
    updatedAt: Date;
    createdAt: Date;
    createdBy: CreatedByNavigation;

    constructor() {
    }
}
export class CreatedByNavigation {
    id: string;
    name: string;
    email: string;
    photo: string;
    professionalCouncilCode: string;
    createdAt: Date;
    updatedAt: Date;
    profile: Profile;
    userUnities: UserUnity[];
}

export class Profile {
    id: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;
}

export class UserUnity {
    id: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
}

export class Period {
    id: string;
    name: string;

    constructor() {
    }
}
