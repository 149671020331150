import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {User} from '../../model/user/user';

@Injectable({
    providedIn: 'root'
})
export class MedicineService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    get(id, type) {
        const url = `${this.BASE_URL}/resident/${id}/medicine/${type}`;
        return this.http.get(url);
    }

    getList(id, month, year) {
        const url = `${this.BASE_URL}/resident/${id}/medicine-table?year=${year}&month=${month} `;
        return this.http.get(url);
    }

    create(residentId, model) {
        const url = `${this.BASE_URL}/resident/${residentId}/medicine`;
        return this.http.post(url, model);
    }

    update(residentId, model) {
        const url = `${this.BASE_URL}/resident/${residentId}/medicine`;
        return this.http.put(url, model);
    }

    delete(id, idMedicine) {
        const url = `${this.BASE_URL}/resident/${id}/medicine/${idMedicine}`;
        return this.http.delete(url);
    }

    createTableItem(model) {
        const url = `${this.BASE_URL}/resident/medicine-table`;
        return this.http.post(url, model);
    }
}
