<div class="container">

    <div class="d-flex align-items-center ml-5 mt-4">
        <h1 class="title">Residente</h1>
    </div>

    <div class="my-4 col-lg-9 mx-auto">
        <nav class="nav nav-pills nav-fill nav-justified">
            <a class="nav-item nav-link active my-1 mx-2" id="pills-home-tab" data-toggle="pill" href="#pills-home"
               role="tab" aria-controls="pills-home" aria-selected="true">
                <svg-icon src="assets/svg/info.svg"></svg-icon>
                DADOS CADASTRAIS</a>
            <a class="nav-item nav-link my-1 mx-2" id="pills-profile-tab" data-toggle="pill" href="#pills-profile"
               role="tab"
               aria-controls="pills-profile" aria-selected="false"
               [ngClass]="{'disabled': isNullOrUndefined(residentId)}">
                <svg-icon src="assets/svg/raw.svg"></svg-icon>
                ACOMPANHAMENTO E CUIDADOS</a>
        </nav>
    </div>

    <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
            <div class="mt-3">
                <div class="card">
                    <div class="pb-0">
                        <ul class="nav nav-tabs nav-title" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active initial" id="management-tab" data-toggle="tab"
                                   href="#management-resident" role="tab"
                                   aria-controls="management" aria-selected="true">
                                    <div class="col-12 mb-2">
                                        <svg-icon src="assets/svg/resident/base.svg"></svg-icon>
                                    </div>
                                    Dados Básicos
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [ngClass]="{'disabled': isNullOrUndefined(residentId)}"
                                   id="responsible-tab"
                                   data-toggle="tab" href="#responsible-resident" role="tab"
                                   aria-controls="responsible" aria-selected="true">
                                    <div class="col-12 mb-2">
                                        <svg-icon src="assets/svg/resident/responsible.svg"></svg-icon>
                                    </div>
                                    Responsável
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [ngClass]="{'disabled': isNullOrUndefined(residentId)}"
                                   id="nursing-tab"
                                   data-toggle="tab" href="#nursing-resident" role="tab"
                                   aria-controls="nursing" aria-selected="true">
                                    <div class="col-12 mb-2">
                                        <svg-icon src="assets/svg/resident/raw.svg"></svg-icon>
                                    </div>
                                    Enfermagem
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [ngClass]="{'disabled': isNullOrUndefined(residentId)}"
                                   id="nutrition-tab"
                                   data-toggle="tab" href="#nutrition-resident" role="tab"
                                   aria-controls="nutrition" aria-selected="true">
                                    <div class="col-12 mb-2">
                                        <svg-icon src="assets/svg/resident/nutrition.svg"></svg-icon>
                                    </div>
                                    Nutricional
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [ngClass]="{'disabled': isNullOrUndefined(residentId)}"
                                   id="physiotherapy-tab" data-toggle="tab" href="#physiotherapy-resident" role="tab"
                                   aria-controls="physiotherapy" aria-selected="true">
                                    <div class="col-12 mb-2">
                                        <svg-icon src="assets/svg/resident/physiotherapy.svg"></svg-icon>
                                    </div>
                                    Fisioterapêutica
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [ngClass]="{'disabled': isNullOrUndefined(residentId)}"
                                   id="psychology-tab"
                                   data-toggle="tab" href="#psychology-resident" role="tab"
                                   aria-controls="psychology" aria-selected="true">
                                    <div class="col-12 mb-2">
                                        <svg-icon src="assets/svg/resident/psychology.svg"></svg-icon>
                                    </div>
                                    Psicologia
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link finish" [ngClass]="{'disabled': isNullOrUndefined(residentId)}"
                                   id="medicines-tab"
                                   data-toggle="tab" href="#medicines-resident" role="tab"
                                   aria-controls="medicines" aria-selected="true">
                                    <div class="col-12 mb-2">
                                        <svg-icon src="assets/svg/resident/medicine.svg"></svg-icon>
                                    </div>
                                    Medicação
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body pt-0 px-0">
                        <div class="tab-content" id="myTabContent">
                            <!--MANAGEMENT-->
                            <div class="tab-pane fade show active" id="management-resident" role="tabpanel"
                                 aria-labelledby="-tab">
                                <app-resident [resident]="residentId"></app-resident>
                            </div>
                            <!--RESPONSIBLE-->
                            <div class="tab-pane fade" id="responsible-resident" role="tabpanel" aria-labelledby="-tab">
                                <app-responsible [resident]="residentId"></app-responsible>
                            </div>
                            <!--NURSING-->
                            <div class="tab-pane fade" id="nursing-resident" role="tabpanel" aria-labelledby="-tab">
                                <app-resident-nursing [resident]="residentId"></app-resident-nursing>
                            </div>
                            <!--NUTRITION-->
                            <div class="tab-pane fade" id="nutrition-resident" role="tabpanel" aria-labelledby="-tab">
                                <app-resident-nutrition [resident]="residentId"></app-resident-nutrition>
                            </div>
                            <!--PHYSIOTHERAPY-->
                            <div class="tab-pane fade " id="physiotherapy-resident" role="tabpanel"
                                 aria-labelledby="-tab">
                                <app-resident-physiotherapy [resident]="residentId"></app-resident-physiotherapy>
                            </div>
                            <!--PSYCHOLOGY-->
                            <div class="tab-pane fade" id="psychology-resident" role="tabpanel" aria-labelledby="-tab">
                                <app-resident-psychology [resident]="residentId"></app-resident-psychology>
                            </div>
                            <!--MEDICINES-->
                            <div class="tab-pane fade" id="medicines-resident" role="tabpanel" aria-labelledby="-tab">
                                <app-resident-medicine [resident]="residentId"></app-resident-medicine>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div class="mt-3">
                <div class="card">
                    <div class="pb-0">
                        <ul class="nav nav-tabs nav-title" id="myTab1" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active initial"
                                   [ngClass]="{'disabled': isNullOrUndefined(residentId)}"
                                   id="evolution-tab" data-toggle="tab" href="#evolution" role="tab"
                                   aria-controls="evolution" aria-selected="true">
                                    <div class="col-12 mb-2">
                                        <svg-icon src="assets/svg/resident/raw.svg"></svg-icon>
                                    </div>
                                    Evolução
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [ngClass]="{'disabled': isNullOrUndefined(residentId)}"
                                   id="nursing1-tab" data-toggle="tab" href="#nursing1" role="tab"
                                   aria-controls="nursing1" aria-selected="true">
                                    <div class="col-12 mb-2">
                                        <svg-icon src="assets/svg/resident/raw.svg"></svg-icon>
                                    </div>
                                    Enfermagem
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [ngClass]="{'disabled': isNullOrUndefined(residentId)}"
                                   id="nutrition1-tab" data-toggle="tab" href="#nutrition1" role="tab"
                                   aria-controls="nutrition1" aria-selected="true">
                                    <div class="col-12 mb-2">
                                        <svg-icon src="assets/svg/resident/nutrition.svg"></svg-icon>
                                    </div>
                                    Nutricional
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [ngClass]="{'disabled': isNullOrUndefined(residentId)}"
                                   id="physiotherapy1-tab" data-toggle="tab" href="#physiotherapy1" role="tab"
                                   aria-controls="physiotherapy1" aria-selected="true">
                                    <div class="col-12 mb-2">
                                        <svg-icon src="assets/svg/resident/physiotherapy.svg"></svg-icon>
                                    </div>
                                    Fisioterapêutica
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [ngClass]="{'disabled': isNullOrUndefined(residentId)}"
                                   id="psychology1-tab" data-toggle="tab" href="#psychology1" role="tab"
                                   aria-controls="psychology1" aria-selected="true">
                                    <div class="col-12 mb-2">
                                        <svg-icon src="assets/svg/resident/psychology.svg"></svg-icon>
                                    </div>
                                    Psicologia
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [ngClass]="{'disabled': isNullOrUndefined(residentId)}"
                                   id="medicines1-tab" data-toggle="tab" href="#medicines1" role="tab"
                                   aria-controls="medicines1" aria-selected="true">
                                    <div class="col-12 mb-2">
                                        <svg-icon src="assets/svg/resident/medicine.svg"></svg-icon>
                                    </div>
                                    Prescrição
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [ngClass]="{'disabled': isNullOrUndefined(residentId)}"
                                   id="medicinesTable-tab" data-toggle="tab" href="#medicinesTable" role="tab"
                                   aria-controls="medicinesTable" aria-selected="true">
                                    <div class="col-12 mb-2">
                                        <svg-icon src="assets/svg/resident/medicine.svg"></svg-icon>
                                    </div>
                                    Medicamentos
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [ngClass]="{'disabled': isNullOrUndefined(residentId)}"
                                   id="vital-tab" data-toggle="tab" href="#vital" role="tab"
                                   aria-controls="vital" aria-selected="true">
                                    <div class="col-12 mb-2">
                                        <svg-icon src="assets/svg/resident/vital.svg"></svg-icon>
                                    </div>
                                    Sinais Vitais
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [ngClass]="{'disabled': isNullOrUndefined(residentId)}"
                                   id="speech1-tab" data-toggle="tab" href="#speech" role="tab"
                                   aria-controls="speech" aria-selected="true">
                                    <div class="col-12 mb-2">
                                        <svg-icon src="assets/svg/resident/vital.svg"></svg-icon>
                                    </div>
                                    Fonoaudiologia
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [ngClass]="{'disabled': isNullOrUndefined(residentId)}"
                                   id="doctor-tab" data-toggle="tab" href="#doctor" role="tab"
                                   aria-controls="doctor" aria-selected="true">
                                    <div class="col-12 mb-2">
                                        <svg-icon src="assets/svg/resident/vital.svg"></svg-icon>
                                    </div>
                                    Diagnóstico Médico
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [ngClass]="{'disabled': isNullOrUndefined(residentId)}"
                                   id="dentist-tab" data-toggle="tab" href="#dentist" role="tab"
                                   aria-controls="dentist" aria-selected="true">
                                    <div class="col-12 mb-2">
                                        <svg-icon src="assets/svg/resident/vital.svg"></svg-icon>
                                    </div>
                                    Dentista
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link finish" [ngClass]="{'disabled': isNullOrUndefined(residentId)}"
                                   id="diet-tab" data-toggle="tab" href="#diet" role="tab" aria-controls="diet"
                                   aria-selected="true">
                                    <div class="col-12 mb-2">
                                        <svg-icon src="assets/svg/resident/diet.svg"></svg-icon>
                                    </div>
                                    Dieta
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <div class="tab-content" id="myTabContent1">
                            <!--EVOLUTION-->
                            <div class="tab-pane fade show active" id="evolution" role="tabpanel"
                                 aria-labelledby="-tab">
                                <app-evolution [resident]="residentId"></app-evolution>
                            </div>
                            <!--NURSING-->
                            <div class="tab-pane fade" id="nursing1" role="tabpanel" aria-labelledby="-tab">
                                <app-nursing [resident]="residentId"></app-nursing>
                            </div>
                            <!--NUTRITION-->
                            <div class="tab-pane fade" id="nutrition1" role="tabpanel" aria-labelledby="-tab">
                                <app-nutrition [resident]="residentId"></app-nutrition>
                            </div>
                            <!--PHYSIOTHERAPY-->
                            <div class="tab-pane fade " id="physiotherapy1" role="tabpanel" aria-labelledby="-tab">
                                <app-physiotherapy [resident]="residentId"></app-physiotherapy>
                            </div>
                            <!--PSYCHOLOGY-->
                            <div class="tab-pane fade" id="psychology1" role="tabpanel" aria-labelledby="-tab">
                                <app-psychology [resident]="residentId"></app-psychology>
                            </div>
                            <!--MEDICINES-->
                            <div class="tab-pane fade" id="medicines1" role="tabpanel" aria-labelledby="-tab">
                                <app-medicine [resident]="residentId"></app-medicine>
                            </div>
                            <!--MEDICINE TABLE-->
                            <div class="tab-pane fade" id="medicinesTable" role="tabpanel" aria-labelledby="-tab">
                                <app-medicine-table [resident]="residentId"></app-medicine-table>
                            </div>
                            <!--VITAL-->
                            <div class="tab-pane fade" id="vital" role="tabpanel" aria-labelledby="-tab">
                                <app-vital [resident]="residentId"></app-vital>
                            </div>
                            <!--SPEECH-->
                            <div class="tab-pane fade" id="speech" role="tabpanel" aria-labelledby="-tab">
                                <app-speech-therapy [resident]="residentId"></app-speech-therapy>
                            </div>
                            <!--DOCTOR EVALUATION-->
                            <div class="tab-pane fade" id="doctor" role="tabpanel" aria-labelledby="-tab">
                                <app-doctor-evaluation [resident]="residentId"></app-doctor-evaluation>
                            </div>
                            <!--DENTIST-->
                            <div class="tab-pane fade" id="dentist" role="tabpanel" aria-labelledby="-tab">
                                <app-dentist [resident]="residentId"></app-dentist>
                            </div>
                            <!--DIET-->
                            <div class="tab-pane fade" id="diet" role="tabpanel" aria-labelledby="-tab">
                                <app-diet [resident]="residentId"></app-diet>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--<div class="mt-3">
        <div class="card">
            <div class="card-header pb-0">
                <ul class="nav nav-tabs border-bottom-0" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="management-tab" data-toggle="tab" href="#management" role="tab"
                           aria-controls="management" aria-selected="true">Informações Gerais do Paciente</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [ngClass]="{'disabled': isNullOrUndefined(residentId)}" id="nursing-tab"
                           data-toggle="tab" href="#nursing" role="tab"
                           aria-controls="nursing" aria-selected="true">Enfermagem</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [ngClass]="{'disabled': isNullOrUndefined(residentId)}" id="nutrition-tab"
                           data-toggle="tab" href="#nutrition" role="tab"
                           aria-controls="nutrition" aria-selected="true">Nutricional</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [ngClass]="{'disabled': isNullOrUndefined(residentId)}"
                           id="physiotherapy-tab" data-toggle="tab" href="#physiotherapy" role="tab"
                           aria-controls="physiotherapy" aria-selected="true">Fisoterapêutica</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [ngClass]="{'disabled': isNullOrUndefined(residentId)}" id="psychology-tab"
                           data-toggle="tab" href="#psychology" role="tab"
                           aria-controls="psychology" aria-selected="true">Psicologia</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [ngClass]="{'disabled': isNullOrUndefined(residentId)}" id="medicines-tab"
                           data-toggle="tab" href="#medicines" role="tab"
                           aria-controls="medicines" aria-selected="true">Medicinal</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [ngClass]="{'disabled': isNullOrUndefined(residentId)}" id="vital-tab"
                           data-toggle="tab" href="#vital" role="tab"
                           aria-controls="vital" aria-selected="true">Sinais Vitais</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [ngClass]="{'disabled': isNullOrUndefined(residentId)}" id="diet-tab"
                           data-toggle="tab" href="#diet" role="tab" aria-controls="diet" aria-selected="true">Dieta</a>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <div class="tab-content" id="myTabContent">
                    &lt;!&ndash;MANAGEMENT&ndash;&gt;
                    <div class="tab-pane fade show active" id="management" role="tabpanel" aria-labelledby="-tab">
                        <app-resident [resident]="residentId"></app-resident>
                    </div>
                    &lt;!&ndash;NURSING&ndash;&gt;
                    <div class="tab-pane fade" id="nursing" role="tabpanel" aria-labelledby="-tab">
                        <app-nursing [resident]="residentId"></app-nursing>
                    </div>
                    &lt;!&ndash;NUTRITION&ndash;&gt;
                    <div class="tab-pane fade" id="nutrition" role="tabpanel" aria-labelledby="-tab">
                        <app-nutrition [resident]="residentId"></app-nutrition>
                    </div>
                    &lt;!&ndash;PHYSIOTHERAPY&ndash;&gt;
                    <div class="tab-pane fade " id="physiotherapy" role="tabpanel" aria-labelledby="-tab">
                        <app-physiotherapy [resident]="residentId"></app-physiotherapy>
                    </div>
                    &lt;!&ndash;PSYCHOLOGY&ndash;&gt;
                    <div class="tab-pane fade" id="psychology" role="tabpanel" aria-labelledby="-tab">
                        <app-psychology [resident]="residentId"></app-psychology>
                    </div>
                    &lt;!&ndash;MEDICINES&ndash;&gt;
                    <div class="tab-pane fade" id="medicines" role="tabpanel" aria-labelledby="-tab">
                        <app-medicine [resident]="residentId"></app-medicine>
                    </div>
                    &lt;!&ndash;VITAL&ndash;&gt;
                    <div class="tab-pane fade" id="vital" role="tabpanel" aria-labelledby="-tab">
                        <app-vital [resident]="residentId"></app-vital>
                    </div>
                    &lt;!&ndash;DIET&ndash;&gt;
                    <div class="tab-pane fade" id="diet" role="tabpanel" aria-labelledby="-tab">
                        <app-diet [resident]="residentId"></app-diet>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
</div>
