import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {User} from '../../model/user/user';
import {UserService} from '../../service/user.service';
import {FilterUser} from '../../model/user/filter-user';
import {ResultUser} from '../../model/user/result-user';
import {Profile} from '../../model/profile';
import {ProfileService} from '../../service/profile.service';
import {UnityService} from '../../service/unity.service';
import {Unity} from '../../model/unity/unity';
import {Resident} from '../../model/resident/resident';

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent extends BaseComponent implements OnInit, OnDestroy {

    file: File = null;
    selectedType: string;
    model: User = new User();
    confirmPassword: string;
    modelFilter: FilterUser = new FilterUser();
    modelResult: ResultUser = new ResultUser();
    currentPage = 1;
    listProfile: Profile[] = [];
    listUnity: Unity[] = [];
    selectedUnity: string;
    thumpPath: string | ArrayBuffer;

    constructor(public router: Router,
                public userService: UserService,
                public profileService: ProfileService,
                public unityService: UnityService) {
        super(router);
    }

    ngOnInit(): void {
        this.setDatePicker();
        this.cleanFilterStart();
        this.actionProfile();
        this.actionUnity();
        this.actionSearch();
    }

    ngOnDestroy(): void {
        const a = $('#userModal');
        if (a[0]) {
            a.remove();
        }
    }

    setDatePicker() {
        const data = moment().subtract(0, 'days');
        $('.js-daterangepicker').daterangepicker({
            showDropdowns: false,
            startDate: data,
            locale: super.objLocaleDateRangePicker()
        }, (start, end, label) => {
            this.modelFilter.start = start.utcOffset(0, true).format();
            this.modelFilter.end = end.utcOffset(0, true).format();
        });
    }

    cleanFilterStart() {
        this.modelFilter.start = null;
        this.modelFilter.end = null;
    }

    actionProfile() {
        this.profileService.get().subscribe({
            next: data => {
                this.listProfile = data as Profile[];
                this.listProfile.forEach(e => {
                    e.name = this.handlerProfileArrayTraduction(e.id);
                });
                if (!this.isNullOrUndefined(this.model.photo)) {
                }
            }, error: err => super.onError(err)
        });
    }

    actionUnity() {
        this.unityService.get().subscribe({
            next: data => {
                this.listUnity = data as Unity[];
            }, error: err => super.onError(err)
        });
    }

    actionSearch() {
        this.userService.search(this.modelFilter, this.currentPage).subscribe({
            next: data => {
                this.modelResult = data as ResultUser;
                if (!this.isNullOrUndefined(this.model.photo)) {
                }
            }, error: err => super.onError(err)
        });
    }

    handlerRegister() {
        this.model = new User();
        this.thumpPath = null;
        this.confirmPassword = null;
        this.selectedUnity = undefined;
        this.showModal('userModal');
    }

    handlerDetail(model) {
        this.model = Object.assign({}, model);
        this.confirmPassword = null;
        this.thumpPath = this.model.photo;
        this.model.profileId = this.model.profile?.id;
        this.showModal('userModal');
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.name, text: 'Nome do Usuário*, '},
            {value: this.model.email, text: 'Email*,<br>'},
            {value: this.model.profileId, text: 'Perfil*, '},
        ];

        if (this.model.profile.name !== 'admin') {
            // @ts-ignore
            arrValidateFields.push({value: this.model.userUnities, text: 'Unidade*, '});
        }

        if (super.isNullOrUndefined(this.model.id) && super.isNullOrUndefined(this.model.password)) {
            arrValidateFields.push({value: this.model.password, text: 'Senha*.<br>'});
        }

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        if (!super.isEMailValid(this.model.email)) {
            return;
        }


        if (this.isNullOrUndefined(this.model.id)) {
            if (this.model.password.length < 6) {
                super.showMessage('Atenção', 'Sua senha deve conter no mínimo 6 caracteres.', 'warning');
                return;
            }
            if (this.model.password !== this.confirmPassword) {
                this.showMessage('Atenção', 'Senhas não são identicas!', 'warning');
                return;
            }

            this.create();
        } else {
            this.update();
        }
    }

    create() {
        this.userService.register(this.model).subscribe({
            next: (data: User) => {
                if (this.isNullOrUndefined(this.file)) {
                    this.model = new User();
                    this.confirmPassword = null;
                    this.actionSearch();
                    $('#userModal').modal('hide');
                } else {
                    this.actionUpload(data.id, 'create');
                }
            }, error: err => super.onError(err)
        });
    }

    update() {
        this.userService.update(this.model).subscribe({
            next: (data: User) => {
                if (this.isNullOrUndefined(this.file)) {
                    this.model = new User();
                    this.actionSearch();
                    $('#userModal').modal('hide');
                } else {
                    this.actionUpload(this.model.id, 'update');
                }
            }, error: err => super.onError(err)
        });
    }

    delete(id) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar este usuário?', () => {
            this.userService.delete(id).subscribe({
                next: data => {
                    this.actionSearch();
                    super.showMessage('Sucesso', 'Usuário deletado com sucesso!', 'success');
                }, error: err => super.onError(err)
            });
        });
    }

    pageChanged(event) {
        this.currentPage = event;
        this.actionSearch();
    }

    actionUpload(id, type) {
        this.userService.upload(this.file, id).subscribe({
            next: (data: User) => {
                if (type === 'create') {
                    this.model = new User();
                    $('#userModal').modal('hide');
                    this.actionSearch();
                    super.showMessage('Sucesso', 'Usuário cadastrado com sucesso!', 'success');
                } else {
                    $('#userModal').modal('hide');
                    this.model = new User();
                    this.actionSearch();
                    super.showMessage('Sucesso', 'Usuário atualizado com sucesso!', 'success');
                }
            }, error: err => super.onError(err)
        });
    }

    getExcel() {
        const token = localStorage.getItem('token') as string;

        const start = !super.isNullOrUndefined(this.modelFilter.start) ?
            moment(this.modelFilter.start).format('YYYY-MM-DD') : null;
        const end = !super.isNullOrUndefined(this.modelFilter.end) ?
            moment(this.modelFilter.end).format('YYYY-MM-DD') : null;

        let url = `${this.getBaseURL()}/user/search/excel?token=${token}`;

        if (!super.isNullOrUndefined(this.modelFilter.name)) {
            url += ('&name=' + this.modelFilter.name);
        }

        if (!super.isNullOrUndefined(this.modelFilter.email)) {
            url += ('&email=' + this.modelFilter.email);
        }

        if (!super.isNullOrUndefined(this.modelFilter.profile)) {
            url += ('&profile=' + this.modelFilter.profile.map(m => m.id).join(','));
        }
        if (!super.isNullOrUndefined(start)) {
            url += ('&start=' + start);
        }
        if (!super.isNullOrUndefined(end)) {
            url += ('&end=' + end);
        }
        console.log('filtro', this.modelFilter);
        console.log( url);

        window.open(encodeURI(url));
    }

    handlerUnity(selectedUnity: string) {
        // this.model.unity = this.listUnity.filter(x => x.id === selectedUnity)[0];
    }

    handlerProfile(profileId) {
        this.model.profile = this.listProfile.filter(x => x.id === profileId)[0];
    }

    handlerUpload(event) {
        const files = event.target.files;
        if (files.length > 0) {
            if (files[0].size > 20000000) {
                super.showMessage('Atenção', 'O arquivo não deve ultrapassar o tamanho de 20MB', 'info');
                return;
            }

            if (files[0].type.indexOf('jpeg') === -1 && files[0].type.indexOf('png') === -1) {
                super.showMessage('Atenção', 'Só será permitido arquivos com extensões .jpeg ou .png', 'info');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (file) => {
            this.thumpPath = file.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);

        this.selectedType = files[0].type.split('/')[1];
        this.file = files[0];
    }

    handlerProfileCouncilCode() {
        switch (this.model.profile.id) {
            case '2d46a64c-39ce-4a8a-9f4b-2ed58e2f4e84' : {
                return 'CRO';
            }
            case '6c58a7e1-f79a-4089-a84d-00f658be82fa' : {
                return 'CREFITO';
            }
            case 'a4e4f59a-5f21-47e1-9e95-2e6a74239595' : {
                return 'CRP';
            }
            case 'a5cf1872-07ed-413e-aa7c-fc9c6c05aa15' : {
                return 'COREN';
            }
            case 'be55443d-79f0-4e97-83e1-9f2e00415d0b' : {
                return 'CRN';
            }
            case 'f2c34c68-b1de-4b4d-81ca-7bda38ff5696' : {
                return 'CRM';
            }
            case 'b975c9f9-eeab-4baa-a72e-551121e6b816' : {
                return 'CREFONO';
            }
        }
    }

    handlerProfileTraduction(val) {
        switch (val) {
            case '2d46a64c-39ce-4a8a-9f4b-2ed58e2f4e84' : {
                return 'Dentista';
            }
            case '6c58a7e1-f79a-4089-a84d-00f658be82fa' : {
                return 'Fisioterapêuta';
            }
            case 'a4e4f59a-5f21-47e1-9e95-2e6a74239595' : {
                return 'Psicólogo';
            }
            case '87115c40-8ac3-45c0-a70b-4f8b7ebba7bb' : {
                return 'Administrador';
            }
            case 'a5cf1872-07ed-413e-aa7c-fc9c6c05aa15' : {
                return 'Enfermeiro';
            }
            case 'be55443d-79f0-4e97-83e1-9f2e00415d0b' : {
                return 'Nutricionista';
            }
            case 'f2c34c68-b1de-4b4d-81ca-7bda38ff5696' : {
                return 'Médico';
            }
            case 'b975c9f9-eeab-4baa-a72e-551121e6b816' : {
                return 'Fonoaudiólogo';
            }
            case '916c5ade-d985-406f-ae3a-8a90f7519e84' : {
                return 'Assistente de Farmácia';
            }
            case 'a5027f28-9ace-4c9c-b05d-8a5835c99e5f' : {
                return 'Cuidador';
            }
            case 'cf17d929-9315-4fdf-90ad-4decd3045b64' : {
                return 'Responsável de Unidade';
            }
            case 'f2b171de-7633-4374-9bdc-3cc1b1b2c31e' : {
                return 'Equipe Técnica';
            }
            default :
                return 'Psicólogo';
        }
    }

    handlerProfileArrayTraduction(val) {
        switch (val) {
            case '2d46a64c-39ce-4a8a-9f4b-2ed58e2f4e84' : {
                return 'Dentista';
            }
            case '6c58a7e1-f79a-4089-a84d-00f658be82fa' : {
                return 'Fisioterapêuta';
            }
            case 'a4e4f59a-5f21-47e1-9e95-2e6a74239595' : {
                return 'Psicólogo';
            }
            case '87115c40-8ac3-45c0-a70b-4f8b7ebba7bb' : {
                return 'Administrador';
            }
            case 'a5cf1872-07ed-413e-aa7c-fc9c6c05aa15' : {
                return 'Enfermeiro';
            }
            case 'be55443d-79f0-4e97-83e1-9f2e00415d0b' : {
                return 'Nutricionista';
            }
            case 'f2c34c68-b1de-4b4d-81ca-7bda38ff5696' : {
                return 'Médico';
            }
            case 'b975c9f9-eeab-4baa-a72e-551121e6b816' : {
                return 'Fonoaudiólogo';
            }
            case '916c5ade-d985-406f-ae3a-8a90f7519e84' : {
                return 'Assistente de Farmácia';
            }
            case 'a5027f28-9ace-4c9c-b05d-8a5835c99e5f' : {
                return 'Cuidador';
            }
            case 'cf17d929-9315-4fdf-90ad-4decd3045b64' : {
                return 'Responsável de Unidade';
            }
            case 'f2b171de-7633-4374-9bdc-3cc1b1b2c31e' : {
                return 'Equipe Técnica';
            }
            default :
                return 'Psicólogo';
        }
    }

}

