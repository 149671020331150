<div class="card-body" id="psychologyDisabled">
    <div class="row mx-0">
        <div class="col-12">
            <div class="bg-gray rounded py-2 row mx-0 mb-3 align-items-center">
                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                        <div class="col-12">
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="1cp" name="1cp" class="custom-control-input"
                                       [checked]="handlerEducationalLevelCheck('1c')"
                                       (change)="handlerEducationalLevel('1c')">
                                <label class="custom-control-label" for="1cp">1º Grau Completo</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="2cp" name="2cp" class="custom-control-input"
                                       [checked]="handlerEducationalLevelCheck('2c')"
                                       (change)="handlerEducationalLevel('2c')">
                                <label class="custom-control-label" for="2cp">2º Grau Completo</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="1ip" name="1ip" class="custom-control-input"
                                       [checked]="handlerEducationalLevelCheck('1i')"
                                       (change)="handlerEducationalLevel('1i')">
                                <label class="custom-control-label" for="1ip">1º Grau Incompleto</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="2ip" name="2ip" class="custom-control-input"
                                       [checked]="handlerEducationalLevelCheck('2i')"
                                       (change)="handlerEducationalLevel('2i')">
                                <label class="custom-control-label" for="2ip">2º Grau Incompleto</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="anp" name="anp" class="custom-control-input"
                                       [checked]="handlerEducationalLevelCheck('an')"
                                       (change)="handlerEducationalLevel('an')">
                                <label class="custom-control-label" for="anp">Analfabeto</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="scp" name="scp" class="custom-control-input"
                                       [checked]="handlerEducationalLevelCheck('sc')"
                                       (change)="handlerEducationalLevel('sc')">
                                <label class="custom-control-label" for="scp">Superior Completo</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="sip" name="sip" class="custom-control-input"
                                       [checked]="handlerEducationalLevelCheck('si')"
                                       (change)="handlerEducationalLevel('si')">
                                <label class="custom-control-label" for="sip">Superior Incompleto</label>
                            </div>
                            <div class="input-material bg-gray mb-0">
                                <input id="educationalLevelOthers1p" class="bg-gray form-control" type="text"
                                       maxlength="10000" autocomplete="off" required name="educationalLevelOthers1p"
                                       [ngModel]="handlerEducationalLevelCheck('others')"
                                       (ngModelChange)="handlerEducationalLevel($event)"/>
                                <label for="educationalLevelOthers1p">Outros</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <div class="input-material bg-gray mb-0">
                        <input id="profession" class="form-control bg-gray" type="text" maxlength="10000"
                               autocomplete="off" [(ngModel)]="model.profession" required/>
                        <label for="profession">Profissão</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary mb-0">Aposentado?</p>
                </div>
                <div class="col-9 d-flex align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="retiredCad" name="retired" class="custom-control-input"
                               [checked]="model.retired === 'yes'" (change)="model.retired = 'yes'">
                        <label class="custom-control-label" for="retiredCad">Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="noRetiredCad" name="noRetired" class="custom-control-input"
                               [checked]="model.retired === 'no'" (change)="model.retired = 'no'">
                        <label class="custom-control-label" for="noRetiredCad">Não</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Leituras?</p>
                </div>
                <div class="col-12 row mx-0 align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="dislike" name="dislike" class="custom-control-input"
                               [checked]="model.reading === 'never'" (change)="model.reading = 'never'">
                        <label class="custom-control-label" for="dislike">Nunca
                            Gostou</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="likedPast" name="likedPast" class="custom-control-input"
                               [checked]="model.reading === 'past'" (change)="model.reading = 'past'">
                        <label class="custom-control-label" for="likedPast">Gostou no
                            passado</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="likes" name="likes" class="custom-control-input"
                               [checked]="model.reading === 'present'" (change)="model.reading = 'present'">
                        <label class="custom-control-label" for="likes">Gosta no
                            presente</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="always" name="always" class="custom-control-input"
                               [checked]="model.reading === 'always'" (change)="model.reading = 'always'">
                        <label class="custom-control-label" for="always">Sempre
                            Gostou</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <div class="input-material bg-gray mb-0">
                        <input id="readType" class="form-control bg-gray" type="text" maxlength="10000"
                               autocomplete="off" [(ngModel)]="model.readingType" required/>
                        <label for="readType">Tipo</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Musica?</p>
                </div>
                <div class="col-12 row mx-0 align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="musicDislike" name="musicDislike"
                               class="custom-control-input"
                               [checked]="model.song === 'never'" (change)="model.song = 'never'">
                        <label class="custom-control-label" for="musicDislike">Nunca
                            Gostou</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="musicPast" name="musicPast" class="custom-control-input"
                               [checked]="model.song === 'past'" (change)="model.song = 'past'">
                        <label class="custom-control-label" for="musicPast">Gostou no
                            passado</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="musicPresent" name="musicPresent"
                               class="custom-control-input"
                               [checked]="model.song === 'present'" (change)="model.song = 'present'">
                        <label class="custom-control-label" for="musicPresent">Gosta no
                            presente</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="musicAlways" name="musicAlways" class="custom-control-input"
                               [checked]="model.song === 'always'" (change)="model.song = 'always'">
                        <label class="custom-control-label" for="musicAlways">Sempre
                            Gostou</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <div class="input-material bg-gray mb-0">
                        <input id="musicType" class="form-control bg-gray" type="text" maxlength="10000"
                               autocomplete="off" [(ngModel)]="model.songType" required/>
                        <label for="musicType">Tipo</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Política</p>
                </div>
                <div class="col-12 row mx-0 align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="politicDislike" name="politicDislike"
                               class="custom-control-input"
                               [checked]="model.politics === 'never'" (change)="model.politics = 'never'">
                        <label class="custom-control-label" for="politicDislike">Nunca
                            Gostou</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="politicPast" name="politicPast" class="custom-control-input"
                               [checked]="model.politics === 'past'" (change)="model.politics = 'past'">
                        <label class="custom-control-label" for="politicPast">Gostou no
                            passado</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="politicPresent" name="politicPresent"
                               class="custom-control-input"
                               [checked]="model.politics === 'present'"
                               (change)="model.politics = 'present'">
                        <label class="custom-control-label" for="politicPresent">Gosta no
                            presente</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="politicAlways" name="politicAlways"
                               class="custom-control-input"
                               [checked]="model.politics === 'always'" (change)="model.politics = 'always'">
                        <label class="custom-control-label" for="politicAlways">Sempre
                            Gostou</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <div class="input-material bg-gray mb-0">
                        <input id="politicType" class="form-control bg-gray" type="text" maxlength="10000"
                               autocomplete="off" [(ngModel)]="model.politicsType" required/>
                        <label for="politicType">Tipo</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Animais</p>
                </div>
                <div class="col-12 row mx-0 align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="animalDislike" name="animalDislike"
                               class="custom-control-input"
                               [checked]="model.pets === 'never'" (change)="model.pets = 'never'">
                        <label class="custom-control-label" for="animalDislike">Nunca
                            Gostou</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="animalPast" name="animalPast" class="custom-control-input"
                               [checked]="model.pets === 'past'" (change)="model.pets = 'past'">
                        <label class="custom-control-label" for="animalPast">Gostou no
                            passado</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="animalPresent" name="animalPresent"
                               class="custom-control-input"
                               [checked]="model.pets === 'present'" (change)="model.pets = 'present'">
                        <label class="custom-control-label" for="animalPresent">Gosta no
                            presente</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="animalAlways" name="animalAlways"
                               class="custom-control-input"
                               [checked]="model.pets === 'always'" (change)="model.pets = 'always'">
                        <label class="custom-control-label" for="animalAlways">Sempre
                            Gostou</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <div class="input-material bg-gray mb-0">
                        <input id="animalType" class="form-control bg-gray" type="text" maxlength="10000"
                               autocomplete="off" [(ngModel)]="model.petsName" required/>
                        <label for="animalType">Nome se tem ou teve animais domésticos</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Plantas</p>
                </div>
                <div class="col-12 row mx-0 align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="plantDislike" name="plantDislike"
                               class="custom-control-input"
                               [checked]="model.plants === 'never'" (change)="model.plants = 'never'">
                        <label class="custom-control-label" for="plantDislike">Nunca Gostou</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="plantPast" name="plantPast" class="custom-control-input"
                               [checked]="model.plants === 'past'" (change)="model.plants = 'past'">
                        <label class="custom-control-label" for="plantPast">Gostou no passado</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="plantPresent" name="plantPresent"
                               class="custom-control-input"
                               [checked]="model.plants === 'present'" (change)="model.plants = 'present'">
                        <label class="custom-control-label" for="plantPresent">Gosta no presente</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="plantAlways" name="plantAlways" class="custom-control-input"
                               [checked]="model.plants === 'always'" (change)="model.plants = 'always'">
                        <label class="custom-control-label" for="plantAlways">Sempre Gostou</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <div class="input-material bg-gray mb-0">
                        <input id="plantType" class="form-control bg-gray" type="text" maxlength="10000"
                               autocomplete="off" [(ngModel)]="model.plantsType" required/>
                        <label for="plantType">Tipo</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Jogos</p>
                </div>
                <div class="col-12 row mx-0 align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="gameDislike" name="gameDislike" class="custom-control-input"
                               [checked]="model.games === 'never'" (change)="model.games = 'never'">
                        <label class="custom-control-label" for="gameDislike">Nunca Gostou</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="gamePast" name="gamePast" class="custom-control-input"
                               [checked]="model.games === 'past'" (change)="model.games = 'past'">
                        <label class="custom-control-label" for="gamePast">Gostou no passado</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="gamePresent" name="gamePresent" class="custom-control-input"
                               [checked]="model.games === 'present'" (change)="model.games = 'present'">
                        <label class="custom-control-label" for="gamePresent">Gosta no presente</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="gameAlways" name="gameAlways" class="custom-control-input"
                               [checked]="model.games === 'always'" (change)="model.games = 'always'">
                        <label class="custom-control-label" for="gameAlways">Sempre Gostou</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <div class="input-material bg-gray mb-0">
                        <input id="gameType" class="form-control bg-gray" type="text" maxlength="10000"
                               autocomplete="off"
                               [(ngModel)]="model.gamesType" required/>
                        <label for="gameType">Tipo</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">TV</p>
                </div>
                <div class="col-12 row mx-0 align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="tvDislike" name="tvDislike" class="custom-control-input"
                               [checked]="model.tv === 'never'" (change)="model.tv = 'never'">
                        <label class="custom-control-label" for="tvDislike">Nunca Gostou</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="tvPast" name="tvPast" class="custom-control-input"
                               [checked]="model.tv === 'past'" (change)="model.tv = 'past'">
                        <label class="custom-control-label" for="tvPast">Gostou no passado</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="tvPresent" name="tvPresent" class="custom-control-input"
                               [checked]="model.tv === 'present'" (change)="model.tv = 'present'">
                        <label class="custom-control-label" for="tvPresent">Gosta no presente</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="tvAlways" name="tvAlways" class="custom-control-input"
                               [checked]="model.tv === 'always'" (change)="model.tv = 'always'">
                        <label class="custom-control-label" for="tvAlways">Sempre Gostou</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <div class="input-material bg-gray mb-0">
                        <input id="tvType" class="form-control bg-gray" type="text" maxlength="10000"
                               autocomplete="off" [(ngModel)]="model.favoriteChannel" required/>
                        <label for="tvType">Tipo</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <span class="text-primary ml-3 font-weight-bold">Hobbies</span>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                        <div class="col-12">
                            <div class="input-material bg-gray mb-0">
                                <input id="pastHobby" class="form-control bg-gray" type="text"
                                       maxlength="10000"
                                       autocomplete="off" [(ngModel)]="model.pastHobbies" required/>
                                <label for="pastHobby">Hobbies do Passado</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                        <div class="col-12">
                            <div class="input-material bg-gray mb-0">
                                <input id="presentHobby" class="form-control bg-gray" type="text"
                                       maxlength="10000"
                                       autocomplete="off" [(ngModel)]="model.currentHobbies" required/>
                                <label for="presentHobby">Hobbies do Presente</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Viagem</p>
                </div>
                <div class="col-12 row mx-0 align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="travelDislike" name="travelDislike"
                               class="custom-control-input"
                               [checked]="model.travel === 'never'" (change)="model.travel = 'never'">
                        <label class="custom-control-label" for="travelDislike">Nunca Gostou</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="travelPast" name="travelPast" class="custom-control-input"
                               [checked]="model.travel === 'past'" (change)="model.travel = 'past'">
                        <label class="custom-control-label" for="travelPast">Gostou no passado</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="travelPresent" name="travelPresent"
                               class="custom-control-input"
                               [checked]="model.travel === 'present'" (change)="model.travel = 'present'">
                        <label class="custom-control-label" for="travelPresent">Gosta no presente</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="travelAlways" name="travelAlways"
                               class="custom-control-input"
                               [checked]="model.travel === 'always'" (change)="model.travel = 'always'">
                        <label class="custom-control-label" for="travelAlways">Sempre Gostou</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <div class="input-material bg-gray mb-0">
                        <input id="travelMarcante" class="form-control bg-gray" type="text" maxlength="10000"
                               autocomplete="off"
                               [(ngModel)]="model.bestTravel" required/>
                        <label for="travelMarcante">Viagem Mais marcante</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Gosta de Conversar</p>
                </div>
                <div class="col-12 row mx-0 align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="talkDislike" name="talkDislike" class="custom-control-input"
                               [checked]="model.talk === 'never'" (change)="model.talk = 'never'">
                        <label class="custom-control-label" for="talkDislike">Nunca Gostou</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="talkPast" name="talkPast" class="custom-control-input"
                               [checked]="model.talk === 'past'" (change)="model.talk = 'past'">
                        <label class="custom-control-label" for="talkPast">Gostou no passado</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="talkPresent" name="talkPresent" class="custom-control-input"
                               [checked]="model.talk === 'present'" (change)="model.talk = 'present'">
                        <label class="custom-control-label" for="talkPresent">Gosta no presente</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="talkAlways" name="talkAlways" class="custom-control-input"
                               [checked]="model.talk === 'always'" (change)="model.talk = 'always'">
                        <label class="custom-control-label" for="talkAlways">Sempre Gostou</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">É bem Humorada?</p>
                </div>
                <div class="col-12 row mx-0 align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="humorPast" name="humorPast" class="custom-control-input"
                               [checked]="model.pastHumor === 'pastYes'"
                               (change)="model.pastHumor = 'pastYes'">
                        <label class="custom-control-label" for="humorPast">(no passado) Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="noHumorPast" name="noHumorPast" class="custom-control-input"
                               [checked]="model.pastHumor === 'pastNo'"
                               (change)="model.pastHumor = 'pastNo'">
                        <label class="custom-control-label" for="noHumorPast">(no passado) Não</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="humorPresent" name="humorPresent"
                               class="custom-control-input"
                               [checked]="model.presentHumor === 'presentYes'"
                               (change)="model.presentHumor = 'presentYes'">
                        <label class="custom-control-label" for="humorPresent">(no presente)Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="noHumorPresent" name="noHumorPresent"
                               class="custom-control-input"
                               [checked]="model.presentHumor === 'presentNo'"
                               (change)="model.presentHumor = 'presentNo'">
                        <label class="custom-control-label" for="noHumorPresent">(no presente)Não</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary mb-0">É agressiva?</p>
                </div>
                <div class="col-12 row mx-0 align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="agressivePast" name="agressivePast"
                               class="custom-control-input"
                               [checked]="model.pastAggressive === 'pastYes'"
                               (change)="model.pastAggressive = 'pastYes'">
                        <label class="custom-control-label" for="agressivePast">(no passado)Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="noAgressivePast" name="noAgressivePast"
                               class="custom-control-input"
                               [checked]="model.pastAggressive === 'pastNo'"
                               (change)="model.pastAggressive = 'pastNo'">
                        <label class="custom-control-label" for="noAgressivePast">(no passado)Não</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="agressivePresent" name="agressivePresent"
                               class="custom-control-input"
                               [checked]="model.presentAggressive === 'presYes'"
                               (change)="model.presentAggressive = 'presYes'">
                        <label class="custom-control-label" for="agressivePresent">(no presente)Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="noAgressivePresent" name="noAgressivePresent"
                               class="custom-control-input"
                               [checked]="model.presentAggressive === 'presNo'"
                               (change)="model.presentAggressive = 'presNo'">
                        <label class="custom-control-label" for="noAgressivePresent">(no
                            presente)Não</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary mb-0">Depressão?</p>
                </div>
                <div class="col-12 row mx-0 align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="depressivePast" name="depressivePast"
                               class="custom-control-input"
                               [checked]="model.depressionPast === 'pastYes'"
                               (change)="model.depressionPast = 'pastYes'">
                        <label class="custom-control-label" for="depressivePast">(no passado)Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="noDepressivePast" name="noDepressivePast"
                               class="custom-control-input"
                               [checked]="model.depressionPast === 'pastNo'"
                               (change)="model.depressionPast = 'pastNo'">
                        <label class="custom-control-label" for="noDepressivePast">(no passado)Não</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="depressivePresent" name="depressivePresent"
                               class="custom-control-input"
                               [checked]="model.depressionPresent === 'presYes'"
                               (change)="model.depressionPresent = 'presYes'">
                        <label class="custom-control-label" for="depressivePresent">(no presente)Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="noDepressivePresent" name="noDepressivePresent"
                               class="custom-control-input"
                               [checked]="model.depressionPresent === 'presNo'"
                               (change)="model.depressionPresent = 'presNo'">
                        <label class="custom-control-label" for="noDepressivePresent">(no
                            presente)Não</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary mb-0">Ja fez terapia?</p>
                </div>
                <div class="col-12 row mx-0 align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="therapyPast" name="therapyPast" class="custom-control-input"
                               [checked]="model.didTherapyPast === 'pastYes'"
                               (change)="model.didTherapyPast = 'pastYes'">
                        <label class="custom-control-label" for="therapyPast">(no passado)Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="noTherapyPast" name="noTherapyPast"
                               class="custom-control-input"
                               [checked]="model.didTherapyPast === 'pastNo'"
                               (change)="model.didTherapyPast = 'pastNo'">
                        <label class="custom-control-label" for="noTherapyPast">(no passado)Não</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="therapyPresent" name="therapyPresent"
                               class="custom-control-input"
                               [checked]="model.therapyPresent === 'presYes'"
                               (change)="model.therapyPresent = 'presYes'">
                        <label class="custom-control-label" for="therapyPresent">(no presente)Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="noTherapyPresent" name="noTherapyPresent"
                               class="custom-control-input"
                               [checked]="model.therapyPresent === 'presNo'"
                               (change)="model.therapyPresent = 'presNo'">
                        <label class="custom-control-label" for="noTherapyPresent">(no presente)Não</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary mb-0">Possuiu ou possui uma vida sentimental/amorosa?</p>
                </div>
                <div class="col-12 row mx-0 align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="lovePast" name="lovePast" class="custom-control-input"
                               [checked]="model.relationshipPast === 'pastYes'"
                               (change)="model.relationshipPast = 'pastYes'">
                        <label class="custom-control-label" for="lovePast">(no passado)Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="noLovePast" name="noLovePast" class="custom-control-input"
                               [checked]="model.relationshipPast === 'pastNo'"
                               (change)="model.relationshipPast = 'pastNo'">
                        <label class="custom-control-label" for="noLovePast">(no passado)Não</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="lovePresent" name="lovePresent" class="custom-control-input"
                               [checked]="model.relationshipPresent === 'presYes'"
                               (change)="model.relationshipPresent = 'presYes'">
                        <label class="custom-control-label" for="lovePresent">(no presente)Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="noLovePresent" name="noLovePresent"
                               class="custom-control-input"
                               [checked]="model.relationshipPresent === 'presNo'"
                               (change)="model.relationshipPresent = 'presNo'">
                        <label class="custom-control-label" for="noLovePresent">(no presente)Não</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <span class="text-primary ml-3 font-weight-bold">Vícios</span>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                        <div class="col-12">
                            <div class="input-material bg-gray mb-0">
                                <input id="pastAddictions" class="form-control bg-gray" type="text"
                                       maxlength="10000"
                                       autocomplete="off"
                                       [ngModel]="model.pastAddictions" required/>
                                <label for="qualityRelation">Vícios no passado:</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                        <div class="col-12">
                            <div class="input-material bg-gray mb-0">
                                <input id="presAddictions" class="form-control bg-gray" type="text"
                                       maxlength="10000"
                                       autocomplete="off"
                                       [ngModel]="model.currentAddictions" required/>
                                <label for="visitPretension">Vícios no presente: </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <span class="text-primary ml-3 font-weight-bold">Responsável x Idoso</span>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                        <div class="col-12">
                            <div class="input-material bg-gray mb-0">
                                <input id="qualityRelation" class="form-control bg-gray" type="text"
                                       maxlength="10000"
                                       autocomplete="off"
                                       [ngModel]="model.seniorResponsibleRelation" required/>
                                <label for="qualityRelation">Como você descreveria a qualidade da sua
                                    relação com o
                                    idoso</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                        <div class="col-12">
                            <div class="input-material bg-gray mb-0">
                                <input id="visitPretension" class="form-control bg-gray" type="text"
                                       maxlength="10000"
                                       autocomplete="off"
                                       [ngModel]="model.responsibleVisitPretension" required/>
                                <label for="visitPretension">Qual a pretenção de visita ao mesmo?</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Solicitado a Familia</p>
                </div>
                <div class="col-12 row mx-0 align-items-center">
                    <div class="custom-control custom-checkbox custom-control-inline mb-0">
                        <input type="checkbox" id="actualPhoto" name="actualPhoto"
                               class="custom-control-input"
                               [checked]="handlerRequestedFamilyCheck('photo')"
                               (change)="handlerRequestedFamily('photo')">
                        <label class="custom-control-label" for="actualPhoto">Fotografias
                            Atuais (dos ultimos 5 anos), dos familiares e amigos mais
                            próximos em boa resolução, no verso anotar, nomes, locais e
                            datas.</label>
                    </div>
                    <div class="custom-control custom-checkbox custom-control-inline mb-0">
                        <input type="checkbox" id="objects" name="objects" class="custom-control-input"
                               [checked]="handlerRequestedFamilyCheck('significantObjects')"
                               (change)="handlerRequestedFamily('significantObjects')">
                        <label class="custom-control-label" for="objects">
                            Objetos significativos do idoso</label>
                    </div>
                    <div class="custom-control custom-checkbox custom-control-inline mb-0">
                        <input type="checkbox" id="familyPresents" name="familyPresents"
                               class="custom-control-input"
                               [checked]="handlerRequestedFamilyCheck('careProject')"
                               (change)="handlerRequestedFamily('careProject')">
                        <label class="custom-control-label" for="familyPresents">
                            Participar do Projeto cuidar - Familiares</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xs-5 ml-auto mt-5" *ngIf="!isCaregiver">
        <a href="javascript:void(0)" class="btn btn-success btn-ico ml-2 btn-block" (click)="actionSave()">
            <svg-icon class="svg-white" src="assets/svg/check-circle.svg"></svg-icon>
            Salvar
        </a>
    </div>
</div>
