import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../service/user.service';
import {User} from '../../model/user/user';

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends BaseComponent implements OnInit, AfterViewInit {

    navBarShort = false;
    modelUser: User = new User();
    isAdmin = true;
    allowActivity = true;

    constructor(public router: Router,
                public route2: ActivatedRoute,
                public userService: UserService) {
        super(router);
    }

    ngOnInit(): void {
        this.handlerCollapse();
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.modelUser = data as User;
                    // ID = ADMIN
                    if (this.modelUser.profile?.id !== '87115c40-8ac3-45c0-a70b-4f8b7ebba7bb') {
                        this.isAdmin = false;
                    }
                }
                if (!this.isNullOrUndefined(data.id)) {
                    this.modelUser = data as User;
                    // ID = ADMIN + UNIT DIRECTOR + NURSER
                    if (this.modelUser.profile?.id !== '87115c40-8ac3-45c0-a70b-4f8b7ebba7bb' && this.modelUser.profile?.id !== 'cf17d929-9315-4fdf-90ad-4decd3045b64' && this.modelUser.profile?.id !== 'a5cf1872-07ed-413e-aa7c-fc9c6c05aa15' && this.modelUser.profile?.id !== '916c5ade-d985-406f-ae3a-8a90f7519e84' && this.modelUser.profile?.id !== 'cf17d929-9315-4fdf-90ad-4decd3045b64') {
                        this.allowActivity = false;
                    }
                }
            }
        });
    }

    ngAfterViewInit(): void {
        this.navActive();
    }

    handlerCollapse() {
        $('.navbar-nav .nav-item').click(function() {
            $('.navbar-nav .nav-item').removeClass('active');
            $(this).addClass('active');
        });
        $('.navbar-nav .nav-link').click(function() {
            $('.navbar-nav .nav-link').removeClass('text-white');
            $(this).addClass('text-white');
        });
    }

    actionCloseCollapse() {
        this.actionSideBar();
        $('#reports').collapse('hide');
        $('#help').collapse('hide');
        $('body').removeClass('nav-open');
        $('.hamburger').removeClass('open');
    }

    navActive() {
        if (this.router.url === '/calibration' || this.router.url === '/engines') {
            return this.handlerNavActive('.report-ts');
        }
        if (this.router.url === '/training' || this.router.url === '/faq') {
            return this.handlerNavActive('.help-ts');
        }
    }

    handlerNavActive(el) {
        $('.navbar-nav .nav-item').removeClass('active');
        $('.navbar-nav .nav-link').removeClass('text-white');
        $(el).addClass('active');
        $(el + ' .nav-link').addClass('text-white');

    }

    actionSideBar() {
        this.navBarShort = !this.navBarShort;
    }

    handlerActiveItem(url, sub: string = '') {
        return url === this.route2.snapshot['_routerState'].url || sub === ('/' + this.route2.snapshot['_routerState'].url.split('/')[1]);
    }
}
