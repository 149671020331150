export class ResidentVital {
    id: string;
    bloodPressure: string;
    pulse: string;
    observation: string;
    temperature: number;
    respiration: string;
    bloodGlucose: number;
    finger: number;
    evaluationTime: Date;
    createdAt: Date;
    updatedAt: Date;

    constructor() {
    }
}
