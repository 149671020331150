import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ServiceBase} from '../base/service.base';

@Injectable({
    providedIn: 'root'
})
export class DietService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    get(id) {
        const url = `${this.BASE_URL}/resident/${id}/diet`;
        return this.http.get(url);
    }

    create(residentId, model) {
        const url = `${this.BASE_URL}/resident/${residentId}/diet`;
        return this.http.post(url, model);
    }

    update(residentId, model) {
        const url = `${this.BASE_URL}/resident/${residentId}/diet`;
        return this.http.put(url, model);
    }

    delete(id , idDiet) {
        const url = `${this.BASE_URL}/resident/${id}/diet/${idDiet}`;
        return this.http.delete(url);
    }

}
