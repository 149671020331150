<div class="mb-4 text-right" *ngIf="!notAllowed">
    <a href="javascript:void(0)" class="btn btn-success btn-ico ml-2 btn-sm" (click)="handlerButton()">
        <svg-icon class="svg-white" src="assets/svg/plus-circle.svg"></svg-icon>
        Cadastrar Evolução
    </a>
</div>

<div class="row mx-0">
    <div class="table-responsive">
        <table class="table ">
            <thead>
            <tr>
                <th class="align-middle" scope="col">Data da Ocorrência</th>
                <th class="align-middle" scope="col">Anámnese</th>
                <th class="align-middle text-center" scope="col">Avaliação</th>
                <th class="align-middle text-center" scope="col">Responsável</th>
                <th class="align-middle text-center" scope="col">Código de Conselho</th>
                <th class="align-middle text-center" scope="col">Data de Registro</th>
                <th class="align-middle text-center" scope="col">Opções</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="isNullOrUndefined(modelSpeechs)">
                <td colspan="6">
                    <p class="pt-5 pb-5 text-center">
                        Nenhum registro encontrado.
                    </p>
                </td>
            </tr>
            <tr *ngFor="let s of modelSpeechs" [ngClass]="{'bluer' : !this.isNullOrUndefined(s.updatedAt)}">
                <td>{{s.evaluationTime | date: 'dd/MM/YYYY'}}</td>
                <td>{{s.anamnesis}}</td>
                <td>{{s.evaluation}}</td>
                <td>{{s.createdBy.name}}</td>
                <td>{{s.createdBy.professionalCouncilCode}}</td>
                <td>{{s.createdAt | date: 'dd/MM/yyyy'}}</td>
                <td class="text-center">
                    <a href="javascript:void('detail')" class="btn"
                       (click)="handlerDetail(s)">
                        <svg-icon class="ico-edit" src="assets/svg/eye.svg"></svg-icon>
                    </a>

                    <!--                    <a href="javascript:void('delete')" *ngIf="!notAllowed" class="btn"-->
                    <!--                       (click)="delete(s.id)">-->
                    <!--                        <svg-icon class="ico-close" src="assets/svg/close.svg"></svg-icon>-->
                    <!--                    </a>-->
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="modal fade" id="registerSpeech" tabindex="-1" role="dialog" aria-labelledby="registerSpeech"
     aria-hidden="true">
    <div class="modal-dialog modal-75 modal-dialog-centered" id="speechDisabled" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    <span>Fonoaudiologia</span>
                </h5>
                <button type="button" class="close close-primary pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">x</span>
                </button>
            </div>
            <nav class="mb-4 px-3">
                <div class="nav nav-tabs" id="nav-tab-speech" role="tablist">
                    <a class="nav-item nav-link active" id="nav-speech-tab" data-toggle="tab" href="#nav-home-speech"
                       role="tab"
                       aria-controls="nav-home" aria-selected="true">Informações</a>
                    <a class="nav-item nav-link" id="nav-risk-speech-tab" data-toggle="tab" href="#nav-risk-level"
                       role="tab" aria-controls="nav-risk-speech-tab" aria-selected="false">Nivel de Risco</a>
                </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-home-speech" role="tabpanel"
                     aria-labelledby="nav-tab-speech">
                    <div class="modal-body">
                        <div class="row mx-0 align-items-center">
                            <div class="col-sm-12 col-lg-12 my-3">
                                <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                    <div class="input-material bg-gray mb-0 mx-3">
                                <textarea id="meal" class="bg-gray form-control" type="text"
                                          [(ngModel)]="model.anamnesis"
                                          maxlength="10000" autocomplete="off" name="meal" required>
                                </textarea>
                                        <label for="meal">Anámnese</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 my-3 col-md-6 col-lg-12">
                                <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                    <div class="input-material bg-gray mb-0 mx-3">
                                <textarea id="item" class="bg-gray form-control" type="text"
                                          [(ngModel)]="model.evaluation"
                                          maxlength="10000" autocomplete="off" name="item" required>
                                </textarea>
                                        <label for="item">Avaliação</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="rounded py-3 row mx-0 align-items-center">
                                    <div class="input-material mb-3 mx-3">
                                        <input id="evaluationDate" class="form-control js-daterangepickerEvaluation"
                                               type="text" maxlength="10000" autocomplete="off" required/>
                                        <label for="evaluationDate">Data da Ocorrência</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-8 mx-auto mt-4 px-0">
                                <a href="javascript:void(0)" class="btn btn-success btn-ico btn-block"
                                   *ngIf="!notAllowed"
                                   (click)="actionSave()">
                                    <svg-icon class="svg-white" src="assets/svg/check-circle.svg"></svg-icon>
                                    Salvar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="nav-risk-level" role="tabpanel" aria-labelledby="nav-risk-speech-tab">
                    <div class="mx-4 mb-3">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                <tr>
                                    <th class="align-middle w-10" scope="col"></th>
                                    <th class="align-middle w-90 text-center" scope="col">Descrição</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngIf="isNullOrUndefined(modelFois)">
                                    <td colspan="6">
                                        <p class="pt-5 pb-5 text-center">
                                            Nenhum registro encontrado.
                                        </p>
                                    </td>
                                </tr>
                                <ng-container *ngIf="model.id">
                                    <tr *ngFor="let fo of modelFois">
                                        <td class="text-center">
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="{{fo.id}}" name="retiredYes"
                                                       disabled class="custom-control-input" [checked]="model.riskLevel.level == fo.level">
                                                <label class="custom-control-label" for="{{fo.id}}"></label>
                                            </div>
                                        </td>
                                        <td>
                                            {{fo.fois}}
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-container *ngIf="isNullOrUndefined(model.id)">
                                    <tr *ngFor="let f of modelFois">
                                        <td class="text-center">
                                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                                <input type="radio" id="{{f.id}}" name="retiredYes"
                                                       class="custom-control-input"
                                                       (change)="riskLevel = f.id">
                                                <label class="custom-control-label" for="{{f.id}}"></label>
                                            </div>
                                        </td>
                                        <td>
                                            {{f.fois}}
                                        </td>
                                    </tr>
                                </ng-container>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-8 mx-auto mt-4 px-0">
                            <a href="javascript:void(0)" class="btn btn-success btn-ico btn-block"
                               (click)="actionSave()" *ngIf="isNullOrUndefined(model.id)">
                                <svg-icon class="svg-white" src="assets/svg/check-circle.svg"></svg-icon>
                                Salvar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
