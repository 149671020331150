export class Diet {
    id: string;
    meal: string;
    item: string;
    observation: string;
    amount: number;
    createdAt: Date;
    updatedAt: Date;

    constructor() {
    }
}
