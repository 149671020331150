import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {User} from '../model/user/user';

@Injectable({
    providedIn: 'root'
})
export class ProfileService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    get() {
        const url = `${this.BASE_URL}/profile`;
        return this.http.get(url);
    }

}
