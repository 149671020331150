export class Doctor {
    id: string;
    residentId: string;
    evaluation: string;
    evaluationTime: Date;
    obs: string;
    updatedAt: Date;
    createdAt: Date;
    createdBy: CreatedBy;

    constructor() {
    }
}

export class CreatedBy {
    id: string;
    name: string;
    email: string;
    photo: string;
    professionalCouncilCode: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    profile: Profile;
    userUnities: UserUnity[];
}

export class Profile {
    id: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;
}

export class UserUnity {
    id: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
}
