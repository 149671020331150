import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {User} from '../../model/user/user';
import {ResidentNutrition} from '../../model/resident/resident-nutrition';

@Injectable({
    providedIn: 'root'
})
export class NutritionService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    get(id) {
        const url = `${this.BASE_URL}/resident/${id}/nutrition`;
        return this.http.get(url);
    }

    create(residentId, model) {
        const url = `${this.BASE_URL}/resident/${residentId}/nutrition`;
        return this.http.post(url, model);
    }

    objectives() {
        const url = `${this.BASE_URL}/resident/nutrition-objectives`;
        return this.http.get(url);
    }

    update(residentId, model) {
        const url = `${this.BASE_URL}/resident/${residentId}/nutrition`;
        return this.http.put(url, model);
    }

    delete(id, idNutrition) {
        const url = `${this.BASE_URL}/resident/${id}/nutrition/${idNutrition}`;
        return this.http.delete(url);
    }

}
