import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../base/base.component';
import {Router} from '@angular/router';
import {PsychologyService} from '../../../../service/resident/psychology.service';
import {ResidentPsychologyEvaluation} from '../../../../model/resident/resident-psychology-evaluation';
import {User} from '../../../../model/user/user';
import {UserService} from '../../../../service/user.service';

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-psychology',
    templateUrl: './psychology.component.html',
    styleUrls: ['./psychology.component.scss']
})
export class PsychologyComponent extends BaseComponent implements OnInit, OnDestroy {

    residentId: string;
    modelList: ResidentPsychologyEvaluation[] = [];
    model: ResidentPsychologyEvaluation = new ResidentPsychologyEvaluation();
    modelUser: User = new User();
    isCaregiver = false;

    @Input() set resident(val: any) {
        this.residentId = val;
    }

    constructor(public router: Router,
                public userService: UserService,
                public psychologyService: PsychologyService) {
        super(router);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.modelUser = data as User;
                    // ID = PSYCHOLOGIST
                    if (this.modelUser.profile?.id !== 'a4e4f59a-5f21-47e1-9e95-2e6a74239595') {
                        $('#phychologyDetailDisabled input').prop('disabled', true);
                        $('#phychologyDetailDisabled select').prop('disabled', true);
                        $('#phychologyDetailDisabled textarea').prop('disabled', true);
                        $('#phychologyDetailDisabled label').addClass('disabled');
                        this.isCaregiver = true;
                    }
                }
            }
        });
        if (!this.isNullOrUndefined(this.residentId)) {
            this.actionDetail();
        }
    }

    ngOnDestroy(): void {
        const a = $('#psychologyModal');
        if (a[0]) {
            a.remove();
        }
    }

    actionDetail() {
        this.psychologyService.getEvaluation(this.residentId).subscribe({
            next: (data: ResidentPsychologyEvaluation[]) => {
                this.modelList = data as ResidentPsychologyEvaluation[];
            }, error: err => super.onError(err)
        });
    }

    actionSave() {
        this.model.residentId = this.residentId;
        const arrValidateFields = [
            {value: this.model.activity, text: `Atividade, `},
            {value: this.model.initiative, text: `Iniciativa, <br>`},
            {value: this.model.participant, text: `Participante, `},
            {value: this.model.modality, text: `Modalidade, <br>`},
        ];

        if (this.model.activity === 'cognitiveStimulation') {
            arrValidateFields.push({value: this.model.participation, text: `Participação, `});
        }


        // arrValidateFields.push({value: this.model.description, text: `Descrição.`});

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        if (this.isNullOrUndefined(this.model.id)) {
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    actionCreate() {
        this.psychologyService.createEvaluation(this.model).subscribe({
            next: (data: ResidentPsychologyEvaluation) => {
                this.model = new ResidentPsychologyEvaluation();
                this.actionDetail();
                $('#psychologyModal').modal('hide');
                super.showMessage('Sucesso', 'Psicológica cadastrada com sucesso!', 'success');
                this.actionDetail();
            }, error: err => super.onError(err)
        });
    }

    actionUpdate() {
        this.psychologyService.updateEvaluation(this.model).subscribe({
            next: (data: ResidentPsychologyEvaluation) => {
                this.model = new ResidentPsychologyEvaluation();
                this.actionDetail();
                $('#psychologyModal').modal('hide');
                super.showMessage('Sucesso', 'Psicológica atualizada com sucesso!', 'success');
            }, error: err => super.onError(err)
        });
    }

    setDatePickerModal() {
        $('.js-daterangepickerEvaluation').daterangepicker({
            showDropdowns: false,
            singleDatePicker: true,
            autoUpdateInput: false,
            locale: super.objLocaleDateRangePicker()
        }, (start, end, label) => {
            this.model.evaluationTime = start.utcOffset(0, true).format();
            $('.js-daterangepickerEvaluation').val(moment(this.model.evaluationTime).add(1, 'day').format('DD/MM/YYYY'));
        });
    }

    handlerRegister() {
        this.model = new ResidentPsychologyEvaluation();
        super.showModal('psychologyModal');
        this.model.evaluationTime = moment().utcOffset(0, true).format();
        $('.js-daterangepickerEvaluation').val(moment(this.model.evaluationTime).format('DD/MM/YYYY'));
        this.setDatePickerModal();
    }

    handlerDetail(model) {
        this.model = Object.assign({}, model);
        super.showModal('psychologyModal');
        this.actionDetail();
    }

    handlerTranslatorActivity(activity) {
        switch (activity) {
            case 'residentWelcome':
                return 'Acolhimento Residente';
            case 'familyWelcome':
                return 'Acolhimento Familiar';
            case 'cognitiveStimulation':
                return 'Estimulação Cognitiva';
        }
    }

    handlerTranslatorInitiative(initiative) {
        switch (initiative) {
            case 'resident':
                return 'Residente';
            case 'family':
                return 'Familiar';
            case 'institution':
                return 'Instituição';
        }
    }

    handlerTranslatorParticipant(participant) {
        switch (participant) {
            case 'resident':
                return 'Residente';
            case 'family':
                return 'Familiar';
        }
    }

    handlerTranslatorModality(modality) {
        switch (modality) {
            case 'individual':
                return 'Individual';
            case 'group':
                return 'Grupo';
        }
    }

    handlerTranslatorParticipation(participation) {
        switch (participation) {
            case 'participation0':
                return 'Presente';
            case 'participation1':
                return 'Não presente';
            case 'participation2':
                return 'Participou';
            case 'participation3':
                return 'Não Participou';
            case 'participation4':
                return 'Expressou-se verbalmente';
            case 'participation5':
                return 'Expressou-se verbalmente em relação à atividade';
            case 'participation6':
                return 'Expressou-se através de gestos';
            case 'participation7':
                return 'Expressou-se através de gestos em relação à atividade';
        }
    }

    handlerParticipantCheck(val) {
        if (!this.isNullOrUndefined(this.model.participant)) {
            return !this.isNullOrUndefined(this.model.participant.split('|').filter(x => x === val)[0]);
        }
    }

    handlerParticipant(val) {
        if (this.isNullOrUndefined(this.model.participant)) {
            this.model.participant = val;
            return;
        }
        const filter = this.model.participant.split('|').filter(x => x === val);
        if (this.isNullOrUndefined(filter)) {
            this.model.participant = this.model.participant + '|' + val;
        } else {
            const participant = this.model.participant;
            this.model.participant = null;
            participant.split('|').forEach(x => {
                if (x !== val) {
                    this.model.participant = !this.isNullOrUndefined(this.model.participant) ?
                        this.model.participant + '|' + x : x;
                }
            });
        }
    }

    handlerParticipationCheck(val) {
        if (!this.isNullOrUndefined(this.model.participation)) {
            return !this.isNullOrUndefined(this.model.participation.split('|').filter(x => x === val)[0]);
        }
    }

    handlerParticipation(val) {
        if (this.isNullOrUndefined(this.model.participation)) {
            this.model.participation = val;
            return;
        }
        const filter = this.model.participation.split('|').filter(x => x === val);
        if (this.isNullOrUndefined(filter)) {
            this.model.participation = this.model.participation + '|' + val;
        } else {
            const participation = this.model.participation;
            this.model.participation = null;
            participation.split('|').forEach(x => {
                if (x !== val) {
                    this.model.participation = !this.isNullOrUndefined(this.model.participation) ?
                        this.model.participation + '|' + x : x;
                }
            });
        }
    }

    delete(id) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar este item?', () => {
            this.psychologyService.deleteEvaluation(id).subscribe({
                next: data => {
                    super.showMessage('Sucesso', 'Item deletado com sucesso!', 'success');
                    this.actionDetail();
                }, error: err => super.onError(err)
            });
        });
    }
}

