import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../base/base.component';
import {Router} from '@angular/router';
import {Diet} from '../../../../model/diet/diet';
import {DietService} from '../../../../service/diet.service';
import {User} from '../../../../model/user/user';
import {UserService} from '../../../../service/user.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-diet',
  templateUrl: './diet.component.html',
  styleUrls: ['./diet.component.scss']
})
export class DietComponent extends BaseComponent implements OnInit, OnDestroy {

  residentId: string;
  model: Diet = new Diet();
  modelDiets: Diet[] = [];
  modelUser: User = new User();
  isCaregiver = false;

  @Input() set resident(val: any) {
    this.residentId = val;
  }

  constructor(public router: Router,
              public userService: UserService,
              public dietService: DietService) {
    super(router);
  }

  ngOnInit(): void {
    this.userService.user.subscribe({
      next: data => {
        if (!this.isNullOrUndefined(data.id)) {
          this.modelUser = data as User;
          // ID = NUTRITIONIST
          if (this.modelUser.profile?.id !== 'be55443d-79f0-4e97-83e1-9f2e00415d0b') {
            $('#dietDisabled input').prop('disabled', true);
            $('#dietDisabled textarea').prop('disabled', true);
            $('#dietDisabled select').prop('disabled', true);
            $('#dietDisabled label').addClass('disabled');
            this.isCaregiver = true;
          }
        }
      }
    });
    if (!this.isNullOrUndefined(this.residentId)) {
      this.actionDiet();
    }
  }

  ngOnDestroy(): void {
    const a = $('#registerDiet');
    if (a[0]) {
      a.remove();
    }
  }

  actionDiet() {
    this.dietService.get(this.residentId).subscribe({
      next: data => {
        this.modelDiets = data as Diet[];
      }, error: err => super.onError(err)
    });
  }

  actionSave() {
    const arrValidateFields = [
      {value: this.model.meal, text: 'Refeição, '},
      {value: this.model.item, text: 'Item, '},
      {value: this.model.amount, text: 'Quantidade.'},
    ];

    const stringError = this.validateField(arrValidateFields);

    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
      return;
    }

    if (this.isNullOrUndefined(this.model.id)) {
      this.actionCreate();
    } else {
      this.actionUpdate();
    }
  }

  actionCreate() {
    this.dietService.create(this.residentId, this.model).subscribe({
      next: (data: Diet) => {
        this.model = new Diet();
        $('#registerDiet').modal('hide');
        super.showMessage('Sucesso', 'Dieta cadastrada com sucesso!', 'success');
        this.actionDiet();
      }, error: err => super.onError(err)
    });
  }

  actionUpdate() {
    this.dietService.update(this.residentId, this.model).subscribe({
      next: (data: Diet) => {
        this.model = new Diet();
        $('#registerDiet').modal('hide');
        super.showMessage('Sucesso', 'Dieta atualizada com sucesso!', 'success');
        this.actionDiet();
      }, error: err => super.onError(err)
    });
  }

  handlerDetail(model) {
    this.model = Object.assign({}, model);
    $('#registerDiet').modal('show');
  }

  delete(id) {
    super.confirmMessage('Atenção', 'Deseja realmente deletar este dieta?', () => {
      this.dietService.delete(this.residentId, id).subscribe({
        next: data => {
          super.showMessage('Sucesso', 'Dieta deletado com sucesso!', 'success');
          this.actionDiet();
        }, error: err => super.onError(err)
      });
    });
  }

  handlerButton() {
    this.model = new Diet();
    $('#registerDiet').modal('show');
  }
}
