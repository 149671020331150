import {User} from './user';

export class ResultUser {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  totalPage: number;
  previousPage: number;
  nextPage: number;
  results: User[];

  constructor() {
  }
}
