<div class="card-body" id="physiotherapyDisabled">
    <div class="row mx-0">
        <div class="col-sm-12 col-lg-4">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Marcha?</p>
                </div>
                <div class="col-12 d-flex align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="march" name="march" class="custom-control-input"
                               [checked]="model.walk === 'yes'" (change)="model.walk = 'yes'">
                        <label class="custom-control-label" for="march">Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="noMarch" name="noMarch" class="custom-control-input"
                               [checked]="model.walk === 'no'" (change)="model.walk = 'no'">
                        <label class="custom-control-label" for="noMarch">Não</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-lg-8">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Faz uso de:</p>
                </div>
                <div class="col-12 d-flex align-items-center">
                    <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                        <input type="checkbox" id="chair" name="chair" class="custom-control-input"
                               [checked]="handlerAssistCheck('chair')"
                               (change)="handlerAssist('chair')">
                        <label class="custom-control-label" for="chair">Cadeira de Rodas</label>
                    </div>
                    <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                        <input type="checkbox" id="walker" name="walker" class="custom-control-input"
                               [checked]="handlerAssistCheck('walker')"
                               (change)="handlerAssist('walker')">
                        <label class="custom-control-label" for="walker">Andador</label>
                    </div>
                    <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                        <input type="checkbox" id="crutch" name="crutch" class="custom-control-input"
                               [checked]="handlerAssistCheck('crutch')"
                               (change)="handlerAssist('crutch')">
                        <label class="custom-control-label" for="crutch">Muletas</label>
                    </div>
                    <div class="custom-control  custom-checkbox custom-control-inline mb-0">
                        <input type="checkbox" id="walkingStick" name="walkingStick"
                               class="custom-control-input"
                               [checked]="handlerAssistCheck('walkingStick')"
                               (change)="handlerAssist('walkingStick')">
                        <label class="custom-control-label" for="walkingStick">Bengala</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Dor Articular:</p>
                </div>
                <div class="col-12 d-flex align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="artPain" name="artPain" class="custom-control-input"
                               [checked]="model.jointPain === 'yes'" (change)="model.jointPain = 'yes'">
                        <label class="custom-control-label" for="artPain">Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="noArtPain" name="noArtPain" class="custom-control-input"
                               [checked]="model.jointPain === 'no'" (change)="model.jointPain = 'no'">
                        <label class="custom-control-label" for="noArtPain">Não</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="discreetPain" name="discreetPain"
                               class="custom-control-input"
                               [checked]="model.jointPain === 'discreet'"
                               (change)="model.jointPain = 'discreet'">
                        <label class="custom-control-label" for="discreetPain">Discreta</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="limitingPain" name="limitingPain"
                               class="custom-control-input"
                               [checked]="model.jointPain === 'limiting'"
                               (change)="model.jointPain = 'limiting'">
                        <label class="custom-control-label" for="limitingPain">Limitante</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <div class="col-12">
                        <p class="text-gray-primary mb-0">Há Quanto Tempo?</p>
                    </div>
                    <div class="input-material bg-gray mb-0">
                        <input id="painTime" class="bg-gray form-control" type="text" maxlength="10000"
                               autocomplete="off"
                               [(ngModel)]="model.jointPainTime" required/>
                        <label for="painTime"></label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-
            ,0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Já praticou ou pratica alguma atividade Física?</p>
                </div>
                <div class="col-12 d-flex align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="atividadeFisica" name="atividadeFisica"
                               class="custom-control-input"
                               [checked]="model.didDoesPhysicalActivity === 'yes'"
                               (change)="model.didDoesPhysicalActivity = 'yes'">
                        <label class="custom-control-label" for="atividadeFisica">Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="noAtividadeFisica" name="noAtividadeFisica"
                               class="custom-control-input"
                               [checked]="model.didDoesPhysicalActivity === 'no'"
                               (change)="model.didDoesPhysicalActivity = 'no'">
                        <label class="custom-control-label" for="noAtividadeFisica">Não</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <div class="col-12">
                        <p class="text-gray-primary mb-0">Qual?</p>
                    </div>
                    <div class="input-material bg-gray mb-0">
                        <input id="whichAtividade" class="form-control bg-gray" type="text" maxlength="10000"
                               autocomplete="off"
                               [(ngModel)]="model.physicalActivityType" required/>
                        <label for="whichAtividade"></label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <div class="col-12">
                        <p class="text-gray-primary mb-0">Pratica a quanto Tempo?</p>
                    </div>
                    <div class="input-material bg-gray mb-0">
                        <input id="atividadeTime" class="form-control bg-gray" type="text" maxlength="10000"
                               autocomplete="off"
                               [(ngModel)]="model.physicalActivityTime" required/>
                        <label for="atividadeTime"></label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <div class="col-12">
                        <p class="text-gray-primary mb-0">Parou a quanto Tempo?</p>
                    </div>
                    <div class="input-material bg-gray mb-0">
                        <input id="atividadeStopTime" class="form-control bg-gray" type="text"
                               maxlength="10000"
                               autocomplete="off"
                               [(ngModel)]="model.physicalActivityStoppedSince" required/>
                        <label for="atividadeStopTime"></label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Já Realizou ou realiza fisioterapia?</p>
                </div>
                <div class="col-9 d-flex align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="fisioterapia" name="fisioterapia"
                               class="custom-control-input"
                               [checked]="model.didDoesPhysiotherapy === 'yes'"
                               (change)="model.didDoesPhysiotherapy = 'yes'">
                        <label class="custom-control-label" for="fisioterapia">Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="noFisioterapia" name="noFisioterapia"
                               class="custom-control-input"
                               [checked]="model.didDoesPhysiotherapy === 'no'"
                               (change)="model.didDoesPhysiotherapy = 'no'">
                        <label class="custom-control-label" for="noFisioterapia">Não</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <div class="col-12">
                        <p class="text-gray-primary mb-0">Tipo:</p>
                    </div>
                    <div class="input-material bg-gray mb-0">
                        <input id="fisioterapiaTipo" class="form-control bg-gray" type="text"
                               maxlength="10000"
                               autocomplete="off"
                               [(ngModel)]="model.physiotherapyType" required/>
                        <label for="fisioterapiaTipo"></label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12  col-lg-12">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Gosta ou gostava de fazer atividade Física?</p>
                </div>
                <div class="col-12 d-flex align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="likeAtividade" name="likeAtividade"
                               class="custom-control-input"
                               [checked]="model.likePhysicalActivity === 'yes'"
                               (change)="model.likePhysicalActivity = 'yes'">
                        <label class="custom-control-label" for="likeAtividade">Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="NoLikeAtividade" name="NoLikeAtividade"
                               class="custom-control-input"
                               [checked]="model.likePhysicalActivity === 'no'"
                               (change)="model.likePhysicalActivity = 'no'">
                        <label class="custom-control-label" for="NoLikeAtividade">Não</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary mb-0">Ja houve Quedas?</p>
                </div>
                <div class="col-12 d-flex align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="fall" name="fall" class="custom-control-input"
                               [checked]="model.falls === 'yes'" (change)="model.falls = 'yes'">
                        <label class="custom-control-label" for="fall">Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="noFall" name="noFall" class="custom-control-input"
                               [checked]="model.falls === 'no'" (change)="model.falls = 'no'">
                        <label class="custom-control-label" for="noFall">Não</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary mb-0">É Frequente?</p>
                </div>
                <div class="col-12 d-flex align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="frequentlyFall" name="frequentlyFall"
                               class="custom-control-input"
                               [checked]="model.fallFrequency === 'yes'"
                               (change)="model.fallFrequency = 'yes'">
                        <label class="custom-control-label" for="frequentlyFall">Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="noFrequentlyFall" name="noFrequentlyFall"
                               class="custom-control-input"
                               [checked]="model.fallFrequency === 'no'"
                               (change)="model.fallFrequency = 'no'">
                        <label class="custom-control-label" for="noFrequentlyFall">Não</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Já Realizou alguma cirurgia Ortopédica?</p>
                </div>
                <div class="col-12 d-flex align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="ortopedica" name="ortopedica" class="custom-control-input"
                               [checked]="model.orthopedicSurgery === 'yes'"
                               (change)="model.orthopedicSurgery = 'yes'">
                        <label class="custom-control-label" for="ortopedica">Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="noOrtopedica" name="noOrtopedica"
                               class="custom-control-input"
                               [checked]="model.orthopedicSurgery === 'no'"
                               (change)="model.orthopedicSurgery = 'no'">
                        <label class="custom-control-label" for="noOrtopedica">Não</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary">Possui Exames de Imagem?</p>
                </div>
                <div class="col-9 d-flex align-items-center">
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="examImage" name="examImage" class="custom-control-input"
                               [checked]="model.imageExam === 'yes'" (change)="model.imageExam = 'yes'">
                        <label class="custom-control-label" for="examImage">Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline mb-0">
                        <input type="radio" id="noExamImage" name="noExamImage" class="custom-control-input"
                               [checked]="model.imageExam === 'no'" (change)="model.imageExam = 'no'">
                        <label class="custom-control-label" for="noExamImage">Não</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary mb-0">Tipo da Cirurgia</p>
                </div>
                <div class="col-12 mt-2" *ngFor="let s of listSurgeries">
                    <div class="d-flex justify-content-between">
                        <p *ngIf="!isNullOrUndefined(s)">
                            {{s}}
                        </p>
                        <div>
                            <a href="javascript:void(0)" class="btn btn-danger btn-custom mb-1"
                               (click)="removeItemOnce(s)">
                                x
                            </a>
                        </div>
                    </div>
                </div>
                <div class="d-flex col-12">
                    <div class="input-material bg-gray mb-0 ">
                        <input id="ortopedicaTipo" class="form-control bg-gray" type="text" maxlength="10000"
                               autocomplete="off" [(ngModel)]="surgeries" required/>
                        <label for="ortopedicaTipo"></label>
                    </div>
                    <div>
                        <button [disabled]="!surgeries" href="javascript:void(0)"
                                class="btn btn-success btn-custom" (click)="add()">
                            +
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                <div class="col-12">
                    <p class="text-gray-primary mb-0">Em que ano ?</p>
                </div>
                <div class="col-12 mt-2" *ngFor="let t of surgeryTime">
                    <div class="d-flex justify-content-between">
                        <p *ngIf="!isNullOrUndefined(t)">
                            {{t}}
                        </p>
                        <div>
                            <a href="javascript:void(0)" class="btn btn-danger btn-custom mb-1" (click)="removeTime(t)">
                                x
                            </a>
                        </div>
                    </div>
                </div>
                <div class="d-flex col-12">
                    <div class="input-material bg-gray mb-0 ">
                        <input id="ortopedicaTime" class="form-control bg-gray" type="text" maxlength="10000"
                               autocomplete="off" [(ngModel)]="times" required mask="0000"/>
                        <label for="ortopedicaTime"></label>
                    </div>
                    <div>
                        <button [disabled]="isNullOrUndefined(times)" href="javascript:void(0)"
                                class="btn btn-success btn-custom" (click)="addTime()">
                            +
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xs-5 ml-auto mt-5" *ngIf="!isCaregiver">
        <a href="javascript:void(0)" class="btn btn-success btn-ico ml-2 btn-block" (click)="actionSave()">
            <svg-icon class="svg-white" src="assets/svg/check-circle.svg"></svg-icon>
            Salvar
        </a>
    </div>
</div>


