<div class="container">
    <!-- Outer Row -->
    <div class="row justify-content-center">
        <div class="col-xl-10 col-lg-12 col-md-9">
            <div class="card o-hidden border-0 shadow-lg my-5">
                <div class="card-body p-0">
                    <!-- Nested Row within Card Body -->
                    <div class="row">
                        <div class="col-lg-6 d-none d-lg-block">
                            <div class="text-center mt-5">
                                <img src="assets/images/logo.png" height="200" alt="login">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="p-5">
                                <div class="text-center">
                                    <h1 class="h4 text-gray-900 mb-2">Digite sua nova senha</h1>
                                    <p class="mb-4">Basta digitar sua nova senha abaixo e prosseguir com a navegação!</p>
                                </div>
                                <form class="user">
                                    <div class="input-material">
                                        <input id="password" name="password" class="form-control" type="password"
                                               required #pass/>
                                        <a href="javascript:void(0)" class="btn p-0 icon" (click)="handlerViewPassword('password')">
                                            <svg-icon [src]="!handlerViewPasswordIcon('password') ? 'assets/svg/password-open.svg' : 'assets/svg/password-closed.svg'"></svg-icon>
                                        </a>
                                        <label for="password">Senha</label>
                                    </div>
                                    <div class="input-material">
                                        <input id="confirmPassword" name="confirmPassword" class="form-control" type="password"
                                               required #confirmPass/>
                                        <a href="javascript:void(0)" class="btn p-0 icon" (click)="handlerViewPassword('confirmPassword')">
                                            <svg-icon [src]="!handlerViewPasswordIcon('confirmPassword') ? 'assets/svg/password-open.svg' : 'assets/svg/password-closed.svg'"></svg-icon>
                                        </a>
                                        <label for="confirmPassword">Senha</label>
                                    </div>
                                    <hr>
                                    <button type="submit" class="btn btn-primary btn-user btn-block"
                                            (click)="reset(pass.value, confirmPass.value)">
                                        Redefinir Senha
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

