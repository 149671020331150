<div class="mb-4 text-right" *ngIf="!isCaregiver">
    <a href="javascript:void(0)" class="btn btn-success btn-ico ml-2 btn-sm" (click)="handlerRegister()">
        <svg-icon class="svg-white" src="assets/svg/plus-circle.svg"></svg-icon>
        Cadastrar Nutrição
    </a>
</div>

<div class="table-responsive">
    <table class="table">
        <thead>
        <tr>
            <th class="align-middle" scope="col">Data de Ocorrência</th>
            <th class="align-middle" scope="col">Peso</th>
            <th class="align-middle text-center" scope="col">Altura</th>
            <th class="align-middle" scope="col">Anamnese</th>
            <th class="align-middle text-center" scope="col">Status Nutricionial</th>
            <th class="align-middle text-center" scope="col">Data</th>
            <th class="align-middle text-center" scope="col">Responsável</th>
            <th class="align-middle text-center" scope="col">Código de Conselho</th>
            <th class="align-middle text-center" scope="col">Data de Registro</th>
            <th class="align-middle text-center" scope="col">Opções</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="isNullOrUndefined(modelList)">
            <td colspan="9">
                <p class="pt-5 pb-5 text-center">
                    Nenhum registro encontrado.
                </p>
            </td>
        </tr>
        <tr *ngFor="let r of modelList" [ngClass]="{'bluer' : !this.isNullOrUndefined(r.updatedAt)}">
            <td>{{r.evaluationTime | date: 'dd/MM/YYYY'}}</td>
            <td>{{r.weight}}</td>
            <td class="text-center">{{r.height}}</td>
            <td>{{r.anamnesis}}</td>
            <td class="text-center">{{r.nutritionalStatus}}</td>
            <td class="text-center">
                <span *ngIf="!isNullOrUndefined(r.updatedAt)">{{r.updatedAt | date: 'dd/MM/yyyy'}}</span>
                <span *ngIf="isNullOrUndefined(r.updatedAt)">{{r.createdAt | date: 'dd/MM/yyyy'}}</span>
            </td>
            <td class="text-center"> {{!isNullOrUndefined(r.createdBy.name) ? r.createdBy.name : ''}}</td>
            <td class="text-center"> {{r.createdBy.professionalCouncilCode}}</td>
            <td class="text-center"> {{r.createdAt | date: 'dd/MM/yyyy'}}</td>
            <td class="text-center">
                <a href="javascript:void('detail')" class="btn" (click)="handlerDetail(r)">
                    <svg-icon class="ico-edit" src="assets/svg/eye.svg"></svg-icon>
                </a>

<!--                <a href="javascript:void('delete')" class="btn" (click)="delete(r.id)" *ngIf="!isCaregiver">-->
<!--                    <svg-icon class="ico-close" src="assets/svg/close.svg"></svg-icon>-->
<!--                </a>-->
            </td>
        </tr>
        </tbody>
    </table>
</div>

<div class="modal fade" id="nutritionModal" tabindex="-1" role="dialog" aria-labelledby="nutritionModal"
     aria-hidden="true">
    <div class="modal-dialog modal-75 modal-dialog-centered" id="nutritionDetailDisabled" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <div class="col-12">
                    <div class="row nav-header">
                        <h5 class="modal-title text-primary">
                            <span>Nutrição</span>
                        </h5>
                        <button type="button" class="close close-primary pt-0" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">x</span>
                        </button>
                    </div>
                    <div class="bg-gray pt-3">
                        <nav class="mb-4 px-3">
                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                <a class="nav-item nav-link active" id="nav-information-tab" data-toggle="tab"
                                   href="#nav-information" role="tab"
                                   aria-controls="nav-information" aria-selected="false">Informações</a>
                                <a class="nav-item nav-link " id="nav-objectives-tab" data-toggle="tab"
                                   href="#nav-objectives" role="tab"
                                   aria-controls="nav-objectives" aria-selected="true">Objetivos</a>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-information" role="tabpanel"
                     aria-labelledby="nav-information-tab">
                    <div class="modal-body">
                        <div class="row mx-0 align-items-center">
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="input-material">
                                    <input id="pesoAcomp" class="form-control" type="text" maxlength="10000"
                                           autocomplete="off" [(ngModel)]="model.weight" required
                                           (ngModelChange)="handlerIMC()"
                                           (ngModelChange)="handlerCAl()"/>
                                    <label for="pesoAcomp">Peso (Kg)</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="input-material">
                                    <input id="alturaAcomp" class="form-control" type="text" maxlength="10000"
                                           autocomplete="off" [(ngModel)]="model.height" required
                                           (ngModelChange)="handlerIMC()" [maxLength]="3" mask="0,00"/>
                                    <label for="alturaAcomp">Estatura (M)</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="aferido" name="aferido" class="custom-control-input"
                                           [checked]="model.collectionMethod === 'measured'"
                                           (change)="model.collectionMethod = 'measured'">
                                    <label class="custom-control-label" for="aferido">Aferido</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="estimado" name="estimado" class="custom-control-input"
                                           [checked]="model.collectionMethod === 'estimated'"
                                           (change)="model.collectionMethod = 'estimated'">
                                    <label class="custom-control-label" for="estimado">Estimado</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                                <div class="col-12">
                                    <p class="text-gray-primary mb-0">Anamnese</p>
                                </div>
                                <div class="col-12 d-flex align-items-center">
                                    <div class="input-material bg-gray mb-0">
                                        <textarea id="anemyTime" class="bg-gray form-control" type="text"
                                                  maxlength="10000" autocomplete="off" required
                                                  name="anemyTime" [(ngModel)]="model.anamnesis">
                                        </textarea>
                                        <label for="anemyTime"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-between px-3 mx-1 mb-2">
                            <div class="col-lg-4">
                                <div class="input-material py-2 mb-3">
                                    <input id="bmi" class=" form-control" type="text"
                                           maxlength="10000" autocomplete="off" required disabled
                                           name="bmi" [(ngModel)]="model.bmi" (ngModelChange)="model.bmi"/>
                                    <label class="disabled" for="bmi">IMC</label>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="input-material py-2 mb-3">
                                    <input id="cb" class=" form-control" type="text"
                                           maxlength="10000" autocomplete="off" required
                                           name="cb" [(ngModel)]="model.cb"/>
                                    <label for="cb">Circunferência do Braço</label>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="input-material py-2 mb-3">
                                    <input id="cp" class=" form-control" type="text"
                                           maxlength="10000" autocomplete="off" required
                                           name="cp" [(ngModel)]="model.cp"/>
                                    <label for="cp">Circunferência da Panturrilha</label>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="input-material py-2 mb-3">
                                    <input id="aj" class=" form-control" type="text"
                                           maxlength="10000" autocomplete="off" required
                                           name="aj" [(ngModel)]="model.aj"/>
                                    <label for="aj">Altura do Joelho</label>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="input-material py-2 mb-3">
                                    <input id="dcse" class=" form-control" type="text"
                                           maxlength="10000" autocomplete="off" required
                                           name="dcse" [(ngModel)]="model.dcse"/>
                                    <label for="dcse">Dobra Cutânea Subescapular</label>
                                </div>
                            </div>
                        </div>
                        <div class="row mx-1">
                            <div class="col-sm-12 col-md-12 col-lg-4">
                                <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                                    <div class="col-12">
                                        <div class="input-material bg-gray py-2 mb-3">
                                            <input id="energy" class="bg-gray form-control" type="text"
                                                   maxlength="10000" autocomplete="off" required
                                                   name="energy" [(ngModel)]="model.energyNeed"
                                                   (ngModelChange)="handlerCAl()"/>
                                            <label for="energy">Energia necessária</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-4">
                                <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                                    <div class="col-12">
                                        <div class="input-material bg-gray py-2 mb-3">
                                            <input id="cal" class="bg-gray form-control" disabled type="text"
                                                   maxlength="10000" autocomplete="off" required
                                                   name="cal" [(ngModel)]="model.calKg"/>
                                            <label class="disabled" for="cal">Calorias/kg</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-4">
                                <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                                    <div class="col-12">
                                        <div class="input-material bg-gray py-2 mb-3">
                                            <input id="nutriStatus" class="bg-gray form-control" type="text"
                                                   maxlength="10000" autocomplete="off" required
                                                   name="nutriStatus" [(ngModel)]="model.nutritionalStatus" disabled/>
                                            <label class="disabled" for="nutriStatus">Status Nutricional</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mx-1">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                                <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                                    <div class="col-12">
                                        <div class="input-material bg-gray py-2 mb-3">
                                            <input id="abdomnCircunference" class="bg-gray form-control" type="text"
                                                   maxlength="10000" autocomplete="off" required
                                                   name="abdomnCircunference"
                                                   [(ngModel)]="model.abdominalCircumference"/>
                                            <label for="abdomnCircunference">Circunferencia do Abdomen</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6">
                                <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                                    <div class="col-12">
                                        <div class="input-material bg-gray py-2 mb-3">
                                            <input id="hipCircunference" class="bg-gray form-control" type="text"
                                                   maxlength="10000" autocomplete="off" required
                                                   name="hipCircunference" [(ngModel)]="model.hipCircumference"/>
                                            <label for="hipCircunference">Circunferencia do Quadril</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 my-3 col-md-6 col-lg-12">
                                <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                                    <div class="bg-gray input-material mb-0 mx-3">
                                        <input id="evaluationDate"
                                               class="form-control bg-gray js-daterangepickerEvaluation"
                                               type="text" maxlength="10000" autocomplete="off" required/>
                                        <label for="evaluationDate">Data da Ocorrência</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-8 mx-auto mt-4 px-0" *ngIf="!isCaregiver">
                            <a href="javascript:void(0)" class="btn btn-success btn-ico btn-block"
                               (click)="actionSave()">
                                <svg-icon class="svg-white" src="assets/svg/check-circle.svg"></svg-icon>
                                Salvar
                            </a>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="nav-objectives" role="tabpanel" aria-labelledby="nav-objectives-tab">
                    <div class="mx-4 mb-3">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                <tr>
                                    <th class="align-middle w-10" scope="col"></th>
                                    <th class="align-middle w-90" scope="col">Descrição</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngIf="isNullOrUndefined(modelList)">
                                    <td colspan="6">
                                        <p class="pt-5 pb-5 text-center">
                                            Nenhum registro encontrado.
                                        </p>
                                    </td>
                                </tr>
                                <tr *ngFor="let o of modelObjectives">
                                    <td>
                                        <div class="text-center">
                                            <input class="custom-checkbox-nutrition" type="checkbox"
                                                   id="index" value="{{o.status}}" [(ngModel)]="o.status"
                                                   aria-label="...">
                                        </div>
                                    </td>
                                    <td>{{o.objective}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-8 mx-auto mt-4 px-0" *ngIf="!isCaregiver">
                            <a href="javascript:void(0)" class="btn btn-success btn-ico btn-block"
                               (click)="actionSave()">
                                <svg-icon class="svg-white" src="assets/svg/check-circle.svg"></svg-icon>
                                Salvar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
