import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../base/base.component';
import {ResidentPhysiotherapy} from '../../../../model/resident/resident-physiotherapy';
import {Router} from '@angular/router';
import {PhysiotherapyService} from '../../../../service/resident/physiotherapy.service';
import {ResidentPhysiotherapyEvaluation} from '../../../../model/resident/resident-physiotherapy-evaluation';
import {User} from '../../../../model/user/user';
import {UserService} from '../../../../service/user.service';

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-physiotherapy',
    templateUrl: './physiotherapy.component.html',
    styleUrls: ['./physiotherapy.component.scss']
})
export class PhysiotherapyComponent extends BaseComponent implements OnInit, OnDestroy {

    residentId: string;
    modelList: ResidentPhysiotherapyEvaluation[] = [];
    model: ResidentPhysiotherapyEvaluation = new ResidentPhysiotherapyEvaluation();
    isCaregiver = false;
    modelUser: User = new User();

    @Input() set resident(val: any) {
        this.residentId = val;
    }

    constructor(public router: Router,
                public physiotherapyService: PhysiotherapyService,
                public userService: UserService,
    ) {
        super(router);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id) && !this.isNullOrUndefined(this.residentId)) {
                    this.modelUser = data as User;
                    // ID = ADMIN + PHISIOTHERAPYST
                    if (this.modelUser.profile?.id !== '6c58a7e1-f79a-4089-a84d-00f658be82fa') {
                        $('#physiotherapyDetailDisabled input').prop('disabled', true);
                        $('#physiotherapyDetailDisabled select').prop('disabled', true);
                        $('#physiotherapyDetailDisabled textarea').prop('disabled', true);
                        $('#physiotherapyDetailDisabled label').addClass('disabled');
                        this.isCaregiver = true;
                        this.actionDetail();
                    }
                }
            }
        });
        if (!this.isNullOrUndefined(this.residentId)) {
            this.actionDetail();
        }
    }

    ngOnDestroy(): void {
        const a = $('#physiotherapyModal');
        if (a[0]) {
            a.remove();
        }
    }

    actionDetail() {
        this.physiotherapyService.getEvaluation(this.residentId).subscribe({
            next: (data: ResidentPhysiotherapyEvaluation[]) => {
                this.modelList = data as ResidentPhysiotherapyEvaluation[];
            }, error: err => super.onError(err)
        });
    }

    actionSave() {
        this.model.residentId = this.residentId;
        const arrValidateFields = [
            {value: this.model.anamnesis, text: `Anamnese, `},
            {value: this.model.bloodPressure, text: `P.A:, `},
            {value: this.model.heartRate, text: `Pulso:, `},
            {value: this.model.heartRateFc, text: `Frequencia Respiratória:, <br> `},
            {value: this.model.oxygenSaturation, text: `Saturação:, `},
            {value: this.model.physiotherapy, text: `Fisioterapia, `},
            {value: this.model.medicalOpinion, text: `Opinião Médica.`},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        if (this.isNullOrUndefined(this.model.id)) {
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    actionCreate() {
        this.physiotherapyService.createEvaluation(this.model).subscribe({
            next: (data: ResidentPhysiotherapyEvaluation) => {
                this.model = new ResidentPhysiotherapyEvaluation();
                $('#physiotherapyModal').modal('hide');
                super.showMessage('Sucesso', 'Fisioterapia cadastrada com sucesso!', 'success');
                this.actionDetail();
            }, error: err => super.onError(err)
        });
    }

    actionUpdate() {
        this.physiotherapyService.updateEvaluation(this.model).subscribe({
            next: (data: ResidentPhysiotherapyEvaluation) => {
                this.model = new ResidentPhysiotherapyEvaluation();
                $('#physiotherapyModal').modal('hide');
                super.showMessage('Sucesso', 'Fisioterapia atualizada com sucesso!', 'success');
                this.actionDetail();
            }, error: err => super.onError(err)
        });
    }

    handlerDetail(model) {
        this.model = Object.assign({}, model);
        super.showModal('physiotherapyModal');
    }

    delete(id) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar este item?', () => {
            this.physiotherapyService.deleteEvaluation(id).subscribe({
                next: data => {
                    super.showMessage('Sucesso', 'Item deletado com sucesso!', 'success');
                    this.actionDetail();
                }, error: err => super.onError(err)
            });
        });
    }

    setDatePickerModal() {
        $('.js-daterangepickerEvaluation').daterangepicker({
            showDropdowns: false,
            singleDatePicker: true,
            autoUpdateInput: false,
            locale: super.objLocaleDateRangePicker()
        }, (start, end, label) => {
            this.model.evaluationTime = start.utcOffset(0, true).format();
            $('.js-daterangepickerEvaluation').val(moment(this.model.evaluationTime).add(1, 'day').format('DD/MM/YYYY'));
        });
    }

    handlerRegister() {
        this.model = new ResidentPhysiotherapyEvaluation();
        super.showModal('physiotherapyModal');
        this.model.evaluationTime = moment().utcOffset(0, true).format();
        $('.js-daterangepickerEvaluation').val(moment(this.model.evaluationTime).format('DD/MM/YYYY'));
        this.setDatePickerModal();
    }
}

