import {User} from '../user/user';

export class Evolution {
    id: string;
    residentId: string;
    description: string;
    evaluationTime: Date;
    obs: string;
    updatedAt: Date;
    createdBy: User;
    createdAt: Date;
    deletedAt: Date;

    constructor() {
    }
}

