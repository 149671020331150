import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {ResultUnity} from '../../model/unity/result-unity';
import {FilterActivities} from '../../model/activities/filter-activities';
import {ResultActivities} from '../../model/activities/result-activities';
import {ActivityService} from '../../service/activity.service';
import {Activities} from '../../model/activities/activities';
import {Unity} from '../../model/unity/unity';
import {UnityService} from '../../service/unity.service';
import {ResidentService} from '../../service/resident/resident.service';
import {Resident} from '../../model/resident/resident';
import {User} from '../../model/user/user';
import {UserService} from '../../service/user.service';

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-activities',
    templateUrl: './activities.component.html',
    styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent extends BaseComponent implements OnInit, OnDestroy {

    modelFilter: FilterActivities = new FilterActivities();
    modelResult: ResultActivities = new ResultActivities();
    model: Activities = new Activities();

    selectedDay: string;
    currentPage = 1;
    listUnity: Unity[] = [];
    listResident: Resident[] = [];
    listResidentUnity: Resident[] = [];
    isAllowed = false;
    modelUser: User;

    hourStart: string;

    constructor(public route: Router,
                public activityService: ActivityService,
                public unityService: UnityService,
                public userService: UserService,
                public residentService: ResidentService) {
        super(route);
    }


    ngOnInit(): void {
        this.setDatePicker();
        this.allowRegister();
        this.actionUnity();
        this.actionResidents();
        this.actionSearch();
    }

    ngOnDestroy(): void {
        const a = $('#activitiesNewModal');
        if (a[0]) {
            a.remove();
        }
    }

    setDatePicker() {
        const data = moment().subtract(30, 'days');
        this.modelFilter.start = data.utcOffset(0, true).format();
        this.modelFilter.end = moment().utcOffset(0, true).format();
        $('.js-daterangepicker').daterangepicker({
            showDropdowns: false,
            startDate: data,
            locale: super.objLocaleDateRangePicker()
        }, (start, end, label) => {
            this.modelFilter.start = start.utcOffset(0, true).format();
            this.modelFilter.end = end.utcOffset(0, true).format();
        });
    }

    actionSearch() {
        this.activityService.search(this.modelFilter, this.currentPage).subscribe({
            next: data => {
                this.modelResult = data as ResultActivities;
            }, error: err => super.onError(err)
        });
    }

    actionUnity() {
        this.unityService.get().subscribe({
            next: data => {
                this.listUnity = data as Unity[];
            }, error: err => super.onError(err)
        });
    }

    actionResidents() {
        this.residentService.get().subscribe({
            next: data => {
                this.listResident = data as Resident[];
            }, error: err => super.onError(err)
        });
    }

    actionResidentUnity() {
        this.residentService.getUnityId(this.model.unity?.id).subscribe({
            next: data => {
                this.listResidentUnity = data as Resident[];
            }, error: err => super.onError(err)
        });
    }

    setDatePickerModal() {
        $('.js-daterangepickerStart').daterangepicker({
            showDropdowns: false,
            singleDatePicker: true,
            autoUpdateInput: false,
            locale: super.objLocaleDateRangePicker()
        }, (start, end, label) => {
            this.model.dateStart = start.utcOffset(0, true).format('yyyy-MM-DD');
            console.log(this.model.dateStart);
            $('.js-daterangepickerStart').val(start.utcOffset(0, true).format('DD/MM/yyyy'));
        });
        $('.js-daterangepickerEnd').daterangepicker({
            showDropdowns: false,
            autoUpdateInput: false,
            singleDatePicker: true,
            locale: super.objLocaleDateRangePicker()
        }, (start, end, label) => {
            this.model.dateEnd = start.utcOffset(0, true).format('yyyy-MM-DD');
            $('.js-daterangepickerEnd').val(start.utcOffset(0, true).format('DD/MM/yyyy'));
        });
    }

    handlerModalActivities(model) {
        this.hourStart = null;
        this.model = Object.assign({}, model);
        this.selectedDay = this.model.period;
        this.setDatePickerModal();
        if (!this.isNullOrUndefined(this.model.dateStart)) {
            $('.js-daterangepickerStart').val(moment(this.model.dateStart).format('DD/MM/YYYY'));
            this.hourStart = moment(this.model.dateStart).format('HH:mm');
            this.model.dateStart = this.model.dateStart.toString().replace(this.hourStart, '').replace('T:00', '').trim();
        }
        this.actionResidentUnity();
        this.showModal('activitiesNewModal');
    }

    handlerNewActivities() {
        this.hourStart = null;
        this.model = new Activities();
        this.selectedDay = null;
        this.model.dateStart = moment().utcOffset(0, true).format('yyyy-MM-DD');
        ;
        $('.js-daterangepickerStart').val(moment(this.model.dateStart).format('DD/MM/YYYY'));
        $('.js-daterangepickerEnd').val('');
        this.showModal('activitiesNewModal');
        this.setDatePickerModal();
    }

    handlerSelected() {
        switch (this.selectedDay) {
            case 'unique':
                return 'bg-noreplic';
            case 'week':
                return 'bg-seg';
            case 'everyday':
                return 'bg-diary';
            case 'weekends':
                return 'bg-weekend';
            default :
                return 'bg-noreplic';
        }
    }

    pageChanged(event) {
        this.currentPage = event;
        this.actionSearch();
    }

    getExcel() {
        const token = localStorage.getItem('token') as string;
        const start = moment(this.modelFilter.start).format('YYYY-MM-DD');
        const end = moment(this.modelFilter.end).format('YYYY-MM-DD');

        let url = `${this.getBaseURL()}/activity/search/excel?token=${token}`;

        if (!super.isNullOrUndefined(this.modelFilter.period)) {
            url += ('&period=' + this.modelFilter.period);
        }
        if (!super.isNullOrUndefined(this.modelFilter.activityName)) {
            url += ('&activityName=' + this.modelFilter.activityName);
        }
        if (!super.isNullOrUndefined(start)) {
            url += ('&start=' + start);
        }
        if (!super.isNullOrUndefined(end)) {
            url += ('&end=' + end);
        }
        window.open(encodeURI(url));
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.name, text: 'Nome da atividade*, '},
            {value: this.model.activityType, text: 'Tipo de atividade*, <br>'},
            {value: this.model.unity.id, text: 'Unidade*, '},
            {value: this.selectedDay, text: 'Periodicidade*,<br> '},
            {value: this.model.residents, text: 'Residentes.'},
            {value: this.hourStart, text: 'Horário.'},
            {value: this.model.dateStart, text: 'Início.'},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }
        this.model.period = this.selectedDay;
        if (this.isNullOrUndefined(this.model.dateEnd) && this.selectedDay !== 'unique') {
            return this.showMessage('Atenção', `Preencha a data final corretamente! <br><br> ${stringError}`, 'warning');
        }
        if (this.isNullOrUndefined(this.hourStart) || this.hourStart === '00:00') {
            return this.showMessage('Atenção', `Preencha o horário corretamente! <br><br> ${stringError}`, 'warning');
        }
        console.log(this.model.dateStart);
        if (!this.isNullOrUndefined(this.model.dateStart)) {
            this.model.dateStart = this.model.dateStart.toString() + ' ' + this.hourStart + ':00';
        }
        if (this.isNullOrUndefined(this.model.id)) {
            this.create();
        } else {
            this.update();
        }
    }

    create() {
        this.activityService.create(this.model).subscribe({
            next: data => {
                this.model = new Activities();
                this.actionSearch();
                $('#activitiesNewModal').modal('hide');
                super.showMessage('Sucesso', 'Atividade cadastrada com sucesso!', 'success');
            }, error: err => super.onError(err)
        });
    }

    update() {
        this.activityService.update(this.model).subscribe({
            next: data => {
                this.model = new Activities();
                this.actionSearch();
                $('#activitiesNewModal').modal('hide');
                super.showMessage('Sucesso', 'Atividade alterada com sucesso!', 'success');
            }, error: err => super.onError(err)
        });
    }

    delete(id) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar esta atividade?', () => {
            this.activityService.delete(id).subscribe({
                next: data => {
                    this.actionSearch();
                    $('#activitiesNewModal').modal('hide');
                    super.showMessage('Sucesso', 'Atividade deletada com sucesso!', 'success');
                }, error: err => super.onError(err)
            });
        });
    }

    onItemSelect(model) {
        this.listResident.forEach((x, index) => {
            if (x.id === model.id) {
                this.model.residents.push(x);
            }
        });
    }

    onItemDeSelect(model) {
        this.model.residents.forEach((x, index) => {
            if (x.id === model.id) {
                this.model.residents.splice(index, 1);
            }
        });
    }

    onSelectAll(items) {
        this.listResidentUnity.forEach((x, index) => {
            this.model.residents.forEach((y, i) => {
                if (x.id === y.id) {
                    this.model.residents.splice(i, 1);
                    this.model.residents.push(x);
                }
            });
        });
    }

    onClearModel(event: FocusEvent) {
        setTimeout(() => {
            const a = $(event.target).val();
            if (super.isNullOrUndefined(a)) {
                this.hourStart = null;
            }
        });
    }

    allowRegister() {
        // ID = ADMIN + UNIT DIRECTOR + NURSER
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.modelUser = data as User;
                    // ID = ADMIN + UNIT DIRECTOR + NURSER
                    if (this.modelUser.profile?.id !== '87115c40-8ac3-45c0-a70b-4f8b7ebba7bb' && this.modelUser.profile?.id !== 'cf17d929-9315-4fdf-90ad-4decd3045b64' && this.modelUser.profile?.id !== 'a5cf1872-07ed-413e-aa7c-fc9c6c05aa15') {
                        this.isAllowed = true;
                    }
                }
            }
        });
    }
}
