import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../../base/base.component';
import {ResidentPhysiotherapy} from '../../../../../model/resident/resident-physiotherapy';
import {Router} from '@angular/router';
import {PhysiotherapyService} from '../../../../../service/resident/physiotherapy.service';
import {User} from '../../../../../model/user/user';
import {UserService} from '../../../../../service/user.service';

declare var $: any;

@Component({
    selector: 'app-resident-physiotherapy',
    templateUrl: './resident-physiotherapy.component.html',
    styleUrls: ['./resident-physiotherapy.component.scss']
})
export class ResidentPhysiotherapyComponent extends BaseComponent implements OnInit, OnDestroy {

    residentId: string;
    modelList: ResidentPhysiotherapy[] = [];
    model: ResidentPhysiotherapy = new ResidentPhysiotherapy();
    isCaregiver = false;
    modelUser: User = new User();
    surgeries: string;
    times: string;
    listSurgeries = [];
    surgeryTime = [];

    @Input() set resident(val: any) {
        this.residentId = val;
    }

    constructor(public router: Router,
                public physiotherapyService: PhysiotherapyService,
                public userService: UserService,
    ) {
        super(router);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.modelUser = data as User;
                    // ADMIN + PHYSIOTHERAPIST + NURSE
                    if (this.modelUser.profile?.id !== '87115c40-8ac3-45c0-a70b-4f8b7ebba7bb' && this.modelUser.profile?.id !== '6c58a7e1-f79a-4089-a84d-00f658be82fa' && this.modelUser.profile?.id !== 'a5cf1872-07ed-413e-aa7c-fc9c6c05aa15' && this.modelUser.profile?.id !== 'cf17d929-9315-4fdf-90ad-4decd3045b64') {
                        $('#physiotherapyDisabled input').prop('disabled', true);
                        $('#physiotherapyDisabled select').prop('disabled', true);
                        $('#physiotherapyDisabled label').addClass('disabled');
                        this.isCaregiver = true;
                    }
                }
            }
        });
        if (!this.isNullOrUndefined(this.residentId)) {
            this.actionDetail();
        }
    }

    ngOnDestroy(): void {
        const a = $('#physiotherapyModal');
        if (a[0]) {
            a.remove();
        }
    }

    actionDetail() {
        this.physiotherapyService.get(this.residentId).subscribe({
            next: (data: ResidentPhysiotherapy) => {
                if (!this.isNullOrUndefined(data)) {
                    this.model = data as ResidentPhysiotherapy;
                    if (!this.isNullOrUndefined(this.listSurgeries)) {
                        this.listSurgeries = this.model.orthopedicSurgeryType;
                    }
                    if (!this.isNullOrUndefined(this.surgeryTime)) {
                        this.surgeryTime = this.model.orthopedicSurgeryTime;
                    }
                }
            }, error: err => super.onError(err)
        });
    }

    physioTraduction(val) {
        switch (val) {
            case 'chair' : {
                return 'Cadeira de Rodas';
            }
            case 'walker' : {
                return 'Andador';
            }
            case 'crutch' : {
                return 'Muletas';
            }
            case 'walkingStick' : {
                return 'Bengala';
            }
            default: {
                return 'Não necessita de Assistência';
            }
        }
    }

    jointPainTraduction(val) {
        switch (val) {
            case 'yes' : {
                return 'Sim';
            }
            case 'no' : {
                return 'Não';
            }
            case 'discreet' : {
                return 'Discreta';
            }
            case 'limiting' : {
                return 'Limitante';
            }
            default: {
                return '';
            }
        }
    }

    actionSave() {
        this.model.orthopedicSurgeryType = this.listSurgeries;
        this.model.orthopedicSurgeryTime = this.surgeryTime;
        const arrValidateFields = [
            {value: this.model.walk, text: `Marcha, `},
            {value: this.model.jointPain, text: `Dor Articular, `},
            {value: this.model.falls, text: `Queda, <br> `},
            {value: this.model.orthopedicSurgery, text: `Cirurgia, `},
            {value: this.model.didDoesPhysicalActivity, text: `Já praticou atividade fisica <br> `},
        ];
        this.model.orthopedicSurgeryType = this.listSurgeries;
        this.model.orthopedicSurgeryTime = this.surgeryTime;
        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }
        if (this.isNullOrUndefined(this.model.id)) {
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    actionCreate() {
        this.physiotherapyService.create(this.residentId, this.model).subscribe({
            next: (data: ResidentPhysiotherapy) => {
                this.model = new ResidentPhysiotherapy();
                $('#physiotherapyModal').modal('hide');
                super.showMessage('Sucesso', 'Detalhes sobre Fisioterapia cadastrados com sucesso!', 'success');
                this.actionDetail();
            }, error: err => super.onError(err)
        });
    }

    handlerAssistCheck(val) {
        if (!this.isNullOrUndefined(this.model.useAssist)) {
            return !this.isNullOrUndefined(this.model.useAssist.split('|').filter(x => x === val)[0]);
        }
    }

    handlerAssist(val) {
        if (this.isNullOrUndefined(this.model.useAssist)) {
            this.model.useAssist = val;
            return;
        }
        const filter = this.model.useAssist.split('|').filter(x => x === val);
        if (this.isNullOrUndefined(filter)) {
            this.model.useAssist = this.model.useAssist + '|' + val;
        } else {
            const useAssist = this.model.useAssist;
            this.model.useAssist = null;
            useAssist.split('|').forEach(x => {
                if (x !== val) {
                    this.model.useAssist = !this.isNullOrUndefined(this.model.useAssist) ?
                        this.model.useAssist + '|' + x : x;
                }
            });
        }
    }

    actionUpdate() {
        this.physiotherapyService.update(this.residentId, this.model).subscribe({
            next: (data: ResidentPhysiotherapy) => {
                this.model = new ResidentPhysiotherapy();
                $('#physiotherapyModal').modal('hide');
                super.showMessage('Sucesso', 'Detalhes sobre fisioterapia atualizados com sucesso!', 'success');
                this.actionDetail();
            }, error: err => super.onError(err)
        });
    }

    handlerDetail(model) {
        this.model = Object.assign({}, model);
        super.showModal('physiotherapyModal');
    }

    delete(id) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar os Detalhes sobre Fisioterapia?', () => {
            this.physiotherapyService.delete(this.residentId, id).subscribe({
                next: data => {
                    super.showMessage('Sucesso', 'Detalhes sobre Fisioterapia deletados com sucesso!', 'success');
                }, error: err => super.onError(err)
            });
        });
    }

    handlerRegister() {
        this.model = new ResidentPhysiotherapy();
        super.showModal('physiotherapyModal');
    }

    // ADICIONAR
    add() {
        this.listSurgeries.push(this.surgeries);
        console.log(this.listSurgeries);
        this.surgeries = null;
    }

    addTime() {
        this.surgeryTime.push(this.times);
        console.log(this.surgeryTime);
        this.times = null;
    }

    // REMOVER
    removeItemOnce(x) {
        const index = this.listSurgeries.indexOf(x);
        if (index > -1) {
            this.listSurgeries.splice(index, 1);
        }
        console.log(this.listSurgeries);
    }

    removeTime(x) {
        const index = this.surgeryTime.indexOf(x);
        if (index > -1) {
            this.surgeryTime.splice(index, 1);
        }
        console.log(this.surgeryTime);
    }
}
