import {Profile} from '../profile';

export class FilterUser {
  name: string;
  email: string;
  profile: Profile[];
  start: Date;
  end: Date;

  constructor() {
    this.profile = [];
  }
}
