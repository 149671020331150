import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../../model/user/user';

@Injectable({
    providedIn: 'root'
})
export class ResidentService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    get() {
        const url = `${this.BASE_URL}/resident`;
        return this.http.get(url);
    }

    search(filter, page) {
        const url = `${this.BASE_URL}/resident/search/${page}`;
        return this.http.post(url, filter);
    }

    getId(id) {
        const url = `${this.BASE_URL}/resident/${id}`;
        return this.http.get(url);
    }

    getUnityId(unityId) {
        const url = `${this.BASE_URL}/resident/search-simple/${unityId}`;
        return this.http.get(url);
    }

    create(model) {
        const url = `${this.BASE_URL}/resident`;
        return this.http.post(url, model);
    }

    update(model) {
        const url = `${this.BASE_URL}/resident`;
        return this.http.put(url, model);
    }

    updateRisk(id, risk) {
        const url = `${this.BASE_URL}/resident/${id}/change-risk/${risk}`;
        return this.http.put(url, null);
    }

    delete(id) {
        const url = `${this.BASE_URL}/resident/${id}`;
        return this.http.delete(url);
    }

    upload(file, id) {
        const url = `${this.BASE_URL}/upload/profile/${id}`;
        return new Observable((observer) => {
            // tslint:disable-next-line:one-variable-per-declaration
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();

            if (file != null) {
                formData.append('file', file, file.name);
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.upload.onprogress = (event) => {
                const progress = Math.round(event.loaded / event.total * 100);
            };

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.getToken());
            xhr.send(formData);
        });
    }
}
