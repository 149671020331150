import {Profile} from '../profile';
import {Unity} from '../unity/unity';

export class User {
    id: string;
    name: string;
    email: string;
    password: string;
    profile: Profile;
    profileId: string;
    initials: string;
    userUnities: Unity[];
    photo: string;
    status: string;
    createdAt: Date;
    updatedAt: Date;
    professionalCouncilCode: string;
    platform: string;

    constructor() {
        this.profile = new Profile();
    }
}
