import {Unity} from '../unity/unity';

export class ResultUnity {
    totalCount: number;
    pageSize: number;
    currentPage: number;
    totalPage: number;
    previousPage: number;
    nextPage: number;
    name: string;
    results: Unity[];

    constructor() {
    }
}
