<div class="mb-4 text-right"> <!--*ngIf="!isCaregiver"-->
    <a href="javascript:void(0)" class="btn btn-success btn-ico ml-2 btn-sm" (click)="handlerButton()">
        <svg-icon class="svg-white" src="assets/svg/plus-circle.svg"></svg-icon>
        Cadastrar Evolução
    </a>
</div>

<div class="card mt-3 bg-white mx-2 mb-4">
    <div class="card-body">
        <div class="row">
            <div class="col-md-12 col-lg-4 col-xxl-4">
                <div class="input-material">
                    <input id="date" class="form-control js-daterangepickerFilter" type="text" maxlength="10000"
                           autocomplete="off" required/>
                    <label for="date">Periodo</label>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4">
                <div class="input-material">
                    <select id="name" name="unity" [(ngModel)]="modelFilter.userId">
                        <option [ngValue]="undefined">Todos</option>
                        <option *ngFor="let u of listUsers" [ngValue]="u.id">{{u.name}}</option>
                    </select>
                    <label for="name">Nome</label>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4">
                <div class="input-material">
                    <select id="profile" name="profile" [(ngModel)]="modelFilter.profileId">
                        <option [ngValue]="undefined">Todos</option>
                        <option *ngFor="let u of listProfile" [ngValue]="u.id">{{handlerProfileArrayTraduction(u.id)}}</option>
                    </select>
                    <label for="profile">Perfil</label>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <div class="row">
            <div class="col-sm-12 text-right">
                <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0"
                   (click)="actionSearch()">
                    Buscar
                </a>
            </div>
        </div>
    </div>
</div>


<div class="row mx-0">
    <div class="table-responsive">
        <table class="table ">
            <thead>
            <tr>
                <th class="align-middle" scope="col">Data da Ocorrência</th>
                <th class="align-middle" scope="col">Hora</th>
                <th class="align-middle desc" scope="col">Descrição</th>
                <th class="align-middle text-center" scope="col">Responsável</th>
                <th class="align-middle text-center" scope="col">Função</th>
                <th class="align-middle text-center" scope="col">Código de Conselho</th>
                <th class="align-middle text-center" scope="col">Data de Registro</th>
                <th class="align-middle text-center" scope="col">Opções</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="isNullOrUndefined(modelResult.results)">
                <td colspan="6">
                    <p class="pt-5 pb-5 text-center">
                        Nenhum registro encontrado.
                    </p>
                </td>
            </tr>
            <tr *ngFor="let s of modelResult.results |
                                paginate: {
                                 itemsPerPage: modelResult.pageSize,
                                 currentPage: modelResult.currentPage,
                                 totalItems: modelResult.totalCount}; let i = index"
                [ngClass]="{'bluer' : !this.isNullOrUndefined(s.updatedAt)}">
                <td class="text-center">{{s.evaluationTime | date: 'dd/MM/YYYY'}}</td>
                <td>{{s.evaluationTime | date: 'HH:mm'}}</td>
                <td class="desc">{{s.description}}</td>
                <td>{{s.createdBy.name}}</td>
                <td>{{handlerProfileArrayTraduction(s.createdBy.profile.id)}}</td>
                <td>{{s.createdBy.professionalCouncilCode}}</td>
                <td>{{s.createdAt | date: 'dd/MM/YYYY'}}</td>
                <td class="text-center">
                    <a href="javascript:void('detail')" class="btn"
                       (click)="handlerDetail(s)">
                        <svg-icon class="ico-edit" src="assets/svg/eye.svg"></svg-icon>
                    </a>
<!--                    <a href="javascript:void('delete')" class="btn"-->
<!--                       (click)="delete(s.id)">-->
<!--                        <svg-icon class="ico-close" src="assets/svg/close.svg"></svg-icon>-->
<!--                    </a>-->
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="text-center">
    <pagination-controls
            [previousLabel]="labels.previousLabel"
            [nextLabel]="labels.nextLabel"
            [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
            [screenReaderPageLabel]="labels.screenReaderPageLabel"
            [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
            (pageChange)="pageChanged($event)"></pagination-controls>
</div>

<div class="modal fade" id="registerEvolution" tabindex="-1" role="dialog" aria-labelledby="registerEvolution"
     aria-hidden="true">
    <div class="modal-dialog modal-75 modal-dialog-centered" id="evolutionDisabled" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    <span>Ficha de Evolução</span>
                </h5>
                <button type="button" class="close close-primary pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">x</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row mx-0 align-items-center">
                    <div class="col-sm-12 col-lg-12 my-3">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="input-material bg-gray mx-3">
                                <textarea id="evolutionDesc" class="bg-gray form-control" type="text"
                                       [(ngModel)]="model.description"
                                       maxlength="10000" autocomplete="off" name="description" required>
                                </textarea>
                                <label for="evolutionDesc">Descrição</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 my-3 col-md-12 col-lg-12">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="bg-gray input-material mb-0 mx-3">
                                <input id="evolutionDate" class="form-control bg-gray js-daterangepickerEvolution"
                                       type="text" maxlength="10000" autocomplete="off" required/>
                                <label for="evolutionDate">Data da Ocorrência</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 mx-auto mt-4 px-0">
                        <a href="javascript:void(0)" class="btn btn-success btn-ico btn-block" *ngIf="!isNullOrUndefined(model.updatedAt) ? '' : 'd-none' " (click)="actionSave()">
                            <svg-icon class="svg-white" src="assets/svg/check-circle.svg"></svg-icon>
                            Salvar
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

