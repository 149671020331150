<nav id="sidebar">
  <ul class="list-unstyled components navbar-nav" id="accordionExample">
    <div class="user d-lg-none">
      <!--<div class="text-center w-100 mb-2">
        <img src="https://via.placeholder.com/30">
      </div>-->
      <span>Olá, <strong>{{modelUser.name}}</strong></span>
    </div>
    <li class="nav-item d-none" [class.active]="handlerActiveItem('/')" *ngIf="isAdmin">
      <a href="javascript:void('Nota');" [routerLink]="['/']" class="nav-link text-white"
         (click)="actionCloseCollapse()">
        <span class="ico"><svg-icon src="assets/svg/sidebar/home.svg"></svg-icon></span>
        <span class="lbl"> Home</span>
      </a>
    </li>
    <li class="nav-item" [class.active]="handlerActiveItem('/residents', '/')">
      <a href="javascript:void('Usuários');" [routerLink]="['/residents']" class="nav-link text-white"
         (click)="actionCloseCollapse()">
        <span class="ico"><svg-icon src="assets/svg/sidebar/residents.svg"></svg-icon></span>
        <span class="lbl">Residentes</span>
      </a>
    </li>
    <li class="nav-item" [class.active]="handlerActiveItem('/atividades')" *ngIf="allowActivity">
      <a href="javascript:void('Nota');" [routerLink]="['/atividades']" class="nav-link text-white"
         (click)="actionCloseCollapse()">
        <span class="ico"><svg-icon src="assets/svg/sidebar/activity.svg"></svg-icon></span>
        <span class="lbl">Atividades</span>
      </a>
    </li>
    <li class="nav-item" [class.active]="handlerActiveItem('/usuarios')" *ngIf="isAdmin">
      <a href="javascript:void('Nota');" [routerLink]="['/usuarios']" class="nav-link text-white"
         (click)="actionCloseCollapse()">
        <span class="ico"><svg-icon src="assets/svg/sidebar/user.svg"></svg-icon></span>
        <span class="lbl">Usuários</span>
      </a>
    </li>
    <li class="nav-item" [class.active]="handlerActiveItem('/unidades')" *ngIf="isAdmin">
      <a href="javascript:void('Nota');" [routerLink]="['/unidades']" class="nav-link text-white"
         (click)="actionCloseCollapse()">
        <span class="ico"><svg-icon src="assets/svg/sidebar/building.svg"></svg-icon></span>
        <span class="lbl">Unidades</span>
      </a>
    </li>
    <li class="nav-item d-none" [class.active]="handlerActiveItem('/logs')" *ngIf="isAdmin">
      <a href="javascript:void('Nota');" [routerLink]="['/logs']" class="nav-link text-white"
         (click)="actionCloseCollapse()">
        <span class="ico"><svg-icon src="assets/svg/sidebar/logs.svg"></svg-icon></span>
        <span class="lbl">Gestão de Logs</span>
      </a>
    </li>
  </ul>
  <div class="logout d-lg-none">
    <a href="javascript:void('sair');" [routerLink]="['/login']">
      Sair
    </a>
  </div>

</nav>
