import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../base/base.component';
import {Router} from '@angular/router';
import {ResidentNutrition} from '../../../../model/resident/resident-nutrition';
import {NutritionService} from '../../../../service/resident/nutrition.service';
import {ResidentPsychology} from '../../../../model/resident/resident-psychology';
import {User} from '../../../../model/user/user';
import {UserService} from '../../../../service/user.service';
import {Objectives} from '../../../../model/resident/objectives';

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-nutrition',
    templateUrl: './nutrition.component.html',
    styleUrls: ['./nutrition.component.scss']
})
export class NutritionComponent extends BaseComponent implements OnInit, OnDestroy {

    residentId: string;
    model: ResidentNutrition = new ResidentNutrition();
    modelList: ResidentNutrition[] = [];
    modelObjectives: Objectives[] = [];
    isCaregiver = false;
    modelUser: User = new User();

    @Input() set resident(val: any) {
        this.residentId = val;
    }

    constructor(public router: Router,
                public userService: UserService,
                public nutritionService: NutritionService) {
        super(router);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.modelUser = data as User;
                    // ID = NUTRITIONIST
                    if (this.modelUser.profile?.id !== 'be55443d-79f0-4e97-83e1-9f2e00415d0b') {
                        $('#nutritionDetailDisabled input').prop('disabled', true);
                        $('#nutritionDetailDisabled select').prop('disabled', true);
                        $('#nutritionDetailDisabled textarea').prop('disabled', true);
                        $('#nutritionDetailDisabled label').addClass('disabled');
                        this.isCaregiver = true;
                    }
                }
            }
        });
        if (!this.isNullOrUndefined(this.residentId)) {
            this.actionNutrition();
            this.actionGetObjectives();
        }
    }

    ngOnDestroy(): void {
        const a = $('#nutritionModal');
        if (a[0]) {
            a.remove();
        }
    }

    actionNutrition() {
        this.nutritionService.get(this.residentId).subscribe({
            next: data => {
                this.modelList = data as ResidentNutrition[];
            }, error: err => super.onError(err)
        });
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.weight, text: `Peso, `},
            {value: this.model.height, text: `Altura, <br> `},
            {value: this.model.anamnesis, text: `Anamnese.`},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }
        this.model.objectives = [];
        console.log(this.modelObjectives);
        this.modelObjectives.forEach((x, index) => {
            console.log(x);
            if (x.status === true) {
                this.model.objectives.push(x);
            }
        });

        if (this.isNullOrUndefined(this.model.id)) {
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    actionCreate() {
        console.log(this.model);
        this.nutritionService.create(this.residentId, this.model).subscribe({
            next: (data: ResidentNutrition) => {
                this.model = new ResidentNutrition();
                $('#nutritionModal').modal('hide');
                super.showMessage('Sucesso', 'Informação nutricional cadastrada com sucesso!', 'success');
                this.actionNutrition();
            }, error: err => super.onError(err)
        });
    }

    actionObjectives() {

    }

    actionGetObjectives() {
        this.nutritionService.objectives().subscribe({
            next: data => {
                this.modelObjectives = data as Objectives[];
                console.log(this.modelObjectives);
            }, error: err => super.onError(err)
        });
    }

    actionUpdate() {
        this.nutritionService.update(this.residentId, this.model).subscribe({
            next: (data: ResidentNutrition) => {
                this.model = new ResidentNutrition();
                $('#nutritionModal').modal('hide');
                super.showMessage('Sucesso', 'Informação nutricional atualizada com sucesso!', 'success');
                this.actionNutrition();
            }, error: err => super.onError(err)
        });
    }

    handlerRegister() {
        this.model = new ResidentNutrition();
        super.showModal('nutritionModal');
        this.model.evaluationTime = moment().utcOffset(0, true).format();
        $('.js-daterangepickerEvaluation').val(moment(this.model.evaluationTime).format('DD/MM/YYYY'));
        this.setDatePickerModal();
    }

    handlerDetail(model) {
        this.model = Object.assign({}, model);
        super.showModal('nutritionModal');
    }

    delete(id) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar esta Informação nutricional?', () => {
            this.nutritionService.delete(this.residentId, id).subscribe({
                next: data => {
                    this.actionNutrition();
                    super.showMessage('Sucesso', 'Informação nutricional deletada com sucesso!', 'success');
                }, error: err => super.onError(err)
            });
        });
    }

    setDatePickerModal() {
        $('.js-daterangepickerEvaluation').daterangepicker({
            showDropdowns: false,
            singleDatePicker: true,
            autoUpdateInput: false,
            locale: super.objLocaleDateRangePicker()
        }, (start, end, label) => {
            this.model.evaluationTime = start.utcOffset(0, true).format();
            $('.js-daterangepickerEvaluation').val(moment(this.model.evaluationTime).add(1, 'day').format('DD/MM/YYYY'));
        });
    }

    handlerButton() {
        this.model = new ResidentNutrition();
    }

    handlerIMC() {
        if (this.model.weight > 1 && this.model.height > 1) {
            // @ts-ignore
            // tslint:disable-next-line:max-line-length
            this.model.bmi = (this.model.weight / ((this.model.height / 100) * (this.model.height / 100))).toFixed(2);
            if (this.model.bmi < 22.0) {
                this.model.nutritionalStatus = 'Magreza';
            } else if (this.model.bmi >= 22.0 && this.model.bmi <= 27.0) {
                this.model.nutritionalStatus = 'Eutrofia';
            } else if (this.model.bmi > 27) {
                this.model.nutritionalStatus = 'Sobrepeso';
            }
        }
    }


    handlerCAl() {
        if (this.model.weight > 0 && this.model.energyNeed > 0) {
            this.model.calKg = this.model.energyNeed / this.model.weight;
        }
    }
}

