export class ResidentPsychology {
    id: string;
    scholarity: string;
    profession: string;
    retired: string;
    reading: string;
    readingType: string;
    song: string;
    songType: string;
    politics: string;
    politicsType: string;
    pets: string;
    petsName: string;
    plants: string;
    plantsType: string;
    games: string;
    gamesType: string;
    tv: string;
    favoriteChannel: string;
    hobby: string;
    pastHobbies: string;
    currentHobbies: string;
    travel: string;
    bestTravel: string;
    talk: string;
    pastHumor: string;
    presentHumor: string;
    pastAggressive: string;
    presentAggressive: string;
    pastAddictions: string;
    currentAddictions: string;
    depressionPast: string;
    depressionPresent: string;
    didTherapyPast: string;
    therapyPresent: string;
    relationshipPast: string;
    relationshipPresent: string;
    seniorResponsibleRelation: string;
    responsibleVisitPretension: string;
    requestedFamily: string;
    createdAt: Date;
    updatedAt: Date;

    constructor() {
    }
}
