import {Unity} from '../unity/unity';

export class FilterResident {
    keyword: string;
    unity: Unity[];
    start: Date;
    end: Date;
    status: string;

    constructor() {
        this.unity = [];
        this.status = 'active';
    }
}
