import DateTimeFormat = Intl.DateTimeFormat;

export class ResidentMedicineTable {
    period: string;
    items: Item[];
}

export class CreateResidentMedicineTableItem {
    date: Date;
    medicineId: string;
    residentId: string;
}

export class Item {
    id: string;
    name: string;
    place: string;
    medicineTime: string;
    timeOfTheDay: string;
    amount: string;
    macerate: boolean;
    administration: string;
    period: string;
    days: Days[];
}

export class Days {
    status: boolean;
    initials: string;
    day: number;
}
