<div class="mb-4 text-right" *ngIf="!isCaregiver">
    <a href="javascript:void(0)" class="btn btn-success btn-ico ml-2 btn-sm" (click)="handlerButton()">
        <svg-icon class="svg-white" src="assets/svg/plus-circle.svg"></svg-icon>
        Cadastrar Evolução
    </a>
</div>

<div class="row mx-0">
    <div class="table-responsive">
        <table class="table ">
            <thead>
            <tr>
                <th class="align-middle" scope="col">Data da Ocorrência</th>
                <th class="align-middle" scope="col">Avaliação</th>
                <th class="align-middle text-center" scope="col">Observação</th>
                <th class="align-middle text-center" scope="col">Responsável</th>
                <th class="align-middle text-center" scope="col">Código de Conselho</th>
                <th class="align-middle text-center" scope="col">Data de Registro</th>
                <th class="align-middle text-center" scope="col">Opções</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="isNullOrUndefined(modelDoctors)">
                <td colspan="6">
                    <p class="pt-5 pb-5 text-center">
                        Nenhum registro encontrado.
                    </p>
                </td>
            </tr>
            <tr *ngFor="let s of modelDoctors" [ngClass]="{'bluer' : !this.isNullOrUndefined(s.updatedAt)}">
                <td>{{s.evaluationTime | date:'dd/MM/YYYY'}}</td>
                <td>{{s.evaluation}}</td>
                <td>{{s.obs}}</td>
                <td>{{s.createdBy.name}}</td>
                <td>{{s.createdBy.professionalCouncilCode}}</td>
                <td>{{s.createdAt | date:'dd/MM/yyyy'}}</td>
                <td class="text-center">
                    <a href="javascript:void('detail')" class="btn"
                       (click)="handlerDetail(s)">
                        <svg-icon class="ico-edit" src="assets/svg/eye.svg"></svg-icon>
                    </a>

<!--                    <a href="javascript:void('delete')" *ngIf="!isCaregiver" class="btn"-->
<!--                       (click)="delete(s.id)">-->
<!--                        <svg-icon class="ico-close" src="assets/svg/close.svg"></svg-icon>-->
<!--                    </a>-->
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="modal fade" id="registerDoctor" tabindex="-1" role="dialog" aria-labelledby="registerDoctor"
     aria-hidden="true">
    <div class="modal-dialog modal-75 modal-dialog-centered" id="doctorDisabled" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    <span>Avaliação Médica</span>
                </h5>
                <button type="button" class="close close-primary pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">x</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row mx-0 align-items-center">
                    <div class="col-sm-12 col-lg-12 my-3">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="input-material bg-gray mb-0 mx-3">
                                <textarea id="meal" class="bg-gray form-control" type="text"
                                          [(ngModel)]="model.evaluation"
                                          maxlength="10000" autocomplete="off" name="meal" required>
                                </textarea>
                                <label for="meal">Avaliação</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 my-3 col-md-6 col-lg-12">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="input-material bg-gray mb-0 mx-3">
                                <textarea id="item" class="bg-gray form-control" type="text"
                                          [(ngModel)]="model.obs"
                                          maxlength="10000" autocomplete="off" name="item" required>
                                </textarea>
                                <label for="item">Observação</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 my-3 col-md-6 col-lg-12">
                        <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                            <div class="bg-gray input-material mb-0 mx-3">
                                <input id="evaluationDate" class="form-control bg-gray js-daterangepickerEvaluation"
                                       type="text" maxlength="10000" autocomplete="off" required/>
                                <label for="evaluationDate">Data da Ocorrência</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 mx-auto mt-4 px-0">
                        <a href="javascript:void(0)" class="btn btn-success btn-ico btn-block" *ngIf="!isCaregiver"
                           (click)="actionSave()">
                            <svg-icon class="svg-white" src="assets/svg/check-circle.svg"></svg-icon>
                            Salvar
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
