import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';

declare var moment: any;
declare var $: any;

export class BaseComponent {

    labels: any = {
        previousLabel: ' ',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };
    listStatusResident = [
        {id: 'active', name: 'Ativo'},
        {id: 'inactive', name: 'Inativo '},
        {id: 'termination', name: 'Rescisão'},
        {id: 'death', name: 'Óbito'}
    ];
    listTimeofTheDay = [
        {id: 'fasting', name: 'Jejum'},
        {id: 'after_breakfast', name: 'Após Café da Manhã '},
        {id: 'before_lunch', name: 'Antes do Almoço'},
        {id: 'lunch', name: 'Almoço'},
        {id: 'after_lunch', name: 'Após o Almoço'},
        {id: 'before_dinner', name: 'Antes do Jantar'},
        {id: 'after_dinner', name: 'Após o Jantar'},
        {id: 'after_supper', name: 'Após a Ceia'},
    ];
    dropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Selecionar Todos',
        unSelectAllText: 'Desmarcar Todos',
        itemsShowLimit: 3,
    };

    constructor(public router: Router) {
    }

    setToken(token: string) {
        localStorage.setItem('token', token);
    }

    getBaseURL() {
        return environment.base_url;
    }

    showModal(id) {
        $('#' + id).modal('show');
    }

    closeModal(id) {
        $('#' + id).modal('hide');
    }

    showMessage(t: string, m: string, type: any = null) {
        Swal.fire({
            title: t,
            html: m,
            icon: type,
            confirmButtonColor: '#546e7a',
        });
    }

    confirmMessage(t: string, m: string, callback: any = null, noCheck: any = null) {
        Swal.fire({
            title: t,
            text: m,
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            confirmButtonColor: '#546e7a',
            cancelButtonColor: '#dc3545'
        }).then((result) => {
            if (!this.isNullOrUndefined(result.value) && result.value === true) {
                if (callback != null) {
                    callback();
                }
            } else {
                if (noCheck != null) {
                    noCheck();
                }
            }
        });
    }

    onError(error) {
        if (error.status === 401) {
            this.router.navigate(['/login']);
            return;
        }

        // const e = JSON.parse(error['error']);

        if (error.status === 400) {
            console.log(error);
            this.showMessage('Atenção', error.error.message, 'warning');
            return;
        }

        if (error.status === 500) {
            this.showMessage('Atenção', 'Ocorreu um erro.', 'warning');
            return;
        }
    }

    isNullOrUndefined(value) {
        return typeof value === 'undefined' || value == null || value.length === 0;
    }

    validateField(obj) {
        let strError = ``;
        obj.map((e) => {
            if (this.isNullOrUndefined(e.value)) {
                strError += `${e.text}`;
            }
        });
        return strError;
    }

    setDate(id: string, callback, startDate?, timepicker?, maxDate?, format = 'DD/MM/YYYY') {
        $('.' + id).daterangepicker({
            timePicker: timepicker ? true : false,
            timePicker24Hour: timepicker ? true : false,
            startDate: startDate ? moment(startDate).utc() : moment(),
            singleDatePicker: true,
            maxDate,
            cancelButtonClasses: 'btn-danger',
            locale: {
                format,
                customRangeLabel: 'Personalizar',
                separator: ' - ',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                monthNames: this.getMonthNames(),
                daysOfWeek: this.getDaysOfDayWeek(),
                firstDay: 1
            }
        }, callback);
    }

    getMonthNames() {
        return [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro'
        ];
    }

    getDaysOfDayWeek() {
        return [
            'Dom',
            'Seg',
            'Ter',
            'Qua',
            'Qui',
            'Sex',
            'Sab'
        ];
    }

    isEMailValid(strEmail) {
        const str = strEmail;
        const exp = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
        const testResult = exp.test(str);
        if (!testResult) {
            this.showMessage('Atenção', 'Informe um e-mail válido para prosseguir.', 'warning');
        }
        return testResult;
    }

    handlerViewPassword(type: string = 'password') {
        const input = document.querySelector('#' + type);
        if (input.getAttribute('type') === 'password') {
            input.setAttribute('type', 'text');
        } else {
            input.setAttribute('type', 'password');
        }
    }

    handlerViewPasswordIcon(type: string = 'password') {
        const input = document.querySelector('#' + type);
        return input.getAttribute('type') === 'password';
    }

    dateOfBirthDateValidate(p: any) {
        // p = (p instanceof Date) ? p : moment(p, 'DD/MM/yyyy').format('DD/MM/yyyy');
        if (p.indexOf('/') >= 0 || p.indexOf('-') >= 0) {
            p = moment(p, 'DD/MM/yyyy').format('DD/MM/yyyy');
        } else if (!this.isNullOrUndefined(p) && p.indexOf('/') < 0) {
            p = moment(p).format('DD/MM/yyyy');
        }
        const dateFormatMin = moment().subtract(18, 'year');
        const dateFormatMax = moment().subtract(120, 'year');
        let valReturn = true;
        if (this.isNullOrUndefined(p) || p === 'Invalid date') {
            this.showMessage('Atenção', 'Preencha a data de nascimento corretamente.', 'warning');
            valReturn = false;
        } else if (!moment(p, 'DD/MM/YYYY', true).isValid()) {
            this.showMessage('Atenção', 'Preencha a data de nascimento corretamente.', 'warning');
            valReturn = false;
        } else if (moment(p, 'DD/MM/YYYY') > dateFormatMin) {
            this.showMessage('Atenção', 'Não é permitido cadastro de menores de 18 anos.', 'warning');
            valReturn = false;
        } else if (moment(p, 'DD/MM/YYYY') < dateFormatMax) {
            this.showMessage('Atenção', 'Não é permitido cadastro de maiores de 120 anos.', 'warning');
            valReturn = false;
        }
        return valReturn;
    }

    handlerAge(birthday) {
        if (!this.isNullOrUndefined(birthday)) {
            const years = moment().diff(moment(birthday, 'DD-MM-YYYY'), 'years');
            return years;
        }
    }

    objLocaleDateRangePicker() {
        return {
            customRangeLabel: 'Personalizar',
            format: 'DD/MM/YYYY',
            separator: ' - ',
            applyLabel: 'Aplicar',
            cancelLabel: 'Cancelar',
            daysOfWeek: [
                'Dom',
                'Seg',
                'Ter',
                'Qua',
                'Qui',
                'Sex',
                'Sab'
            ],
            monthNames: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro'
            ],
            firstDay: 1
        };
    }

    booleanTraduction(val) {
        switch (val) {
            case 'yes' :
                return 'Sim';
            case 'no' :
                return 'Não';
        }
    }

    toggleLoader(b: boolean) {
        if (!b) {
            $('#loaderBox').fadeOut();
        } else {
            $('#loaderBox').fadeIn();
        }
    }

}
