import {CreatedBy} from './resident-nutrition';

export class ResidentHealthEvolution {
    id: string;
    resume: string;
    observation: string;
    temperature: number;
    bloodPressure: number;
    evaluationTime: Date;
    bloodGlucose: number;
    residentId: string;
    createdAt: Date;
    updatedAt: Date;
    createdByNavigation: CreatedByNavigation;
    constructor() {
    }
}

export class CreatedByNavigation {
    id: string;
    name: string;
    email: string;
    photo: string;
    professionalCouncilCode: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    profile: Profile;
    userUnities: UserUnity[];
}

export class Profile {
    id: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;
}

export class UserUnity {
    id: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
}
