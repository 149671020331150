<div class="card-body">
    <div class="mb-4 text-right" *ngIf="!isCaregiverOrTech">
        <a href="javascript:void(0)" class="btn btn-success btn-ico ml-2 btn-sm" (click)="handlerRegister()">
            <svg-icon class="svg-white" src="assets/svg/plus-circle.svg"></svg-icon>
            Cadastrar Responsável
        </a>
    </div>

    <div class="table-responsive">
        <table class="table ">
            <thead>
            <tr>
                <th class="align-middle" scope="col">Nome</th>
                <th class="align-middle text-center" scope="col">E-mail</th>
                <th class="align-middle text-center" scope="col">Telefone</th>
                <th class="align-middle text-center" scope="col">Grau de Parentesco</th>
                <th class="align-middle text-center" scope="col">Data Nascimento</th>
                <th class="align-middle text-center" scope="col">Opções</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="isNullOrUndefined(listResponsible)">
                <td colspan="5">
                    <p class="pt-5 pb-5 text-center">
                        Nenhum registro encontrado.
                    </p>
                </td>
            </tr>
            <tr *ngFor="let r of listResponsible">
                <td>{{r.name}}</td>
                <td class="text-center">{{r.email}}</td>
                <td class="text-center">{{r.phoneNumber | mask: '(00) 0000-0000||(00) 0 0000-0000'}}</td>
                <td class="text-center">{{kinshipTraduction(r.degreeOfKinship)}}</td>
                <td class="text-center">{{r.birthday | date: 'dd/MM/yyyy'}}</td>
                <td class="text-center">
                    <a href="javascript:void('detail')" class="btn" (click)="handlerDetail(r)">
                        <svg-icon class="ico-edit" src="assets/svg/eye.svg"></svg-icon>
                    </a>

                    <a href="javascript:void('delete')" class="btn" *ngIf="!isCaregiverOrTech" (click)="delete(r.id)">
                        <svg-icon class="ico-close" src="assets/svg/close.svg"></svg-icon>
                    </a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="modal fade" id="responsibleModal" tabindex="-1" role="dialog" aria-labelledby="responsibleModal"
     aria-hidden="true">
    <div class="modal-dialog modal-75 modal-dialog-centered" id="responsibleDisabled" role="document">
        <div class="modal-content p-0">
            <div class="modal-header">
                <h5 class="modal-title text-primary">
                    <span>Responsável</span>
                </h5>
                <button type="button" class="close close-primary pt-0" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">x</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row align-items-center">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="input-material">
                            <input id="nameRelative" name="nameRelative" class="form-control" type="text"
                                   maxlength="10000" autocomplete="off" [(ngModel)]="model.name" required/>
                            <label for="nameRelative">Nome</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                        <div class="input-material">
                            <input id="cpf" name="cpf" class="form-control" type="text" mask="000.000.000-00"
                                   maxlength="10000" autocomplete="off" [(ngModel)]="model.cpf" required/>
                            <label for="cpf">CPF</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-8 col-lg-4">
                        <div class="input-material">
                            <input id="birthdayRelative" class="form-control" type="text" maxlength="10000"
                                   autocomplete="off" mask="00/00/0000" [dropSpecialCharacters]="false"
                                   [(ngModel)]="model.birthday" name="birthday" required/>
                            <label for="birthdayRelative">Data de Nascimento</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-2">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="customSwitch3"
                                   [checked]="model.isCurator === true"
                                   (change)="handlerCurator(model.isCurator)">
                            <label class="custom-control-label font-weight-bold" for="customSwitch3">Curador</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                            <div class="col-12">
                                <p class="text-gray-primary">Grau de Parentesco</p>
                            </div>
                            <div class="col-12 row mx-0 align-items-center">
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="son" name="son" class="custom-control-input"
                                           [checked]="handlerResponsibleCheck('son')"
                                           (change)="handlerResponsible('son')">
                                    <label class="custom-control-label" for="son">Filho</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="spouse" name="spouse" class="custom-control-input"
                                           [checked]="handlerResponsibleCheck('spouse')"
                                           (change)="handlerResponsible('spouse')">
                                    <label class="custom-control-label" for="spouse">Cônjuge</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="brother" name="brother" class="custom-control-input"
                                           [checked]="handlerResponsibleCheck('brother')"
                                           (change)="handlerResponsible('brother')">
                                    <label class="custom-control-label" for="brother">Irmão</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="cousins" name="cousins" class="custom-control-input"
                                           [checked]="handlerResponsibleCheck('cousins')"
                                           (change)="handlerResponsible('cousins')">
                                    <label class="custom-control-label" for="cousins">Primos</label>
                                </div>
                                <div class="input-material bg-gray mb-0">
                                    <input id="othersRelative" class="bg-gray form-control" type="text"
                                           maxlength="10000" autocomplete="off" required name="othersRelative"
                                           [ngModel]="handlerResponsibleCheck('others')"
                                           (ngModelChange)="handlerResponsible($event)"/>
                                    <label for="othersRelative">Outros</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                        <div class="input-material">
                            <input id="actualAddress" name="actualAddress" class="form-control" type="text"
                                   maxlength="10000"
                                   autocomplete="off" [(ngModel)]="model.address" required/>
                            <label for="actualAddress">Endereço Atual</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                        <div class="input-material">
                            <input id="cityRelative" name="cityRelative" class="form-control" type="text"
                                   maxlength="10000"
                                   autocomplete="off" [(ngModel)]="model.city" required/>
                            <label for="cityRelative">Cidade</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                        <div class="input-material">
                            <input id="phones" name="phones" class="form-control" type="text" maxlength="10000"
                                   autocomplete="off" mask="(00) 0000-0000||(00) 0 0000-0000"
                                   [(ngModel)]="model.phoneNumber" required/>
                            <label for="phones">Telefone</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                        <div class="input-material">
                            <input id="email" name="email" class="form-control" type="text" maxlength="10000"
                                   autocomplete="off" [(ngModel)]="model.email" required/>
                            <label for="email">E-mail</label>
                        </div>
                    </div>
                </div>

                <div class="col-8 mx-auto mt-4 px-0" *ngIf="!isCaregiverOrTech">
                    <a href="javascript:void(0)" class="btn btn-success btn-ico btn-block" (click)="actionSave()">
                        <svg-icon class="svg-white" src="assets/svg/check-circle.svg"></svg-icon>
                        Salvar
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
