export class ResidentPhysiotherapy {
    id: string;
    walk: string;
    useAssist: string;
    jointPain: string;
    jointPainTime: string;
    didDoesPhysicalActivity: string;
    physicalActivityType: string;
    physicalActivityTime: string;
    physicalActivityStoppedSince: string;
    didDoesPhysiotherapy: string;
    physiotherapyType: string;
    likePhysicalActivity: string;
    orthopedicSurgery: string;
    orthopedicSurgeryType: string[];
    orthopedicSurgeryTime: string[];
    imageExam: string;
    falls: string;
    fallFrequency: string;
    createdAt: Date;
    updatedAt: Date;

    constructor() {
    }
}
