import {Unity} from '../unity/unity';
import {Resident} from '../resident/resident';

export class FilterActivities {
    keyword: string;
    period: string;
    activityName: string;
    residents: Resident[];
    unities: Unity[];
    start: Date;
    end: Date;

    constructor() {
        this.residents = [];
        this.unities = [];
    }
}
