import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {User} from '../../model/user/user';

@Injectable({
    providedIn: 'root'
})
export class NursingService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    get(id) {
        const url = `${this.BASE_URL}/resident/${id}/health`;
        return this.http.get(url);
    }

    create(residentId, model) {
        const url = `${this.BASE_URL}/resident/${residentId}/health`;
        return this.http.post(url, model);
    }

    update(residentId, model) {
        const url = `${this.BASE_URL}/resident/${residentId}/health`;
        return this.http.put(url, model);
    }

    delete(id, idHealth) {
        const url = `${this.BASE_URL}/resident/${id}/health/${idHealth}`;
        return this.http.delete(url);
    }

    // EVALUATION

    getEvaluation(id) {
        const url = `${this.BASE_URL}/resident/${id}/list`;
        return this.http.get(url);
    }

    createEvaluation(residentId, model) {
        const url = `${this.BASE_URL}/resident/health-evolution`;
        return this.http.post(url, model);
    }

    updateEvaluation(residentId, model) {
        const url = `${this.BASE_URL}/resident/health-evolution`;
        return this.http.put(url, model);
    }

    deleteEvaluation(id) {
        const url = `${this.BASE_URL}/resident/${id}/health-evolution`;
        return this.http.delete(url);
    }

}
