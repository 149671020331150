import {Evolution} from './evolution';

export class ResultEvolution {
    totalCount: number;
    pageSize: number;
    currentPage: number;
    totalPage: number;
    previousPage: number;
    nextPage: number;
    results: Evolution[];

    constructor() {
    }
}
