import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../../base/base.component';
import {ResidentPsychology} from '../../../../../model/resident/resident-psychology';
import {Router} from '@angular/router';
import {PsychologyService} from '../../../../../service/resident/psychology.service';
import {Resident} from '../../../../../model/resident/resident';
import {User} from '../../../../../model/user/user';
import {UserService} from '../../../../../service/user.service';

declare var $: any;

@Component({
    selector: 'app-resident-psychology',
    templateUrl: './resident-psychology.component.html',
    styleUrls: ['./resident-psychology.component.scss']
})
export class ResidentPsychologyComponent extends BaseComponent implements OnInit, OnDestroy {

    residentId: string;
    modelList: ResidentPsychology[] = [];
    modelResident: Resident = new Resident();
    model: ResidentPsychology = new ResidentPsychology();
    modelUser: User = new User();
    isCaregiver = false;

    @Input() set resident(val: any) {
        this.residentId = val;
    }

    constructor(public router: Router,
                public userService: UserService,
                public psychologyService: PsychologyService) {
        super(router);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.modelUser = data as User;
                    // ID = ADMIN + PSYCHOLOGIST + NURSE
                    if (this.modelUser.profile?.id !== '87115c40-8ac3-45c0-a70b-4f8b7ebba7bb' && this.modelUser.profile?.id !== 'a4e4f59a-5f21-47e1-9e95-2e6a74239595' && this.modelUser.profile?.id !== 'a5cf1872-07ed-413e-aa7c-fc9c6c05aa15' && this.modelUser.profile?.id !== 'cf17d929-9315-4fdf-90ad-4decd3045b64' ) {
                        $('#psychologyDisabled input').prop('disabled', true);
                        $('#psychologyDisabled select').prop('disabled', true);
                        $('#psychologyDisabled label').addClass('disabled');
                        this.isCaregiver = true;
                    }
                }
            }
        });
        if (!this.isNullOrUndefined(this.residentId)) {
            this.actionDetail();
        }
    }

    ngOnDestroy(): void {
        const a = $('#psychologyModal');
        if (a[0]) {
            a.remove();
        }
    }

    actionDetail() {
        this.psychologyService.get(this.residentId).subscribe({
            next: (data: ResidentPsychology) => {
                if (!this.isNullOrUndefined(data)) {
                    this.model = data as ResidentPsychology;
                }
            }, error: err => super.onError(err)
        });
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.scholarity, text: `Escolaridade, `},
            {value: this.model.profession, text: `Profissão, <br> `},
            {value: this.model.retired, text: `Aposentado, `},
            {value: this.model.talk, text: `Gosta de conversar.`},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        if (this.isNullOrUndefined(this.model.id)) {
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    actionCreate() {
        this.psychologyService.create(this.residentId, this.model).subscribe({
            next: (data: ResidentPsychology) => {
                this.model = new ResidentPsychology();
                this.actionDetail();
                $('#psychologyModal').modal('hide');
                super.showMessage('Sucesso', 'Descrição psicológica cadastrada com sucesso!', 'success');
                this.actionDetail();
            }, error: err => super.onError(err)
        });
    }

    actionUpdate() {
        this.psychologyService.update(this.residentId, this.model).subscribe({
            next: (data: ResidentPsychology) => {
                this.model = new ResidentPsychology();
                this.actionDetail();
                $('#psychologyModal').modal('hide');
                super.showMessage('Sucesso', 'Descrição psicológica atualizada com sucesso!', 'success');
            }, error: err => super.onError(err)
        });
    }

    handlerRegister() {
        this.model = new ResidentPsychology();
        super.showModal('psychologyModal');
        this.actionDetail();
    }

    handlerDetail(model) {
        this.model = Object.assign({}, model);
        super.showModal('psychologyModal');
        this.actionDetail();
    }

    handlerAggNull(val) {
        if (this.isNullOrUndefined(val)) {
            val = 'presNo';
        }
    }

    aggressiveTraduction(val, value) {
        if (val === 'pastYes' && value === 'presYes') {
            return 'Sempre foi';
        } else if (val === 'pastYes' && value === 'presNo') {
            return 'Era no passado';
        } else if (val === 'pastNo' && value === 'presYes') {
            return 'Sim';
        } else if (val === 'pastNo' && value === 'presNo') {
            return 'Nunca foi';
        } else if (val === null && value === 'presNo') {
            return 'Não';
        } else if (val === null && value === 'presYes') {
            return 'Sim';
        }
    }

    talkTraduction(val) {
        switch (val) {
            case 'talkDislike' :
                return 'Não';
            case 'talkPast' :
                return 'No passado sim';
            case 'talkPresent' :
                return 'Sim';
            case 'talkAlways' :
                return 'Sempre gostou';
            default:
                return 'Não';

        }
    }

    delete(id) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar este traço psicológico?', () => {
            this.psychologyService.delete(this.residentId, id).subscribe({
                next: data => {
                    super.showMessage('Sucesso', 'Traço psicológico deletado com sucesso!', 'success');
                    this.actionDetail();
                }, error: err => super.onError(err)
            });
        });
    }

    handlerRequestedFamily(val) {
        if (this.isNullOrUndefined(this.model.requestedFamily)) {
            this.model.requestedFamily = val;
            return;
        }
        const filter = this.model.requestedFamily.split('|').filter(x => x === val);
        if (this.isNullOrUndefined(filter)) {
            this.model.requestedFamily = this.model.requestedFamily + '|' + val;
        } else {
            const requestedFamily = this.model.requestedFamily;
            this.model.requestedFamily = null;
            requestedFamily.split('|').forEach(x => {
                if (x !== val) {
                    this.model.requestedFamily = !this.isNullOrUndefined(this.model.requestedFamily) ?
                        this.model.requestedFamily + '|' + x : x;
                }
            });
        }
    }

    handlerRequestedFamilyCheck(val) {
        if (!this.isNullOrUndefined(this.model.requestedFamily)) {
            return !this.isNullOrUndefined(this.model.requestedFamily.split('|').filter(x => x === val)[0]);
        }
    }

    handlerEducationalLevel(value) {
        this.model.scholarity = value;
    }

    handlerEducationalLevelCheck(value) {
        if (value === 'others' && this.model.scholarity !== '1c'
            && this.model.scholarity !== '2c' && this.model.scholarity !== '1i' && this.model.scholarity !== '2i'
            && this.model.scholarity !== 'an' && this.model.scholarity !== 'sc' && this.model.scholarity !== 'si') {
            return this.model.scholarity;
        } else if (value === '1c' || value === '2c' || value === '1i' || value === '2i' || value === 'an' || value === 'sc' || value === 'si') {
            return value === this.model.scholarity;
        }
    }
}
