import {Activities} from './activities';

export class ResultActivities {
    totalCount: number;
    pageSize: number;
    currentPage: number;
    totalPage: number;
    previousPage: number;
    nextPage: number;
    results: Activities[];

    constructor() {
    }
}
