import {Resident} from './resident';

export class ResultResident {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  totalPage: number;
  previousPage: number;
  nextPage: number;
  results: Resident[];

  constructor() {
  }
}
