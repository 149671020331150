import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';

@Injectable()
export class LogService extends ServiceBase {

  constructor(http: HttpClient) {
    super(http);
  }

  getLog() {
    const url = `${this.BASE_URL}/log/get-total`;
    return this.http.get(url);
  }

  getLogViews(model, page) {
    const url = `${this.BASE_URL}/log/get-logviews/${page}`;
    return this.http.post(url, model);
  }

  getLogFilter(id) {
    const url = `${this.BASE_URL}/log/filter-log`;
    return this.http.post(url, {filterDate: id});
  }
}
