import {Component, Input, OnDestroy, OnInit, AfterViewInit} from '@angular/core';
import {BaseComponent} from '../../../../../base/base.component';
import {Period, ResidentMedicine} from '../../../../../model/resident/resident-medicine';
import {Router} from '@angular/router';
import {MedicineService} from '../../../../../service/resident/medicine.service';
import {User} from '../../../../../model/user/user';
import {UserService} from '../../../../../service/user.service';

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-resident-medicine',
    templateUrl: './resident-medicine.component.html',
    styleUrls: ['./resident-medicine.component.scss']
})
export class ResidentMedicineComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() set resident(val: any) {
        this.residentId = val;
    }

    constructor(public router: Router,
                public medicineService: MedicineService,
                public userService: UserService,
    ) {
        super(router);
    }

    residentId: string;
    model: ResidentMedicine = new ResidentMedicine();
    modelMedicine: ResidentMedicine[] = [];
    isCaregiver = false;
    modelUser: User = new User();
    duplicate = false;

    periodResidentTeste: Period[] = [];

    ngOnInit(): void {
        this.handlerContinuous('temporary');
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.modelUser = data as User;
                    // ID = ADMIN + PHARMACY + NURSE
                    if (this.modelUser.profile?.id !== '87115c40-8ac3-45c0-a70b-4f8b7ebba7bb' && this.modelUser.profile?.id !== '916c5ade-d985-406f-ae3a-8a90f7519e84' && this.modelUser.profile?.id !== 'a5cf1872-07ed-413e-aa7c-fc9c6c05aa15' && this.modelUser.profile?.id !== 'cf17d929-9315-4fdf-90ad-4decd3045b64') {
                        $('#medicineDisabled input').prop('disabled', true);
                        $('#medicineDisabled select').prop('disabled', true);
                        $('#medicineDisabled label').addClass('disabled');
                        this.isCaregiver = true;
                    }
                }
            }
        });
        if (!this.isNullOrUndefined(this.residentId)) {
            this.actionMedicine();
        }
    }

    ngAfterViewInit(): void {
        this.periodResidentTeste = [
            {id: 'Diurno', name: 'Diurno'},
            {id: 'Noturno', name: 'Noturno'},
        ];
    }

    ngOnDestroy(): void {
        const a = $('#registerMedicineR');
        if (a[0]) {
            a.remove();
        }
    }

    setDatePicker() {
        const data = this.isNullOrUndefined(this.model.treatmentStart) ? moment() : moment(this.model.treatmentStart);
        const dataEnd = this.isNullOrUndefined(this.model.treatmentFinish) ? moment() : moment(this.model.treatmentFinish);
        this.model.treatmentStart = data;
        this.model.treatmentFinish = dataEnd;
        $('.js-daterangepicker').daterangepicker({
            showDropdowns: false,
            startDate: data,
            opens: 'left',
            drops: 'up',
            endDate: dataEnd,
            locale: super.objLocaleDateRangePicker()
        }, (start, end, label) => {
            this.model.treatmentStart = start.utcOffset(0, true).format();
            this.model.treatmentFinish = end.utcOffset(0, true).format();
        });
    }

    actionMedicine() {
        this.medicineService.get(this.residentId, 'register').subscribe({
            next: data => {
                this.modelMedicine = data as ResidentMedicine[];
            }, error: err => super.onError(err)
        });
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.name, text: `Medicação, `},
            {value: this.model.type, text: `Tipo <br>`},
            {value: this.model.amount, text: `Posologia,`},
            {value: this.model.period, text: `Período <br>`},
            {value: this.model.prescription, text: `Receita,`},
            {value: this.model.controlledPrescription, text: `Receita Controlada <br>`},
            {value: this.model.continuousUse, text: `Uso Continuo,`},
            {value: this.model.highPriceMedicine, text: `Farmácia de alto custo <br>`},
        ];

        if (this.model.continuousUse === 'temporary') {
            // @ts-ignore
            arrValidateFields.push({value: this.model.treatmentStart, text: `Início do tratamento,`},
                {value: this.model.treatmentFinish, text: `Fim do tratamento <br>`});
        }

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }
        this.model.mode = 'register';

        if (this.isNullOrUndefined(this.model.id)) {
            this.actionCreate();
        }
        if (!this.isNullOrUndefined(this.model.id) && this.duplicate === true) {
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    useTypeTraduction(val) {
        switch (val) {
            case 'tablet' : {
                return 'Comprimido';
            }
            case 'liquid' : {
                return 'Líquido';
            }
            case 'dust' : {
                return 'Pó';
            }
            default: {
                return 'Outros';
            }
        }
    }

    periodTraduction(val) {
        switch (val) {
            case 'morning' : {
                return 'Manhã';
            }
            case 'afternoon' : {
                return 'Tarde';
            }
            case 'evening' : {
                return 'Noite';
            }
            default: {
                return '-';
            }
        }
    }

    timeofTheDayTraduction(val) {
        switch (val) {
            case 'fasting' : {
                return 'Jejum';
            }
            case 'after_breakfast' : {
                return 'Após o café';
            }
            case 'before_lunch' : {
                return 'Antes do almoço';
            }
            case 'lunch' : {
                return 'No Almoço';
            }
            case 'after_lunch' : {
                return 'Após o almoço';
            }
            case 'before_dinner' : {
                return 'Antes do Jantar';
            }
            case 'after_dinner' : {
                return 'Após o Jantar';
            }
            case 'after_supper' : {
                return 'Após a Ceia';
            }
            default: {
                return '-';
            }
        }
    }

    useTimeTraduction(val) {
        switch (val) {
            case 'temporary' : {
                return 'Temporário';
            }
            case 'continuous' : {
                return 'Contínuo';
            }
        }
    }

    actionCreate() {
        this.medicineService.create(this.residentId, this.model).subscribe({
            next: (data: ResidentMedicine) => {
                this.model = new ResidentMedicine();
                $('#registerMedicineR').modal('hide');
                super.showMessage('Sucesso', 'Medicamento cadastrado com sucesso!', 'success');
                this.actionMedicine();
            }, error: err => super.onError(err)
        });
    }

    actionUpdate() {
        this.medicineService.update(this.residentId, this.model).subscribe({
            next: (data: ResidentMedicine) => {
                this.model = new ResidentMedicine();
                $('#registerMedicineR').modal('hide');
                super.showMessage('Sucesso', 'Medicamento atualizado com sucesso!', 'success');
                this.actionMedicine();
            }, error: err => super.onError(err)
        });
    }

    handlerDetail(model) {
        this.model = Object.assign({}, model);
        $('#registerMedicineR').modal('show');

        if (this.model.continuousUse === 'temporary') {
            this.setDatePicker();
        }
        this.duplicate = false;
    }

    handlerDuplicate(model) {
        this.model = Object.assign({}, model);
        $('#registerMedicineR').modal('show');

        if (this.model.continuousUse === 'temporary') {
            this.setDatePicker();
        }
        this.duplicate = true;
    }

    delete(id) {
        super.confirmMessage('Atenção', 'Deseja realmente deletar este medicamento ?', () => {
            this.medicineService.delete(this.residentId, id).subscribe({
                next: data => {
                    super.showMessage('Sucesso', 'Medicamento deletado com sucesso!', 'success');
                    this.actionMedicine();
                }, error: err => super.onError(err)
            });
        });
    }

    handlerButton() {
        this.model = new ResidentMedicine();
        $('#registerMedicineR').modal('show');
    }

    handlerContinuous(val) {
        if (val === 'temporary') {
            this.model.continuousUse = val;
            this.setDatePicker();
        } else {
            this.model.continuousUse = val;
            this.model.treatmentStart = null;
            this.model.treatmentFinish = null;
            $('#date').val('');
        }
    }
}
