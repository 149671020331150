import {Unity} from '../unity/unity';
import {Resident} from '../resident/resident';
import {User} from '../user/user';

export class Activities {
    unity: Unity;
    activityType: string;
    id: string;
    name: string;
    period: string;
    dateStart: string;
    dateEnd: string;
    updateAt: Date;
    createdAt: Date;
    description: string;
    residents: Resident[];
    user: User;

    constructor() {
        this.unity = new Unity();
        this.residents = [];
    }
}
