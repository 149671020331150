<div class="bg-gray pt-3">
    <nav class="mb-4 px-3">
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a class="nav-item nav-link active" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab"
               aria-controls="nav-profile" aria-selected="false">Perfil</a>
            <a class="nav-item nav-link " id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab"
               aria-controls="nav-home" aria-selected="true">Grau de Escolaridade</a>
            <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab"
               aria-controls="nav-contact" aria-selected="false">Histórico Social</a>
        </div>
    </nav>
</div>

<div class="card-body" id="profileDisabled">
    <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
            <div class="row">
                <div class="col-lg-4 mx-0 ">
                    <div class="col-12 mb-3 text-center">
                        <img [src]="isNullOrUndefined(thumpPath) ? 'https://via.placeholder.com/120' : thumpPath"
                             class="photo-edit" alt="...">
                    </div>
                    <form *ngIf="!isCaregiverOrTech">
                        <div class="form-group mt-4">
                            <label for="FormControlFile2" class="btn btn-block btn-outline-primary">
                                <span>Alterar Imagem</span>
                            </label>
                            <input type="file" class="form-control-file d-none" id="FormControlFile2"
                                   onclick="this.value = null" (change)="handlerUpload($event)">
                        </div>
                    </form>
                </div>
                <div class="col-lg-8 row mx-0">
                    <div class="col-sm-12">
                        <div class="input-material">
                            <input id="name" name="name" class="form-control" type="text" maxlength="10000"
                                   autocomplete="off"
                                   [(ngModel)]="model.name" required/>
                            <label for="name">Nome</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <div class="input-material">
                            <input id="birthday" class="form-control" type="text" maxlength="10000" autocomplete="off"
                                   required
                                   [dropSpecialCharacters]="false" [(ngModel)]="dateOfBirth" mask="00/00/0000"
                                   name="birthday"/>
                            <label for="birthday">Data de Nascimento</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3 col-lg-3">
                        <div class="input-material">
                            <input id="age" class="form-control" type="text" maxlength="10000" autocomplete="off"
                                   required disabled
                                   [ngModel]="handlerAge(dateOfBirth)"
                                   name="age"/>
                            <label class="disabled" for="age">Idade</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3 col-lg-3">
                        <div class="col-sm-12 col-md-12 col-lg-3">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="customSwitch1"
                                       [checked]="model.judicialInterdiction === 'yes'"
                                       (change)="handlerJudicial(model.judicialInterdiction)">
                                <label class="custom-control-label font-weight-bold" for="customSwitch1">Interdição
                                    Judicial</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <div class="input-material">
                            <input id="RG" class="form-control" type="text" maxlength="10000" autocomplete="off"
                                   required
                                   [(ngModel)]="model.rg" mask="00.000.000-0"
                                   name="RG"/>
                            <label for="RG">RG</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <div class="input-material">
                            <input id="CPF" class="form-control" type="text" maxlength="10000" autocomplete="off"
                                   required
                                   [(ngModel)]="model.cpf" mask="000.000.000-00"
                                   name="CPF"/>
                            <label for="CPF">CPF</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <div class="input-material">
                            <select id="unity" name="unity" [(ngModel)]="model.unity.id"
                                    (ngModelChange)="handlerSelectedUnity(model.unity)">
                                <option [ngValue]="undefined">Selecione</option>
                                <option *ngFor="let u of listUnity" [ngValue]="u.id">{{u.name}}</option>
                            </select>
                            <label for="unity">Unidade</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <div class="input-material">
                            <select id="status" name="status" [(ngModel)]="model.status">
                                <option [ngValue]="undefined">Selecione</option>
                                <option *ngFor="let s of listStatusResident" [ngValue]="s.id ">{{s.name}}</option>
                            </select>
                            <label for="status">Status</label>
                        </div>
                    </div>
                    <div class="col-12 my-3 bg-gray rounded p-3">
                        <p class="text-gray-primary mb-0">Datas</p>
                        <div class="row  ">
                            <div class="col-sm-6">
                                <div class="input-material mb-0 bg-gray">
                                    <input id="internationDate" name="internationDate" type="text"
                                           class="form-control bg-gray js-admissionDate"
                                           mask="00/00/0000" [dropSpecialCharacters]="false" required/>
                                    <label for="internationDate">Internação</label>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="input-material mb-0 bg-gray">
                                    <input id="outDate" type="text" name="outDate"
                                           class="form-control bg-gray js-exitDate"
                                           mask="00/00/0000" [dropSpecialCharacters]="false" required/>
                                    <label for="outDate">Saída</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="input-material">
                        <select [(ngModel)]="model.riskLevel" id="retired" name="retired"
                                class="form-control" required>
                            <option [ngValue]="'low'">Baixo</option>
                            <option [ngValue]="'medium'">Médio</option>
                            <option [ngValue]="'high'">Alto</option>
                        </select>
                        <label for="retired">Nível de Risco</label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="input-material">
                        <input id="religion" name="religion" class="form-control" type="text" maxlength="10000"
                               autocomplete="off" [(ngModel)]="model.religion" required/>
                        <label for="religion">Religião</label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                        <div class="col-12">
                            <p class="text-gray-primary mb-1">Aposentado(a)</p>
                        </div>
                        <div class="col-12">
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="retiredYes" name="retiredYes" class="custom-control-input"
                                       [checked]="model.retired === 'yes'" (change)="model.retired = 'yes'">
                                <label class="custom-control-label" for="retiredYes">Sim</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="retiredNo" name="retiredNo" class="custom-control-input"
                                       [checked]="model.retired === 'no'" (change)="model.retired = 'no'">
                                <label class="custom-control-label" for="retiredNo">Não</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                        <div class="col-4">
                            <p class="text-gray-primary mb-0">Sexo</p>
                        </div>
                        <div class="col-8">
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="male" name="male" class="custom-control-input"
                                       [checked]="handlerGenderCheck('male')"
                                       (change)="handlerGender('male')">
                                <label class="custom-control-label" for="male">M</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="female" name="female" class="custom-control-input"
                                       [checked]="handlerGenderCheck('female')"
                                       (change)="handlerGender('female')">
                                <label class="custom-control-label" for="female">F</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-8 col-lg-8">
                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                        <div class="col-3">
                            <p class="text-gray-primary mb-0">Cor</p>
                        </div>
                        <div class="col-9 row align-items-center">
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="white" name="white" class="custom-control-input"
                                       [checked]="handlerSkinToneCheck('white')"
                                       (change)="handlerSkinTone('white')">
                                <label class="custom-control-label" for="white">Branca</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="black" name="black" class="custom-control-input"
                                       [checked]="handlerSkinToneCheck('black')"
                                       (change)="handlerSkinTone('black')">
                                <label class="custom-control-label" for="black">Negra</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="yellow" name="yellow" class="custom-control-input"
                                       [checked]="handlerSkinToneCheck('yellow')"
                                       (change)="handlerSkinTone('yellow')">
                                <label class="custom-control-label" for="yellow">Amarela </label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="brown" name="brown" class="custom-control-input"
                                       [checked]="handlerSkinToneCheck('brown')"
                                       (change)="handlerSkinTone('brown')">
                                <label class="custom-control-label" for="brown">Parda </label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="mulatto" name="mulatto" class="custom-control-input"
                                       [checked]="handlerSkinToneCheck('mulatto')"
                                       (change)="handlerSkinTone('mulatto')">
                                <label class="custom-control-label" for="mulatto">Mulata </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                        <div class="col-3">
                            <p class="text-gray-primary mb-0">Estado Civil</p>
                        </div>
                        <div class="col-9 row align-items-center">
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="married" name="married" class="custom-control-input"
                                       [checked]="handlerMaritalStatusCheck('married')"
                                       (change)="handlerMaritalStatus('married')">
                                <label class="custom-control-label" for="married">Casado(a)</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="single" name="single" class="custom-control-input"
                                       [checked]="handlerMaritalStatusCheck('single')"
                                       (change)="handlerMaritalStatus('single')">
                                <label class="custom-control-label" for="single">Solteiro(a)</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="widow" name="widow" class="custom-control-input"
                                       [checked]="handlerMaritalStatusCheck('widow')"
                                       (change)="handlerMaritalStatus('widow')">
                                <label class="custom-control-label" for="widow">Viúvo(a)</label>
                            </div>
                            <div class="input-material bg-gray mb-0">
                                <input id="others" class="bg-gray form-control" type="text" maxlength="10000"
                                       autocomplete="off" required name="others"
                                       [ngModel]="handlerMaritalStatusCheck('others')"
                                       (ngModelChange)="handlerMaritalStatus($event)"
                                />
                                <label for="others">Outros</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                        <div class="col-12">
                            <p class="text-gray-primary">Onde Nasceu?</p>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="input-material bg-gray mb-0">
                                <select id="stateBorn" class="form-control bg-gray" name="stateBorn"
                                        [(ngModel)]="model.bornCity?.state.id" (ngModelChange)="handlerSelectedStateBorn()">
                                    <option [ngValue]="undefined">Selecione</option>
                                    <option *ngFor="let s of listStateBorn" [ngValue]="s.id">{{s.name}}</option>
                                </select>
                                <label for="stateBorn">Estado</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="input-material bg-gray mb-0">
                                <select id="cityBorn" class="form-control bg-gray" name="cityBorn"
                                        [(ngModel)]="model.bornCity.id"
                                        (ngModelChange)="handlerSelectedCityBorn(model.bornCity)">
                                    <option [ngValue]="undefined">Selecione</option>
                                    <option *ngFor="let c of listCityBorn" [ngValue]="c.id">{{c.name}}</option>
                                </select>
                                <label for="cityBorn">Cidade</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                        <div class="col-sm-12 col-md-8 col-lg-8">
                            <div class="input-material bg-gray">
                                <input id="currentAddress" class="form-control bg-gray" type="text" maxlength="10000"
                                       autocomplete="off"
                                       name="currentAddress" [(ngModel)]="model.address" required/>
                                <label for="currentAddress">Endereço Atual</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-4 col-lg-4">
                            <div class="input-material bg-gray">
                                <input id="howMuchTime" class="form-control bg-gray" type="text" maxlength="10000"
                                       autocomplete="off"
                                       name="howMuchTime" [(ngModel)]="model.cityTime" required/>
                                <label for="howMuchTime">Quanto Tempo?</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="input-material bg-gray mb-0">
                                <select id="state" class="form-control bg-gray" name="state"
                                        [(ngModel)]="model.city?.state.id"
                                        (ngModelChange)="handlerSelectedState()">
                                    <option [ngValue]="undefined">Selecione</option>
                                    <option *ngFor="let s of listState" [ngValue]="s.id">{{s.name}}</option>
                                </select>
                                <label for="state">Estado</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="input-material bg-gray mb-0">
                                <select id="city" class="form-control bg-gray" name="city" [(ngModel)]="model.city.id"
                                        (ngModelChange)="handlerSelectedCity(model.city)">
                                    <option [ngValue]="undefined">Selecione</option>
                                    <option *ngFor="let c of listCity" [ngValue]="c.id">{{c.name}}</option>
                                </select>
                                <label for="city">Cidade</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                        <div class="col-3">
                            <p class="text-gray-primary mb-0">Zona</p>
                        </div>
                        <div class="col-9 d-flex justify-content-between">
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="urban" name="urban" class="custom-control-input"
                                       [checked]="handlerZoneCheck('urban')"
                                       (change)="handlerZone('urban')" required>
                                <label class="custom-control-label" for="urban">Urbana</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-2">
                                <input type="radio" id="rural" name="rural" class="custom-control-input"
                                       [checked]="handlerZoneCheck('rural')"
                                       (change)="handlerZone('rural')" required>
                                <label class="custom-control-label" for="rural">Rural</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                        <div class="col-5">
                            <p class="text-gray-primary mb-0">Local Agitado?</p>
                        </div>
                        <div class="col-7 d-flex justify-content-between">
                            <div class="custom-control custom-radio custom-control-inline m-0">
                                <input type="radio" id="busyYes" name="busyYes" class="custom-control-input"
                                       [checked]="handlerAgitatedPlaceCheck('yes')"
                                       (change)="handlerAgitatedPlace('yes')" required>
                                <label class="custom-control-label" for="busyYes">Sim</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline m-0">
                                <input type="radio" id="busyNo" name="busyNo" class="custom-control-input"
                                       [checked]="handlerAgitatedPlaceCheck('no')"
                                       (change)="handlerAgitatedPlace('no')" required>
                                <label class="custom-control-label" for="busyNo">Não</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="tab-pane fade" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                        <div class="col-12">
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="1c" name="male" class="custom-control-input"
                                       [checked]="handlerEducationalLevelCheck('1c')"
                                       (change)="handlerEducationalLevel('1c')">
                                <label class="custom-control-label" for="1c">1º Grau Completo</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="2c" name="female" class="custom-control-input"
                                       [checked]="handlerEducationalLevelCheck('2c')"
                                       (change)="handlerEducationalLevel('2c')">
                                <label class="custom-control-label" for="2c">2º Grau Completo</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="1i" name="female" class="custom-control-input"
                                       [checked]="handlerEducationalLevelCheck('1i')"
                                       (change)="handlerEducationalLevel('1i')">
                                <label class="custom-control-label" for="1i">1º Grau Incompleto</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="2i" name="female" class="custom-control-input"
                                       [checked]="handlerEducationalLevelCheck('2i')"
                                       (change)="handlerEducationalLevel('2i')">
                                <label class="custom-control-label" for="2i">2º Grau Incompleto</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="an" name="female" class="custom-control-input"
                                       [checked]="handlerEducationalLevelCheck('an')"
                                       (change)="handlerEducationalLevel('an')">
                                <label class="custom-control-label" for="an">Analfabeto</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="sc" name="female" class="custom-control-input"
                                       [checked]="handlerEducationalLevelCheck('sc')"
                                       (change)="handlerEducationalLevel('sc')">
                                <label class="custom-control-label" for="sc">Superior Completo</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="si" name="female" class="custom-control-input"
                                       [checked]="handlerEducationalLevelCheck('si')"
                                       (change)="handlerEducationalLevel('si')">
                                <label class="custom-control-label" for="si">Superior Incompleto</label>
                            </div>
                            <div class="input-material bg-gray mb-0">
                                <input id="educationalLevelOthers" class="bg-gray form-control" type="text"
                                       maxlength="10000" autocomplete="off" required name="educationalLevelOthers"
                                       [ngModel]="handlerEducationalLevelCheck('others')"
                                       (ngModelChange)="handlerEducationalLevel($event)"/>
                                <label for="educationalLevelOthers">Outros</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="bg-gray rounded py-3 row mx-0 align-items-center">
                        <div class="col-12">
                            <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                                <div class="col-12">
                                    <div class="input-material bg-gray mb-0">
                                        <input id="profession1" class="form-control bg-gray" type="text"
                                               maxlength="10000"
                                               autocomplete="off" [(ngModel)]="model.occupation" required/>
                                        <label for="profession1">Profissão</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                        <div class="col-sm-3">
                            <p class="text-gray-primary mb-0">Tipo de Residência</p>
                        </div>
                        <div class="col-sm-9 d-flex align-items-center">
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="pro" name="pro" class="custom-control-input"
                                       [checked]="handlerTypeOfResidenceCheck('pro')"
                                       (change)="handlerTypeOfResidence('pro')">
                                <label class="custom-control-label" for="pro">Própria</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="alu" name="alu" class="custom-control-input"
                                       [checked]="handlerTypeOfResidenceCheck('alu')"
                                       (change)="handlerTypeOfResidence('alu')">
                                <label class="custom-control-label" for="alu">Alugada</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline mb-0">
                                <input type="radio" id="ced" name="ced" class="custom-control-input"
                                       [checked]="handlerTypeOfResidenceCheck('ced')"
                                       (change)="handlerTypeOfResidence('ced')">
                                <label class="custom-control-label" for="ced">Cedida</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="input-material">
                        <input id="total" class="form-control" type="text" maxlength="10000" autocomplete="off"
                               [(ngModel)]="model.numberOfPeopleInTheHouse" required/>
                        <label for="total">Número total de pessoas na casa</label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                        <div class="col-12">
                            <p class="text-gray-primary mb-0 text-align-center">Mora sozinho?</p>
                        </div>
                        <div class="row">
                            <div class="col-12 ml-3 d-flex align-items-center">
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="historyYes" name="historyYes"
                                           class="custom-control-input"
                                           [checked]="handlerLiveAloneCheck('yes')"
                                           (change)="handlerLiveAlone('yes')">
                                    <label class="custom-control-label" for="historyYes">Sim</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="historyNo" name="historyNo" class="custom-control-input"
                                           [checked]="handlerLiveAloneCheck('no')"
                                           (change)="handlerLiveAlone('no')">
                                    <label class="custom-control-label" for="historyNo">Não</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                        <div class="col-12">
                            <p class="text-gray-primary mb-0">Mora com Parentes?</p>
                        </div>
                        <div class="row">
                            <div class="col-12 ml-3 d-flex align-items-center">
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="relativeYes" name="relativeYes"
                                           class="custom-control-input"
                                           [checked]="handlerLivesRelativesCheck('yes')"
                                           (change)="handlerLivesRelatives('yes')">
                                    <label class="custom-control-label" for="relativeYes">Sim</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="relativeNo" name="relativeNo"
                                           class="custom-control-input"
                                           [checked]="handlerLivesRelativesCheck('no')"
                                           (change)="handlerLivesRelatives('no')">
                                    <label class="custom-control-label" for="relativeNo">Não</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                        <div class="col-12">
                            <p class="text-gray-primary mb-0">Mora com Cuidadores?</p>
                        </div>
                        <div class="row">
                            <div class="col-12 ml-3 d-flex align-items-center">
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="caregiversYes" name="caregiversYes"
                                           class="custom-control-input"
                                           [checked]="handlerLivesCaregiversCheck('yes')"
                                           (change)="handlerLivesCaregivers('yes')">
                                    <label class="custom-control-label" for="caregiversYes">Sim</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="caregiversNo" name="caregiversNo"
                                           class="custom-control-input"
                                           [checked]="handlerLivesCaregiversCheck('no')"
                                           (change)="handlerLivesCaregivers('no')">
                                    <label class="custom-control-label" for="caregiversNo">Não</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12  col-md-4 col-lg-4">
                    <div class="bg-gray rounded py-3 row mx-0 mb-3 align-items-center">
                        <div class="col-12">
                            <p class="text-gray-primary mb-0">Possui animais Domésticos?</p>
                        </div>
                        <div class="row">
                            <div class="ml-3 col-12  d-flex align-items-center">
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="petYes" name="petYes" class="custom-control-input"
                                           [checked]="handlerPetsCheck('yes')"
                                           (change)="handlerPets('yes')">
                                    <label class="custom-control-label" for="petYes">Sim</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline mb-0">
                                    <input type="radio" id="petNo" name="petNo" class="custom-control-input"
                                           [checked]="handlerPetsCheck('no')"
                                           (change)="handlerPets('no')">
                                    <label class="custom-control-label" for="petNo">Não</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="input-material">
                        <input id="filhos" class="form-control" type="text" maxlength="10000" autocomplete="off"
                               [(ngModel)]="model.descendants" required/>
                        <label for="filhos">Numero total de Filhos</label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-12">
                    <div class="input-material">
                        <input id="filhosvivos" class="form-control" type="text" maxlength="10000" autocomplete="off"
                               [(ngModel)]="model.aliveDescendent" required/>
                        <label for="filhosvivos">Numero total de Filhos Vivos</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xs-5 ml-auto mt-5" *ngIf="!isCaregiverOrTech">
        <a href="javascript:void(0)" class="btn btn-success btn-ico ml-2 btn-block" (click)="actionSave()">
            <svg-icon class="svg-white" src="assets/svg/check-circle.svg"></svg-icon>
            Salvar
        </a>
    </div>
</div>
