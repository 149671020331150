import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {UserService} from '../../service/user.service';
import {User} from '../../model/user/user';

declare var $: any;

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent extends BaseComponent implements OnInit, AfterViewInit {

    model: User = new User();

    constructor(public router: Router,
                public  userService: UserService) {
        super(router);
    }

    ngOnInit(): void {
        moment.locale('pt-br');
        this.getMe();
    }

    ngAfterViewInit(): void {
        $(document).on('show.bs.modal', '.modal', function() {
            $(this).appendTo('body');
        });
    }

    getMe() {
        this.userService.getMe().subscribe({
            next: data => {
                this.model = data as User;
                this.userService.updateUser(this.model);
            }, error: err => super.onError(err)
        });
    }
}
