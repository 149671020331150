import {ResidentHealths} from './resident-healths';
import {ResidentVital} from './resident-vital';
import {ResidentMedicine} from './resident-medicine';
import {ResidentPsychology} from './resident-psychology';
import {ResidentResponsible} from './resident-responsible';
import {ResidentPhysiotherapy} from './resident-physiotherapy';
import {ResidentSkinIntegrity} from './resident-skin-integrity';
import {ResidentAllergies} from './resident-allergies';
import {ResidentNutrition} from './resident-nutrition';
import {City} from '../city';
import {Unity} from '../unity/unity';

export class Resident {
    id: string;
    name: string;
    birthday: Date;
    cpf: string;
    rg: string;
    room: string;
    bed: string;
    height: string;
    weight: string;
    collectionMethod: string;
    createdAt: Date;
    updatedAt: Date;
    admissionDate: Date;
    exitDate: Date;
    gender: string;
    maritalStatus: string;
    status: string;
    address: string;
    residenceTime: string;
    zone: string;
    agitatedPlace: string;
    skinTone: string;
    educationDegree: string;
    religion: string;
    riskLevel: string;
    occupation: string;
    retired: string;
    judicialInterdiction: string;
    typeOfResidence: string;
    numberOfPeopleInTheHouse: string;
    cityTime: string;
    liveAlone: string;
    livesWithRelatives: string;
    livesWithCaregiver: string;
    pets: string;
    descendants: string;
    aliveDescendent: string;
    sweetener: string;
    sweetenerType: string;
    alcohol: string;
    alcoholType: string;
    alcoholDay: string;
    alcoholSince: string;
    alcoholUpset: string;
    habitualDrink: string;
    removeFat: string;
    removeSkin: string;
    salt: string;
    chili: string;
    chiliType: string;
    meatMethod: string;
    meatWeekly: string;
    favoriteFood: string;
    foodAversion: string;
    foodAversionType: string;
    supplement: string;
    supplementName: string;
    numberMealsADay: string;
    outsideHomeWeeklyMeals: string;
    anemia: string;
    anemiaSince: string;
    dyslipidemia: string;
    dyslipidemiaSince: string;
    restaurant: string;
    restaurantType: string;
    sugar: string;
    sugarType: string;
    picture: string;
    monthlyPayment: number;
    city: City;
    bornCity: City;
    unity: Unity;
    residentHealth: ResidentHealths;
    residentVitalSigns: ResidentVital[];
    residentMedicines: ResidentMedicine[];
    residentPsychology: ResidentPsychology;
    residentResponsibles: ResidentResponsible[];
    residentPhysiotherapy: ResidentPhysiotherapy;
    residentSkinIntegrities: ResidentSkinIntegrity[];
    residentAllergy: ResidentAllergies;
    residentNutrition: ResidentNutrition;

    constructor() {
        this.bornCity = new City();
        this.city = new City();
        this.unity = new Unity();
    }
}
